import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown, CustomTextField } from '../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../utils/axios';
import { firstDay2MonthsAgo, firstDayLastMonth, formatDate, formatDateDMY, lastDay } from '../../utils/date';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';
import { DownloadForm } from '../../components/DownloadForm';
const PAGE_TITLE = "Báo cáo doanh thu nhân viên so sánh tháng";

let userList=[],roleList=[]
const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getUserList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/users/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/user-roles/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Reports_Full')){
        result.add("all")
        result.add("group")
        result.add("other")
    }
    if (fncCode.includes('Reports_Read_Family')){
        result.add("group")
        result.add("other")
    }
    return result
}

const UserRevenue = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [chanelList, setChanelList] = React.useState([]);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });
    const [columns, setColumns] = React.useState([]);
    const [month, setMonth] = React.useState(locParams ? locParams.month : 2);
    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [channelId, setChannelId] = React.useState(locParams ? locParams.channelId : '');
    const permisson = React.useMemo(()=>parseFunctionCode(localStorage.getItem('functionCodes')),[])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [staffFilterType,setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh,setRefresh] = React.useState(0)


    //init
    React.useEffect(() => {
        if (!locParams) {
            setIsFirstLoad(true);
        }
        getUserList(permisson,localStorage.getItem('userId'))
        .then(res=>{
            const tmp = res?.items || []
            userList = tmp.map(x=>({key:''+x.userId,text: x.userName}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoleList(permisson,localStorage.getItem('roleId')).then(res=>{
            const tmp = res || []
            roleList = tmp.map(x=>({key:x.roleNode,text: x.name}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
    }, []);

    React.useEffect(() => {
        getAxios('channels', null, response => {
            if (response.data && response.data) {
                const chanelList = response.data.map(x => ({ key: x.channelId, text: x.name }));
                chanelList.unshift({ key: '', text: 'Tất cả' });
                setChanelList(chanelList);
            }
        });

        if (locParams) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
    }, [isFirstLoad]);


    const options = {
        monthRange: [
            { key: 2, text: '2 tháng' },
            { key: 3, text: '3 tháng' }
        ]
    }

    const buildDetailviewColumns = () => {
        let newColumns = [];
        const monthCalc = new Date();
        monthCalc.setDate(1)

        for (let i = 0; i < month; i++) {
            const name = (monthCalc.getMonth() + 1) + '/' + monthCalc.getFullYear();
            const groupColumn = {
                name: name, key: `group${month-i}`, fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                    { name: 'Số lượng', key: `total${month-i}`, fieldName: `total${month-i}`, minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                    { name: 'Số tiền', key: `amount${month-i}`, fieldName: `amount${month-i}`, minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
                ]
            };

            newColumns.unshift(groupColumn);
            monthCalc.setMonth(monthCalc.getMonth()-1)
        }

        const groupColumnTotal = {
            name: "Tổng", key: 'Group12', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                { name: 'Số lượng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
            ]
        };
        newColumns.push(groupColumnTotal);
        return [...baseColumns, ...newColumns];
    }
    let forwardParams = {}
    //function
    const post = (page, firstLoad) => {
        setIsLoading(true);

        const date = new Date();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        const firstDayLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        const firstDay2MonthsAgo = new Date(date.getFullYear(), date.getMonth() - 2, 1);

        let endDate = '';
        let beginDate = '';

        if (month == 2) {
            endDate = lastDay;
            beginDate = firstDayLastMonth;
        }
        else {
            endDate = lastDay;
            beginDate = firstDay2MonthsAgo;
        }


        const params = {
            staffFilterType,
            channelId: channelId,
            page: page + 1,
            pageSize: pageSize,
            beginDate: formatDate(beginDate),
            endDate: formatDate(endDate),
            month: month,
            toogleFilter: filter.value
        };

        if (staffFilterType=="group"){
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            params.userId = userId
        } else if (staffFilterType=="me"){
            params.userId = localStorage.getItem('userId')
        }

        if (!firstLoad) {
            pushHistory(history, '/report/user-revenue', params);
        } else if (!permisson.has('all')) {
            params.userId = localStorage.getItem('userId')
        }

        getAxios('reports/user-revenue-compares-by-month', params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data;

                    const columns = buildDetailviewColumns();
                    setColumns(columns);
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const baseColumns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Họ tên', key: 'userName', fieldName: 'userName', minWidth: 280, maxWidth: 280, isResizable: true },
    ];

    let onExportClick=null
    if (gridData.items?.length>0)
        onExportClick = ()=>{
        let beginDate = '';
        let endDate = '';

        
        if (month == 2) {
            endDate = lastDay;
            beginDate = firstDayLastMonth;
        }
        else {
            endDate = lastDay;
            beginDate = firstDay2MonthsAgo;
        }

        let params = {
            beginDate: formatDate(beginDate),
            endDate: formatDate(endDate),
            channelId: channelId
        };

        if (staffFilterType=="group"){
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            params.userId = userId
        } else if (staffFilterType=="me"){
            params.userId = localStorage.getItem('userId')
        }
        
        getFile('/reports/user-revenue-compares-by-month/excel', params, response => {
            const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
            DownloadForm(response.data,filename)
        });
    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>

                {refresh == 2 &&<>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList} 
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                    />
                </>}
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kênh đăng"
                        width={250}
                        height={350}
                        options={chanelList}
                        value={channelId}
                        onChange={setChannelId}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Khoảng thời gian"
                        width={250}
                        height={350}
                        options={options.monthRange}
                        value={month}
                        onChange={setMonth}
                    />
                </div>

            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text:  `${PAGE_TITLE}`}}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        isReport={true}
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}
export default UserRevenue;
