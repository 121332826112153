import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';

import { getClasses } from './styles';

export default function DropDown({
    selectedContainerCss = {},
    dropdownListCss = {},
    dropdownSelectedCss = {},
    label = '',
    labelCss = {},
    required = false,
    formControlCss = {},
    ...restProps
}) {
    const {
        formControlStyles,
        optionalFormControl,
        dropdownStyles,
        optionalDropdownStyles,
        optionalLabelStyles,
        labelStyles,
    } = getClasses({
        formControlCss,
        selectedContainerCss,
        labelCss,
        dropdownSelectedCss,
        dropdownListCss,
    });

    return (
        <div className={`${formControlStyles} ${optionalFormControl}`}>
            {label && (
                <label className={`${labelStyles} ${optionalLabelStyles}`}>
                    {label} {required ? <span style={{ color: 'red' }}>*</span> : ''}
                </label>
            )}
            <div className={`${dropdownStyles} ${optionalDropdownStyles}`}>
                <Dropdown {...restProps} />
            </div>
        </div>
    );
}

DropDown.propTypes = {
    onChange: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    dropdownCss: PropTypes.object,
};
