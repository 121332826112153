import React from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import CustomDetailView from '../../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../../utils/helpers';
import { CustomDropdown, CustomTextField } from '../../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../../utils/axios';
import FilterButton from '../../../components/Buttons/FilterButon';
import FilterPanel from '../../../components/FilterPanel/FilterPanel';
import StaffFilter2 from '../../../components/StaffFilter/StaffFilter2';
import { isAgentOptions } from '../../../utils/constants';
import DateFilterFormByStatus from '../../../components/DateFilter/DateFilterFormByStatus';
import ExpirationServiceDetails from './details';
import ChannelProductFilter from './ChannelProductFilter';
import { formatDateDMY } from '../../../utils/date';
import { DownloadForm } from '../../../components/DownloadForm';

const PAGE_TITLE = "Báo cáo dịch vụ sắp hết hạn tổng hợp";

let userList = [], roleList = [], channelList = [], first_Channel = true
const getApi = async (url, param) => {
    return new Promise((res, rej) => {
        getAxios(url, param, (response) => {
            if (response) {
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getUserList = async (permisson, id) => {
    if (permisson.has('all')) {
        return getApi('/users/', { pageSize: 10000000 }).then((res) => {
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson, id) => {
    if (permisson.has('all')) {
        return getApi('/user-roles/', { pageSize: 10000000 }).then((res) => {
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Reports_Full')) {
        result.add("all")
        result.add("group")
        result.add("other")
    }
    if (fncCode.includes('Reports_Read_Family')) {
        result.add("group")
        result.add("other")
    }
    return result
}

const useIsReadyHook = () => {

    const [Staff, setStaff] = React.useState(false)
    const [Status, setStatus] = React.useState(false)
    const [ChannelProduct, setChannelProduct] = React.useState(false)

    return [
        { Staff, Status, ChannelProduct },
        { setStaff, setStatus, setChannelProduct }
    ]
}

const ExpirationServices = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isReady, setIsReady] = useIsReadyHook()

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [isAgent, setIsAgent] = React.useState(locParams ? locParams.isAgent : '');

    const [date, setDate] = React.useState(
        locParams ? { from: locParams.from, to: locParams.to, key: locParams.dateKey } : { from: "1", to: "7", key: "1" }
    );

    const [channelId, setChannelId] = React.useState(locParams?.channelId)
    const [productId, setProductId] = React.useState(locParams?.productId)

    const permisson = React.useMemo(() => parseFunctionCode(localStorage.getItem('functionCodes')), [])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [staffFilterType, setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh, setRefresh] = React.useState(0)

    //init
    React.useEffect(() => {
        getUserList(permisson, localStorage.getItem('userId'))
            .then(res => {
                const tmp = res?.items || []
                userList = tmp.map(x => ({ key: '' + x.userId, text: x.userName }))
            }).catch(console.log).finally(() => setRefresh(a => a + 1))
        getRoleList(permisson, localStorage.getItem('roleId')).then(res => {
            const tmp = res || []
            roleList = tmp.map(x => ({ key: x.roleNode, text: x.name }))
        }).catch(console.log).finally(() => setRefresh(a => a + 1))
    }, []);

    React.useEffect(() => {
        if (isReady.ChannelProduct && isReady.Status && isReady.Staff) {
            post(0, true);
        }
    }, [isReady.ChannelProduct, isReady.Status, isReady.Staff]);

    //function
    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            staffFilterType,
            productId,
            isAgent,
            channelId: channelId,
            page: page + 1,
            pageSize: pageSize,
            from: date.from,
            to: date.to,
            dateKey: date.key,
            beginDate: date.beginDate,
            endDate: date.endDate,
            toogleFilter: filter.value
        };

        if (staffFilterType == "group") {
            params.roleNode = roleNode
        } else if (staffFilterType == "other") {
            params.userId = userId
        } else if (staffFilterType == "me") {
            params.userId = localStorage.getItem('userId')
        }

        if (!firstLoad) {
            pushHistory(history, '/report/expiration-services', params);
        }

        getAxios('reports/expiration-services', params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const actionClick = (action, value, item, event) => {
        const params = {
            productId,
            isAgent,
            userName: item.userName,
            channelId: channelId,
            from: date.from,
            to: date.to,
            dateKey: date.key,
            beginDate: date.beginDate,
            endDate: date.endDate,
            toogleFilter: filter.value
        };

        if (action == "detail") {
            pushHistory(history, location.pathname + `/${item.userId}`, params, event.ctrlKey);
        }
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }



    const columns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
        { name: 'Tổng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
    ];

    let onExportClick = null
    if (gridData.items?.length > 0)
        onExportClick = () => {

            const params = {
                productId: productId,
                isAgent: isAgent,
                channelId: channelId,
                beginDate: date.beginDate,
                endDate: date.endDate
            };

            if (staffFilterType == "group") {
                params.roleNode = roleNode
            } else if (staffFilterType == "other") {
                params.userId = userId
            } else if (staffFilterType == "me") {
                params.userId = localStorage.getItem('userId')
            }

            getFile('/reports/expiration-services/excel', params, response => {
                const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
                DownloadForm(response.data, filename)
            });
        }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>

                {refresh == 2 && <>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList}
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                        onLoaded={setIsReady.setStaff}
                    />
                </>}

                <DateFilterFormByStatus keyId={date.key} from={date.from}
                    to={date.to} width={250} onChange={(date) => setDate(date)}
                    onLoaded={setIsReady.setStatus}
                />

                <ChannelProductFilter channelId={channelId} productId={productId}
                    setChannelId={setChannelId} setProductId={setProductId}
                    onLoaded={setIsReady.setChannelProduct}
                />

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại tài khoản"
                        width={250}
                        height={350}
                        options={isAgentOptions}
                        value={isAgent}
                        onChange={setIsAgent}
                    />
                </div>

            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: `${PAGE_TITLE}` }}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}

export default function () {
    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <ExpirationServices />
            </Route>
            <Route path={`${path}/:userId/`}>
                <ExpirationServiceDetails />
            </Route>
        </Switch>
    </>)
}

