import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

// ui components
import SectionHeaderFlex from './../../components/SectionHeaderFlex/SectionHeaderFlex';
import DetailItem from '../../components/DetailItem/DetailItem';
import { FormHeader } from '../../components/FormHeader/FormHeader';
import YesNoRadio from './YesNoRadio/YesNoRadio';

// customer hooks
import { useFetch, handleInitalResponse } from '../../hooks/useFetch';

// reusable styles
import { whiteColor, secondaryLightColor, btnBgCol200, btnCol500 } from './../../styles/colors';

// utils
import { apiUrl, usersApiEndpoint, userGroupsApiEndpoint, userRolesApiEndpoint } from './../../utils/constants';
import { token } from './../../utils/token';

import { getInfoSystem } from './helpers';

// local styles
import { getClasses } from './styles';
import { ChevronRightMedIcon } from '../../components/Icons/Icons';
import { NormalButton } from '../../components/Buttons/Buttons';
import { pushHistory } from '../../utils/helpers';

const { infoContainer, hierachy, info } = getClasses();

export default function StaffDetails() {
    const { userId } = useParams();
    const history = useHistory();

    const { error, data, loading } = useFetch(`${usersApiEndpoint}/${userId}`);

    const {
        sectionContainer,
        headerContainer,
        content,
        itemContainer,
        itemKey,
        itemValue,
        infoList,
        userGroupContainer,
    } = getClasses();

    // local state
    const [userGroup, setUserGroup] = React.useState(null);
    const [userRole, setUserRole] = React.useState(null);
    const [roleNodeNames, setRoleNodeNames] = React.useState(null);

    // get group of this user
    React.useEffect(() => {
        if (data) {
            fetch(`${apiUrl}${userGroupsApiEndpoint}/${data.groupId}`, {
                // fetch(`${apiUrl}${userGroupsApiEndpoint}/${data?.groupId}`, {
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            })
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        data.res.then((data) => {
                            setUserGroup(data);
                        });
                    } else {
                        console.log(data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [data]);

    // get role of this user
    React.useEffect(() => {
        if (data) {
            fetch(`${apiUrl}${userRolesApiEndpoint}/${data.roleId}`, {
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            })
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        data.res.then((data) => {
                            setUserRole(data);
                        });
                    } else {
                        console.log(data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [data]);

    // recursively call api to get hierachy of roleNode of the user
    // get role of this user
    React.useEffect(() => {
        if (data) {
            fetch(`${apiUrl}${userRolesApiEndpoint}/${data.roleId}/family`, {
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            })
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        data.res.then((data) => {
                            const roleNodeNames = data.map((roleName) => {
                                return roleName.name;
                            });
                            setRoleNodeNames(roleNodeNames);
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [data]);

    const renderRoleHierachy = (roleNames) => {
        if (roleNames) {
            const links = roleNames.map((name, index) => {
                if (index !== roleNames.length - 1) {
                    return (
                        <span key={name} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                            <span>{name}</span>
                            <ChevronRightMedIcon
                                css={{
                                    fontSize: 10,
                                    width: 10,
                                    height: 10,
                                    marginRight: 8,
                                }}
                            />
                        </span>
                    );
                } else {
                    return (
                        <span
                            key={name}
                            style={{
                                display: 'flex',
                                gap: 10,
                                alignItems: 'center',
                                fontWeight: 500,
                                color: secondaryLightColor,
                            }}
                        >
                            {name}
                        </span>
                    );
                }
            });
            return links;
        }
        return null;
    };

    const infoItems = [
        {
            key: 'Tên tài khoản',
            value: data?.userName,
        },
        {
            key: 'FullName',
            value: data?.fullName,
        },
        {
            key: 'Số điện thoại',
            value: data?.mobile,
        },
        {
            key: 'Email',
            value: data?.email,
        },
        {
            key: 'Nhóm chức năng',
            value: userGroup?.name,
        },
        {
            key: 'Nhóm',
            value: userRole?.name,
        },
    ];

    return (
        <div className={sectionContainer}>
            <div className={headerContainer}>
                <SectionHeaderFlex
                    boxCss={{ backgroundColor: secondaryLightColor }}
                    text={'Chi tiết NVCS'}
                    sectionHeaderCss={{
                        backgroundColor: whiteColor,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                >
                    <NormalButton
                        btnCss={{ backgroundColor: btnBgCol200, border: 'none', color: btnCol500 }}
                        text={'Chỉnh sửa'}
                        style={{ alignSelf: 'center', marginLeft: 'auto' }}
                        onClick={(event) => {
                            pushHistory(history, `/staffs/${userId}/edit`, null, event.ctrlKey);
                        }}
                    />
                </SectionHeaderFlex>
            </div>

            {loading && <Spinner size={SpinnerSize.large} />}

            {data && !loading ? (
                <>
                    <div className={content}>
                        <div className={infoContainer}>
                            <div className={hierachy}>{renderRoleHierachy(roleNodeNames)}</div>
                            <div className={info}>
                                <FormHeader text="THÔNG TIN" />
                                <div className={infoList}>
                                    {infoItems.map((item) => (
                                        <div className={itemContainer} key={item.key}>
                                            <div className={itemKey}>{item?.key}</div>
                                            <div className={itemValue}>{item?.value}</div>
                                        </div>
                                    ))}
                                    <YesNoRadio isActive={data?.isActive} />
                                </div>
                            </div>
                        </div>

                        <div className={userGroupContainer}>
                            <DetailItem item={getInfoSystem(data)} isEditable={false} />
                        </div>
                    </div>
                </>
            ) : (
                    <p style={{ textAlign: 'center', color: 'red' }}>{error?.message}</p>
                )}
        </div>
    );
}
