import React from 'react';

import { useHistory } from 'react-router-dom';
import { pushHistory } from '../../utils/helpers';
import { getClasses } from './styles';

export default function NVMBNLink({ person }) {
    const history = useHistory();
    const { editLink } = getClasses();
    return (
        <>
            <span
                className={editLink}
                style={{ cursor: 'pointer' }}
                onClick={(event) => pushHistory(history, `/staffs/${person?.userId}/details`, null, event.ctrlKey)}>
                {person?.fullName}
            </span>
            &nbsp;
        </>
    );
}
