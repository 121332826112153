import {
    useHistory
} from "react-router-dom";
import React, { useEffect } from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton } from 'office-ui-fabric-react';


import { CustomButton,CustomTextField } from "../../components/BasicInput/CustomInput";
import { getAxios, postAxios } from "../../utils/axios";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateStaff.module.css'
import { validateEmail, validateMobile } from "../../utils/helpers";

const options= [
    { key: true, text: 'Có' },
    { key: false, text: 'Không' },
];

const choiceStyle = {
    flexContainer: {
        display: 'flex',
        selectors: {
            ".ms-ChoiceField": {
                margin:'3px 24px 0 0',
            }
        }
    }
}

const initalData = {isActive:true}

const uuidV4Regex = /^[A-F\d]{8}-[A-F\d]{4}-4[A-F\d]{3}-[89AB][A-F\d]{3}-[A-F\d]{12}$/i;
// compared to:     /^[a-fA-F0-9]{8}-[a-fA-F0-9]{4}-4{1}[a-fA-F0-9]{3}-[89abAB]{1}[a-fA-F0-9]{3}-[a-fA-F0-9]{12}$/

const isValidV4UUID = uuid => uuidV4Regex.test(uuid);

const onCreateStaff = async(data) => {
    return new Promise((res,rej) => {
        const param = {
            "appUserId": data.appUserId.trim(),
            "userName": data.userName.trim(),
            "fullName": data.fullName.trim(),
            "mobile": validateMobile(data.mobile.trim()),
            "email": data.email.trim(),
            "isActive": data.isActive
        }
        postAxios('users',param,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(appUserId,userName,fullName,mobile,email)=>{
    const errorValidate = {}
    if (!appUserId){
        errorValidate.appUserId = "AppUserId không được bỏ trống"
    }
    else if (!isValidV4UUID(appUserId)){
        errorValidate.appUserId =  "AppUserId không hợp lệ"
    }
    if (!userName){
        errorValidate.userName = "Tên tài khoản không được bỏ trống"
    }
    if (!fullName){
        errorValidate.fullName = "FullName không được bỏ trống"
    }
    if (!mobile){
        errorValidate.mobile = "Số điện thoại không được bỏ trống"
    }
    else if (!validateMobile(mobile.trim())){
        errorValidate.mobile = "Số điện thoại không hợp lệ"
    }
    if (!email){
        errorValidate.email = "Email không được bỏ trống"
    }
    else if (!validateEmail(email.trim())){
        errorValidate.email = "Email không hợp lệ"
    }
    return errorValidate
}

export default function AddStaff() {
    const history = useHistory()
    
    const [data,setData] = React.useState(initalData)

    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const OnBeforeCreate = async () => {
        const errorValidate = validate(data.appUserId,data.userName,data.fullName,data.mobile,data.email)
        if (Object.keys(errorValidate).length == 0){
            setErrorInput({})
            return new Promise((res,rej)=>onCreateStaff(data).then(res).catch(err=>{
                ShowErrorModal(err)
                rej(err)
            }))
        }
        setErrorInput(errorValidate)
        return Promise.reject(errorValidate)
    }

    const onAdd = () => {
        OnBeforeCreate().then(()=>history.goBack()).catch(()=>{})
    }
    
    const onAddMore = (callback) => {
        OnBeforeCreate().then(()=>setData(initalData)).catch(()=>{})
    }

    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Thêm mới NVCS</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin</span>
                <div className={styles.box__component}>
                    
                    <div className={styles.item}>
                        <label className={styles.item__label}>AppUserId</label>
                        <CustomTextField width={318} errorMessage={errorInput.appUserId}
                            value={data.appUserId||''} onChange={v=>setData(prev=>({...prev,appUserId:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Tên tài khoản</label>
                        <CustomTextField width={318} errorMessage={errorInput.userName}
                            value={data.userName||''} onChange={v=>setData(prev=>({...prev,userName:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>FullName</label>
                        <CustomTextField width={318} errorMessage={errorInput.fullName}
                            value={data.fullName||''} onChange={v=>setData(prev=>({...prev,fullName:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Số điện thoại</label>
                        <CustomTextField width={318} errorMessage={errorInput.mobile}
                            value={data.mobile||''} onChange={v=>setData(prev=>({...prev,mobile:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Email</label>
                        <CustomTextField width={318} errorMessage={errorInput.email}
                            value={data.email||''} onChange={v=>setData(prev=>({...prev,email:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Kích hoạt</label>
                        <ChoiceGroup styles={choiceStyle} options={options} 
                            selectedKey={data.isActive} 
                            onChange={(e,it)=>setData(prev=>({...prev,isActive:it.key}))} />
                    </div>
                </div>
                
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    <DefaultButton style={{marginRight: 16}} text="Lưu & Thêm mới" onClick={onAddMore} />
                    <CustomButton label="Lưu" onClick={onAdd} />
                </div>
            </div>
        </div>
    );
}   