import { find } from '@fluentui/react';
import React from 'react';
import { getAxios } from '../../utils/axios';
import { CustomDropdown } from '../BasicInput/CustomInput';

const StaffFilter = (props) => {
    const width = props.width ? props.width : 300;
    const currentUser = { key: localStorage.getItem('userId'), text: localStorage.getItem('userName') };

    const hasChildRole = localStorage.getItem('hasChildRole');
    
    const functionCodes = localStorage.getItem("functionCodes");
    
    let hasA_RA_Permisson = false
    if (functionCodes && functionCodes.length > 0) {
        const functionCodeArr = functionCodes.split(',');
        if (functionCodeArr.indexOf("Leads_Full") != -1 || functionCodeArr.indexOf("Leads_Read_All") != -1){
            hasA_RA_Permisson=true
        }
    }

    const filterType = props.typeList ? (hasChildRole == "0" && ! hasA_RA_Permisson ? props.typeList.filter(x => x.key != '2') : props.typeList) : [{ key: '1', text: 'Tôi chăm sóc' }];


    const propData = props.data ? { ...props.data } : { key: '', value: '' };
    if (!propData.key) { propData.key = '' }
    if (!propData.value) { propData.value = '' }

    const [data, setData] = React.useState(propData);
    const [label, setLabel] = React.useState('');
    const [valueList, setValueList] = React.useState([]);
    const [displayValue, setDisplayValue] = React.useState(false);

    React.useEffect(() => {
        const defaultType = filterType.find(x => x.key == data.key);
        if (defaultType) {
            bindDefaultValue(data.key);
        }
        else {
            onKeyChange(filterType[0].key);
        }
    }, []);

    const bindDefaultValue = (key) => {
        switch (key) {
            case '':
                setValueList([{ key: '', text: 'Tất cả' }]);
                setDisplayValue(false);
                break;
            case '1':
                setValueList([currentUser]);
                setDisplayValue(false);
                break;
            case '2':
                const opt = filterType.find(x => x.key == '2');
                if (opt && opt.data) {
                    const url = opt.data.url ? opt.data.url : 'user-roles';
                    const keyFiled = opt.data.key ? opt.data.key : 'roleId';
                    const textField = opt.data.text ? opt.data.text : 'name';

                    getAxios(url, { pageSize: 10000000 }, response => {
                        if (response.data && response.data) {
                            const groups = response.data.items ? response.data.items : response.data;
                            const groupList = groups.map(x => ({ key: x[keyFiled], text: x[textField] }));
                            setValueList(groupList);
                            setLabel('Tên nhóm');
                            setDisplayValue(true);
                        }
                    });
                }
                break;
            case '3':
                const opt2 = filterType.find(x => x.key == '3');
                if (opt2 && opt2.data) {
                    const url = opt2.data.url ? opt2.data.url : 'users';
                    const keyFiled = opt2.data.key ? opt2.data.key : 'userId';
                    const textField = opt2.data.text ? opt2.data.text : 'name';

                    getAxios(url, { pageSize: 10000000 }, response => {
                        if (response && response.data && response.data.length > 0) {
                            const users = response.data;
                            const staffList = users.map(x => ({ key: ''+x[keyFiled], text: x[textField] }));
                            setValueList(staffList);
                            setLabel('Tên nhân viên');
                            setDisplayValue(true);
                        }
                    });
                }
                break;
            case '4':
                setValueList([{ key: '0', text: 'Chưa chăm sóc' }]);
                setDisplayValue(false);
                break;
        }
    }

    // update new stats
    const onKeyChange = (key) => {
        let value = '';
        switch (key) {
            case '':
                setDisplayValue(false);
                setValueList([{ key: '', text: 'Tất cả' }]);
                setData({ key, value });
                onChange({ key, value });
                break;
            case '1':
                setDisplayValue(false);
                setValueList([currentUser]);
                value = currentUser.key;
                setData({ key, value });
                onChange({ key, value });
                break;
            case '2':
                const opt = filterType.find(x => x.key == '2');
                if (opt && opt.data) {
                    const url = opt.data.url ? opt.data.url : 'user-roles';
                    const keyFiled = opt.data.key ? opt.data.key : 'roleId';
                    const textField = opt.data.text ? opt.data.text : 'name';

                    getAxios(url, { pageSize: 10000000 }, response => {
                        if (response.data) {
                            const groups = response.data.items ? response.data.items : response.data;
                            const groupList = groups.map(x => ({ key: x[keyFiled], text: x[textField] }));
                            setValueList(groupList);
                            if (groupList.length > 0) {
                                value = groupList[0].key;
                            }
                            setLabel('Tên nhóm');
                            setDisplayValue(true);
                            setData({ key, value });
                            onChange({ key, value });
                        }
                    });
                }
                break;
            case '3':
                const opt2 = filterType.find(x => x.key == '3');
                if (opt2 && opt2.data) {
                    const url = opt2.data.url ? opt2.data.url : 'users';
                    const keyFiled = opt2.data.key ? opt2.data.key : 'userId';
                    const textField = opt2.data.text ? opt2.data.text : 'name';

                    getAxios(url, { pageSize: 10000000 }, response => {
                        if (response.data || response.data.items) {
                            const users = response.data.items ? response.data.items : response.data;
                            const staffList = users.map(x => ({ key: x[keyFiled], text: x[textField] }));
                            setValueList(staffList);

                            if (staffList.length > 0) {
                                value = staffList[0].key;
                            }
                            setLabel('Tên nhân viên');
                            setDisplayValue(true);
                            setData({ key, value });
                            onChange({ key, value });
                        }
                    });
                }
                break;
            case '4':
                setValueList([{ key: '0', text: 'Chưa chăm sóc' }]);
                value = '0';
                setDisplayValue(false);
                setData({ key, value });
                onChange({ key, value });
                break;
            case '5':
                setDisplayValue(false);
                setValueList([currentUser]);
                value = currentUser.key;
                setData({ key, value });
                onChange({ key, value });
                break;
        };
    };

    const onValueChange = (value) => {
        let tempDate = { ...data };
        tempDate.value = value;
        setData(tempDate);
        onChange(tempDate);
    };

    const onChange = (data) => {
        if (props.onChange) {
            props.onChange(data);
        }
    };

    const render = () => {
        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <div style={{ width: width, paddingBottom: '10px' }}>
                    <CustomDropdown
                        key="StaffType"
                        label="NVCS"
                        value={data.key}
                        onChange={onKeyChange}
                        options={filterType}
                        width={width} />
                </div>

                {displayValue && <div style={{ paddingBottom: '10px', width: width }}>
                    <CustomDropdown
                        key="Value"
                        label={label}
                        onChange={onValueChange}
                        options={valueList}
                        value={data.value}
                        width={width} />
                </div>}
            </div>
        )
    }

    return render();
}

const areEqual = (prevProps, nextProps) => {
    var result = (prevProps.data.key == nextProps.data.key && prevProps.data.value == nextProps.data.value);
    return result;
}

export default React.memo(StaffFilter, areEqual);
