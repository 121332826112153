import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { getAxios } from '../../utils/axios';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import { CustomChoiceGroup, CustomDropdown } from './../../components/BasicInput/CustomInput';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import BasicInput from '../../components/BasicInput/BasicInput';
import { useForm } from '../../hooks/useForm';
import { secondaryLightColor } from '../../styles/colors';
import { apiUrl, contactsApiEndpoint, isAgentOptions } from '../../utils/constants';
import { handleError } from '../../hooks/useFetch';
import { token } from './../../utils/token';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';

// initial mobile form field for delete modal
const FIELDS = {
    mobile: { value: '' },
};

let userList=[],roleList=[],AppList=[]
const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getUserList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/users/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/user-roles/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}
const getApp = async () => {
    return getApi('/apps')
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Contacts_Full') || fncCode.includes('Contacts_Read_All')){
        result.add("all")
        result.add("group")
        result.add("other")
        result.add("orphan")
    }
    if (fncCode.includes('Contacts_Read_Family')){
        result.add("group")
        result.add("other")
        result.add("mychild")
    }
    return result
}

const TimeFilterOption = [
    {
        key: 'assignDate',
        text: 'Ngày chăm sóc',
    },
    {
        key: 'createDate',
        text: 'Ngày tạo',
    },
    {
        key: 'updateDate',
        text: 'Ngày cập nhật',
    },
]

const Contact = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    //state
    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [groupId, setGroupId] = React.useState('1');
    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');
    const [staffList, setStaffList] = React.useState('');
    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: '',
        items: [],
        page: 0,
        pageCount: 1,
    });
    const [date, setDate] = React.useState(
        locParams
            ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey }
            : { beginDate: '', endDate: '', key: '5' }
    );

    const [dateType, setDateType] = React.useState(locParams ? locParams.dateType : "assignDate");
    
    const [isAgent,setIsAgent] =  React.useState(locParams ? locParams.isAgent : '');
    
    const [filter, setFilter] = React.useState(
        locParams && locParams.toogleFilter
            ? { value: true, panelWidth: '300px', contentWidth: '320px' }
            : { value: false, panelWidth: '0', contentWidth: '4px' }
    );

    const permisson = React.useMemo(()=>parseFunctionCode(localStorage.getItem('functionCodes')),[])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [appId,setAppId] = React.useState(locParams ?locParams.appId:'');
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [staffFilterType,setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh,setRefresh] = React.useState(0)

    //state to control Modal
    const [toggledModal, setToggledModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const showErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);
    const [isDeleting, setIsDeleting] = React.useState(false);

    // state to define item to be deleted
    const [deletedItem, setDeletedItem] = React.useState({});

    // mobile form field to show on the delete modal
    const { fields, handleInputChange } = useForm(FIELDS);

    //init
    React.useEffect(() => {
        getAxios('users', { pageSize: 10000000 }, (response) => {
            if (response.data && response.data.items) {
                const staffList = response.data.items.map((x) => ({ key: x.userId, text: x.userName }));
                setStaffList(staffList);
            }
        });

        getApp().then(resp=>{
            AppList = resp.map(x=>({key:x.appId,text:x.name}))
            AppList.unshift({ key: '', text: 'Tất cả' })
        }).catch(console.log)

        getUserList(permisson,localStorage.getItem('userId'))
        .then(res=>{
            const tmp = res?.items || []
            userList = tmp.map(x=>({key:''+x.userId,text: x.userName}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoleList(permisson,localStorage.getItem('roleId')).then(res=>{
            const tmp = res || []
            roleList = tmp.map(x=>({key:x.roleNode,text: x.name}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
    }, []);

    React.useEffect(() => {
        if (locParams) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
    }, [isFirstLoad]);

    //function
    const onAddClick = () => {history.push('/contacts/add')};

    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            staffFilterType,
            isAgent,
            appId,
            keyword: keyword?.trim(),
            page: page + 1,
            pageSize: pageSize,
            dateType,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            toogleFilter: filter.value,
        };
        
        let url

        if (staffFilterType=="all"){
            url = '/contacts'
        } else if (staffFilterType=="mychild"){
            url = `/contacts/managed-by/${localStorage.getItem('userId')}/included-children`
        } else if (staffFilterType=="group"){
            url = `/contacts/role-node/${encodeURIComponent(roleNode)}`
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            url = `/contacts/managed-by/${userId}`
            params.userId = userId
        } else if (staffFilterType=="orphan"){
            url = '/contacts/managed-by/0'
        } else {
            url = '/contacts/managed-by/me'
        }

        if (!firstLoad) {
            pushHistory(history, '/contacts', params);
        } 
        
        getAxios(url, params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    // delete contact api call
    React.useEffect(() => {
        if (isDeleting) {
            // DELETE request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            };
            fetch(`${apiUrl}${contactsApiEndpoint}/${deletedItem?.contactId}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.contactId) {
                        // hide delete modal and load new data again
                        setToggledModal(false);
                        post(0);
                    } else {
                        handleError(data.message);
                    }
                    setIsDeleting(false);
                })
                .catch((error) => {
                    handleError(error);
                });
        }
    }, [isDeleting]);

    // delete contact if mobile is matched, if not show ErrorModal
    const deleteContact = () => {
        if (fields.mobile.value.trim() === deletedItem.mobile) {
            // reset modal mobile form state after successfully deleting a contact
            fields.mobile.value = '';
            setIsDeleting(true);
        } else {
            showErrorModal('SĐT không đúng');
            return;
        }
    };

    const closeErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), []);

    const actionClick = (action, value, item, event) => {
        if (action == 'update') {
            pushHistory(history, `/contact/${value}`, null, event.ctrlKey);
        } else if (action == 'link') {
            pushHistory(history, `/contacts/${item.contactId}/details`, null, event.ctrlKey);
        } else if (action === 'delete') {
            setToggledModal(true);
            // set contact to be deleted
            setDeletedItem(item);
        }
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' });
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' });
        }
    };

    //options
    const actionItems = [
        {
            key: 'update',
            icon: 'Edit',
            text: 'Chỉnh sửa',
            onClick: actionClick,
        },
        {
            key: 'delete',
            icon: 'Cancel',
            text: 'Xóa',
            onClick: actionClick,
        },
    ];

    const options = {
        group: [
            {
                key: '1',
                text: 'Tất cả contact',
            },
            {
                key: '2',
                text: 'Vai trò',
            },
            {
                key: '3',
                text: 'Chức năng',
            },
            {
                key: '4',
                text: 'Nhóm',
            },
        ]
    };

    const columns = [
        { name: '#', key: 'title', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Họ tên', key: 'name', fieldName: 'name', minWidth: 300, maxWidth: 300, isResizable: true },
        {
            name: 'Điện thoại',
            key: 'mobile',
            fieldName: 'mobile',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            render: { type: 'link', key: 'link', onClick: actionClick },
        },
        { name: 'Email', key: 'email', fieldName: 'email', minWidth: 280, maxWidth: 280, isResizable: true },
        { name: 'Ứng dụng', key: 'appName', fieldName: 'appName', minWidth: 180, maxWidth: 180, isResizable: true , render: {type: 'text'}} ,
        {
            name: 'NVCS',
            key: 'assignedToName',
            fieldName: 'assignedToName',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true
        },
        // {
        //     key: 'contactId',
        //     fieldName: 'contactId',
        //     minWidth: 100,
        //     maxWidth: 100,
        //     render: { type: 'button', text: 'Chức năng', items: actionItems },
        // },
    ];

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel width={filter.panelWidth} onClose={() => toogleFilter(false)} onClick={() => post(0, false)}>
                {refresh == 2 &&<>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList} 
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                        onLoaded={setIsFirstLoad}
                    />
                </>}
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại tài khoản"
                        width={250}
                        height={350}
                        options={isAgentOptions}
                        value={isAgent}
                        onChange={setIsAgent}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Nguồn"
                        width={250}
                        height={350}
                        options={AppList}
                        value={appId}
                        onChange={setAppId}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kiểu ngày"
                        width={250}
                        dropdownWidth={250}
                        options={TimeFilterOption}
                        value={dateType}
                        onChange={setDateType}
                    />
                </div>
                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div
                style={{
                    display: 'block',
                    width: `calc(100% - ${filter.contentWidth})`,
                    height: '100%',
                    backgroundColor: 'white',
                    borderRadius: '10px',
                    float: 'left',
                }}
            >
                <div style={{ width: '100%', display: 'inline-flex', height: '70px' }}>
                    <div style={{ width: 'calc(100% - 500px)', height: '100%' }}>
                        <SectionHeader
                            width={"100%"}
                            title={{ text: "Tài khoản" }}
                            // addButton={{ text: "Tạo mới", onClick: onAddClick }} 
                        />
                    </div>
                    <div
                        style={{
                            height: '100%',
                            width: '500px',
                            padding: '17px',
                            display: 'flex',
                            flexDirection: 'row-reverse',
                        }}
                    >
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={(page) => post(page)}
                    />
                </div>
            </div>

            {/* modal to inform error */}
            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={() => {
                    closeErrorModal();
                }}
            />

            {/* modal to delete a contact */}
            <CustomModal
                title="Xóa Contact"
                isModalOpen={toggledModal}
                buttonAction="Xóa"
                width={640}
                hideModal={() => {
                    setToggledModal(false);
                }}
                onClick={deleteContact}
            >
                <BasicInput
                    label={`Nhập SĐT của Lead để xóa: ${deletedItem?.mobile}`}
                    labelCss={{ color: secondaryLightColor }}
                    formControlCss={{ width: '100%' }}
                    name="mobile"
                    onChange={handleInputChange}
                    value={fields.mobile.value}
                />
            </CustomModal>
        </div>
    );
};
export default Contact;
