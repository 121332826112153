
// JavaScript source code
const Env = () => {
    return (<div>
        <table>
            <tbody>
                <tr>
                    <td style={{ width: "300px" }}>REACT_APP_API_ENDPOINT</td>
                    <td>{process.env.REACT_APP_API_ENDPOINT}</td>
                </tr>
                <tr>
                    <td>REACT_APP_OAUTH_ENDPOINT</td>
                    <td>{process.env.REACT_APP_OAUTH_ENDPOINT}</td>
                </tr>
                <tr>
                    <td>REACT_APP_AUTH_ENDPOINT</td>
                    <td>{process.env.REACT_APP_AUTH_ENDPOINT}</td>
                </tr>
            </tbody>
        </table>
    </div>);
}

export default Env;