import * as React from 'react';
import { NormalPeoplePicker, ValidationState } from 'office-ui-fabric-react/lib/Pickers';

const suggestionProps = {
    suggestionsHeaderText: 'Nhân viên chăm sóc',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'Không tìm thấy nhân viên này',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
};

export default function CustomPeoplePicker({ nvcs, selectNVCS, onPeoplePickerChange, ...restProps }) {
    const [peopleList, setPeopleList] = React.useState(nvcs);

    const picker = React.useRef(null);

    const onFilterChanged = (filterText, currentPersonas, limitResults) => {
        if (currentPersonas.length > 0) {
            let nv = currentPersonas[0];
            if (nv.userId) {
                selectNVCS(currentPersonas[0].userId);
            } else {
                selectNVCS(null);
                onPeoplePickerChange('nv ko co');
            }
        }
        if (filterText) {
            let filteredPersonas = filterPersonasByText(filterText);

            filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
            filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
            return filterPromise(filteredPersonas);
        } else {
            return [];
        }
    };

    const filterPersonasByText = (filterText) => {
        return peopleList.filter((item) => doesTextStartWith(item.text, filterText));
    };

    const filterPromise = (personasToReturn) => {
        return personasToReturn;
    };

    const onRemoveSuggestion = (item) => {
        const indexPeopleList = peopleList.indexOf(item);

        if (indexPeopleList >= 0) {
            const newPeople = peopleList.slice(0, indexPeopleList).concat(peopleList.slice(indexPeopleList + 1));
            setPeopleList(newPeople);
        }
    };

    function getTextFromItem(persona) {
        return persona.text;
    }
    /**
     * Takes in the picker input and modifies it in whichever way
     * the caller wants, i.e. parsing entries copied from Outlook (sample
     * input: "Aaron Reid <aaron>").
     *
     * @param input The text entered into the picker.
     */
    function onInputChange(input) {
        onPeoplePickerChange(input);
        const outlookRegEx = /<.*>/g;
        const emailAddress = outlookRegEx.exec(input);

        if (emailAddress && emailAddress[0]) {
            return emailAddress[0].substring(1, emailAddress[0].length - 1);
        }

        return input;
    }

    const handleEmpty = () => {
        selectNVCS(null);
        onPeoplePickerChange('');
    };

    return (
        <div>
            <NormalPeoplePicker
                // eslint-disable-next-line react/jsx-no-bind
                onResolveSuggestions={onFilterChanged}
                // eslint-disable-next-line react/jsx-no-bind
                getTextFromItem={getTextFromItem}
                pickerSuggeionsProps={suggestionProps}
                className={'ms-PeoplePicker'}
                key={'normal'}
                // eslint-disable-next-line react/jsx-no-bind
                onRemoveSuggestion={onRemoveSuggestion}
                onValidateInput={validateInput}
                removeButtonAriaLabel={'Remove'}
                inputProps={{
                    placeholder: 'Chọn nhân viên chăm sóc',
                }}
                componentRef={picker}
                onInputChange={onInputChange}
                resolveDelay={300}
                itemLimit={1}
                {...restProps}
                onEmptyInputFocus={handleEmpty}
            />
        </div>
    );
}

function doesTextStartWith(text, filterText) {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
}

function removeDuplicates(personas, possibleDupes) {
    return personas.filter((persona) => !listContainsPersona(persona, possibleDupes));
}

function listContainsPersona(persona, personas) {
    if (!personas || !personas.length || personas.length === 0) {
        return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
}

function validateInput(input) {
    if (input.indexOf('@') !== -1) {
        return ValidationState.valid;
    } else if (input.length > 1) {
        return ValidationState.warning;
    } else {
        return ValidationState.invalid;
    }
}
