import React from 'react';

import DetailItem from '../../../components/DetailItem/DetailItem';

import { getAccountInfo, getAccountInfoSystem, getAccountPlusInfo, getAccountInfoDescription } from './helpers';

import { AccountContext } from '../../../context/account';

export default function Details() {
    const { account} = React.useContext(AccountContext);

    if (account) {
        return (
            <div style={{ marginTop: 16 }}>
                <DetailItem item={getAccountInfo(account)} />
                <DetailItem item={getAccountPlusInfo(account)} />
                <DetailItem item={getAccountInfoDescription(account)} />
                <DetailItem item={getAccountInfoSystem(account)} />
            </div>
        );
    }
    return null;
}
