import React from 'react';
import { getAxios } from '../../../utils/axios';
import CustomModal from '../../../components/Modal/Modal';
import { CustomDropdown } from '../../../components/BasicInput/CustomInput';

let functionList = []

export default function AddModal(props){
    const [data,setData] = React.useState(props.data)
    const [error,setError] = React.useState({})
    
    const hideModal = () => {
        props.hideModal(false)
        setData(null)
        setError({})
    }
    const onClick = ()=>{
        if (!data)
            return setError('Chức năng không được bỏ trống')
        return props.onSubmit(data)
    }

    React.useEffect(()=>{
        getAxios('user-group-functions',{pageSize:10000000},(response)=>{
            if (response){
                if (response.message)
                    return props.onErrorApi(response.message)
                functionList=response.data.items
                functionList.forEach(x=>{
                    x.key = x.functionId
                    x.text = x.code
                })
            }
        })
    },[])

    return <>
        <CustomModal title='Thêm Chức năng vào nhóm quyền' isModalOpen={props.isShow}
            hideModal={hideModal} buttonAction="Lưu" width={640}
            onClick={onClick} >
            <p style={{fontSize:18,fontWeight:700,paddingBottom:16}}>{props?.name}</p>
            <CustomDropdown width='100%' label='Chức năng' options={functionList} height={200}
                value={data} onChange={setData} errorMessage={error}
            />
        </CustomModal>
    </>
}