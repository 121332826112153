import React from 'react';
import { CustomDropdown } from '../../../components/BasicInput/CustomInput';
import { getAxios } from '../../../utils/axios';

const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getChannel = async () => {
    return getApi('/channels')
}
const getProductByApp = async (id) => {
    return getApi(`/apps/${id}/products`)
}
const getProduct = async () => {
    return getApi(`/products`)
}

const allFilter = { key: '', text: 'Tất cả' };
let cploadFirst


/**
 * 
 * @state channelId, productId
 * @function setChannelId, setProductId
 * @event onLoaded
 */
const ChannelProductFilter = (props) =>{
    const [channelList,setChannelList] = React.useState([])
    const [channelId,setChannelId] = React.useState(null)
    const [productList,setProductList] = React.useState([])
    const [productId,setProductId] = React.useState(props.productId)

    const onSetChannelId = (value)=>{
        setChannelId(value)
        props.setChannelId(value)
    }
    const onSetProductId = (value)=>{
        setProductId(value)
        props.setProductId(value)
    }

    React.useEffect(() => {
        cploadFirst = true
        getChannel().then(res=>{
            const temp = res || []
            const _channelList = temp.map(x=>({key:''+x.channelId,text:x.name,appId:x.appId}))
            _channelList.unshift(allFilter)

            const currentChannel = _channelList.find(x=>x.key==props.channelId) || _channelList[0]
            setChannelList(_channelList)
            onSetChannelId(currentChannel.key)
        }).catch(console.log)
    }, []);

    React.useEffect(()=>{
        if (channelId!=null){

            let fn = getProductByApp
            if (channelId=='')
                fn = getProduct

            fn(channelList.find(x=>x.key==channelId).appId)
            .then(res =>{
                const temp = res || []
                const _productList2 = temp.map(x=>({key:''+x.productId,text:x.name}))
                _productList2.unshift(allFilter)
                setProductList(_productList2)

                let _productId = ''
                if(cploadFirst){
                    cploadFirst=false
                    _productId = productId||_productId
                    
                    onSetProductId(_productId)
                    props.onLoaded?.(true)
                }
                else 
                    onSetProductId(_productId)
            }).catch(console.log)
        }
    },[channelId])

    return <>
        <div style={{ paddingBottom: '10px' }}>
            <CustomDropdown
                label="Kênh đăng"
                width={250}
                height={350}
                options={channelList}
                value={channelId}
                onChange={onSetChannelId}
            />
        </div>

        <div style={{ paddingBottom: '10px' }}>
            <CustomDropdown
                label="Dịch vụ"
                width={250}
                height={350}
                options={productList}
                value={productId}
                onChange={onSetProductId}
            />
        </div>
    </>
}

export default ChannelProductFilter