// dd/mm/yyyy
export function formatDateDMY(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('/');
}

// hh:mm
export function formatTimeMH(date) {
    let d = new Date(date);
    let time = 
        d.getHours().toString().padStart(2, '0') + ':' + 
        d.getMinutes().toString().padStart(2, '0');
    return time;
}

export function formatDateTime(date) {
    let d = new Date(date);
    return formatDateDMY(d) + ' ' + formatTimeMH(d);
}

// hh:mm dd/mm/yyyy
export function formatTimeDate(date) {
    let d = new Date(date);
    return formatDateDMY(d) + ' ' + formatTimeMH(d);
}
export function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function formatDateVn(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
}

export const monthNames = [
    '01/',
    '02/',
    '03/',
    '04/',
    '05/',
    '06/',
    '07/',
    '08/',
    '09/',
    '10/',
    '11/',
    '12/',
];

export const shortMonths = [
    'Th 1',
    'Th 2',
    'Th 3',
    'Th 4',
    'Th 5',
    'Th 6',
    'Th 7',
    'Th 8',
    'Th 9',
    'Th 10',
    'Th 11',
    'Th 12',
];
export const shortDays = ['CN', 'T2', 'T3', 'T4', 'T5', 'T6', 'T7'];

let date = new Date();
let willBeYesterday = new Date();
let willBe10daysAgo = new Date();
let willBe30daysAgo = new Date();
let willBe90daysAgo = new Date();
let willBeAYearAgo = new Date();

export let yesterday = willBeYesterday.setDate(willBeYesterday.getDate() - 1);
export let tenDaysAgo = willBe10daysAgo.setDate(willBe10daysAgo.getDate() - 9);
export let thirtyDaysAgo = willBe30daysAgo.setDate(willBe30daysAgo.getDate() - 30);
export let ninetyDaysAgo = willBe90daysAgo.setDate(willBe90daysAgo.getDate() - 90);
export let aYearAgo = willBeAYearAgo.setDate(willBeAYearAgo.getDate() - 365);

export let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
export let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

export let firstDayLastMonth = new Date(date.getFullYear(), date.getMonth() - 1, 1);
export let lastDayLastMonth = new Date(date.getFullYear(), date.getMonth(), 0);

export let firstDay2MonthsAgo = new Date(date.getFullYear(), date.getMonth() - 2, 1);
export let lastDay2MonthsAgo = new Date(date.getFullYear(), date.getMonth() - 1, 0);
