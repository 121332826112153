import React from 'react';

// 3rd party packaged
import { Link, useHistory, useParams } from 'react-router-dom';

// ui components
import SectionHeaderFlex from '../../../components/SectionHeaderFlex/SectionHeaderFlex';
import CustomDetailView from '../../../components/Table/CustomDetailView';
import AddButton from '../../../components/Buttons/Buttons';
import CustomModal from '../../../components/Modal/Modal';
import ErrorModal from '../../../components/Modal/ErrorModal';
import BasicInput from '../../../components/BasicInput/BasicInput';

// hooks
import { useFetch, handleError, useConditionedFetch, handleInitalResponse } from '../../../hooks/useFetch';
import { useForm } from '../../../hooks/useForm';

// utils
import { accountsApiEndpoint, leadsApiEndpoint, apiUrl, usersApiEndpoint, activityTypesApiEndpoint } from '../../../utils/constants';
import { token } from '../../../utils/token';
import { formatTimeDate } from '../../../utils/date';

// global styles
import { secondaryLightColor } from '../../../styles/colors';

import { getClasses, getLinkClasses } from './styles';

// styles
const { sectionContainer } = getClasses();
const { link } = getLinkClasses();

const FIELDS = {
    title: { value: '' },
    description: { value: '' },
};
export default function AccountFeeds() {
    const id = useParams().id;
    const [apiCallTimes, setApiCallTimes] = React.useState(1);

    // local states
    const [page, setPage] = React.useState(1);
    const [nvcs, setNVCS] = React.useState([]);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [choosenItem, setChoosenItem] = React.useState(null);
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const [errors, setErrors] = React.useState({});
    const [toggledModal, setToggledModal] = React.useState(false);
    const [modalState, setModalState] = React.useState({
        title: '',
        handleClick: () => {},
        type: 'DELETE',
        height: 24,
        buttonText: 'Xóa',
    });

    // useForm is called here
    const { fields, handleInputChange } = useForm(FIELDS);

    const defaultQS = `page=${page}&pageSize=20`;

    //  GET activityTypeId
    const { data: activityTypeData } = useConditionedFetch(`${activityTypesApiEndpoint}`, 'GET', '', true);
    let activityTypeId;
    if (activityTypeData) {
        // filter only activityTypeId with code including 'comment'
        activityTypeId = activityTypeData.filter((actId) => {
            return actId.code.includes('comment');
        })[0].id;
    }

    // call apis to process feed:
    // 1/ GET feeds
    const { data, error: err, loading } = useConditionedFetch(
        `${accountsApiEndpoint}/${id}/feeds?${defaultQS}`,
        'GET',
        '',
        apiCallTimes
    );

    // 2/ GET account
    const { data: accountData } = useFetch(`${accountsApiEndpoint}/${id}`);


    // calling api to PUT , POST, DELETE feed
    React.useEffect(() => {
        if (isUpdating) {
            const bodyOptions = {
                activityTypeId: activityTypeId,
                title: fields.title.value,
                description: fields.description.value,
            };

            // confif extra path for url to cal api
            let extraPath = '';
            if (modalState.type === 'PUT' || modalState.type === 'DELETE') {
                extraPath = `${choosenItem?.feedId}`;
            } else if (modalState.type === 'POST') {
                extraPath = ``;
            }

            const configRequestOptions = (method) => {
                switch (method) {
                    case 'POST':
                        return {
                            method: modalState.type,
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }),
                            body: JSON.stringify(bodyOptions),
                        };
                    case 'PUT': {
                        return {
                            method: modalState.type,
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }),
                            body: JSON.stringify(bodyOptions),
                        };
                    }
                    case 'DELETE': {
                        return {
                            method: modalState.type,
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }),
                        };
                    }
                    default:
                        return;
                }
            };

            fetch(`${apiUrl}${accountsApiEndpoint}/${id}/feeds/${extraPath}`, configRequestOptions(modalState.type))
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        setToggledModal(false);
                        setIsUpdating(false);
                        setErrors({});
                        setApiCallTimes((prev) => {
                            return prev + 1;
                        });
                        resetForm();
                    } else {
                        if (data.code.includes('title')) {
                            setErrors({ errTitle: data?.message });
                        } else if (data?.code.includes('description')) {
                            setErrors({ errDesc: data?.message });
                            // catch DELETE errors
                        } else {
                            showErrorModal(handleError(data).message);
                        }
                    }
                })
                // catch unexpected errors
                .catch((error) => {
                    showErrorModal(handleError(error).message);
                });

            setIsUpdating(false);
        }
    }, [isUpdating]);

    // handle show and clode ErrorModal
    const showErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);
    const closeErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), []);

    //  calculate pageCount
    let pageCount;
    // all relating nvcs ids to call api
    let ids = [];
    if (data) {
        pageCount = data.total > 20 ? Math.ceil(data.total / 20) : 1;
        // collect all relating nvcs ids to call api
        data?.items.forEach((item) => {
            ids.push(item.createdById);
            ids.push(item.updatedById);
        });
    }

    // get nvcs of this lead
    React.useEffect(() => {
        if (data) {
            const onlyUnique = (value, index, self) => {
                return self.indexOf(value) === index;
            };
            // format array with multiple same value to get an array with unique values
            let idx = ids.filter(onlyUnique);

            // call api to get nvcs for each id
            idx.forEach((id) => {
                fetch(`${apiUrl}${usersApiEndpoint}/${id}`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }),
                })
                    .then((res) => {
                        if (res.ok) {
                            return { status: 'successful', res: res.json() };
                        } else {
                            return res.json();
                        }
                    })
                    .then((data) => {
                        if (data.status === 'successful') {
                            data.res.then((nvcsRes) => {
                                setNVCS((nvcs) => {
                                    return [...nvcs, { userName: nvcsRes.userName, userId: nvcsRes.userId }];
                                });
                            });
                        } else {
                            console.log(handleError(data));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }
    }, [data]);

    // handle dropdown actions in data table
    const actionClick = (action, _value, item) => {
        setChoosenItem(item);
        if (action === 'update') {
            setModalState({
                title: 'Chỉnh Sửa',
                type: 'PUT',
                handleClick: () => {
                    setIsUpdating(true);
                },
                buttonText: 'Lưu',
                height: 270,
            });

            fields.title.value = item.title;
            fields.description.value = item.description;

            setToggledModal(true);
        } else if (action === 'delete') {
            setModalState({
                title: 'Xóa Ghi chú',
                type: 'DELETE',
                handleClick: () => {
                    setIsUpdating(true);
                },

                buttonText: 'Xóa',
            });
            setToggledModal(true);
            return;
        }
    };

    //options
    const actionItems = [
        {
            key: 'update',
            icon: 'Edit',
            text: 'Chỉnh Sửa',
            onClick: actionClick,
        },

        {
            key: 'delete',
            icon: 'Cancel',
            text: 'Xóa',
            onClick: actionClick,
        },
    ];

    const columns = [
        {
            key: '0',
            name: 'Tiêu đề',
            fieldName: 'title',
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
        },
        {
            name: 'Nội dung',
            key: '1',
            minWidth: 340,
            fieldName: 'description',
            isResizable: true,
        },
        {
            name: 'Người tạo',
            key: '2',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            onRender: (item) => {
                return (
                    <div style={{color: '#0078D4'}}>{item.createdByName}</div>
                );
            },
        },
        {
            name: 'Ngày cập nhật',
            key: '3',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.latestUpdate);
            },
        },
        {
            name: 'Người cập nhật',
            key: '4',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item) => {
                return (
                    <div style={{color:'#0078D4'}}>{item.updatedByName}</div>
                );
            },
        },
        {
            key: '5',
            minWidth: 100,
            maxWidth: 100,
            render: { type: 'button', text: 'Chức năng', items: actionItems },
        },
    ];

    // get userName from id
    const getName = (id) => {
        let nv = nvcs.filter((nv) => {
            return nv.userId === id;
        });
        return nv[0]?.userName;
    };
    // resetFrom
    const resetForm = () => {
        fields.title.value = '';
        fields.description.value = '';
    };

    // config items to pass to table
    const configItems = (data) => {
        let items = data?.items;

        items = items.map((item) => {
            // config one user
            const single = {
                ...item,
                createdBy: getName(item.createdById),
                updatedBy: getName(item.updatedById),
            };
            return single;
        });
        return items;
    };
    
    // const returnAccountIdRoute = '/accounts/' + `${id}` + ''
    const subTextArr = [
        {
            text: 'Khách hàng',
            link: '/accounts',
            key: 1,
        },
        {
            text: accountData?.name,
            link: `/accounts/${id}/extra/relating-to/call`,
            key: 2,
        }
    ];
    

    // render the modal based on type : DELETE, PUT, POST
    const renderAddEditModal = (type) => {
        if (type === 'DELETE') {
            return <p style={{ textAlign: 'center' }}>Bạn có chắc chắn muốn xóa ghi chú này không?</p>;
        } else {
            return (
                <div style={{ height: '100%' }}>
                    <form>
                        <BasicInput
                            label="Tiêu đề"
                            labelCss={{ color: secondaryLightColor }}
                            onChange={handleInputChange}
                            value={fields.title.value}
                            name="title"
                            formControlCss={{ width: '100%', marginBottom: 20 }}
                            errorMessage={errors.errTitle}
                        />
                        <BasicInput
                            label="Mô tả"
                            labelCss={{ color: secondaryLightColor }}
                            formControlCss={{ width: '100%', height: '24px' }}
                            inputCss={{ height: 24 }}
                            multiline
                            rows={6}
                            onChange={handleInputChange}
                            value={fields.description.value}
                            name="description"
                            errorMessage={errors.errDesc}
                        />
                    </form>
                </div>
            );
        }
    };

    //  handle add  feed
    const handleAddFeed = () => {
        setModalState({
            title: 'Thêm mới',
            type: 'POST',
            handleClick: () => {
                setIsUpdating(true);
            },
            height: 270,
            buttonText: 'Lưu',
        });
        setToggledModal(true);
        resetForm();
    };

    return (
        <div className={sectionContainer}>
            <SectionHeaderFlex mainText={'Ghi chú'} subText={subTextArr}>
                <AddButton
                    text={'Thêm mới'}
                    style={{ alignSelf: 'center', marginLeft: 'auto' }}
                    onClick={() => handleAddFeed()}
                />
            </SectionHeaderFlex>
            <div style={{ width: '100%', height: 'calc(100% - 90px)' }}>
                <CustomDetailView
                    loading={loading}
                    columns={columns}
                    gridData={{
                        error: err ? err?.message : '',
                        items: data ? configItems(data) : [],
                        page: page - 1,
                        pageCount: pageCount,
                    }}
                    onPageChange={(page) => setPage(page + 1)}
                />
            </div>
            <CustomModal
                title={modalState.title}
                isModalOpen={toggledModal}
                buttonAction={modalState.buttonText}
                width={640}
                height={modalState.height}
                hideModal={() => {
                    setToggledModal(false);
                    setErrors({});
                }}
                onClick={modalState.handleClick}
            >
                {renderAddEditModal(modalState.type)}
            </CustomModal>
            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={closeErrorModal}
            />
        </div>
    );
}
