import {
    useHistory
} from "react-router-dom";
import React, { useEffect } from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton } from 'office-ui-fabric-react';

import { CustomButton,CustomTextField } from "../../components/BasicInput/CustomInput";
import { postAxios } from "../../utils/axios";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateFunction.module.css'

const options= [
    { key: true, text: 'Hoạt động' },
    { key: false, text: 'Không hoạt động' },
];

const choiceStyle = {
    flexContainer: {
        display: 'flex',
        selectors: {
            ".ms-ChoiceField": {
                margin:'3px 24px 0 0',
            }
        }
    }
}

const initalData = {isActive:true}

const onCreateFunction = async (data) => {
    validate(data.code,data.name)

    return new Promise((res,rej) => {
        postAxios('user-group-functions',data,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(code,name)=>{
    if (!code){
        throw "Mã Code không được bỏ trống"
    }
    if (!name){
        throw "Thông tin mô tả không được bỏ trống"
    }
}


export default function AddFunction() {
    const history = useHistory()
    
    const [data,setData] = React.useState(initalData)

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const onAdd = () => 
        onCreateFunction(data).then(()=>history.goBack()).catch(err=>ShowErrorModal(err))
    
    const onAddMore = (callback) => {
        onCreateFunction(data).then(()=>setData(initalData)).catch(err=>ShowErrorModal(err))
    }
   
    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>

            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Thêm mới Chức năng</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Code</label>
                        <CustomTextField width={318}
                            value={data.code||''} onChange={v=>setData(prev=>({...prev,code:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Active</label>
                        <ChoiceGroup styles={choiceStyle} options={options} 
                            selectedKey={data.isActive} 
                            onChange={(e,it)=>setData(prev=>({...prev,isActive:it.key}))} />
                    </div>
                </div>
                
                <span className={styles.box__label}>Thông tin mô tả</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Mô tả</label>
                        <CustomTextField resizable={true} width={652} rows={7} 
                            value={data.name||''}
                            onChange={v=>setData(prev=>({...prev,name:v}))} />
                    </div>
                </div>
                
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Huỷ" onClick={()=>history.goBack()} />
                    <DefaultButton style={{marginRight: 16}} text="Lưu & Thêm mới" onClick={onAddMore} />
                    <CustomButton label="Lưu" onClick={onAdd} />
                </div>
            </div>
        </div>
    );
}   