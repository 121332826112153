import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import { Spinner } from '@fluentui/react';
import CustomDetailView from '../../../components/Table/CustomDetailView';
import MoreLink from '../../../components/MoreLink/MoreLink';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// utils
import { accountsApiEndpoint, postsApiEndpoint } from '../../../utils/constants';
import { formatTimeDate } from '../../../utils/date';

// local style
import { getClasses } from './styles';
import { getAxios } from '../../../utils/axios';
import { pushHistory } from '../../../utils/helpers';

const { container, heading } = getClasses();

let ChannelList = [], ProductList = []
const getApi = async (url, param) => {
    return new Promise((res, rej) => {
        getAxios(url, param, (response) => {
            if (response) {
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getChannel = async () => {
    return getApi('/channels')
}
const getProduct = async () => {
    return getApi('/products')
}

export default function ServicedClassifieds({ accountId }) {
    const history = useHistory();
    const path = `page=1&pageSize=20`;

    const { error, data, loading } = useFetch(
        `${accountsApiEndpoint}/${accountId}${postsApiEndpoint}/has-services?${path}`
    );

    const columns = [
        {
            key: 'productId',
            fieldName: 'productId',
            name: 'Dịch vụ',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            onRender: (item) => {
                return ProductList.find(x => x.key == item.productId)?.text
            }
        },
        {
            key: '2',
            name: 'Bắt đầu',
            minWidth: 170,
            maxWidth: 170,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.startDate);
            },
        },
        {
            key: '3',
            name: 'Kết thúc',
            fieldName: 'endDate',
            minWidth: 170,
            maxWidth: 170,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.endDate);
            },
        },
        {
            key: 'invoiceCode',
            name: 'Mã hóa đơn',
            fieldName: 'invoiceCode',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
        },
        {
            key: 'paymentDate',
            name: 'Ngày thanh toán',
            fieldName: 'paymentDate',
            minWidth: 170,
            maxWidth: 170,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.paymentDate);
            },
        },
    ];

    const items = data?.items || [];
    items.forEach((x) => {
        x.postTitle = `${x.postId} - ${x.title}`;
    });

    const [first, setFirst] = React.useState(0)
    React.useEffect(() => {
        getChannel().then(res => {
            const temp = res || []
            ChannelList = temp.map(x => ({ key: x.channelId, text: x.name }))
            setFirst(x => x + 1)
        }).catch(() => { })

        getProduct().then(res => {
            const temp = res || []
            ProductList = temp.map(x => ({ key: x.productId, text: x.name }))
            setFirst(x => x + 1)
        }).catch(() => { })
    }, [])

    const renderHeading = (data) => {
        if (data) {
            return `Tin đang có dịch vụ  (${data?.total} +)`;
        } else {
            return 'Tin đang có dịch vụ ';
        }
    };
    return (
        <div
            style={{
                flex: 1,
                flexDirection: 'column',
                gap: 16,
                display: 'flex',
                marginTop: 16,
            }}
        >
            <div className={container}>
                <h4 className={heading}>{renderHeading()}</h4>

                {loading && <Spinner />}

                {data && (
                    <CustomDetailView
                        loading={loading}
                        columns={columns}
                        gridData={{
                            error: error ? error?.message : '',
                            items: data ? data.items : [],
                        }}
                    />
                )}

                {error && <p style={{ textAlign: 'center', margin: '16px 0', fontSize: 14 }}>{error.message}</p>}

                {/* {data && (
                    <MoreLink
                        moreClassName={{ marginTop: 16 }}
                        onClick={event => pushHistory(history, `${accountsApiEndpoint}/${accountId}/serviced-classifieds`, null, event.ctrlKey)}
                    />
                )} */}
            </div>
        </div>
    );
}
