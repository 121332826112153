import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';

import { AccountProvider } from './context/account';
import { LeadProvider } from './context/lead';
//axios
import { getAxios, getAxiosWithToken } from './utils/axios';
//domain
import CareStaff from './domain/CareStaff/CareStaff';
import Header from './domain/Header/Header';
import Lead from './domain/Lead/Lead';
import LeadDetails from './domain/LeadDetails/LeadDetails';
import LeadEdit from './domain/LeadEdit/LeadEdit';
import LeadAttachments from './domain/LeadAttachments/LeadAttachments';
import LeadFeeds from './domain/LeadFeeds/LeadFeeds';
import MenuBar from './domain/SideBar/MenuBar';
import Contact from './domain/Contact/Contact';
import ContactEditAdd from './domain/ContactEditAdd/ContactEditAdd';
import Account from './domain/Account/Account';
import AddAccount from './domain/Account/AddAccount';
import UpdateAccount from './domain/Account/UpdateAccount';
import AccountDetails from './domain/AccountDetails/AccountDetails';
import AccountAttachments from './domain/AccountDetails/AccountAttachments/AccountAttachments.js';
import AccountFeeds from './domain/AccountDetails/AccountFeeds/AccountFeeds';
//report
import CustomerRevenue from './domain/Report/CustomerRevenue';
import UserRevenueBriefs from './domain/Report/UserRevenueBriefs';
import ExpirationServices from './domain/Report/ExpirationServices';
import UserInvoiceUnpaidBriefs from './domain/Report/UserInvoiceUnpaidBriefs';
import UserStatsCustomers from './domain/Report/UserStatsCustomers';
import UserRevenueBriefsByDate from './domain/Report/UserRevenueBriefsByDate';
import UserRevenue from './domain/Report/UserRevenue';
import UserRevenuesByCustomer from './domain/Report/UserRevenuesByCustomer';
import UserRevenuesByProducts from './domain/Report/UserRevenuesByProducts';
import UserRevenuesByProductsDetail from './domain/Report/UserRevenuesByProductsDetail';
import UserAccountBriefs from './domain/Report/UserAccountBriefs';
import LeadsByStatus from './domain/Report/LeadsByStatus';
import ReportIndex from './domain/Report/ReportIndex';

import styles from './App.module.css';
import Role from './domain/Role/Role';
import RoledUsers from './domain/RoledUsers/RoledUsers';
import Group from './domain/Group/Group';
import Function from './domain/Function/Function';
import ContactDetail from './domain/ContactDetail';
import AddLead from './domain/AddLead';
import Invoice from './domain/Invoice';
import InvoiceDetail from './domain/InvoiceDetail/InvoiceDetail';
import QuickSearchResult from './domain/QuickSearchResult/QuickSearchResult';

const App = () => {
    // setState for sidebar
    const [isOpen, setIsOpen] = React.useState(true);
    const [navWidth, setNavWidth] = React.useState('275px');
    const menuHeight = window.innerHeight - 65 + 'px';
    const height = window.innerHeight - 58 + 'px';

    // toggle sideBar
    const toggleSideBar = () => {
        if (isOpen) {
            setIsOpen(!isOpen);
            setNavWidth('60px');
        } else {
            setIsOpen(!isOpen);
            setNavWidth('275px');
        }
    };

    return (
        <Router>
            <div className={styles['App']} >
                <Header />
                <div className={styles.content}>
                    <MenuBar width={navWidth} height={menuHeight} toogleMenu={toggleSideBar} />
                    <main
                        className={styles.main}
                        style={{
                            minWidth: `calc(100% - ${navWidth})`,
                            height: height,
                            padding: '1.5rem 1rem 1rem 1rem',
                            marginTop: '3.5rem',
                            flex: 1,
                            transition: 'all 0.2s ease',
                        }}
                    >
                        <Switch>
                            <Route path="/auth/:authKey" render={(props) => {
                                getAxios(process.env.REACT_APP_AUTH_ENDPOINT, { authKey: props.match.params.authKey }, (response) => {
                                    if (response.data && response.data) {
                                        localStorage.setItem('AuthToken', response.data.token);
                                        localStorage.setItem('roleId', response.data.roleId);
                                        localStorage.setItem('userId', response.data.userId);
                                        localStorage.setItem('userName', response.data.userName);
                                        localStorage.setItem('functionCodes', response.data.functionCodes);

                                        if (response.data.roleId && response.data.roleId > 0) {
                                            getAxiosWithToken(response.data.token, `user-roles/${response.data.roleId}/children`, null, response => {

                                                if (response.data && response.data.length > 0) {
                                                    localStorage.setItem('hasChildRole', 1);
                                                }
                                                else {
                                                    localStorage.setItem('hasChildRole', 0);
                                                }


                                                window.location.replace('/');
                                            })
                                        } else {
                                            window.location.replace('/');
                                        }


                                    } else {
                                        localStorage.removeItem('AuthToken');
                                        localStorage.removeItem('userName');
                                        window.location = '/authenticateError';
                                    }
                                });
                            }}>
                            </Route>
                            <Route path="/invoices">
                                <Invoice />
                            </Route>
                            <Route path="/staffs">
                                <CareStaff />
                            </Route>
                            <Route path="/roles">
                                <Role />
                            </Route>
                            <Route path="/roled-users/:id">
                                <RoledUsers />
                            </Route>
                            <Route path="/groups">
                                <Group />
                            </Route>
                            <Route path="/functions">
                                <Function />
                            </Route>
                            <Route exact path="/leads">
                                <Lead />
                            </Route>
                            <Route exact path="/leads/add">
                                <AddLead />
                            </Route>
                            <Route path="/leads/:id/edit">
                                <LeadEdit />
                            </Route>
                            <Route path="/leads/:id/details">
                                <LeadProvider>
                                    <LeadDetails />
                                </LeadProvider>
                            </Route>
                            <Route path="/leads/:id/invoices">
                                <LeadProvider>
                                    <LeadDetails />
                                </LeadProvider>
                            </Route>
                            <Route path="/leads/:id/activities">
                                <LeadProvider>
                                    <LeadDetails />
                                </LeadProvider>
                            </Route>
                            <Route path="/leads/:id/serviced-classifieds">
                                <LeadProvider>
                                    <LeadDetails />
                                </LeadProvider>
                            </Route>
                            <Route exact path="/leads/:id/feeds">
                                <LeadFeeds />
                            </Route>
                            <Route exact path="/leads/:id/attachments">
                                <LeadAttachments />
                            </Route>
                            <Route exact path="/contacts">
                                <Contact />
                            </Route>
                            <Route path="/contacts/:contactId/details">
                                <ContactDetail />
                            </Route>
                            <Route path="/contacts/:id/edit">
                                <ContactEditAdd />
                            </Route>
                            <Route path="/contacts/add">
                                <ContactEditAdd />
                            </Route>
                            <Route exact path="/accounts">
                                <Account />
                            </Route>
                            <Route exact path="/accounts/add">
                                <AddAccount />
                            </Route>
                            <Route exact path="/accounts/:accountId/edit">
                                <UpdateAccount />
                            </Route>
                            <Route exact path="/accounts/:id/feeds">
                                <AccountFeeds></AccountFeeds>
                            </Route>
                            <Route eaxct path="/accounts/:id/attachments">
                                <AccountAttachments></AccountAttachments>
                            </Route>
                            <Route path="/accounts/:id/extra/:extra">
                                <AccountProvider>
                                    <AccountDetails />
                                </AccountProvider>
                            </Route>
                            <Route path="/quicksearchresult">
                                <QuickSearchResult />
                            </Route>
                            <Redirect from="/accounts/:id/details" to="/accounts/:id/extra/details/call"/>
                            <Route path="/report/customer-revenue">
                                <CustomerRevenue />
                            </Route>
                            <Route path="/report/user-revenue-briefs">
                                <UserRevenueBriefs />
                            </Route>
                            <Route path="/report/expiration-services">
                                <ExpirationServices />
                            </Route>
                            <Route path="/report/user-invoice-unpaid-briefs">
                                <UserInvoiceUnpaidBriefs />
                            </Route>
                            <Route path="/report/user-contact-briefs">
                                <UserStatsCustomers />
                            </Route>
                            <Route path="/report/user-revenue-briefs-by-date">
                                <UserRevenueBriefsByDate />
                            </Route>
                            <Route path="/report/user-revenue">
                                <UserRevenue />
                            </Route>
                            <Route path="/report/user-revenues-by-customer">
                                <UserRevenuesByCustomer />
                            </Route>
                            <Route path="/report/user-revenues-by-products">
                                <UserRevenuesByProducts />
                            </Route>
                            <Route path="/report/user-revenues-by-products-detail">
                                <UserRevenuesByProductsDetail />
                            </Route>
                            <Route path="/report/user-account-briefs">
                                <UserAccountBriefs/>
                            </Route>
                            <Route path="/report/leads-by-status">
                                <LeadsByStatus />
                            </Route>
                            <Route exact path="/reports">
                                <ReportIndex />
                            </Route>
                            <Route path="/invoicedetail">
                                <InvoiceDetail />
                            </Route>

                        </Switch>
                    </main>
                </div>
            </div>
        </Router >
    );
};

export default App;
