import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown, CustomTextField } from '../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../utils/axios';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import DateFilterFormByStatus from '../../components/DateFilter/DateFilterFormByStatus';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';
import { isAgentOptions } from '../../utils/constants';
import { formatDateDMY } from '../../utils/date';
import { DownloadForm } from '../../components/DownloadForm';

const PAGE_TITLE = "Báo cáo tài khoản theo nhân viên";

let userList=[],roleList=[],AppList=[]
const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getApp = async () => {
    return getApi('/apps')
}
const getUserList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/users/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/user-roles/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Reports_Full')){
        result.add("all")
        result.add("group")
        result.add("other")
    }
    if (fncCode.includes('Reports_Read_Family')){
        result.add("group")
        result.add("other")
    }
    return result
}

const useIsReadyHook = ()=>{

    const [Staff,setStaff] = React.useState(false)

    return [
        {Staff},
        {setStaff}
    ]
}

let forwardParam = {}

const UserStatsCustomers = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isReady, setIsReady] = useIsReadyHook()

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });

    const [appId,setAppId] = React.useState(locParams ?locParams.appId:'');
    const [isAgent,setIsAgent] =  React.useState(locParams ? locParams.isAgent : '');

    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );

    const permisson = React.useMemo(()=>parseFunctionCode(localStorage.getItem('functionCodes')),[])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [staffFilterType,setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh,setRefresh] = React.useState(0)

    //init
    React.useEffect(() => {
        getApp().then(resp=>{
            AppList = resp.map(x=>({key:''+x.appId,text:x.name}))
            AppList.unshift({ key: '', text: 'Tất cả' })
        }).catch(console.log)
        getUserList(permisson,localStorage.getItem('userId'))
        .then(res=>{
            const tmp = res?.items || []
            userList = tmp.map(x=>({key:''+x.userId,text: x.userName}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoleList(permisson,localStorage.getItem('roleId')).then(res=>{
            const tmp = res || []
            roleList = tmp.map(x=>({key:x.roleNode,text: x.name}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
    }, []);

    React.useEffect(() => {
        if (isReady.Staff){
            post(0, true);
        }
    }, [isReady.Staff]);


    const actionClick = (action, value, item, event) => {
        if (action === "detail") {
            let params = {...forwardParam}
            params.dateType = 'assignDate'
            params.page = 1

            let userId = localStorage.getItem('userId') || 0
            params.staffFilterType = 'me'
            if (userId != item.userId){
                params.staffFilterType = 'other'
                userId = item.userId
            }
            params.userId = ''+userId
            pushHistory(history, '/contacts', params, event.ctrlKey);
        }
    };


    //function
    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length !== 0 && (date.beginDate.length === 0 || date.endDate.length === 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            staffFilterType,
            appId,
            isAgent,
            page: page + 1,
            pageSize: pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            toogleFilter: filter.value
        };

        if (staffFilterType=="group"){
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            params.userId = userId
        } else if (staffFilterType=="me"){
            params.userId = localStorage.getItem('userId')
        }

        if (!firstLoad) {
            pushHistory(history, '/report/user-contact-briefs', params);
        } else if (!permisson.has('all')) {
            params.userId = localStorage.getItem('userId')
        }

        forwardParam = params
        
        getAxios('reports/user-contact-briefs', params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const columns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 300, maxWidth: 300, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
        { name: 'Tổng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
    ];

    let onExportClick = null
    if (gridData.items?.length > 0)
        onExportClick = () => {

            const params = {
                appId: appId,
                isAgent: isAgent,
                beginDate: date.beginDate,
                endDate: date.endDate,
            };

            if (staffFilterType=="group"){
                params.roleNode = roleNode
            } else if (staffFilterType=="other"){
                params.userId = userId
            } else if (staffFilterType=="me"){
                params.userId = localStorage.getItem('userId')
            }

            getFile('/reports/user-contact-briefs/excel', params, response => {
                const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
                DownloadForm(response.data, filename)
            });
        }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>
                
                {refresh == 2 &&<>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList} 
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                        onLoaded={setIsReady.setStaff}
                    />
                </>}

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Ứng dụng"
                        width={250}
                        height={350}
                        options={AppList}
                        value={appId}
                        onChange={setAppId}
                    />
                </div>
                
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại tài khoản"
                        width={250}
                        height={350}
                        options={isAgentOptions}
                        value={isAgent}
                        onChange={setIsAgent}
                    />
                </div> 

                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />

            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: `${PAGE_TITLE}` }}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}
export default UserStatsCustomers;
