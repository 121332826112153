import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import Attachments from '../../../components/Attachments/Attachments';
import Feeds from '../../../components/Feeds/Feeds';
import MoreLink from '../../../components/MoreLink/MoreLink';

// utils
import { accountsApiEndpoint, attchmentsApiEndpoint, feedsApiEndpoint } from '../../../utils/constants';
import { pushHistory } from '../../../utils/helpers';

export default function RelatingTo({ accountId }) {
    const history = useHistory()

    return (
        <div
            style={{
                flex: 1,
                flexDirection: 'column',
                gap: 16,
                display: 'flex',
                marginTop: 16,
            }}
        >
            <Attachments
                headingClassName={{ padding: '8px 16px' }}
                fileListClassName={{ flexDirection: 'row', gap: 75 }}
                fileItemClassName={{ border: 'none' }}
                path={`${accountsApiEndpoint}/${accountId}${attchmentsApiEndpoint}`}
                handleMoreLinkClick={event => pushHistory(history, `${accountsApiEndpoint}/${accountId}/attachments`, null, event.ctrlKey)}
            >
                {/* <MoreLink text="Thêm tập tin" moreClassName={{ border: 'none' }} onClick={() => alert('add attachment')} /> */}
            </Attachments>

            <Feeds
                noteItemClassname={{ border: 'none' }}
                path={`${accountsApiEndpoint}/${accountId}${feedsApiEndpoint}`}
                handleMoreLinkClick={(event) => pushHistory(history, `${accountsApiEndpoint}/${accountId}/feeds`, null, event.ctrlKey)}
            >
                {/* <MoreLink text={'Tạo mới'} /> */}
            </Feeds>
        </div>
    );
}
