import React from 'react';
import { useParams } from 'react-router-dom';
import { getAxios } from '../../../utils/axios';

const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status === 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getLeadReason = async ()=>{
    return getApi('/lead-reasons')
}
const getLeadStatus = async ()=>{
    return getApi('/lead-status')
}
const getLead = async (id)=>{
    return getApi('/leads/'+id)
}

const LeadDead = React.memo(()=>{
    const {id} = useParams()
    const [leadStatus,setLeadStatus] = React.useState({status:0})
    React.useEffect(()=>{
        let currentLead = null
        getLead(id).then(res=>{
            if (res){
                currentLead = res
                return getLeadStatus()
            }
        }).then(res=>{
            const deadId = res?.find(x=>x.code ==='lead_dead')?.id
            if (deadId && currentLead && deadId===currentLead.status){
                return getLeadReason()
            }
        }).then(res=>{
            if (res){
                const reason = res.find(x=>x.id === currentLead.reason)?.name
                if (res)
                    setLeadStatus({status:1, reason})
            }
        }).catch(()=>{})
    },[])
    if (leadStatus.status !== 1)
        return null

    return <div style={{"background":"#FFFFFF","boxShadow":"0px 1px 2px rgba(28, 30, 33, 0.2)","borderRadius":"12px",padding:16}}>
        <p style={{fontSize:16,fontWeight:'bold'}}>Lý do thất bại</p>
        <div style={{borderBottom: '1px solid #D0D6E0',margin:'16px -16px'}}></div>
        <p style={{fontSize:14,color:'#F24822'}}>{leadStatus.reason}</p>
    </div>
})

export default LeadDead