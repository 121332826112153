
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from '@fluentui/react';
import SectionHeader from './../../components/SectionHeader/SectionHeader';

const ReportIndex = () => {
    let history = useHistory();

    const panelStyles = {
        width: '50%',
        height: '100%',
        float: "left",
        display: 'flex',
        justifyContent: 'center',
        margin: '16px 0'
    };

    const itemStyles = {
        width: '90%',
        height: '100%',
        float: 'right',
        border: '1px solid #ddd',
        borderRadius: '8px',
        margin: '18px',
        backgroundColor: '#FFF7EC',
        cursor: 'pointer',
    };

    const iconStyles = {
        fontSize: '52px',
        padding: '24px',
        color: '#FFD69B'
    };

    const titleStyles = {
        width: '100%',
        verticalAlign: 'middle',
        height: '50px',
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'column-reverse',
        fontWeight: 600,
        padding: '0 8px 4px 0'
    };

    const contentStyles = {
        width: '100%',
        fontSize: '16px',
        height: '50px',
        padding: '4px 0 0 0'
    };

    const reportList = [
        {
            title: 'DOANH THU KHÁCH HÀNG',
            content: 'So sánh: 2 tháng - 3 tháng',
            url: '/report/customer-revenue',
        },
        {
            title: 'DOANH THU NHÂN VIÊN TỔNG HỢP',
            content: 'Theo kênh đăng - Theo nhóm - Theo tháng',
            url: '/report/user-revenue-briefs',
        },
        {
            title: 'DỊCH VỤ SẮP HẾT HẠN TỔNG HỢP',
            content: 'Theo kênh đăng - Theo nhóm - Theo tháng',
            url: '/report/expiration-services',
        },
        {
            title: 'HÓA ĐƠN CHƯA THANH TOÁN TỔNG HỢP',
            content: 'Theo kênh đăng - Theo nhóm - Theo tháng',
            url: '/report/user-invoice-unpaid-briefs',
        },
        {
            title: 'DOANH THU NHÂN VIÊN THEO NGÀY',
            content: 'Theo kênh đăng - Theo nhóm - Theo tháng',
            url: '/report/user-revenue-briefs-by-date',
        },
        {
            title: 'DOANH THU NHÂN VIÊN',
            content: 'So sánh: 2 tháng - 3 tháng',
            url: '/report/user-revenue',
        },
        {
            title: 'DOANH THU KHÁCH HÀNG',
            content: 'Theo kênh đăng - Theo nhóm - Theo tháng',
            url: '/report/user-revenues-by-customer',
        },
        {
            title: 'TÀI KHOẢN THEO NHÂN VIÊN',
            content: 'Theo nhóm - Theo tháng',
            url: '/report/user-contact-briefs',
        },
        {
            title: 'DOANH THU DỊCH VỤ THEO NHÂN VIÊN TỔNG HỢP',
            content: 'Theo nhóm - Theo dịch vụ - Theo tháng',
            url: '/report/user-revenues-by-products',
        },
        {
            title: 'KHÁCH HÀNG THANH TOÁN THEO NHÂN VIÊN',
            content: 'Theo nhóm - Theo tháng',
            url: '/report/user-account-briefs',
        },
        {
            title: 'THỐNG KÊ LEADS THEO TRẠNG THÁI',
            content: 'Theo nhóm - Theo tháng',
            url: '/report/leads-by-status',
        }
    ];

    const renderReportItem = () => {
        return reportList.map((item, index) => {
            if (index % 2 == 0) {
                const item2 = reportList[index + 1];

                return <div key={`item${index}`} style={{ width: '100%', height: '100px' }}>
                    <div style={panelStyles}>
                        <div style={itemStyles} onClick={() => history.push(item.url)}>
                            <div style={{ height: '100%', width: '100px', float: 'left', display: "flex", justifyContent: "center" }}>
                                <Icon iconName='ReportDocument' style={iconStyles} />
                            </div>
                            <div style={{ width: 'calc(100% - 100px)', float: 'left' }}>
                                <div style={titleStyles}>{item.title}</div>
                                <div style={contentStyles}>{item.content}</div>
                            </div>
                        </div>
                    </div>

                    {item2 && <div style={panelStyles}>
                        <div style={itemStyles} onClick={() => history.push(item2.url)}>
                            <div style={{ height: '100%', width: '100px', float: 'left', display: "flex", justifyContent: "center" }}>
                                <Icon iconName='ReportDocument' style={iconStyles} />
                            </div>
                            <div style={{ width: 'calc(100% - 100px)', float: 'left' }}>
                                <div style={titleStyles}>{item2.title}</div>
                                <div style={contentStyles}>{item2.content}</div>
                            </div>
                        </div>
                    </div>}
                </div>
            };
        });
    };

    const render = () => {
        return (
            <div style={{ display: 'block', width: '100%', height: '100%' }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <SectionHeader
                        title={{ text: "Báo cáo" }}
                        width={"100%"}
                    />
                </div>
                <div style={{ display: 'block', width: '100%', marginTop: '24px', paddingBottom: '25vh', height: 'calc(100% - 94px)', backgroundColor: '#fff', borderRadius: '16px', overflow: 'auto'}}>
                    {renderReportItem()}
                </div>
            </div>
        );
    }

    return render();
}

export default ReportIndex;