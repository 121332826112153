import React from 'react';
import { Checkbox, DefaultButton, FontIcon, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import CollapsePanel from '../../components/CollapsePanel'
import { CustomTextField } from '../../components/BasicInput/CustomInput';
import CustomDetailView from '../../components/Table/CustomDetailView';
import ErrorModal from '../../components/Modal/ErrorModal';
import Comments from './Comments';
import AttachmentsList from './Attachments';
import { formatDateTime, formatDateDMY, formatDate, thirtyDaysAgo, aYearAgo } from '../../utils/date';
import { getAxios, postAxios } from '../../utils/axios';
import { NormalButton } from '../../components/Buttons/Buttons';
import styles from './styles.module.css';
// reusable styles
import {
    whiteColor,
    lightPrimaryColor,
    darkGrayColor,
    buttonGrayColor,
    secondaryLightColor,
} from './../../styles/colors';
import { pushHistory, formatMoney, getParams } from '../../utils/helpers';

export default function () {
    let { path, url } = useRouteMatch();
    return <Switch>
        <Route path={`${path}/comments/`}>
            <Comments />
        </Route>
        <Route path={`${path}/attachments/`}>
            <AttachmentsList />
        </Route>
        <Route exact path={`${path}/:optional`}>
            <ContactDetail />
        </Route>
        <Route path={path}>
            <Redirect to={`${url}/relative`} />
        </Route>
    </Switch>
}

const ContactContext = React.createContext({ nvcs: {} })
const StaffContext = React.createContext([])
let ChannelList = [], ProductList = [], MediaTypeList = []

const getApi = async (url, param) => {
    return new Promise((res, rej) => {
        getAxios(url, param, (response) => {
            if (response) {
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getAllStaff = async () => {
    return getApi('/users/compact', { pageSize: 1000000 })
}
const getAttachments = async (id) => {
    return getApi(`contacts/${id}/attachments`, { pageSize: 6 })
}
const getNotes = async (id) => {
    return getApi(`contacts/${id}/feeds`, { pageSize: 6 })
}
const getServices = async (id) => {
    return getApi(`contacts/${id}/posts/has-services`, { pageSize: 6 })
}
// const getInvoices = async (id) => {
//     return getApi(`contacts/${id}/invoices`, { pageSize: 6 })
// }
const getApp = async () => {
    return getApi('/apps')
}
const getActivitiesType = async () => {
    return getApi('/activity-types')
}
const getChannel = async () => {
    return getApi('/channels')
}
const getProduct = async () => {
    return getApi('/products')
}
const getMediaType = async () => {
    return getApi('/media-types')
}
const toogleModal = setFn =>
    setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

const parseMediaType = (id) =>
    MediaTypeList.find(x => x.id == id)?.name

// content
const ContactDetail = React.memo(function () {
    let { path, url } = useRouteMatch();
    const { contactId } = useParams()
    const [isLoading, setIsLoading] = React.useState(true)
    const [contactData, setContactData] = React.useState({})
    const [staffData, setStaffData] = React.useState([])

    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const ShowErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);
    const CloseErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), []);

    React.useEffect(() => {
        let staffList = []
        getAxios(`contacts/${contactId}`, null, async response => {
            if (response.message) {
                ShowErrorModal(response.message)
                return
            }
            getMediaType().then(res => MediaTypeList = res || []).catch(console.log)
            getAllStaff().then(data => {
                staffList = data

                setStaffData(staffList)
                const nvcs = staffList.find(x => x.userId == response.data.assignedToId)
                response.data.nvcs = nvcs || {}
                return getAttachments(contactId)
            })
                .then(data => {
                    if (data === null)
                        response.data.dinhkem = { items: [], total: 0 }
                    else {
                        const dinhkem = data.items
                        dinhkem.forEach(x => {
                            const actionUser = staffList.find(x1 => x1.userId == x.createdById)
                            x.create = actionUser || {}
                        })
                        response.data.dinhkem = { items: dinhkem, total: data.total }
                    }
                    return getNotes(contactId)
                })
                .then(data => {
                    if (data === null)
                        response.data.ghichu = { items: [], total: 0 }
                    else {
                        const ghichu = data.items
                        ghichu.forEach(x => {
                            const actionUser = staffList.find(x1 => x1.userId == x.updatedById)
                            x.create = actionUser || {}
                        })
                        response.data.ghichu = { items: ghichu, total: data.total }
                    }
                    return getActivitiesType()
                })
                .then(data => {
                    response.data.activitiesType = data
                })
                .then(() => {
                    setContactData(response.data)
                    setIsLoading(false)
                })
                .catch(ShowErrorModal)
        })
    }, [])

    const actionClick = (action) => {
        if (action === 'call') {
            const url = `/contacts/${contactId}/call`;
            postAxios(url, null, response => {
                console.log('post: ' + url);
                if (response && response.message) {
                    ShowErrorModal(response.message);
                }
            });
        }
    };

    return <div style={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <ErrorModal
            message={errorModal.value}
            isModalOpen={errorModal.isDisplay}
            width={640}
            hideModal={CloseErrorModal}
        />

        {isLoading ?
            <Spinner size={SpinnerSize.large} />
            : <ContactContext.Provider value={contactData}>
                <StaffContext.Provider value={staffData}>
                    <Header actionClick={actionClick} />
                    <div className={styles.content}>
                        <div className={styles.content__left}>
                            <LeftSide />
                        </div>
                        <div className={styles.content__right}>
                            <Activities />
                            <Notes />
                            <Attachments />
                        </div>
                    </div>
                </StaffContext.Provider>
            </ContactContext.Provider>
        }
    </div>
})

//header
const Header = React.memo(function (props) {
    const onClick = (type, event) => {
        switch (type) {
            case 'link':
                pushHistory(history, `/contacts/${data.contactId}/edit`, null, event.ctrlKey)
                break
            case 'linknvcs':
                pushHistory(history, `/staffs/${data.nvcs.userId}/details`, null, event.ctrlKey)
                break
        }
    }

    const history = useHistory()
    const data = React.useContext(ContactContext)
    // const [modalDelete, setModalDelete] = React.useState({
    //     isToggle: false,
    //     value: '',
    // })
    // const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    // const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    // const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    // const onDelete = () => {
    //     if (modalDelete.value.trim() != data.mobile) {
    //         ShowErrorModal('Số điện thoại không chính xác')
    //         return
    //     }
    //     deleteAxios('contacts/'+data.contactId,null,(response)=>{
    //         if (response){
    //             if (response.message)
    //                 return ShowErrorModal(response.message)

    //             history.replace('/contacts')
    //         }
    //     })
    // }

    return <div className={styles.block}>
        <div className={styles.title}>
            <div className={styles.title__name}>
                <div className={styles.title__icon} />
                {data.name}
            </div>
            <div className={styles.title__btn}>
                <NormalButton
                    text="Gọi điện thoại"
                    btnCss={{
                        color: darkGrayColor,
                        backgroundColor: buttonGrayColor,
                        marginRight: 8,
                        border: 'none',
                    }}
                    onClick={(event) => props.actionClick('call', event)}
                />
            </div>
        </div>
        <div className={styles.infomation}>
            <div className={styles.infomation__block}>
                <label className={styles.infomation__label}>Tên tài khoản</label>
                <span className={styles.infomation__item}>{data.name}</span>
            </div>
            <div className={styles.infomation__block}>
                <label className={styles.infomation__label}>Điện Thoại</label>
                <span className={styles.infomation__item + ' ' + styles['infomation__item--blue']}>{data.mobile}</span>
            </div>
            <div className={styles.infomation__block}>
                <label className={styles.infomation__label}>Email</label>
                <span className={styles.infomation__item + ' ' + styles['infomation__item--blue']}>{data.email}</span>
            </div>
            <div className={styles.infomation__block}>
                <label className={styles.infomation__label}>NVCS</label>
                <span className={styles.infomation__item + ' ' + styles['infomation__item--blue']}>{
                    data.assignedToName}
                </span>
            </div>
        </div>
    </div>
})

//left
const LeftSide = React.memo(function () {
    const params = useParams()
    const history = useHistory()

    const [tab, setTab] = React.useState(params?.optional ? params.optional : 'relative')

    const setTabFn = React.useCallback((item) => {
        const current = item.props.itemKey
        history.push(current)
        setTab(current)
    }, [])

    const onClick = (e) => {
        alert('clicked')
    }
    return <div className={styles.block + ' ' + styles['block--white']}>
        <Pivot styles={pivotStyle} selectedKey={tab} onLinkClick={setTabFn}>
            <PivotItem headerText="Liên quan" itemKey="relative">
                <div style={{ padding: '16px 0 0' }}>
                    <AttachmentsTab />
                    <NotesTab />
                </div>
            </PivotItem>
            <PivotItem headerText="Chi tiết" itemKey="information">
                <div style={{ padding: '16px 0 0' }}>
                    <DetailsTab />
                </div>
            </PivotItem>
            <PivotItem headerText="Dịch vụ" itemKey="service">
                <div style={{ padding: '16px 0 0' }}>
                    <ServicesTab />
                </div>
            </PivotItem>
            <PivotItem headerText="Hóa đơn" itemKey="invoice">
                <div style={{ padding: '16px 0 0' }}>
                    <InvoicesTab />
                </div>
            </PivotItem>
        </Pivot>
    </div>

})

const AttachmentsTab = React.memo(() => {
    const onClick = (e) => {
        alert('clicked')
    }

    const history = useHistory()
    let { dinhkem } = React.useContext(ContactContext)
    let upto = dinhkem.total
    if (upto > 6) {
        upto = '6+'
    }

    const onShowAll = (event) => {
        pushHistory(history, 'attachments', null, event.ctrlKey)
    }
    const onLinkStaff = (id, event) => {
        pushHistory(history, `/staffs/${id}/details`, null, event.ctrlKey)
    }

    return <div className={styles.subbox}>
        <div className={styles.subbox__header}>
            <p>Tập tin ({upto})</p>
            {/* CRM-673 */}
            {/* <p onClick={onClick} className={styles.link + ' ' + styles['link--showmore']}>Thêm tập tin</p> */}
        </div>
        <div className={styles.subbox__content}>
            {dinhkem.items.map(x =>
                <div key={x.attachmentId} className={styles.attachment}>
                    <div className={styles.attachment__icon}>
                        <img src={x.mediaUrl} height={32} width={32} />
                    </div>
                    <div>
                        <p className={styles['infomation__item--blue']}>{x.name}</p>
                        <p className={styles.modified}>{formatDateTime(x.latestUpdate)}  bởi
                        <span className={styles['infomation__item--blue']}> {x.create.userName}</span>
                        </p>
                    </div>
                </div>
            )}
        </div>
        {upto != 0 && <>
            <div className={styles.subbox__separate}></div>
            <div className={styles.subbox__showmore}>
                <p onClick={(event) => onShowAll(event)} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
            </div>
        </>}
    </div>
})

const NotesTab = React.memo(() => {
    const onClick = (e) => {
        alert('clicked')
    }

    const history = useHistory()
    let { ghichu } = React.useContext(ContactContext)
    let upto = ghichu.total
    if (upto > 6) {
        upto = '6+'
    }

    const onShowAll = (event) => {
        pushHistory(history, 'comments', null, event.ctrlKey)
    }
    const onLinkStaff = (id, event) => {
        pushHistory(history, `/staffs/${id}/details`, null, event.ctrlKey)
    }

    return <div className={styles.subbox}>
        <div className={styles.subbox__header}>
            <p>Các ghi chú ({upto})</p>
            {/* CRM-673 */}
            {/* <p className={styles.link + ' ' + styles['link--showmore']}>Tạo mới</p> */}
        </div>
        <div className={styles.subbox__content}>
            {ghichu.items.map(x =>
                <div key={x.feedId} className={styles.note}>
                    <p className={styles['infomation__item--blue']}>{x.title}</p>
                    <p className={styles.modified}>{formatDateTime(x.latestUpdate)} bởi
                    <span className={styles['infomation__item--blue']}> {x.create.userName}</span>
                    </p>
                    <p>{x.description}</p>
                </div>
            )}
        </div>
        {upto != 0 && <>
            <div className={styles.subbox__separate}></div>
            <div className={styles.subbox__showmore}>
                <p onClick={onShowAll} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
            </div>
        </>}
    </div>
})

const DetailsTab = React.memo(() => {

    const data = React.useContext(ContactContext)

    const onClick = (event) => {
        pushHistory(history, `/contacts/${contactId}/edit`, null, event.ctrlKey);
    }

    const history = useHistory()

    const [isLoading, setIsLoading] = React.useState(true)
    const [appData, setAppData] = React.useState(null)
    const [systemData, setSystemData] = React.useState(null)

    const staffList = React.useContext(StaffContext)

    let {
        name, mobile, email, birthDate, contactId,
        accountId, isAgent, appId, appUserId,
        description, createDate, createdById, latestUpdate, updatedById, assignedDate
    } = React.useContext(ContactContext)

    React.useEffect(() => {
        getApp().then(data => {
            let App = data?.find(x => x.appId == appId) || { name: appId }
            setAppData(App)
            let cr = staffList.find(x => x.userId == createdById) || {}
            let ud = staffList.find(x => x.userId == updatedById) || {}
            setSystemData({ create: cr, update: ud })
            setIsLoading(false)
        })
    }, [])

    const linkToNVCS = (nvcsId, event) => {
        pushHistory(history, `/staffs/${nvcsId}/details`, null, event.ctrlKey)
    }

    return <>
        {!isLoading && <>
            <CollapsePanel title="Thông tin tài khoản" btnName="Chỉnh sửa" btnAction={onClick}>
                <div className={styles.collapsebox}>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>Họ Tên</p>
                        <p>{name}</p>
                    </div>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>Điện thoại</p>
                        <p className={styles['infomation__item--blue']}>{mobile}</p>
                    </div>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>Email</p>
                        <p className={styles['infomation__item--blue']}>{email}</p>
                    </div>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>Ngày sinh</p>
                        <p>{formatDateDMY(birthDate)}</p>
                    </div>
                </div>
            </CollapsePanel>

            <CollapsePanel title="Thông tin thêm" btnName="Chỉnh sửa" btnAction={onClick}>
                <div className={styles.collapsebox}>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>Accountld</p>
                        <p>{accountId}</p>
                    </div>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>Đại lý/CTV</p>
                        <Checkbox checked={isAgent} disabled />
                    </div>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>App</p>
                        <p>{appData.name}</p>
                    </div>
                    <div className={styles.collapsebox__item}>
                        <p className={styles.collapsebox__label}>AppUserID</p>
                        <p>{appUserId}</p>
                    </div>
                </div>
            </CollapsePanel>

            <CollapsePanel title="Thông tin mô tả" btnName="Chỉnh sửa" btnAction={onClick}>
                <div className={styles.collapsebox}>
                    <div className={`${styles.collapsebox__item} ${styles['collapsebox__item--full']} `}>
                        <p className={styles.collapsebox__label}>Mô tả</p>
                        <p>{description}</p>
                    </div>
                </div>
            </CollapsePanel>

        <CollapsePanel title="Thông tin hệ thống">
            <div className={styles.collapsebox}>
                <div className={styles.collapsebox__item}>
                    <p className={styles.collapsebox__label}>Ngày tạo</p>
                    <p>
                        <span className={styles.modified_time}>{formatDateTime(createDate)}</span>
                        <span className={styles['infomation__item--blue']}>{data.createdByName}</span>
                    </p>
                </div>
                <div className={styles.collapsebox__item}>
                    <p className={styles.collapsebox__label}>Ngày cập nhật</p>
                    <p>
                        <span className={styles.modified_time}>{formatDateTime(latestUpdate)}</span>
                        <span className={styles['infomation__item--blue']}>{data.updatedByName}</span>
                        {/* <span onClick={() => linkToNVCS(data.updatedById)} className={styles.link + ' ' + styles['link--note']}>{data.updatedByName}</span> */}
                    </p>
                </div>
                <div className={styles.collapsebox__item}>
                    <p className={styles.collapsebox__label}>Ngày chăm sóc</p>
                    <p>
                        <span className={styles.modified_time}>{formatDateTime(assignedDate)}</span>
                        <span className={styles['infomation__item--blue']}>{data.updatedByName}</span>
                        {/* <span onClick={() => linkToNVCS(data.updatedById)} className={styles.link + ' ' + styles['link--note']}>{data.updatedByName}</span> */}
                    </p>
                </div>
            </div>
        </CollapsePanel>
        </>}
    </>
})

const ServicesTab = React.memo(() => {
    let history = useHistory();

    const onClick = (action, value, item, event) => {
        switch (action) {
            case 'invoice':
                pushHistory(history, '/invoicedetail', { code: value }, event.ctrlKey);
                break;
            default:
                alert('cliked');
        }
    }

    const column = [
        {
            key: '//#region ',
            name: '#',
            minWidth: 80,
            maxWidth: 80,
            isResizable: false,
            render: { type: 'index' }
        },
        {
            key: 'madichvu',
            fieldName: 'madichvu',
            name: 'Tiêu đề',
            minWidth: 500,
            maxWidth: 500,
            isResizable: true,
            render: { type: 'link', key: 'link', onClick: onClick }
        },
        {
            key: 'channelId',
            fieldName: 'channelId',
            name: 'Kênh',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'dropdown', options: ChannelList }
        },
        {
            key: 'productId',
            fieldName: 'productId',
            name: 'Dịch vụ',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'dropdown', options: ProductList }
        },
        {
            key: 'startDate',
            fieldName: 'startDate',
            name: 'Bắt đầu',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'endDate',
            fieldName: 'endDate',
            name: 'Kết thúc',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'invoiceCode',
            fieldName: 'invoiceCode',
            name: 'Mã hóa đơn',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'link', key: 'invoice', onClick: onClick }
        },
        {
            key: 'paymentDate',
            fieldName: 'paymentDate',
            name: 'Ngày thanh toán',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'datetime' }
        },
    ];

    const [loading, setLoading] = React.useState(true)
    const [gridData, setGridData] = React.useState({
        error: '', items: [], page: 0, pageCount: 1, total: 0
    })

    const { contactId } = React.useContext(ContactContext)

    React.useEffect(() => {
        getChannel().then(res => {
            const temp = res || []
            ChannelList = temp.map(x => ({ key: x.channelId, text: x.name }))
        }).catch(() => { })

        getProduct().then(res => {
            const temp = res || []
            ProductList = temp.map(x => ({ key: x.productId, text: x.name }))
        }).catch(() => { })

        getServices(contactId).then(data => {
            setLoading(false)
            const items = data?.items
            if (+items?.length) {
                items.forEach(x => {
                    x.madichvu = x.postId + ' - ' + x.title
                    // x.soky = Math.round((new Date(x.endDate) - new Date(x.startDate))/86400000)
                })
                setGridData(x => ({ ...x, items, total: data.total }))
                
            }
            else {
                setLoading(false)
                setGridData({ error: 'Không có dữ liệu', total: 0 })
            }
                
        }).catch(error => {
            setLoading(false)
            setGridData({ error })
        })
    }, [])

    let upto = gridData.total
    if (upto > 6) {
        upto = '6+'
    }

    return <div className={styles.subbox}>
        <div className={styles.subbox__header}>
            <p>Dịch vụ ({upto})</p>
        </div>
        <div style={{ margin: '0 -16px 0 -16px' }}>
            <CustomDetailView
                loading={loading}
                columns={column}
                gridData={gridData}
            />
        </div>
        {/* CRM-673 */}
        {/* {upto != 0 && <>
            <div className={styles.subbox__showmore}>
                <p onClick={onClick} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
            </div>
        </>} */}
    </div>
})

const InvoicesTab = React.memo(() => {
    let history = useHistory();
    // let location = useLocation();
    // let locParams = getParams(location);
    const params =
    {
        beginDate: formatDate(aYearAgo),
        endDate: formatDate(new Date()),
        dateKey: '1',
        dateType: 'createDate'
    }

    const data = React.useContext(ContactContext)

    const onClick = (action, value, item, event) => {
        switch (action) {
            case 'invoice':
                pushHistory(history, '/invoicedetail', { code: value }, event.ctrlKey);
                break;
            default:
                break;
        }
    }

    const onAllClick = (e) => {
        
        pushHistory(history, `/invoices/contacts/${data.contactId}`, {...params,
            fullName: data.name,
            originalId: data.contactId
        }, e.ctrlKey)
    }

    const column = [
        {
            key: 'code',
            fieldName: 'code',
            name: 'Mã hóa đơn',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            render: { type: 'link', key: 'invoice', onClick: onClick }
        },
        {
            key: 'createDate',
            fieldName: 'createDate',
            name: 'Ngày tạo',
            minWidth: 121,
            maxWidth: 121,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'paymentDate',
            fieldName: 'paymentDate',
            name: 'Ngày thanh toán',
            minWidth: 121,
            maxWidth: 121,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'amount',
            fieldName: 'amount',
            name: 'Số tiền',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            render: {type: 'money'}
        },
        {
            key: 'assignedToId',
            fieldName: 'assignedToId',
            name: 'Thụ hưởng',
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            render: { type: 'assignedId' }
        }
    ];

    const [loading, setLoading] = React.useState(true)
    const [gridData, setGridData] = React.useState({
        error: '', items: [], page: 0, pageCount: 1, total: 0
    })
    const { contactId } = React.useContext(ContactContext)
    React.useEffect(() => {
        setLoading(false)
        getApi(`contacts/${contactId}/invoices`,{...params,pageSize:6}).then(data => {
            const items = data?.items
            if (+items?.length) {
                setGridData(x => ({ ...x, items, total: data.total }))
                
            }
            else {
                setLoading(false);
                setGridData({ error: 'Không có dữ liệu', total: 0 })
            }
                
        }).catch(error => {
            setLoading(false)
            setGridData({ error })
        })
    }, [])

    let upto = gridData.total
    if (upto > 6) {
        upto = '6+'
    }

    return <div className={styles.subbox}>
        <div className={styles.subbox__header}>
            <p>Hóa đơn ({upto})</p>
        </div>
        <div style={{ margin: '0 -16px 0 -16px' }}>
            <CustomDetailView
                loggedId={localStorage.getItem('userId')}
                loading={loading}
                columns={column}
                gridData={gridData}
            />
        </div>
        {upto != 0 && <>
            <div className={styles.subbox__showmore}>
                <p onClick={onAllClick} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
            </div>
        </>}
    </div>
})

const pivotStyle = {
    root: { borderBottom: "1px solid #D0D6E0;" },
    link: { color: '#657786', fontWeight: 500 },
    linkIsSelected: { color: '#3B4144' }
}

//right
const Activities = React.memo(function () {
    return <div className={styles.block + ' ' + styles['block--white']}>
        <div className={styles.boxtitle}>
            <b>Hoạt động</b>
        </div>
        <div className={styles.separate}></div>
        <div className={styles.activitiesTab}>
            <Pivot styles={pivotStyle}>
                <PivotItem headerText="Ghi chú">
                    <div style={{ padding: '16px' }}>
                        <CallRecord />
                    </div>
                </PivotItem>
            </Pivot>
        </div>
    </div>
})

const Notes = React.memo(function () {
    const onClick = (e) => {
        alert('clicked')
    }

    const history = useHistory()
    let { ghichu: { items, total } } = React.useContext(ContactContext)
    const ghichu = [...items]
    let upto = total
    if (upto == 0) {
        return null
    }
    if (upto > 3) {
        ghichu.length = 3
        upto = '3+'
    }

    const onShowAll = (event) => {
        pushHistory(history, 'comments', null, event.ctrlKey);
    }
    const onLinkStaff = (id, event) => {
        pushHistory(history, `/staffs/${id}/details`, null, event.ctrlKey)
    }

    return <div className={styles.block + ' ' + styles['block--white']}>
        <div className={styles.boxtitle}>
            <b>Các ghi chú ({upto})</b>
        </div>
        <div className={styles.separate}></div>
        <div>
            {ghichu.map(x =>
                <div key={x.feedId} className={`${styles.note} ${styles['note--underline']}`}>
                    <p className={styles['infomation__item--blue']}>{x.title}</p>
                    <p className={styles.modified}>{formatDateTime(x.latestUpdate)} bởi
                    <span className={styles['infomation__item--blue']}> {x.create.userName}</span>
                    </p>
                    <p>{x.description}</p>
                </div>
            )}
        </div>

        <div className={styles.separate}></div>
        <div className={styles.showmore}>
            <p onClick={onShowAll} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
        </div>
    </div>
})

const Attachments = React.memo(function () {
    const onClick = (e) => {
        alert('clicked')
    }

    const history = useHistory()
    let { dinhkem: { items, total } } = React.useContext(ContactContext)
    const dinhkem = [...items]
    let upto = total
    if (upto == 0) {
        return null
    }
    if (upto > 3) {
        dinhkem.length = 3
        upto = '3+'
    }

    const onShowAll = (event) => {
        pushHistory(history, 'attachments', null, event.ctrlKey);
    }
    const onLinkStaff = (id, event) => {
        pushHistory(history, `/staffs/${id}/details`, null, event.ctrlKey);
    }

    return <div className={styles.block + ' ' + styles['block--white']}>
        <div className={styles.boxtitle}>
            <b>Tập tin ({upto})</b>
        </div>
        <div className={styles.separate}></div>
        <div>
            {dinhkem.map(x =>
                <div key={x.attachmentId} className={`${styles.attachment} ${styles['attachment--underline']}`}>
                    <div className={styles['infomation__item--blue']}>
                        <img src={x.mediaUrl} height={32} width={32} />
                    </div>
                    <div style={{marginLeft: '8px'}}>
                        <p className={styles['infomation__item--blue']}> {x.name}</p>
                        <p className={styles.modified}> {formatDateTime(x.latestUpdate)} bởi
                        <span className={styles['infomation__item--blue']}> {x.create.userName}</span>
                        </p>
                    </div>
                </div>
            )}
        </div>

        <div className={styles.separate}></div>
        <div className={styles.showmore}>
            <p onClick={onShowAll} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
        </div>
    </div>
})

const CallRecord_validate = (title, description) => {
    const errorValidate = {}

    if (!title) {
        errorValidate.title = 'Chủ đề không được bỏ trống'
    }
    if (!description) {
        errorValidate.description = "Nội dung không được bỏ trống"
    }

    return errorValidate
}
const CallRecord = React.memo(() => {
    const onClick = (e) => {
        const errorValidate = CallRecord_validate(data.title, data.description)
        if (Object.keys(errorValidate).length == 0) {
            setErrorInput({})
            const param = {
                title: data.title,
                description: data.description,
                activityTypeId: activitiesType.find(x => x.code == 'activitytype_comment').id
            }
            postAxios(`/contacts/${contactId}/feeds/`, param, response => {
                if (response.message) {
                    ShowErrorModal(response.message)
                    return
                }
                //done
                setData({})
                ShowErrorModal("Thêm thành công", () => history.go(0))
            })
            return
        }
        setErrorInput(errorValidate)
    }

    const history = useHistory()
    const { contactId, activitiesType } = React.useContext(ContactContext)
    const [data, setData] = React.useState({})
    const [errorInput, setErrorInput] = React.useState({})
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false })
    const ShowErrorModal = React.useCallback((err, cb) => setErrorModal({ value: err, isDisplay: true, cb }), [])
    const CloseErrorModal = React.useCallback(() => errorModal.cb?.(), [errorModal.cb])

    return <>
        <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
            hideModal={CloseErrorModal} />
        <div className={styles.input}>
            <TextField maxLength={250} label="Chủ đề"
                onChange={(_, v) => setData(prev => ({ ...prev, title: v }))}
                value={data.title || ''} errorMessage={errorInput.title}
            />
        </div>
        <div className={styles.input}>
            <CustomTextField width="100%" rows={4} label="Nội dung"
                onChange={v => setData(prev => ({ ...prev, description: v }))}
                value={data.description || ''} errorMessage={errorInput.description}
            />
        </div>
        <div className={styles['input--right']}>
            <PrimaryButton text="Lưu" onClick={onClick} />
        </div>
    </>
})
