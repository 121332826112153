import { mergeStyleSets } from '@fluentui/react';

import { whiteColor, secondaryLightColor, borderGrayColor, darkGrayColor, textGrayColor } from '../../../styles/colors';

import { borderRadius15px } from '../../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        mainContent: {
            backgroundColor: whiteColor,
            padding: 16,
            borderRadius: borderRadius15px,
            flex: 2,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
        },
        contentNav: {
            display: 'flex',
            borderBottom: `1px solid ${borderGrayColor}`,
        },
        navLink: {
            textDecoration: 'none',
            color: textGrayColor,
            padding: 16,
        },
        navLinkActive: {
            borderBottom: `2px solid ${secondaryLightColor}`,
            color: darkGrayColor,
        },
    });
};
