import React from 'react';
import { CustomDropdown } from '../BasicInput/CustomInput';

let StaffFilter2_options=[]
/**
 * @input: permisson,userList,roleList,staffFilterType,userId,roleNode.
 * @event: setStaffFilterType,setUserId,setRoleNode.
 */
const StaffFilter2 = React.memo((props) => {
    const [option1,setOption1] = React.useState(props.staffFilterType)
    const [option2,setOption2] = React.useState(props.roleNode)
    const [option3,setOption3] = React.useState(props.userId)

    const onChange = (action,value) => {
        switch(action) {
            case 'option1':
                setOption1(value)
                props.setStaffFilterType(value)
                break;
            case 'option2':
                setOption2(value)    
                props.setRoleNode(value)
                break;
            case 'option3':
                setOption3(value)    
                props.setUserId(value)
        }
    }

    React.useEffect(()=>{
        const permisson = props.permisson

        let staffFilterType = props.staffFilterType
        if (!staffFilterType || !permisson.has(staffFilterType)){
            if (permisson.has("all")) {
                onChange('option1','all')
            } else {
                onChange('option1','me')
            }
        }
        StaffFilter2_options.length = 0
        if (permisson.has("all")) {
            StaffFilter2_options.push({key:'all', text:'Tất cả'})
        }

        if (permisson.has("all") || permisson.has("group")) {
            if (props.roleList.length>0) {
                StaffFilter2_options.push({key:'group', text:'Nhóm'})
                const key =  option2 || props.roleList[0].key
                onChange('option2',key)
            }
            if (props.userList.length>0) {
                StaffFilter2_options.push({key:'other', text:'Nhân viên khác'})
                const key = option3 || props.userList[0].key
                onChange('option3',key)
            }
        }

        if (permisson.has("mychild") && props.roleList.length>0){
            StaffFilter2_options.push({key:'mychild', text:'Tất cả tôi quản lý'})
        }

        StaffFilter2_options.push({key:'me', text:'Tôi chăm sóc'})

        if (permisson.has("orphan")){
            StaffFilter2_options.push({key:'orphan', text:'Chưa chăm sóc'})
        }

        props.onLoaded?.(true)
    },[])

    return <>
        <div style={{ paddingBottom: '10px' }}>
            <CustomDropdown
                label="Nhân viên chăm sóc"
                width={250}
                height={350}
                options={StaffFilter2_options}
                value={option1}
                onChange={(v)=>{onChange('option1',v)}}
            />
        </div>
        <div style={{ paddingBottom: '10px' }}>
            {option1 == 'group' && <>
                <CustomDropdown
                    label="Tên nhóm"
                    width={250}
                    height={350}
                    options={props.roleList}
                    value={option2}
                    onChange={(v)=>{onChange('option2',v)}}
                />
            </>}

            {option1 == 'other' && <>
                <CustomDropdown
                    label="Tên nhân viên"
                    width={250}
                    height={350}
                    options={props.userList}
                    value={option3}
                    onChange={(v)=>{onChange('option3',v)}}
                />
            </>}
        </div>
    </>
})

export default StaffFilter2