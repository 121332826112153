import { mergeStyleSets } from '@fluentui/react';

import { whiteColor, lightGrayColor, borderLightGrayColor } from '../../../styles/colors';

export const getClasses = () => {
    return mergeStyleSets({
        container: {
            borderRadius: 15,
            backgroundColor: whiteColor,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
            border: `1px solid ${borderLightGrayColor}`,
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 16,
            borderBottom: `1px solid ${borderLightGrayColor}`,
            marginBottom: 16,
            backgroundColor: lightGrayColor
        },
    });
};