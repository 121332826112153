import React from 'react';
import { Icon, Stack, TextField, Dropdown, ChoiceGroup, PrimaryButton, Toggle, DropdownMenuItemType, DirectionalHint } from '@fluentui/react';

//TextField
export const CustomTextField = (props) => {
    const styles = { root: { width: props.width ? props.width : '300px' } };

    const inputStyle = {
        ...props.styles,
        fieldGroup: { background: 'none' },
    }

    return (
        <Stack.Item key={props.code} styles={styles}>
            <TextField
                errorMessage={props.errorMessage}
                onKeyPress={props.onKeyPress}
                borderless={props.borderless}
                label={props.label}
                styles={inputStyle}
                placeholder={props.placeholder}
                disabled={props.disabled ? props.disabled : false}
                rows={props.rows ? props.rows : 1}
                resizable={props.resizable ? props.resizable : false}
                multiline={props.rows && props.rows > 1 ? true : false}
                autoAdjustHeight={props.autoAdjustHeight ? props.autoAdjustHeight : false}
                value={props.value}
                componentRef={props.componentRef}
                onChange={(e) => props.onChange(e.target.value)} />
        </Stack.Item >
    )
}

export const CustomDropdown = (props) => {
    let tfFilter = {};
    const widthValue = props.width ? props.width : 300;
    const height = props.height ? props.height : 300;

    const [filter, SetFilter] = React.useState('');
    const [options, setOptions] = React.useState([]);

    let styles = {};
    if (props.options.length > 10) {
        styles.dropdownItemHeader = {
            position: 'fixed',
            backgroundColor: 'white',
            'zIndex': 2,
            borderBottom: '1px solid #ddd',
            width: 'calc(100% - 8px)',
            top: 0
        };
        styles.dropdownItemsWrapper = { paddingTop: 36 };
    }

    React.useEffect(() => {
        if (props.options.length > 10) {
            setOptions([{
                key: 'filter',
                text: '',
                itemType: DropdownMenuItemType.Header
            }, ...props.options])
        }
        else {
            setOptions(props.options);
        }
    }, [props.options]);

    const onFilterChange = (text) => {
        if (props.options.length > 10) {
            SetFilter(text);
            if (text.length === 0) {
                setOptions([{
                    key: 'filter',
                    text: '',
                    itemType: DropdownMenuItemType.Header
                }, ...props.options]);
            }
            else {
                const tempOptions = props.options.filter(x => x.key !== 'filter' && x.text.toLowerCase().indexOf(text.toLowerCase()) !== -1);
                tempOptions.unshift({
                    key: 'filter',
                    text: '',
                    itemType: DropdownMenuItemType.Header
                });

                if (tempOptions.length === 1) {
                    tempOptions.push({
                        key: null,
                        text: 'Không tìm thấy kết quả',
                        itemType: DropdownMenuItemType.Normal,
                        disabled: true
                    });
                }

                setOptions(tempOptions);
            }
        }
    };

    const onChange = (value) => {
        if (props.options.length > 10) {
            SetFilter('');
            setOptions(props.options);
        }

        if (props.onChange) {
            props.onChange(value)
        }
    };

    const onRenderOption = (opt) => {
        if (opt.key === 'filter') {
            return <div style={{ display: 'inline-flex', lineHeight: '36px', width: `${widthValue - 8}px` }}>
                <Icon iconName="Search" />
                <CustomTextField
                    styles={{ fieldGroup: { marginBottom: 10 } }}
                    placeholder='...'
                    value={filter}
                    height={36}
                    width='calc(100% - 16px)'
                    borderless
                    componentRef={tfFilter}
                    onChange={(value) => onFilterChange(value)} />
            </div>
        } else {
            return <div>{opt.text}</div>
        }
    };

    const onRestoreFocus = () => {
        onFilterChange('');
    }

    const onMouse = () => {
        if (tfFilter && tfFilter.current) {
            tfFilter.current.focus();
        }
    }

    let calloutProps = { calloutMaxHeight: height, directionalHint: DirectionalHint.bottomCenter, directionalHintFixed: true, directionalHintForRTL: DirectionalHint.bottomCenter };
    if (props.options.length > 10) {
        calloutProps.onRestoreFocus = onRestoreFocus;
        calloutProps.onMouseMove = onMouse;
        calloutProps.onMouseEnter = onMouse;
        calloutProps.onMouseOver = onMouse;
    }

    const render = () => {
        return (
            <div style={{ width: widthValue }}>
                <Dropdown
                    errorMessage={props.errorMessage}
                    styles={styles}
                    label={props.label}
                    calloutProps={calloutProps}
                    options={options}
                    disabled={props.disabled ? props.disabled : false}
                    selectedKey={props.value}
                    onRenderOption={onRenderOption}
                    onChange={(e, opt) => onChange(opt.key)}
                />
            </div >
        )
    }

    return render();
}


//ChoiceGroup
export const CustomChoiceGroup = (props) => {
    const styles = { root: { width: props.width ? props.width : '300px' } };
    return (

        <Stack.Item key={props.code} styles={styles}>
            <ChoiceGroup
                label={props.label}
                styles={props.horizontal ? { flexContainer: { display: "flex" } } : {}}
                options={props.options}
                selectedKey={props.value}
                onChange={(e, opt) => props.onChange(opt.key)} />
        </Stack.Item >
    )
}

//Button
export const CustomButton = (props) => {
    const styles = { root: { width: props.width ? props.width : '100px' } };
    return (
        <PrimaryButton
            styles={styles}
            text={props.label}
            onClick={props.onClick} />
    )
}

//Toggle
export const CustomToggle = (props) => {
    return (
        <Toggle label={props.label} defaultChecked={props.value} onChange={(e, value) => { props.onChange(value) }} />
    )
}
