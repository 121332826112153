import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import CustomDetailView from '../../../components/Table/CustomDetailView';

// hooks
import { useFetch } from '../../../hooks/useFetch';
import {pushHistory} from '../../../utils/helpers';

// utils
import { accountsApiEndpoint, contactsApiEndpoind, usersApiEndpoint } from '../../../utils/constants';

// local styles
import { getClasses } from './styles';
import { secondaryLightColor } from '../../../styles/colors';
const { container, heading } = getClasses();

export default function Contacts({ accountId }) {
    const history = useHistory();
    const path = `page=1&pageSize=6`;

    const actionClick = (action, value, item, event) => {
        if (action == 'detail')
        {
            pushHistory(history, `/contacts/${item.contactId}/details/information`, null, event.ctrlKey);
        }
    }
    const { error, data, loading } = useFetch(`${accountsApiEndpoint}/${accountId}${contactsApiEndpoind}?${path}`);
    const columns = [
        {
            key: '1',
            name: 'Họ Tên',
            fieldName: 'name',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            render : { type: 'link', key:'detail', onClick:actionClick}
        },
        {
            key: '2',
            name: 'Điện thoại',
            fieldName: 'mobile',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
        },
        {
            key: '3',
            name: 'Email',
            fieldName: 'email',
            minWidth: 100,
            maxWidth: 250,
            isResizable: true,
        },
        {
            key: '4',
            name: 'NVCS',
            fieldName: 'assignedToName',
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
        },
    ];

    const renderHeading = (data) => {
        let upto = `${data?.total}`;
        if (upto > 6)
            upto = '6+'
        if (data) {
            return ` Danh sách tài khoản (${upto})`;
        } else {
            return 'Danh sách tài khoản';
        }
    };
    return (
        <div
            style={{
                flex: 1,
                flexDirection: 'column',
                gap: 16,
                display: 'flex',
                marginTop: 16,
            }}
        >
            <div className={container}>
                <h4 className={heading}>{renderHeading(data)}</h4>

                {data && (
                    <CustomDetailView
                        loading={loading}
                        columns={columns}
                        gridData={{
                            error: error ? error?.message : '',
                            items: data.items,    
                        }}
                    />
                )}

                {error && <p style={{ textAlign: 'center', margin: '16px 0', fontSize: 14 }}>{error.message}</p>}

                {/* {data && (
                    <MoreLink
                        moreClassName={{ marginTop: 16 }}
                        onClick={() => {
                            history.push(`${accountsApiEndpoint}/${accountId}/contacts`);
                        }}
                    />
                )} */}
            </div>
        </div>
    );
}
