import React from 'react';
import { NavLink, Route, useParams, Redirect } from 'react-router-dom';

import RelatingTo from '../RelatingTo/RelatingTo';
import Invoices from '../Invoices/Invoices'
import Details from '../Details/Details';
import ServicedClassifieds from '../ServicedClassifieds/ServicedClassifieds'
import Contacts from '../Contacts/Contacts'

import { getClasses } from './styles';

const { mainContent, contentNav, navLink, navLinkActive } = getClasses();

export default function MainContent() {
    const { id, extra } = useParams();

    return (
        <div className={mainContent} style={{maxWidth:'70%'}}>
            <nav className={contentNav}>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/accounts/${id}/extra/invoices`}>
                    Hóa đơn
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/accounts/${id}/extra/services`}>
                    Dịch vụ
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/accounts/${id}/extra/relating-to`}>
                    Liên quan
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/accounts/${id}/extra/details`}>
                    Chi tiết
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/accounts/${id}/extra/contacts`}>
                    Tài khoản
                </NavLink>
            </nav>

            <div>
                <Route path={`/accounts/${id}/extra/relating-to`}>
                    <RelatingTo accountId={id} />
                </Route>
                <Route path={`/accounts/${id}/extra/details`}>
                    <Details />
                </Route>
                <Route path={`/accounts/${id}/extra/services`}>
                    <ServicedClassifieds accountId={id} />
                </Route>
                <Route path={`/accounts/${id}/extra/invoices`}>
                    <Invoices accountId={id} />
                </Route>
                <Route path={`/accounts/${id}/extra/contacts`}>
                    <Contacts accountId={id} />
                </Route>
                <Route exact path={`/accounts/${id}`}>
                    <Redirect to={{ pathname: `/accounts/${id}/${extra}/relating-to` }} />
                </Route>
            </div>
        </div>
    );
}
