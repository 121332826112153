import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { LeadContext } from '../../context/lead';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

// ui components
import { NormalButton, PhoneCallButton } from '../../components/Buttons/Buttons';
import SectionHeaderFlex from './../../components/SectionHeaderFlex/SectionHeaderFlex';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import BasicInput from '../../components/BasicInput/BasicInput';

// hooks
import { useConditionedFetch, handleError, handleInitalResponse } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';

// child components
import SideInfo from './SideInfo/SideInfo';
import MainContent from './MainContent/MainContent';
import ProgressStatuses from './ProgressStatuses/ProgressStatuses';

//axios
import { putAxios } from '../../utils/axios';

// reusable styles
import {
    whiteColor,
    secondaryLightColor,
    lightPrimaryColor,
    darkGrayColor,
    buttonGrayColor,
} from './../../styles/colors';

// utils
import { leadsApiEndpoint, activityTypesApiEndpoint, apiUrl } from './../../utils/constants';
import { token } from './../../utils/token';

//axios
import { postAxios } from '../../utils/axios';

import { getClasses } from './styles';
import { pushHistory } from '../../utils/helpers';

// LeadDetails component (main)
const FIELDS = {
    mobile: { value: '' },
};

const FEEDFIELDS = {
    title: { value: '' },
    description: { value: '' },
};

export const FeedContext = React.createContext();

export default function LeadDetails() {
    const { id } = useParams();
    const history = useHistory();

    const [apiCallTimes, setApiCallTimes] = React.useState(1);

    const { error, data, loading } = useConditionedFetch(`${leadsApiEndpoint}/${id}`, 'GET', '', apiCallTimes);
    const { getLead } = React.useContext(LeadContext);
    // states
    const [toggledModal, setToggledModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const showErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);

    const [isDeleting, setIsDeleting] = React.useState(false);
    const [isUpdatingFeed, setIsUpdatingFeed] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const { fields, handleInputChange } = useForm(FIELDS);
    const { fields: feedFields, handleInputChange: handleFeedInputChange } = useForm(FEEDFIELDS);

    const [leadModalAssignMe, setLeadModalAssignMe] = React.useState({
        isToggle: false,
        value: '',
    })

    // getLead
    React.useEffect(() => {
        if (data) {
            getLead(data);
        } else {
            getLead(null);
        }
    }, [data]);

    //  GET activityTypeId
    const { data: activityTypeData } = useConditionedFetch(`${activityTypesApiEndpoint}`, 'GET', '', true);
    let activityTypeId;
    if (activityTypeData) {
        // filter only activityTypeId with code including 'comment'
        activityTypeId = activityTypeData.filter((actId) => {
            return actId.code.includes('comment');
        })[0].id;
    }

    const configErrors = () => {
        const fieldNames = Object.keys(feedFields);

        let errTitle = '';
        let errDescription = '';
        fieldNames.forEach((field) => {
            if (feedFields[field].value === '') {
                if (field === 'title') {
                    errTitle = 'Vui lòng nhập tiêu đề';
                }
                if (field === 'description') {
                    errDescription = 'Vui lòng nhập mô tả';
                }
            }
        });

        setErrors({ errTitle, errDescription });

        if (errTitle || errDescription) {
            return false;
        }

        return true;
    };
    //
    // handle add new feed for this lead
    const handleFeedUpdate = () => {
        let isValid = configErrors();

        // call api
        if (isValid) {
            setIsUpdatingFeed(true);
        }
    };

    // delete lead
    React.useEffect(() => {
        if (isDeleting) {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            };
            fetch(`${apiUrl}${leadsApiEndpoint}/${data?.leadId}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.leadId) {
                        history.goBack();
                    } else {
                        handleError(data.message);
                    }
                    setIsDeleting(false);
                })
                .catch((error) => {
                    handleError(error);
                });
        }
    }, [isDeleting]);

    const resetFeedForm = () => {
        feedFields.title.value = '';
        feedFields.description.value = '';
    };

    // post new feed for this lead
    React.useEffect(() => {
        if (isUpdatingFeed) {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'POST',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                body: JSON.stringify({
                    activityTypeId: activityTypeId,
                    title: feedFields.title.value,
                    description: feedFields.description.value,
                }),
            };

            fetch(`${apiUrl}${leadsApiEndpoint}/${data?.leadId}/feeds`, requestOptions)
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        setErrors({});
                        setApiCallTimes((prev) => {
                            return prev + 1;
                        });
                        resetFeedForm();
                    } else {
                        if (data.code.includes('title')) {
                            setErrors({ errTitle: data?.message });
                        } else if (data?.code.includes('description')) {
                            setErrors({ errDesc: data?.message });
                            // catch DELETE errors
                        } else {
                            showErrorModal(handleError(data).message);
                        }
                    }
                })
                // catch unexpected errors
                .catch((error) => {
                    showErrorModal(handleError(error).message);
                });
            setIsUpdatingFeed(false);
        }
    }, [isUpdatingFeed]);

    // styles
    const {
        sectionContainer,
        headerContainer,
        btnGroup,
        briefInfo,
        briefInfoTitle,
        briefInfoContent,
        content,
    } = getClasses();

    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const deleteLead = () => {
        if (fields.mobile.value === data.mobile) {
            fields.mobile.value = '';
            setIsDeleting(true);
        } else {
            showErrorModal('SĐT không đúng');
            return;
        }
    };

    const onLeadModalAssignMe = () => {
        if (leadModalAssignMe.value.trim() != data.mobile) {
            showErrorModal("Số điện thoại không chính xác")
            return false;
        }

        putAxios(`/leads/${id}/assigned-to/${localStorage.getItem('userId')}`, null, response => {
            if (response) {
                if (response.message)
                    return showErrorModal(response.message)

                toogleModal(setLeadModalAssignMe);
            }
        });
    }

    const closeErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), []);

    return (
        <div className={sectionContainer}>
            <div className={headerContainer}>
                <SectionHeaderFlex
                    boxCss={{ backgroundColor: lightPrimaryColor }}
                    subText="Leads"
                    mainText={data?.title}
                    sectionHeaderCss={{
                        backgroundColor: whiteColor,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                >
                    <div className={btnGroup}>
                        <NormalButton
                            text="Chỉnh sửa"
                            btnCss={{
                                color: darkGrayColor,
                                backgroundColor: buttonGrayColor,
                                marginRight: 8,
                                border: 'none',
                            }}
                            onClick={(event) => {
                                pushHistory(history, `/leads/${data?.leadId}/edit` , data, event.ctrlKey);
                            }}
                        />
                        {/* <NormalButton
                            text="Xóa"
                            btnCss={{
                                backgroundColor: buttonGrayColor,
                                color: darkGrayColor,
                                marginRight: 8,
                                border: 'none',
                            }}
                            onClick={() => setToggledModal(true)}
                        /> */}
                        {data?.assignedToName == 'anonymous' && <NormalButton
                            text="Lấy chăm sóc"
                            btnCss={{
                                color: darkGrayColor,
                                backgroundColor: buttonGrayColor,
                                marginRight: 8,
                                border: 'none',
                            }}
                            onClick={() => {
                                setLeadModalAssignMe({ value: '', isToggle: true });
                            }}
                        />}
                    </div>
                </SectionHeaderFlex>

                <div className={briefInfo}>
                    <div>
                        <span className={briefInfoTitle}>Họ và tên</span>
                        <p style={{ fontSize: 14 }}>{data?.title}</p>
                    </div>
                    <div>
                        <span className={briefInfoTitle}>Điện Thoại</span>
                        <PhoneCallButton
                            onClick={() => {
                                if (data) {
                                    const url = `/leads/${data.leadId}/call`;
                                    postAxios(url, null, response => {
                                        if (response && response.message) {
                                            setErrorModal({ value: response.message, isDisplay: true });
                                        }
                                    });
                                }
                            }}
                        >
                            <p className={briefInfoContent}>{data?.mobile}</p>
                        </PhoneCallButton>
                    </div>
                    <div>
                        <span className={briefInfoTitle}>Email</span>
                        <p className={briefInfoContent}>{data?.email}</p>
                    </div>
                    <div>
                        <span className={briefInfoTitle}>NVCS</span>
                        <p className={briefInfoContent}>
                            {data?.assignedToName}
                        </p>
                    </div>
                </div>
            </div>

            {loading && <Spinner size={SpinnerSize.large} />}

            {data && !loading ? (
                <>
                    <ProgressStatuses
                        currentStatus={data.status}
                    />
                    <div className={content}>
                        <FeedContext.Provider value={{ handleFeedUpdate, feedFields, handleFeedInputChange, errors }}>
                            <MainContent contactId={data.contactId} />
                        </FeedContext.Provider>
                        <SideInfo id={data?.leadId} />
                    </div>
                </>
            ) : (
                    <p style={{ textAlign: 'center', color: 'red' }}>{error?.message}</p>
                )}

            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={() => {
                    closeErrorModal();
                }}
            />

            <CustomModal title='Phân công cho tôi' isModalOpen={leadModalAssignMe.isToggle}
                hideModal={() => toogleModal(setLeadModalAssignMe)}
                buttonAction="Thực thi"
                width={640}
                onClick={onLeadModalAssignMe} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setLeadModalAssignMe({ ...leadModalAssignMe, value })}
                    value={leadModalAssignMe.value}
                    label={`Nhập Số điện thoại ${data?.mobile} để lấy phân công`} />
            </CustomModal>

            {/* <CustomModal
                title="Xóa Leads"
                isModalOpen={toggledModal}
                buttonAction="Xóa"
                width={640}
                hideModal={() => {
                    setToggledModal(false);
                }}
                onClick={deleteLead}
            >
                <BasicInput
                    label={`Nhập SĐT của Lead để xóa: ${data?.mobile}`}
                    labelCss={{ color: secondaryLightColor }}
                    formControlCss={{ width: '100%' }}
                    name="mobile"
                    onChange={handleInputChange}
                    value={fields.mobile.value}
                />
            </CustomModal> */}
        </div>
    );
}
