import React from 'react';
import { formatDate } from '../../utils/date';
import { CustomDropdown, CustomTextField } from '../BasicInput/CustomInput';

const LimitDay = 30

const statusOptions = [
    {
        key: '1',
        text: 'Sắp hết hạn',
        fn: (from,to)=> {
            const fromD=new Date(),endD=new Date()
            return {beginDate:new Date(fromD.setDate(fromD.getDate()+from)),
                    endDate:new Date(endD.setDate(endD.getDate()+to))}
        }
    },
    {
        key: '2',
        text: 'Đã hết hạn',
        fn: (from,to)=> {
            const fromD=new Date(),endD=new Date()
            return {endDate:new Date(fromD.setDate(fromD.getDate()-from)),
                    beginDate:new Date(endD.setDate(endD.getDate()-to))}
        }
    },
];

const numOption = [...Array(LimitDay).keys()].map(x=>({key:''+(x+1),text:''+(x+1)}))

const DateFilterFormByStatus = (props) => {
    const width = props.width ? props.width : 300;
    const height = props.height ? props.height : 30;

    const [key, setKey] = React.useState(props.keyId);
    const [from, setFrom] = React.useState(props.from);
    const [to, setTo] = React.useState(props.to);

    const onChange = (obj)=>{
        let newData = {key,from,to,...obj}
        const fn = statusOptions.find(x=>x.key==newData.key).fn
        const {beginDate,endDate} = fn(+newData.from-1,+newData.to)
        newData.beginDate = formatDate(beginDate)
        newData.endDate = formatDate(endDate)
        props.onChange(newData)
    }

    const onStatusChange = (key) => {
        setKey(key);
        onChange({key});
    };
    const onFromChange = (from) => {
        let _to = to
        if (+to < +from)
            _to = from
        setFrom(from)
        setTo(_to)
        onChange({from,to:_to});
    };
    const onToChange = (to) => {
        let _from = from
        if (+to < +from)
            _from = to
        setFrom(_from)
        setTo(to)
        onChange({to,from:_from});
    };
    
    React.useEffect(()=>{
        onFromChange(from)
        props.onLoaded(true)
    },[])

    return (
        <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
            <div style={{ width: width, paddingBottom: '10px' }}>
                <CustomDropdown
                    label="Trạng thái"
                    width={width}
                    value={key}
                    onChange={onStatusChange}
                    options={statusOptions}
                    />
            </div>

            <div style={{ paddingBottom: '10px', width: width, display:'flex', gap:8 }}>
                <CustomDropdown
                    label="Từ"
                    width={width}
                    value={from}
                    onChange={onFromChange}
                    options={numOption}
                    />
                <CustomDropdown
                    label="Đến"
                    width={width}
                    value={to}
                    onChange={onToChange}
                    options={numOption}
                    />
            </div>
        </div>
    )
}

let init=true
const areEqual = (prevProps, nextProps) => {
    if (init)
    {
        init = false
        return false
    }
    return true
}

export default React.memo(DateFilterFormByStatus, areEqual);
