import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { secondaryLightColor } from '../../styles/colors';
import { getClasses } from './styles.js';

export default function BasicInput({ label, labelCss = {}, inputCss = {}, formControlCss = {}, ...restProps }) {
    const {
        formControlDefaultStyles,
        optionalFormControlStyles,
        inputDefaultStyles,
        optionalInputStyles,
        defaultLabelStyles,
        optionalLabelStyles,
    } = getClasses({ inputCss, formControlCss, labelCss });

    return (
        <div className={`${formControlDefaultStyles} ${optionalFormControlStyles}`}>
            {label && <label className={`${defaultLabelStyles} ${optionalLabelStyles}`}>{label}</label>}
            <TextField
                className={`${inputDefaultStyles} ${optionalInputStyles}`}
                styles={{ root: { label: { color: `${secondaryLightColor}` } } }}
                {...restProps}
            />
        </div>
    );
}

BasicInput.propTypes = {
    inputCss: PropTypes.object,
    formControlCss: PropTypes.object,
    labelCss: PropTypes.object,
    label: PropTypes.string,
};
