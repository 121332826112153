import { mergeStyleSets } from '@fluentui/react';

import {
    whiteColor,
    secondaryLightColor,
    borderGrayColor,
    borderLightGrayColor,
    textGrayColor,
} from './../../../styles/colors';

import { borderRadius15px } from './../../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        sideInfo: {
            flex: 1,
            flexDirection: 'column',
            gap: 16,
            display: 'flex',
        },
        sideInfoItem: {
            borderRadius: borderRadius15px,
            backgroundColor: whiteColor,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 16,
            borderBottom: `1px solid ${borderLightGrayColor}`,
        },
        more: {
            color: secondaryLightColor,
            padding: 12,
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'center',
            borderTop: `1px solid ${borderLightGrayColor}`,
        },
    });
};

export const getHistoryClasses = () => {
    return mergeStyleSets({
        historyContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: 16,
        },
        historyKey: {
            color: textGrayColor,
            fontSize: 13,
        },
        historyValue: {
            color: textGrayColor,
            fontSize: 13,
        },
        itemContainer: {
            display: 'grid',
            gridTemplateColumns: 'auto 1fr',
            gridGap: 8,
            padding: '5px 0px',
        },
        titleContainer: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        historyTitle: {
            color: secondaryLightColor,
            fontSize: 14,
            borderBottom: `1px solid ${borderLightGrayColor}`,
            paddingBottom: 5,
        },
    });
};

export const getSideInfoFileClasses = () => {
    return mergeStyleSets({
        fileContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: 16,
        },
        fileItem: {
            display: 'flex',
            gap: 8,
            padding: '8px 0',
            borderBottom: `1px solid ${borderLightGrayColor}`,
        },
        fileInfor: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        fileName: {
            color: secondaryLightColor,
            fontSize: 13,
        },
        fileValues: {
            fontSize: 12,
            color: textGrayColor,
        },
        imgContainer: {
            width: 32,
            height: 32,
        },
        img: {
            width: '100%',
        },
    });
};

export const getNoteClasses = () => {
    return mergeStyleSets({
        noteContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0 16px',
        },
        noteItem: {
            padding: '8px 0',
            borderBottom: `1px solid ${borderLightGrayColor}`,
        },
        noteTitle: {
            color: secondaryLightColor,
            fontSize: 14,
        },
        noteUpdated: {},
        noteUpdatedBy: {
            color: secondaryLightColor,
            fontSize: 12,
        },
        noteUpdatedAt: {
            color: textGrayColor,
            fontSize: 12,
        },
        noteContent: { lineHeight: '21px', fontSize: 14 },
    });
};

export const caretStyles = {
    border: `.5px solid ${borderGrayColor}`,
    height: 20,
    width: 20,
    fontSize: 13,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
};
