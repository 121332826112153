import React from 'react';
import { getAxios } from '../../utils/axios';
import { CustomDropdown } from '../../components/BasicInput/CustomInput';
import { CustomMultiDropdown } from '../../components/DropDown/CustomMultiDropdown';
import { useLocation } from 'react-router-dom';
import { getParams } from '../../utils/helpers';

const MultiChannelProductFilter = (props) => {
    const location = useLocation();
    const locParams = getParams(location);

    const [channelList, setChannelList] = React.useState([]);
    const [productList, setProductList] = React.useState([]);
    const [productListFull, setProductListFull] = React.useState([]);
    const [channelId, setChannelId] = React.useState(props.value ? props.value.channelId : locParams && locParams.channelId);
    const [productId, setProductId] = React.useState(props.value ? props.value.productId : locParams && locParams.products);

    React.useEffect(() => {
        let _channelList
        getAxios('channels', null, res => {
            const temp = res.data || [];
            _channelList = temp.map(x => ({ key: '' + x.channelId, text: x.name, appId: x.appId }));
            _channelList.unshift({ key: '', text: 'Tất cả' ,appId: '0'});
            setChannelList(_channelList);
        }); 
        getAxios('products', null, res => {
            const temp = res.data || [];
            const _productList = temp.map(x => ({ key: '' + x.productId, text: x.name, appId: x.appId }));
            if (props.onChangeProduct) {
                if (props.value.productId && props.value.productId.length != 0) {
                    const idArr = props.value.productId.split(',');
                    props.onChangeProduct(_productList.filter(x => idArr.indexOf(x.key) != -1));
                }
                else {
                    let defaultProducts = '';
                    for(let i = 0; i < 10; i++){
                        defaultProducts += temp[i].productId + ','
                    }
                    defaultProducts = defaultProducts.slice(0,-1);
                    setProductId(defaultProducts)
                    setValue({channelId: '', productId: defaultProducts})
                    const idArr = defaultProducts.split(',');
                    props.onChangeProduct(_productList.filter(x => idArr.indexOf(x.key) != -1));
                }
            }

            setProductListFull(_productList);
            if (channelId == "") {
                setProductList(_productList);
            }
            else {
                if (_channelList && _channelList.length != 0){
                    const app = _channelList.find(x => x.key == channelId).appId;
                    setProductList(_productList.filter(x => x.appId == app));
                }
            }
        });
    },[productId]);

    const onSetChannelId = (value) => {
        const app = channelList.find(x => x.key == value).appId;
        let defaultProducts = '';

        setChannelId(value);
        if (value == "") {
            setProductList(productListFull);
            for (let i = 0; i < 10; i++) {
                defaultProducts += productListFull[i].key + ','
            }
            defaultProducts = defaultProducts.slice(0,-1);
            setProductId(defaultProducts);
        }
        else {
            const temp = productListFull.filter(x => x.appId == app);
            const maxIndex = temp.length > 10 ? 10 : temp.length;
            for (let i = 0; i < maxIndex; i++) {
                defaultProducts += temp[i].key + ','
            }
            defaultProducts = defaultProducts.slice(0,-1);
            setProductList(temp);
            setProductId(defaultProducts)
        }
        setValue({channelId: value, productId: defaultProducts})
    }

    const onSetProductId = (value) => {
        setProductId(value);
        if (props.onChangeProduct) {
            const idArr = value.split(',');
            const products = productList.filter(x => idArr.indexOf(x.key) != -1);
            props.onChangeProduct(products);
        }
        setValue({ channelId: channelId, productId: value });
    }

    const setValue = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    }

    return <>
        <div style={{ paddingBottom: '10px' }}>
            <CustomDropdown
                label="Kênh đăng"
                width={250}
                height={350}
                options={channelList}
                value={channelId}
                onChange={onSetChannelId}
            />
        </div>

        <div style={{ paddingBottom: '10px' }}>
            <CustomMultiDropdown
                label="Dịch vụ"
                width={250}
                height={350}
                options={productList}
                value={productId}
                onChange={onSetProductId}
                limit={10}
                required
            />
        </div>
    </>
}

export default MultiChannelProductFilter;