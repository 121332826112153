import React from 'react';

// 3rd party packaged
import { Link, useParams } from 'react-router-dom';

// ui components
import SectionHeaderFlex from '../../../components/SectionHeaderFlex/SectionHeaderFlex';
import CustomDetailView from '../../../components/Table/CustomDetailView';
// import AddButton from '../../components/Buttons/Buttons';
import CustomModal from '../../../components/Modal/Modal';
import ErrorModal from '../../../components/Modal/ErrorModal';

// hooks
import { useFetch, handleError, useConditionedFetch, handleInitalResponse } from '../../../hooks/useFetch';

// utils
import { accountsApiEndpoint, apiUrl, usersApiEndpoint, mediaTypeApiEndpoint } from '../../../utils/constants';
import { token } from '../../../utils/token';
import { formatTimeDate } from '../../../utils/date';

import { getClasses, getLinkClasses } from './styles';

// styles
const { sectionContainer } = getClasses();
const { link } = getLinkClasses();

export default function LeadAttachments() {
    const id = useParams().id;

    // local states
    const [apiCallTimes, setApiCallTimes] = React.useState(1);
    const [page, setPage] = React.useState(1);
    const [nvcs, setNVCS] = React.useState([]);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [choosenItem, setChoosenItem] = React.useState(null);
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const [setErrors] = React.useState({});
    const [toggledModal, setToggledModal] = React.useState(false);

    const defaultQS = `page=${page}&pageSize=20`;
    // call apis to process data:
    // 1/ GET feeds
    const { data, error: err, loading } = useConditionedFetch(
        `${accountsApiEndpoint}/${id}/attachments?${defaultQS}`,
        'GET',
        '',
        apiCallTimes
    );

    // 2/ GET lead
    const { data: accountData } = useFetch(`${accountsApiEndpoint}/${id}`);

    // 3/ GET mediaType
    const { data: mediaTypeData } = useConditionedFetch(`${mediaTypeApiEndpoint}`, 'GET', '', true);

    //  calculate pageCount
    let pageCount;
    // all relating nvcs ids to call api
    let ids = [];
    if (data) {
        pageCount = data.total > 20 ? Math.ceil(data.total / 20) : 1;
        // collect all relating nvcs ids to call api
        data?.items.forEach((item) => {
            ids.push(item.createdById);
            ids.push(item.updatedById);
        });
    }

    // handle show and clode ErrorModal
    const showErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);
    const closeErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), []);

    // get nvcs of this lead
    React.useEffect(() => {
        if (data) {
            const onlyUnique = (value, index, self) => {
                return self.indexOf(value) === index;
            };
            // format array with multiple same value to get an array with unique values
            let idx = ids.filter(onlyUnique);

            // call api to get nvcs for each id
            idx.forEach((id) => {
                fetch(`${apiUrl}${usersApiEndpoint}/compact`, {
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }),
                })
                    .then(handleInitalResponse)
                    .then((data) => {
                        if (data.status === 'successful') {
                            data.res.then((nvcsRes) => {
                                setNVCS((nvcs) => {
                                    return [...nvcs, { userName: nvcsRes.userName, userId: nvcsRes.userId }];
                                });
                            });
                        } else {
                            console.log(handleError(data));
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            });
        }
    }, [data]);
    // calling api to PUT , POST, DELETE feed
    React.useEffect(() => {
        if (isUpdating) {
            const configRequestOptions = (method) => {
                switch (method) {
                    case 'DELETE': {
                        return {
                            method: 'DELETE',
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }),
                        };
                    }
                    default:
                        return;
                }
            };

            fetch(
                `${apiUrl}${accountsApiEndpoint}/${id}/attachments/${choosenItem.attachmentId}`,
                configRequestOptions('DELETE')
            )
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        setToggledModal(false);
                        setIsUpdating(false);
                        setErrors({});
                        setApiCallTimes((prev) => {
                            return prev + 1;
                        });
                    } else {
                        if (data.code.includes('title')) {
                            setErrors({ errTitle: data?.message });
                        } else if (data?.code.includes('description')) {
                            setErrors({ errDesc: data?.message });
                            // catch DELETE errors
                        } else {
                            showErrorModal(handleError(data).message);
                        }
                    }
                })
                // catch unexpected errors
                .catch((error) => {
                    showErrorModal(handleError(error).message);
                });

            setIsUpdating(false);
        }
    }, [isUpdating]);

    const actionClick = (action, _value, item) => {
        setChoosenItem(item);

        if (action === 'download') {
        } else if (action === 'delete') {
            setToggledModal(true);
        }
    };

    const detectMediaType = (typeId) => {
        return mediaTypeData?.filter((type) => {
            return type.id == typeId;
        })[0]?.name;
    };

    const handleDelete = () => {
        setIsUpdating(true);
    };

    //options
    const actionItems = [
        {
            key: 'download',
            icon: 'Download',
            text: 'Tải xuống',
            onClick: actionClick,
        },

        {
            key: 'delete',
            icon: 'Cancel',
            text: 'Xóa',
            onClick: actionClick,
        },
    ];

    const columns = [
        {
            key: '0',
            name: 'Tiêu đề',
            minWidth: 250,
            isResizable: true,
            onRender: (item) => {
                return (
                    <div style={{ display: 'flex', gap: 16, alignItems: 'center' }}>
                        <div style={{ width: 32, height: 32 }}>
                            <img style={{ width: '100%' }} src={item.mediaUrl} alt="" />
                        </div>
                        {/* <a className={link} href={`${item.mediaUrl}`}>
                            {item.name}
                        </a> */}
                        <div style={{color: '#0078D4'}}>{item.name}</div>
                    </div>
                );
            },
        },
        {
            name: 'Người tạo',
            key: '1',
            minWidth: 170,
            maxWidth: 170,
            fieldName: 'description',
            isResizable: true,
            onRender: (item) => {
                return (
                    <div style={{color: '#0078D4'}}>{item.createdByName}</div>
                );
            },
        },
        {
            name: 'Ngày cập nhật',
            key: '2',
            minWidth: 130,
            maxWidth: 130,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.latestUpdate);
            },
        },
        {
            name: 'Loại hình',
            key: '3',
            minWidth: 170,
            maxWidth: 170,
            isResizable: true,
            onRender: (item) => {
                return detectMediaType(item?.mediaType);
            },
        },
        {
            key: '4',
            minWidth: 100,
            maxWidth: 100,
            render: { type: 'button', text: 'Chức năng', items: actionItems },
        },
    ];

    // get userName from id
    const getName = (id) => {
        let nv = nvcs.filter((nv) => {
            return nv.userId === id;
        });
        return nv[0]?.userName;
    };

    // config items to pass to table
    const configItems = (data) => {
        let items = data?.items;

        items = items.map((item) => {
            // config one user
            const single = {
                ...item,
                createdBy: getName(item.createdById),
                updatedBy: getName(item.updatedById),
            };
            return single;
        });
        return items;
    };

    // config subText to pass to SectionHeaderFlex
    const subTextArr = [
        {
            text: 'Khách hàng',
            link: '/accounts',
            key: 1,
        },
        {
            text: accountData?.name,
            link: `/accounts/${id}/extra/relating-to/call`,
            key: 2,
        },
        
    ];

    return (
        <div className={sectionContainer}>
            <SectionHeaderFlex mainText={'Tập tin'} subText={subTextArr}>
                {/* <AddButton text={'Thêm mới'} style={{ alignSelf: 'center', marginLeft: 'auto' }} /> */}
            </SectionHeaderFlex>

            <div style={{ width: '100%', height: 'calc(100% - 90px)' }}>
                <CustomDetailView
                    loading={loading}
                    columns={columns}
                    gridData={{
                        error: err ? err?.message : '',
                        items: data ? configItems(data) : [],
                        page: page - 1,
                        pageCount: pageCount,
                    }}
                    onPageChange={(page) => setPage(page + 1)}
                />
            </div>
            <CustomModal
                title={'Xóa tâp tin'}
                isModalOpen={toggledModal}
                buttonAction={'Xóa'}
                width={640}
                hideModal={() => {
                    setToggledModal(false);
                    setErrors({});
                }}
                onClick={handleDelete}
            >
                <p style={{ textAlign: 'center' }}>Bạn có chắc chắn muốn xóa tâp tin này không?</p>
            </CustomModal>
            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={closeErrorModal}
            />
        </div>
    );
}
