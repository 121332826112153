export const color = {
    textfield: {
        text: '',
        background: '',
        border: '',
    },
    dropdown: {
        text: '',
        background: '',
        border: '',
    },
    radio: {
        text: '',
        background: '',
        border: '',
    },
    detailview: {
        header: {
            text: '',
            background: '',
            border: '',
        },
        body: {
            text: '',
            background: '',
            border: '',
        },
        footer: {
            text: '',
            background: '',
            border: '',
        },
    },
    white: {
        light: '#fff',
        normal: '#fff',
        dark: '#e4e8ed',
    },
    gray: {
        light: '#f5f6f7',
        normal: '#ced2d4',
        dark: '#3c4144',
    },
};

export const primaryColor = '#f57301';
export const lightPrimaryColor = '#F68864';

export const secondaryLightColor = '#006FB6';
export const secondaryColor = '#35495D';
export const secondaryDarkColor = '#2C3945';
export const darkJellyBeanColor = '#3D719E';

export const whiteColor = '#FFF';
export const lowWhiteColor = '#E4E8ED';

export const lightGrayColor = '#F5F6F7';
export const middleGrayColor = '#F7F8F9';
export const borderGrayColor = '#D1D6E0';
export const borderLightGrayColor = '#E6ECF0';
export const buttonGrayColor = '#E4E6EB';
export const darkGrayColor = '#3B4144';
export const textGrayColor = '#657786';

export const milkGreenColor = '#4BCA81';
export const lightGreenColor = '#4BC076';
export const lotusPinkColor = '#EB7092';
export const tintColor = '#48C3CC';

export const contactDecoColor = '#A094ED';
export const btnCol500 = '#3B4144'
export const btnBgCol200 = '#E4E6EB'