import React from 'react';

import {useHistory, useParams } from 'react-router-dom';

import Feeds from '../../../components/Feeds/Feeds';
import MoreLink from '../../../components/MoreLink/MoreLink';
import Activities from '../../../components/Activities/Activities';
import Attachments from '../../../components/Attachments/Attachments';

import { accountsApiEndpoint, feedsApiEndpoint ,attchmentsApiEndpoint} from '../../../utils/constants';

import { borderLightGrayColor, darkGrayColor, whiteColor } from '../../../styles/colors';
import { getClasses } from './styles';
import { pushHistory } from '../../../utils/helpers';

const { sideInfo, title } = getClasses();

export default function SideInfo({ path }) {
    const { id, extra } = useParams();
    const extraPath = `${id}/extra/${extra}`;
    const history = useHistory();
    return (
        <aside className={sideInfo}>
            <h3 className={title}>Hoạt động</h3>
            <Activities path={path} extraPath={extraPath} />
            <Feeds
                noteItemClassName={{ borderBottom: `1px solid ${borderLightGrayColor}` }}
                noteListClassName={{ gridGap: 16 }}
                headingClassName={{ backgroundColor: whiteColor }}
                path={`${accountsApiEndpoint}/${id}/feeds`}
                handleMoreLinkClick={(event) => pushHistory(history, `${accountsApiEndpoint}/${id}${feedsApiEndpoint}`, null, event.ctrlKey)}
            >
                {/* <MoreLink text={'Tạo mới'} /> */}
            </Feeds>
            <div style={{marginTop: '1rem'}}>
                <Attachments
                    fileItemClassName={{ border: 'none'}}
                    fileListClassName={{ gridGap: 16 }}
                    headingClassName={{ color: darkGrayColor, backgroundColor: whiteColor }}
                    path={`${accountsApiEndpoint}/${id}${attchmentsApiEndpoint}`}
                    handleMoreLinkClick={(event) => pushHistory(history, `${accountsApiEndpoint}/${id}${attchmentsApiEndpoint}`, null, event.ctrlKey)}
                />
            </div>
        </aside>
    );
}
