/**
 * All icons from fluent ui,
 * https://uifabricicons.azurewebsites.net
 *
 * Usage Example:
 * <PersonIcon  css = {{color: 'red', fontSize: 39}}
 */

import React from 'react';
import { FontIcon } from 'office-ui-fabric-react/lib/Icon';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { initializeIcons } from '@uifabric/icons';

/**
 * initialize fluent ui icons
 */
initializeIcons();

/*
 * icon styles
 */
const defaultClass = {
    fontSize: 20,
    height: 20,
    fontWeight: 'bold',
    width: 20,
};

/*
 * all the followings are fluent ui icon components
 */
export function PersonIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="AccountManagement" className={mergedIconClass} />;
}

export function ContactIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="ContactLink" className={mergedIconClass} />;
}

export function AccountIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="AccountBrowser" className={mergedIconClass} />;
}

export function LeftIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="ChevronLeftSmall" className={mergedIconClass} />;
}

export function RightIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="ChevronRightSmall" className={mergedIconClass} />;
}

export function StaffIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="People" className={mergedIconClass} />;
}

export function CaretSolidDownIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="CaretSolidDown" className={mergedIconClass} />;
}

export function SearchIcon({ css, ...restProps } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="Search" className={mergedIconClass} {...restProps} />;
}

export function AddIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="Add" className={mergedIconClass} />;
}

export function ActiveIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="CheckMark" className={mergedIconClass} />;
}

export function ChevronDownMedIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="ChevronDownMed" className={mergedIconClass} />;
}
export function ChevronRightMedIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="ChevronRightMed" className={mergedIconClass} />;
}
export function FilterSolidIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="FilterSolid" className={mergedIconClass} />;
}

export function GroupIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="Group" className={mergedIconClass} />;
}

export function CheckListIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="CheckList" className={mergedIconClass} />;
}

export function PermissionsIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="Permissions" className={mergedIconClass} />;
}


export function CheckboxCompositeIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="CheckboxComposite" className={mergedIconClass} />;
}
export function CheckboxIcon({ css } = {}) {
    let mergedIconClass = mergeStyles({ ...defaultClass, ...css });
    return <FontIcon iconName="Checkbox" className={mergedIconClass} />;
}

export function PhoneCallIcon() {
    return <FontIcon iconName="Phone" style={{ 'font-size': '120%', 'color': '#0499A8', 'font-weight': 'bold', outline: 'none' }} />;
}

export function CheckIcon () {
    return <FontIcon iconName="SkypeCheck" style={{ 'font-size': '120%', 'color': 'green', 'font-weight': 'bold', outline: 'none' }} />;
}