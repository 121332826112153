import React from 'react';
import { SearchIcon } from './../Icons/Icons';
import BasicInput from '../BasicInput/BasicInput';

const SearchField = (props) => {
    const searchInput = {
        height: props.height ? props.height : 36,
        width: props.width ? props.width : 300,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 5,
        border: props.borderless ? 'none' : '1px solid',
        paddingLeft: '12px',
    };

    const onKeyPress = (e) => {
        if (props.onEnter && e.charCode == 13) {
            props.onEnter();
        }
    };

    return (
        <div style={{ ...searchInput }}>
            <SearchIcon css={{ cursor: 'pointer', marginRight: '0 5px' }} onClick={props.onSearchClick} />
            <BasicInput
                borderless={true}
                placeholder={props.placeholder}
                value={props.value}
                onKeyPress={onKeyPress}
                onChange={props.onChange}
                width={props.width - 40}
                name={props.name}
                inputCss={{ height: '100%' }}
            />
        </div>
    );
};

export default SearchField;
