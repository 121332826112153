import React from 'react';
import { Breadcrumb, DefaultButton, FontIcon, Link, Pivot, PivotItem, PrimaryButton, Spinner, SpinnerSize, TextField } from "@fluentui/react";
import { Redirect, Route, Switch, useHistory, useLocation, useParams, useRouteMatch } from "react-router-dom";
import CustomDetailView from '../../../components/Table/CustomDetailView'
import CustomModal from '../../../components/Modal/Modal';
import ErrorModal from '../../../components/Modal/ErrorModal';
import {formatTimeDate,formatDateDMY} from '../../../utils/date'
import {deleteAxios,getAxios,postAxios} from '../../../utils/axios'
import { getParams, pushHistory } from '../../../utils/helpers';

export default React.memo(()=>{
    let {path,url} = useRouteMatch();
    return <Switch>
        <Route exact path={path}>
            <AttachList />
        </Route>
    </Switch>
})

const toogleModal = (setFn) =>
    setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getAllStaff = async () => {
    return getApi('/users/compact',{pageSize:1000000})
}
const getMediaType = async () => {
    return getApi('/media-types')
}

let staffList = []
let mediaTypeList = []

const AttachList = React.memo(()=>{

    const {contactId} = useParams()
    const [contact,setContact] = React.useState({})
    const history = useHistory()
    const location = useLocation();
    const locParams = getParams(location);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });
    
    const [actionObject, setActionObject] = React.useState({})
    const [ModalDelete, setModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const actionClick = (action, id, item, event) => {
        switch (action) {
            case "delete":
                setActionObject(item)
                setModalDelete({value:'',isToggle:true})
                break
            case "linkstaffcreate":
                pushHistory(history, `/staffs/${item.createdById}/details`, null, event.ctrlKey);
                break
            case "linkstaffupdate":
                pushHistory(history, `/staffs/${item.updatedById}/details`, null, event.ctrlKey);
                break
            case "linkcontact":
                pushHistory(history, `/contacts/${id}/details`, null, event.ctrlKey);
                break
            case "linkcontacts":
                pushHistory(history, `/contacts`, null, event.ctrlKey);
                break
            default:
                alert('clicked')
        }
    }
    
    const actionItems = [
        {
            key: 'delete',
            text: 'Xóa',
            icon: 'Cancel',
            onClick: actionClick,
        },
        {
            key: 'download',
            text: 'Tải xuống',
            icon: 'CloudDownload',
            onClick: actionClick,
        }
    ];

    const Columns = [
        {
            key: 'index',
            name: '#',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            render : {type: 'index'}
        },
        {
            key: 'title',
            name: 'Tiêu đề',
            minWidth: 500,
            maxWidth: 500,
            isResizable: true,
            onRender: item=>{
                return <div><img width={32} style={{marginRight:8,verticalAlign:'middle'}} src={item.mediaUrl}/>{item.name}</div>
            }
        },
        {
            key: 'createdBy',
            fieldName: 'createdBy',
            name: 'Người tạo',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true,
            onRender: item => {
                return <div style={{color: '#0078D4'}}> {item.createdByName} </div>
            }   
        },
        {
            key: 'latestUpdate',
            fieldName: 'latestUpdate',
            name: 'Ngày cập nhật',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'datetime' },
        },
        {
            key: 'mediaType',
            fieldName: 'mediaType',
            name: 'Loại Media',
            minWidth: 150,
            maxWidth: 150,
            isResizable: true,
            render: { type: 'dropdown', options:mediaTypeList },
        },
        {
            key: 'functionId',
            fieldName: 'attachmentId',
            minWidth: 100,
            maxWidth: 100,
            render: { type: 'button', text: 'Chức năng', icon: '', items: actionItems },
        },
    ]

    const post = (page, firstLoad) => {
        setIsLoading(true);

        const params = {
            page: page + 1,
            pageSize: pageSize,
        };

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios(`contacts/${contactId}/attachments`, params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;
                    newGridData.items.forEach(x=>{
                        x.createdBy = staffList.find(y=>y.userId == x.createdById)?.userName
                    })
                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const onDelete = () => {
        deleteAxios(`contacts/${contactId}/attachments/${actionObject.attachmentId}`,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setModalDelete)

                let nextPage = gridData.page
                if (gridData.items.length-1 == 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })
        
    }

    const onAddClick=()=> {
        alert('clicked')
    }

    React.useEffect(() => {
        getAxios(`contacts/${contactId}`,null,async response=>{
            if (response.message){
                ShowErrorModal(response.message)
                return
            }

            await getMediaType().then(res => {
                mediaTypeList = res.map(x => ({
                    key: x.id,
                    text: x.name
                }))
            }).catch(()=>{})

            getAllStaff().then(res=>{
                if (res)
                    staffList=res
                const page = locParams ? locParams.page - 1 : 0;
                post(page, true);

                setContact(response.data)
            }).catch(setErrorModal)
        })
    }, []);

    return <div style={{ display: 'block', width: '100%', height: '100%' }}>
        <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
            hideModal={CloseErrorModal}/>

        <CustomModal title='Xóa tập tin' isModalOpen={ModalDelete.isToggle}
            hideModal={() => toogleModal(setModalDelete)} buttonAction="Xóa" width={640}
            onClick={onDelete} >
            <p style={{textAlign:'center'}}>Bạn có chắc chắn muốn xóa tập tin này không?</p>
        </CustomModal>

        <div style={{ display: "block", width: "100%", height: "100%", backgroundColor: "white", borderRadius: "10px" }}>
            <div style={{ padding:'16px', width: '100%', display: 'flex', height: "70px", justifyContent:'space-between'}}>
                <div style={{fontSize:14}}>
                    <Link onClick= {(event)=>actionClick("linkcontacts", null, null, event)}>Tài khoản</Link>
                    <label>	&gt; </label>
                    <Link onClick={(event)=>actionClick("linkcontact",contactId, null, event)}>{contact.name}</Link>
                    <p style={{fontSize:18,fontWeight:'bold'}}>Tập tin</p>
                </div>
                <div>
                    <DefaultButton text="Thêm mới" onClick={onAddClick}/>
                </div>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                <CustomDetailView
                    loading={isLoading}
                    columns={Columns}
                    gridData={gridData}
                    onPageChange={page => post(page)} 
                />
            </div>
        </div>
    </div>
})