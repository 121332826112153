import React from 'react';

import { useHistory } from 'react-router-dom';

import { ChevronDownMedIcon, ChevronRightMedIcon } from './../../../components/Icons/Icons';

import { useConditionedFetch } from '../../../hooks/useFetch';

import { sourcesApiEndpoint } from '../../../utils/constants';

import { getLeadInfo, getLeadInfoSystem, getLeadInfoDescription } from './helpers/leadInfo';

import { LeadContext } from '../../../context/lead';

import { pushHistory } from '../../../utils/helpers';

import { getClasses } from './styles';

/**
 *  get all classes
 */
const {
    detailItemContainer,
    detailItemHeader,
    itemDetails,
    singleItem,
    singleItemKey,
    singleItemValue,
    detailTitle,
    editLink,
} = getClasses();

/**
 * DetailItem component
 */
function DetailItem({ item, editable, leadId }) {
    const { values, title } = item;
    const history = useHistory();

    // toggle Item state
    const [isOpen, setIsOpen] = React.useState(true);

    // toggle item function
    const onOpenItem = () => {
        setIsOpen(!isOpen);
    };

    // styles for chevronIcons
    const chevCss = { cursor: 'pointer', width: 16, height: 16, fontSize: 16, fontWeight: '400' };
    return (
        <div className={detailItemContainer}>
            <div className={detailItemHeader}>
                <span
                    onClick={() => {
                        onOpenItem();
                    }}
                >
                    {isOpen ? <ChevronDownMedIcon css={chevCss} /> : <ChevronRightMedIcon css={chevCss} />}
                </span>
                <span className={detailTitle}>{title}</span>
                {editable && (
                    <span onClick={(event) => pushHistory(history, `/leads/${leadId}/edit`, null, event.ctrlKey)} className={editLink}>
                        Chỉnh sửa
                    </span>
                )}
            </div>
            {isOpen && (
                <div className={itemDetails}>
                    {values.map((oneItem, index) => {
                        return (
                            <div key={index} className={singleItem}>
                                <span className={singleItemKey}>{oneItem.key}</span>
                                <p className={singleItemValue}>{oneItem.value}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default function Details() {
    const { data: sourceData } = useConditionedFetch(`${sourcesApiEndpoint}`, 'GET', '', true);

    const { lead} = React.useContext(LeadContext);

    if (lead) {
        return (
            <div style={{ marginTop: 16 }}>
                <DetailItem editable={true} item={getLeadInfo(lead, sourceData)} leadId={lead.leadId} />
                <DetailItem editable={true} item={getLeadInfoDescription(lead)} leadId={lead.leadId} />
                <DetailItem editable={false} item={getLeadInfoSystem(lead)} />
            </div>
        );
    }
    return null;
}
