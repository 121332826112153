import {
    useHistory, useParams
} from "react-router-dom";
import React, { useEffect } from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton } from 'office-ui-fabric-react';


import { CustomButton,CustomDropdown,CustomTextField } from "../../components/BasicInput/CustomInput";
import { getAxios, putAxios } from "../../utils/axios";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateStaff.module.css'
import { validateEmail, validateMobile } from "../../utils/helpers";

const options= [
    { key: true, text: 'Có' },
    { key: false, text: 'Không' },
];

const choiceStyle = {
    flexContainer: {
        display: 'flex',
        selectors: {
            ".ms-ChoiceField": {
                margin:'3px 24px 0 0',
            }
        }
    }
}

const initalData = {isActive:true}

const onUpdateStaff = async(userId,data) => {
    return new Promise((res,rej) => {
      debugger;
        const param = {
            "userName": data.userName.trim(),
            "fullName": data.fullName.trim(),
            "mobile": validateMobile(data.mobile.trim()),
            "callCenterExt": data.callCenterExt.trim(),
            "email": data.email.trim(),
            "groupId": data.groupId,
            "isActive": data.isActive
        }
        putAxios(`users/${userId}`,param,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(appUserId,userName,fullName,mobile,email,groupId)=>{
    const errorValidate = {}
    if (!userName){
        errorValidate.userName = "Tên tài khoản không được bỏ trống"
    }
    if (!fullName){
        errorValidate.fullName = "FullName không được bỏ trống"
    }
    if (!mobile){
        errorValidate.mobile = "Số điện thoại không được bỏ trống"
    }
    else if (!validateMobile(mobile.trim())){
        errorValidate.mobile = "Số điện thoại không hợp lệ"
    }
    if (!email){
        errorValidate.email = "Email không được bỏ trống"
    }
    else if (!validateEmail(email.trim())){
        errorValidate.email = "Email không hợp lệ"
    }
    if (!groupId){
        errorValidate.group = "Nhóm chức năng không được bỏ trống"
    }
    return errorValidate
}

let groupList = []

const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getAllGroup = async () => {
    return getApi('/user-groups',{pageSize:1000000})
}

export default function UpdateStaff() {

    const {userId} = useParams()
    const history = useHistory()
    
    const [data,setData] = React.useState(initalData)

    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const OnBeforeUpdate = async () => {
        const errorValidate = validate(data.appUserId,data.userName,data.fullName,data.mobile,data.email,data.groupId)
        if (Object.keys(errorValidate).length == 0){
            setErrorInput({})
            return new Promise((res,rej)=>onUpdateStaff(userId,data).then(res).catch(err=>{
                ShowErrorModal(err)
                rej(err)
            }))
        }
        setErrorInput(errorValidate)
        return Promise.reject(errorValidate)
    }

    const onUpdate = () => {
        OnBeforeUpdate().then(()=>history.goBack()).catch(()=>{})
    }

    useEffect(()=>{
        getAxios(`users/${userId}`,null, async response=>{
            if (response.message) {
                //todo 
                ShowErrorModal(response.message)
                return
            }

            getAllGroup().then(res=>{
                const tmp = res?.items || []
                groupList = tmp.map(x=>({key:x.groupId,text:x.name}))
            })
            .catch(ShowErrorModal)
            .finally(()=>{
                response.data.permisson = localStorage.getItem('functionCodes').includes('Users_Full')
                setData(response.data)
            })
        })
        return ()=>setData({})
    },[])

    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Chỉnh sửa NVCS</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin</span>
                <div className={styles.box__component}>
                    
                    <div className={styles.item}>
                        <label className={styles.item__label}>AppUserId</label>
                        <CustomTextField width={318} disabled errorMessage={errorInput.appUserId}
                            value={data.appUserId}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Tên tài khoản</label>
                        <CustomTextField width={318} errorMessage={errorInput.userName}
                            value={data.userName||''} onChange={v=>setData(prev=>({...prev,userName:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>FullName</label>
                        <CustomTextField width={318} errorMessage={errorInput.fullName}
                            value={data.fullName||''} onChange={v=>setData(prev=>({...prev,fullName:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Số điện thoại</label>
                        <CustomTextField width={318} errorMessage={errorInput.mobile}
                            value={data.mobile||''} onChange={v=>setData(prev=>({...prev,mobile:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Số nội bộ</label>
                        <CustomTextField width={318} errorMessage={errorInput.callCenterExt}
                            value={data.callCenterExt||''} onChange={v=>setData(prev=>({...prev,callCenterExt:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Email</label>
                        <CustomTextField width={318} errorMessage={errorInput.email}
                            value={data.email||''} onChange={v=>setData(prev=>({...prev,email:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Nhóm chức năng</label>
                        <CustomDropdown width={318} errorMessage={errorInput.group}
                            value={data.groupId||''} onChange={v=>setData(prev=>({...prev,groupId:v}))}
                            options={groupList} />
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Kích hoạt</label>
                        <ChoiceGroup styles={choiceStyle} options={options} 
                            selectedKey={data.isActive} 
                            onChange={(e,it)=>setData(prev=>({...prev,isActive:it.key}))} />
                    </div>
                </div>
                
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    {data.permisson && <CustomButton label="Lưu" onClick={onUpdate} />}
                </div>
            </div>
        </div>
    );
}   