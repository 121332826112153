import React from 'react';

// styles
import { getClasses } from './styles';

const { headerDeco, headerText } = getClasses();

export function FormHeader({ text }) {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={headerDeco}></span>
            <h3 className={headerText}>{text}</h3>
        </div>
    );
}
