import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor, borderLightGrayColor } from '../../styles/colors';

export const getClasses = ({ moreClassName }) => {
    return mergeStyleSets({
        more: {
            color: secondaryLightColor,
            padding: 12,
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'center',
            borderTop: `1px solid ${borderLightGrayColor}`,
            cursor: 'pointer',
        },
        moreOptional: moreClassName,
    });
};
