import { mergeStyleSets } from '@fluentui/react';

import { borderGrayColor, whiteColor, secondaryLightColor } from '../../../styles/colors';

import { borderRadius15px } from '../../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: borderRadius15px,
            paddingBottom: '1rem',
            height: '100%',
            gap: 16,
            overflow: 'hidden',
            backgroundColor: whiteColor,

        },
        headerContainer: {
            borderRadius: borderRadius15px,
            border: `1px solid ${borderGrayColor}`,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
        },
    });
};

export const getLinkClasses = () => {
    return mergeStyleSets({
        link: {
            color: secondaryLightColor,
            cursor: 'pointer',
            textDecoration: 'none',
        },
    });
};
