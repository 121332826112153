import React from 'react';

const LeadContext = React.createContext();

function LeadProvider({ children }) {
    const [lead, setLead] = React.useState(null);

    // get lead
    const getLead = (lead) => {
        setLead(lead);
    };

    return (
        <LeadContext.Provider
            value={{
                lead,
                getLead,
            }}
        >
            {children}
        </LeadContext.Provider>
    );
}

export { LeadContext, LeadProvider };
