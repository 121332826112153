import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { whiteColor, darkJellyBeanColor, textGrayColor, secondaryLightColor } from './../../styles/colors';

export const getClasses = ({ sectionHeaderCss, boxCss }) => {
    return mergeStyleSets({
        textContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '1rem',
        },
        sectionHeader: {
            padding: 20,
            backgroundColor: whiteColor,
            display: 'flex',
            position: 'relative',
            borderRadius: 15,
        },
        optionalSectionHeader: sectionHeaderCss,
        boxStyles: {
            display: 'block',
            width: 36,
            height: 36,
            backgroundColor: darkJellyBeanColor,
            borderRadius: 6,
            marginRight: 12,
        },
        optionalBox: boxCss,
        subtextSectionHeader: {
            fontSize: '13px',
            fontWeight: '400',
            lineHeight: '16.9px',
            color: textGrayColor,
        },
        subTextLinkHeader: {
            fontSize: '13px',
            fontWeight: '400',
            lineHeight: '16.9px',
            listStyle: 'none',
            textDecoration: 'none',
            color: secondaryLightColor,
        },
        mainTextStyles: {
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '23.4px',
        },
    });
};
