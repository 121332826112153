import React from 'react';
import { NavLink, Route, Redirect } from 'react-router-dom';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { PrimaryButton } from 'office-ui-fabric-react';

import { getClasses } from './styles';
import { CustomTextField } from '../BasicInput/CustomInput';

import { getAxios, postAxios } from "../../utils/axios";
import ErrorModal from '../../components/Modal/ErrorModal';
import { AccountContext } from "../../context/account";

const {
    nav,
    navLink,
    navLinkActive,
    container,
    formContainer,
    navFormContainer,
} = getClasses();

const CallForm_validate = (title, description) => {
    const errorValidate = {}

    if (!title) {
        errorValidate.title = 'Chủ đề không được bỏ trống'
    }
    if (!description) {
        errorValidate.description = "Nội dung không được bỏ trống"
    }

    return errorValidate
}
function CallForm({ path, typeId }) {
    const [formData, setFormData] = React.useState({})
    const { account } = React.useContext(AccountContext)
    const [errorInput, setErrorInput] = React.useState({})
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false })
    const ShowErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), [])
    const CloseErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), [])


    const onClick = () => {
        const errorValidate = CallForm_validate(formData.title, formData.description)
        if (Object.keys(errorValidate).length === 0) {
            setErrorInput({})
            const param = {
                title: formData.title,
                description: formData.description,
                activityTypeId: typeId?.id
            }
            postAxios(`${path}/${account.accountId}/feeds/`, param, response => {
                if (response.message) {
                    ShowErrorModal(response.message)
                    return
                }
                //done
                setFormData({})
                ShowErrorModal("Thêm thành công")
            })
            return
        }
        setErrorInput(errorValidate)
    }

    return (
        <>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal} />
            <form style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>

                <div>
                    <TextField maxLength={250} value={formData.title || ''} errorMessage={errorInput.title}
                        onChange={(_, v) => setFormData(prev => ({ ...prev, title: v }))}
                        label="Chủ đề" />
                </div>
                <div>
                    <CustomTextField value={formData.description || ''} errorMessage={errorInput.description}
                        onChange={v => setFormData(prev => ({ ...prev, description: v }))}
                        width="100%" rows={4} label="Nội dung" />
                </div>
                <div style={{ alignSelf: 'flex-end' }}>
                    <PrimaryButton text="Lưu"
                        onClick={onClick}
                    />
                </div>
            </form>
        </>
    );
}

function EmailForm() {
    const [toValue, setToValue] = React.useState('');

    return (
        <form style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <TextField label="Từ" type="email" />
            <TextField label="Đến" type="email" />
            <TextField label="Bcc" value={toValue} onChange={(e) => setToValue(e.value)} />
            <TextField label="Chủ đề" />
            <div style={{ marginTop: '1rem' }}>
                <TextField multiline rows={6} />
            </div>
            <div style={{ alignSelf: 'flex-end' }}>
                <PrimaryButton text="Gửi" />
            </div>
        </form>
    );
}

const Activities_getTypeID = (arr, code) => {
    return arr?.find(x => x.code === code)
}

export default function Activities({ path, extraPath }) {
    const [clicked, setClicked] = React.useState(false);

    const [activityType, setActivityType] = React.useState(null)
    React.useEffect(() => {
        getAxios('activity-types', null, resp => {
            if (resp.data) {
                setActivityType(resp.data)
            }
        })
    }, [])

    const lastLinkClicked = () => {
        if (clicked) {
            return;
        }

        setClicked(true);
    };
    const firstLinkClicked = () => {
        setClicked(false);
    };

    return (
        <>
            <div className={container}>
                <div className={navFormContainer}>
                    <nav className={nav}>
                        <NavLink
                            className={navLink}
                            onClick={firstLinkClicked}
                            activeClassName={navLinkActive}
                            to={`${path}/${extraPath}/call`}
                        >
                            Ghi chú
                        </NavLink>
                        {/* <NavLink
                            className={navLink}
                            activeClassName={navLinkActive}
                            onClick={lastLinkClicked}
                            to={`${path}/${extraPath}/email`}
                        >
                            Email
                        </NavLink> */}
                        {clicked && <NavLink className={navLink} onClick={lastLinkClicked} to="#"></NavLink>}
                    </nav>

                    <div className={formContainer}>
                        <Route path={`${path}/${extraPath}/call`}>
                            {activityType && <CallForm path={path}
                                typeId={Activities_getTypeID(activityType, 'activitytype_call')} />}
                        </Route>
                        <Route path={`${path}/${extraPath}/email`}>
                            <EmailForm />
                        </Route>
                        <Route exact path={`${path}/${extraPath}`}>
                            <Redirect to={{ pathname: `${path}/${extraPath}/call` }} />
                        </Route>
                    </div>
                </div>
            </div>
        </>
    );
}
