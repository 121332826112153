import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor, borderGrayColor, middleGrayColor, textGrayColor } from './../../styles/colors';

import { borderRadius15px } from './../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: borderRadius15px,
            paddingBottomd: '1rem',
            height: '100%',
            overflow: 'auto',
            gap: 16,
        },
        headerContainer: {
            borderRadius: borderRadius15px,
            border: `1px solid ${borderGrayColor}`,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
        },
        btnGroup: {
            alignSelf: 'flex-end',
            display: 'flex',
        },
        briefInfo: {
            backgroundColor: middleGrayColor,
            display: 'flex',
            gridColumnStart: '1',
            gridColumnEnd: '3',
            borderBottomLeftRadius: borderRadius15px,
            borderBottomRightRadius: borderRadius15px,
            padding: 16,
            gap: 32,
        },
        content: {
            display: 'flex',
            gap: 16,
        },

        briefInfoTitle: {
            color: textGrayColor,
            fontSize: 12,
        },
        briefInfoContent: {
            color: secondaryLightColor,
            fontSize: 14,
        },
    });
};
