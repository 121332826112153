import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import CustomDetailView from '../../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../../utils/helpers';
import { CustomDropdown, CustomToggle } from '../../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../../utils/axios';
import DateFilterForm from '../../../components/DateFilter/DateFilterForm';
import FilterButton from '../../../components/Buttons/FilterButon';
import FilterPanel from '../../../components/FilterPanel/FilterPanel';
import DateFilterFormByStatus from '../../../components/DateFilter/DateFilterFormByStatus';
import { isAgentOptions } from '../../../utils/constants';
import ChannelProductFilter from './ChannelProductFilter';
import { Icon, Link } from '@fluentui/react';
import { formatDateDMY } from '../../../utils/date';
import { DownloadForm } from '../../../components/DownloadForm';

const PAGE_TITLE = "Báo cáo dịch vụ sắp hết hạn chi tiết";

const useIsReadyHook = ()=>{

    const [Status,setStatus] = React.useState(false)
    const [ChannelProduct,setChannelProduct] = React.useState(false)

    return [
        {Status,ChannelProduct},
        {setStatus,setChannelProduct}
    ]
}

const ExpirationServiceDetails = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const { userId } = useParams();
    const [userName,setUserName] = React.useState(locParams.userName)

    const [isReady, setIsReady] = useIsReadyHook()

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [isAgent,setIsAgent] =  React.useState(locParams.isAgent);

    const [date, setDate] = React.useState(
        { from: locParams.from, to: locParams.to, key: locParams.dateKey }
    );

    const [channelId,setChannelId] = React.useState(locParams.channelId)
    const [productId,setProductId] = React.useState(locParams.productId)
   
    React.useEffect(() => {
        if (isReady.ChannelProduct && isReady.Status){
            post(0, true);
        }
    }, [isReady.ChannelProduct, isReady.Status]);

    const refParam = React.useRef({})
    
    //function
    const post = (page, firstLoad,isPageChange) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        let params = {
            userName,
            productId,
            userId: userId,
            channelId: channelId,
            isAgent: isAgent,
            pageSize: pageSize,
            from: date.from,
            to: date.to,
            dateKey: date.key,
            beginDate: date.beginDate,
            endDate: date.endDate,
            toogleFilter: filter.value
        };

        if (!isPageChange)
            refParam.current = params
        else 
            params = refParam.current
        
        params.page= page + 1

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios('reports/expiration-service-details', params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    // response.data.items.forEach((item) => {
                    //     item.numDate = (new Date(item.endDate) - new Date(item.startDate)) / 86400000;
                    // });

                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0)
                        pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                    if (pageSize == newGridData.items.length) {
                        newGridData.isNext = true
                    }
                }
                else if (response.message) {
                    if (!isPageChange)
                        newGridData.error = response.message;
                    else 
                        return
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    };

    const actionClick = (action, value, item, event) => {
        if (action == "detail") {
            pushHistory(history, `/contacts/${item.contactId}/details`, null, event.ctrlKey);
        } else if (action == "invoice") {
            pushHistory(history, '/invoicedetail', { code: value }, event.ctrlKey);
        }
    };

    const onBack = ()=>{
        pushHistory(history,'/report/expiration-services',locParams)
    }

    const columns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        // { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 200, maxWidth: 200, isResizable: true },
        { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
        { name: 'Tên', key: 'contactName', fieldName: 'contactName', minWidth: 200, maxWidth: 200, isResizable: true },
        { name: 'Dịch vụ', key: 'productName', fieldName: 'productName', minWidth: 200, maxWidth: 200, isResizable: true },
        { name: 'Tiêu đề', key: 'title', fieldName: 'title',isMultiline:true, minWidth: 200, maxWidth: 200, isResizable: true },
        { name: 'Số ngày', key: 'quantity', fieldName: 'quantity', minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'money' } },
        { name: 'Ngày hết hạn', key: 'endDate', fieldName: 'endDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        { name: 'Hóa đơn', key: 'invoiceCode', fieldName: 'invoiceCode', minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'link', key: 'invoice', onClick: actionClick }  },
    ];

    let onExportClick = null
    if (gridData.items?.length > 0)
        onExportClick = () => {

            const params = {
                userId: userId,
                productId: productId,
                channelId: channelId,
                isAgent: isAgent,
                beginDate: date.beginDate,
                endDate: date.endDate  
            };

        
            getFile('/reports/expiration-service-details/excel', params, response => {
                const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
                DownloadForm(response.data, filename)
            });
        }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>
                    
                <DateFilterFormByStatus keyId={date.key} from={date.from} 
                    to={date.to} width={250} onChange={(date) => setDate(date)}
                    onLoaded={setIsReady.setStatus}
                    />

                <ChannelProductFilter channelId={channelId} productId={productId}
                    setChannelId={setChannelId} setProductId={setProductId}
                    onLoaded={setIsReady.setChannelProduct}
                    />
                
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại tài khoản"
                        width={250}
                        height={350}
                        options={isAgentOptions}
                        value={isAgent}
                        onChange={setIsAgent}
                    />
                </div>

            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>

                <div style={{ width: '100%', display: 'inline-flex', height: "106px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <div style={{fontSize:14,padding: '18px 0 0 16px'}}>
                            <Link onClick={onBack}><Icon iconName="NavigateBack"/> Trở lại báo cáo tổng hợp</Link>
                        </div>
                        <SectionHeader
                            title={{ text: `${PAGE_TITLE} của ${userName}` }}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 106px)' }}>
                    <CustomDetailView
                        dynamicPagination
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page,false,true)} />
                </div>
            </div>
        </div >
    );
}
export default ExpirationServiceDetails;
