import { mergeStyleSets } from '@fluentui/react';
import { whiteColor } from './../../../styles/colors';
export const getClasses = () => {
    return mergeStyleSets({
        sideInfo: {
            flex: '1',
            flexDirection: 'column',
            display: 'flex',
            borderRadius: 15,
        },
        title: {
            fontSize: 16,
            fontWeight: '700',
            backgroundColor: whiteColor,
            padding: 16,
            borderTopLeftRadius: 15,
            borderTopRightRadius: 15,
        },
    });
};
