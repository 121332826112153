import React from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { secondaryLightColor } from '../../../styles/colors';

export default function YesNoRadio({ label = 'Kích hoạt', isActive }) {
    const options = [
        { key: 'A', text: 'Có', disabled: !isActive },
        { key: 'B', text: 'Không', disabled: isActive },
    ];

    return (
        <div>
            <ChoiceGroup
                label={label}
                options={options}
                styles={{
                    flexContainer: { display: 'flex', gap: 16 },
                    label: {
                        color: secondaryLightColor,
                        fontWeight: 500,
                        padding: 0,
                        paddingBottom: 4,
                        fontSize: 14,
                        fontFamily: 'Roboto',
                    },
                }}
                defaultSelectedKey={isActive ? 'A' : 'B'}
            />
        </div>
    );
}
