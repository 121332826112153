// JavaScript source code
import axios from 'axios';
const url = process.env.REACT_APP_API_ENDPOINT;

export async function deleteAxios(getUrl, param, callback) {
    const token = localStorage.getItem('AuthToken');
    axios.defaults.baseURL = url;
    axios.method = 'DELETE';
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

    axios.delete(getUrl, { params: param }).then((response) => {
        if (callback) {
            return callback(response)
        }
    }).catch((error) => {
        if (error.response && error.response.data) {
            var errData = error.response.data;
            if (callback) {
                return callback({ status:error.response.status, message: errData.message });
            }
            else {
                console.log(errData);
            }
        }
        else {
            if (callback) {
                return callback({ message: error.message });
            }
            else {
                console.log(error);
            }
        }
    });
}

export async function getFile(getUrl, param, callback) {
    const token = localStorage.getItem('AuthToken');
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

    axios.get(getUrl, { params: param, responseType: 'arraybuffer' }).then((response) => {
        if (callback) {
            return callback(response)
        }
    }).catch((error) => {
        if (error.response && error.response.data) {
            var errData = error.response.data;
            if (callback) {
                return callback({ status:error.response.status, message: errData.message });
            }
            else {
                console.log(errData);
            }
        }
        else {
            if (callback) {
                return callback({ message: error.message });
            }
            else {
                console.log(error);
            }
        }
    });
}

export async function getAxios(getUrl, param, callback) {
    const token = localStorage.getItem('AuthToken');
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

    axios.get(getUrl, { params: param }).then((response) => {
        if (callback) {
            return callback(response)
        }
    }).catch((error) => {
        if (error.response && error.response.data) {
            var errData = error.response.data;
            if (callback) {
                return callback({ status:error.response.status, message: errData.message });
            }
            else {
                console.log(errData);
            }
        }
        else {
            if (callback) {
                return callback({ message: error.message });
            }
            else {
                console.log(error);
            }
        }
    });
}

export async function getAwaitAxios(getUrl, param, callback) {
    const token = localStorage.getItem('AuthToken');
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

    const result= await axios.get(getUrl, { params: param });

    if (callback) {
        return callback(result)
    }
    return result
}

export async function getAxiosWithToken(newToken, getUrl, param, callback) {
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${newToken}` };

    axios.get(getUrl, { params: param }).then((response) => {
        if (callback) {
            return callback(response)
        }
    }).catch((error) => {
        if (error.response && error.response.data) {
            var errData = error.response.data;
            if (callback) {
                return callback({ status: error.response.status, message: errData.message });
            }
            else {
                console.log(errData);
            }
        }
        else {
            if (callback) {
                return callback({ message: error.message });
            }
            else {
                console.log(error);
            }
        }
    });
}

export async function postAxios(postUrl, body, callback) {
    const token = localStorage.getItem('AuthToken');
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

    axios.post(postUrl, body).then((response) => {
        if (callback)
            return callback(response);
    }).catch((error) => {
        var errData = error.response.data;
        if (callback) {
            if (Array.isArray(errData)) {
                var arrMess = errData.map(obj => {
                    return obj.message;
                });
                return callback({ status:error.response.status, message: arrMess });
            }
            else {
                return callback({ status:error.response.status, message: errData.message });
            }
        }
        else {
            console.log(errData);
        }
    });
}

export async function putAxios(postUrl, body, callback) {
    const token = localStorage.getItem('AuthToken');
    axios.defaults.baseURL = url;
    axios.defaults.headers.common = { 'Authorization': `Bearer ${token}` };

    axios.put(postUrl, body).then((response) => {
        if (callback)
            return callback(response);
    }).catch((error) => {
        var errData = error.response.data;
        if (callback) {
            if (Array.isArray(errData)) {
                var arrMess = errData.map(obj => {
                    return obj.message;
                });
                return callback({ status:error.response.status, message: arrMess });
            }
            else {
                return callback({ status:error.response.status, message: errData.message });
            }
        }
        else {
            console.log(errData);
        }
    });
}
