import { mergeStyleSets } from '@fluentui/react';

import {
    whiteColor,
    secondaryLightColor,
    borderLightGrayColor,
    textGrayColor,
    lightGrayColor,
} from '../../styles/colors';

export const getClasses = ({ noteListClassName, noteItemClassName, headingClassName }) => {
    return mergeStyleSets({
        container: {
            borderRadius: 15,
            backgroundColor: whiteColor,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
            border: `1px solid ${borderLightGrayColor}`,
            overflow: 'hidden',
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: 16,
            backgroundColor: lightGrayColor,
            borderBottom: `1px solid ${borderLightGrayColor}`,
        },
        headingOptional: headingClassName,
        noteList: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(260px, 1fr))',
            padding: '0 16px',
            gridGap: 16,
        },
        noteListOptional: noteListClassName,
        noteItem: {
            padding: '8px 0',
            // borderBottom: `1px solid ${borderLightGrayColor}`,
        },
        noteItemOptional: noteItemClassName,
        noteTitle: {
            color: secondaryLightColor,
            fontSize: 14,
        },
        noteUpdated: {},
        noteUpdatedBy: {
            color: secondaryLightColor,
            fontSize: 12,
        },
        noteUpdatedAt: {
            color: textGrayColor,
            fontSize: 12,
        },
        noteContent: { lineHeight: '21px', fontSize: 14 },
    });
};
