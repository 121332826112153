import { mergeStyleSets } from '@fluentui/react';

import {
    secondaryLightColor,
    borderGrayColor,
    lightGrayColor,
    textGrayColor,
    whiteColor,
} from '../../../styles/colors';

export const getClasses = () => {
    return mergeStyleSets({
        container: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        navFormContainer: {
            marginTop: 16,
            border: `1px solid ${borderGrayColor}`,
        },
        nav: {
            backgroundColor: lightGrayColor,
            display: 'flex',
            whiteSpace: 'nowrap'
        },
        navLink: {
            padding: 16,
            fontSize: 15,
            fontWeight: 500,
            textDecoration: 'none',
            color: textGrayColor,
            borderBottom: `1px solid ${borderGrayColor}`,
            '&:last-child': {
                flex: 1,
            },
        },
        navLinkFitContent: {
            width: 'fit-content'
        },
        navLinkActive: {
            backgroundColor: whiteColor,
            borderRight: `1px solid ${borderGrayColor}`,
            borderLeft: `1px solid ${borderGrayColor}`,
            borderBottom: 'none',

            '&:first-child': {
                borderLeft: 'none',
            },
            '&:last-child': {
                flex: 0,
            },
        },
        formContainer: {
            padding: 16,
        },
        filter: {
            alignSelf: 'flex-end',
            fontSize: 13,
            display: 'flex',
            flexDirection: 'column',
            gap: 14,
            alignItems: 'flex-end',
        },
        filterUpper: { display: 'flex', alignItems: 'center' },
        filterLower: {
            color: secondaryLightColor,
        },
    });
};
