import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { getAwaitAxios, getAxios, getFile } from '../../utils/axios';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import StaffFilter from '../../components/StaffFilter/StaffFilter';
import MultiChannelProductFilter from '../../components/ChannelProductFilter/MultiChannelProductFilter';
import { formatDateDMY } from '../../utils/date';
import { DownloadForm } from '../../components/DownloadForm';

const PAGE_TITLE = "Báo cáo doanh thu dịch vụ theo nhân viên - Tổng hợp";

const staffList = () => {
    let tempStaffType = [{ key: '1', text: 'Tôi chăm sóc' }];
    const functionCodes = localStorage.getItem("functionCodes");

    if (functionCodes && functionCodes.length > 0) {
        const functionCodeArr = functionCodes.split(',');
        if (functionCodeArr.indexOf("Reports_Full") != -1) {
            tempStaffType.push(
                {
                    key: '',
                    text: 'Tất cả',
                },
                {
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles`,
                        key: 'roleNode',
                        text: 'name'
                    }
                },
                {
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: 'users',
                        key: 'userId',
                        text: 'userName'
                    }
                }
            );
        }

        const hasChildRole = localStorage.getItem('hasChildRole');
        if (hasChildRole != '0' && functionCodeArr.indexOf("Reports_Read_Family") != -1) {
            if (!tempStaffType.find(x => x.key == '2')) {
                tempStaffType.push({
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles/${localStorage.getItem('roleId')}/children`,
                        key: 'roleNode',
                        text: 'name',
                    }
                });
            };

            if (!tempStaffType.find(x => x.key == '3')) {
                tempStaffType.push({
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: `users/${localStorage.getItem('userId')}/children`,
                        key: 'userId',
                        text: 'userName'
                    }
                });
            };
        }
    }

    return tempStaffType.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
}

const UserRevenuesByProducts = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);
    //state
    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [columns, setColumns] = React.useState([]);
    const [filter, setFilter] = React.useState(locParams && locParams.toggleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );

    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [isFirstLoad2, setIsFirstLoad2] = React.useState(true);
    const [staffFilter, setStaffFilter] = React.useState(
        locParams ? { key: locParams.filterKey, value: locParams.filterValue } : { key: '', value: '' });
    
    const [productList, setProductList] = React.useState([]);
    
    const [productFilter, setProductFilter] = React.useState(
        locParams ? 
        { channelId: locParams.channelId, productId: locParams.products } : 
        { channelId: '', productId: '' });
    //init
    React.useEffect(() => {
        if (locParams && locParams.page) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
        
    }, [isFirstLoad]);

    //function
    const buildDetailviewColumns = () => {
        let i;
        let newColumns = [];
        const productArr = productFilter.productId.split(',').filter(x => x.length > 0).sort((a,b) => a-b);

        if (productArr.length > 0 && productList.length > 0 && productArr.length == productList.length) {
            for (i = 0; i < productArr.length; i++) {
                const name = productList.find(x => x.key == productArr[i]).text;
                const groupColumn = {
                    name: name, key: `group${i + 1}`, fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                        { name: 'Số HĐ', key: `total${i + 1}`, fieldName: `total${i + 1}`, minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'money' } },
                        { name: 'Số ngày', key: `quantity${i + 1}`, fieldName: `quantity${i + 1}`, minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'money' } },
                        { name: 'Số tiền', key: `amount${i + 1}`, fieldName: `amount${i + 1}`, minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'money' } },

                    ]
                };
                newColumns.push(groupColumn);
            }
        }

        const groupColumnTotal = {
            name: "Tổng", key: '', fieldName: '', minWidth: 200, maxWidth: 200, render: { type: 'group' }, subColumns: [
                { name: 'Số HĐ', key: `total`, fieldName: `total`, minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'money' } },
                { name: 'Số tiền', key: `amount`, fieldName: `amount`, minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'money' } },
            ]
        };
        newColumns.push(groupColumnTotal);
        return [...baseColumns, ...newColumns];
    }

    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }

            if (productList.length == 0) {
                return false;
            }
        }
        
        setIsLoading(true);

        const params = {
            filterKey: staffFilter.key,
            filterValue: staffFilter.value,
            channelId: productFilter.channelId,
            products: productFilter.productId,
            dateKey: date.key,
            beginDate: date.beginDate,
            endDate: date.endDate,
        };

        if (staffFilter.key == "1") {
            params.userId = localStorage.getItem('userId');
        }
        else if (staffFilter.key == "2") {
            params.roleNode = staffFilter.value;
        }
        else {
            params.userId = staffFilter.value;
        }

        if (!firstLoad) {
            pushHistory(history, '/report/user-revenues-by-products', params);
        }

        getAxios('reports/user-revenues-by-products', params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data;

                    const columns = buildDetailviewColumns();
                    setColumns(columns);
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toggleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const actionClick = (action, value, item, event) => {
        if (action == "detail") {
            const params = {
                userId: item.userId,
                userName: item.userName,
                channelId: productFilter.channelId,
                productId: productFilter.productId,
                dateKey: date.key,
                beginDate: date.beginDate,
                endDate: date.endDate,
            };

            pushHistory(history, '/report/user-revenues-by-products-detail', params, event.ctrlKey);
        }
    };

    const baseColumns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 250, maxWidth: 250, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
    ];

    const onChangeProduct = (products) => {
        setProductList(products);
        setIsFirstLoad(true);
    }

    let onExportClick = null
    if (gridData.items?.length > 0)
        onExportClick = () => {
            const params = {
                products: productFilter.productId,
                channelId: productFilter.channelId,
                beginDate: date.beginDate,
                endDate: date.endDate,
            };

            if (staffFilter.key == "1") {
                params.userId = localStorage.getItem('userId');
            }
            else if (staffFilter.key == "2") {
                params.roleNode = staffFilter.value;
            }
            else {
                params.userId = staffFilter.value;
            }

            getFile('/reports/user-revenues-by-products/excel', params, response => {
                const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
                DownloadForm(response.data, filename)
            });
        }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toggleFilter(false)}
                onClick={() => post(0, false)}>

                <StaffFilter data={staffFilter} typeList={staffList()} width={250} onChange={data => setStaffFilter(data)} />

                <MultiChannelProductFilter
                    value={productFilter}
                    onChange={(value) => setProductFilter(value)}
                    onChangeProduct={onChangeProduct}
                    isFirstLoad={isFirstLoad2}
                    onLoad={() => setIsFirstLoad2(false)}
                />
                
                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: `${PAGE_TITLE}` }}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toggleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        isReport={true}
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)}
                        fixedMode />
                </div>
            </div>
        </div >
    );
}
export default UserRevenuesByProducts;
