import React from 'react';
import { useHistory } from 'react-router-dom';

import Feeds from '../../../components/Feeds/Feeds';
import Attachments from '../../../components/Attachments/Attachments';

import { leadsApiEndpoint, feedsApiEndpoint, attchmentsApiEndpoint } from '../../../utils/constants';

import { getClasses } from './styles';
import { borderLightGrayColor, darkGrayColor, whiteColor } from '../../../styles/colors';
import LeadDead from './LeadDead';
import { pushHistory } from '../../../utils/helpers';

const { sideInfo } = getClasses();

// SideInfo component
export default function SideInfo({ id }) {
    const history = useHistory();

    return (
        <aside className={sideInfo}>
            <LeadDead />
            <Feeds
                noteItemClassName={{ borderBottom: `1px solid ${borderLightGrayColor}` }}
                path={`${leadsApiEndpoint}/${id}${feedsApiEndpoint}`}
                noteListClassName={{ gridGap: 16 }}
                headingClassName={{ backgroundColor: whiteColor }}
                handleMoreLinkClick={(event) => pushHistory(history, `/leads/${id}/feeds`, null, event.ctrlKey)}
            />
            <Attachments
                fileItemClassName={{ border: 'none' }}
                path={`${leadsApiEndpoint}/${id}${attchmentsApiEndpoint}`}
                headingClassName={{ color: darkGrayColor, backgroundColor: whiteColor }}
                handleMoreLinkClick={(event) => pushHistory(history, `/leads/${id}/attachments`, null, event.ctrlKey )}
            ></Attachments>
        </aside>
    );
}
