import * as React from 'react';
import { monthNames, shortMonths, shortDays, formatDateVn } from '../../utils/date';

import { DatePicker, mergeStyleSets } from 'office-ui-fabric-react';

const DayPickerStrings = {
    months: monthNames,
    shortMonths,
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays,
};

const DateSelect = (props) => {
    const { disabled, onSelectDate, value, label, width, borderless = false, styles } = props;

    const controlClass = mergeStyleSets({
        control: {
            width: width ? width : 300,
        },
    });

    return (
        <div>
            <DatePicker
                disabled={disabled}
                label={label}
                className={controlClass.control}
                strings={DayPickerStrings}
                placeholder="Chọn ngày..."
                value={value}
                formatDate={formatDateVn}
                onSelectDate={onSelectDate}
                borderless={borderless}
                styles={styles}
            />
        </div>
    );
};

export default DateSelect;
