import React from 'react';

// utils
import { formatTimeDate } from './../../../../utils/date';
import { detectSource, detectStatus, styleBlueText } from './../../../../utils/helpers';


export const getLeadInfo = (lead, sourceData) => {
    const {
        leadId,
        mobile,
        email,
        title,
        status,
        sourceId,
        assignedToName,
        assignedDate,
        convertedDate
    } = lead;

    let info = {
        key: leadId,
        title: 'Thông tin lead',
        values: [
            {
                key: 'Họ tên',
                value: title,
            },
            {
                key: 'Điện thoại',
                value: styleBlueText(mobile),
            },
            {
                key: 'Email',
                value: styleBlueText(email),
            },
            {
                key: 'Trạng thái',
                value: detectStatus(status),
            },
            {
                key: 'Nguồn Leads',
                value: detectSource(sourceData, sourceId),
            },
            {
                key: 'NVCS',
                value: styleBlueText(assignedToName) ,
            },
            {
                key: 'Ngày chuyển đổi',
                value: convertedDate ? formatTimeDate(convertedDate) : '' ,
            },
            {
                key: 'Ngày chăm sóc',
                value: formatTimeDate(assignedDate) ,
            },
        ],
    };

    return info;
};

export const getLeadInfoDescription = (lead) => {
    const { description } = lead;
    return {
        title: 'Thông tin Mô tả',
        values: [
            {
                key: 'Mô tả',
                value: description,
            },
        ],
    };
};

export const getLeadInfoSystem = (lead) => {
    const { createDate, latestUpdate, createdByName, updatedByName } = lead;

    return {
        title: 'Thông tin Hệ thống',
        values: [
            {
                key: 'Ngày tạo',
                value: (
                    <>
                        <span>{formatTimeDate(createDate)} </span>
                        {styleBlueText(createdByName)}
                    </>
                ),
            },
            {
                key: 'Ngày cập nhật',
                value: (
                    <>
                        <span>{formatTimeDate(latestUpdate)} </span>
                        {styleBlueText(updatedByName)}
                    </>
                ),
            },
        ],
    };
};
