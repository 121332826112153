import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor } from '../../styles/colors';

export const getClasses = () => {
    return mergeStyleSets({
        editLink: {
            color: secondaryLightColor,
            cursor: 'pointer',
            fontSize: 14,
        },
    });
};
