import { mergeStyleSets } from '@fluentui/react';

import {
    secondaryLightColor,
    borderGrayColor,
    lightGrayColor,
    whiteColor,
} from './../../styles/colors';

import { borderRadius15px } from './../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: borderRadius15px,
            paddingBottomd: '1rem',
            height: '100%',
            gap: 16,
            overflow: 'auto',
        },
        headerContainer: {
            borderRadius: borderRadius15px,
            border: `1px solid ${borderGrayColor}`,
            overflow: 'hidden',
        },
        content: {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
        },
        infoContainer: {
            backgroundColor: whiteColor,
            borderRadius: borderRadius15px,
        },
        hierachy: {
            display: 'flex',
            padding: '16px 0 0 16px',
            fontSize: 12,
        },
        info: {
            padding: '16px 0 16px 0 ',
        },
        infoList: {
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(318px, 318px))',
            gridGap: 16,
            padding: 16,
        },
        itemContainer: {
            display: 'flex',
            flexDirection: 'column',
            gap: 8,
        },
        itemKey: {
            color: secondaryLightColor,
            fontSize: 14,
            fontWeight: 500,
        },
        itemValue: {
            backgroundColor: lightGrayColor,
            padding: 8,
            fontSize: 15,
            height: 36,
        },
        userGroupContainer: {
            padding: 16,
            backgroundColor: whiteColor,
            borderRadius: borderRadius15px,
        },
    });
};
