import React from 'react';
import { useHistory, useLocation, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { getAxios,deleteAxios } from '../../utils/axios';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import StaffDetails from '../StaffDetails/StaffDetails';

import AddStaff from './AddStaff'
import UpdateStaff from './UpdateStaff';

export default function CareStaff () {
    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <ListCareStaff />
            </Route>
            <Route path={`${path}/:userId/edit`}>
                <UpdateStaff />
            </Route>
            <Route path={`${path}/add`}>
                <AddStaff />
            </Route>
            <Route path={`${path}/:userId/details`}>
                <StaffDetails />
            </Route>
        </Switch>
    </>)
}

function ListCareStaff() {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');

    const [actionObject, setActionObject] = React.useState({})

    const [staffModalDelete, setStaffModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })
    
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    //init
    React.useEffect(() => {
        const page = locParams ? locParams.page - 1 : 0;
        post(page, true);
    }, []);

    //function
    const onAddClick = (event) => {         
        pushHistory(history, `${location.pathname}/add`, null, event.ctrlKey);
    };

    const post = (page, firstLoad) => {

        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            page: page + 1,
            pageSize: pageSize,
        };

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios('users', params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const actionClick = (action, id, item, event) => {
        switch (action) {
            case "update":
                pushHistory(history, `${location.pathname}/${item.userId}/edit`, null, event.ctrlKey);
                break
            case "delete":
                setActionObject(item)
                setStaffModalDelete({value:'',isToggle:true})
                break
            case 'link': 
                pushHistory(history, `${location.pathname}/${item.userId}/details`, null, event.ctrlKey);
                break;
        }
    }

    //options
    const actionItems = [
        {
            key: 'update',
            text: 'Chỉnh sửa',
            icon: 'Edit',
            onClick: actionClick,
        },
        {
            key: 'delete',
            text: 'Xóa',
            icon: 'Cancel',
            onClick: actionClick,
        }
    ];


    const groupColumns = [
        {
            key: 'userId',
            fieldName: 'userId',
            name: 'UserID',
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
        },
        {
            key: 'userName',
            fieldName: 'userName',
            name: 'Tên tài khoản',
            minWidth: 100,
            maxWidth: 180,
            isResizable: true,
            render: { type: 'link', key: 'link', onClick: actionClick }
        },
        {
            key: 'fullName',
            fieldName: 'fullName',
            name: 'Họ và tên',
            minWidth: 170,
            maxWidth: 180,
            isResizable: true,
        },
        {
            key: 'mobile',
            fieldName: 'mobile',
            name: 'Số điện thoại',
            minWidth: 90,
            maxWidth: 120,
            isResizable: true,
        },
        {
            key: 'callCenterExt',
            fieldName: 'callCenterExt',
            name: 'Số nội bộ',
            minWidth: 90,
            maxWidth: 120,
            isResizable: true,
        },
        {
            key: 'email',
            fieldName: 'email',
            name: 'Email',
            minWidth: 150,
            maxWidth: 180,
            isResizable: true,
        },
        {
            key: 'isActive',
            fieldName: 'isActive',
            name: 'Active',
            minWidth: 42,
            maxWidth: 70,
            isResizable: true,
            render: { type: 'checkbox' },
        },
        {
            key: 'createDate',
            fieldName: 'createDate',
            name: 'Ngày tạo',
            minWidth: 160,
            maxWidth: 160,
            isResizable: true,
            render: { type: 'datetime' },
        },
        {
            key: 'latestUpdate',
            fieldName: 'latestUpdate',
            name: 'Ngày cập nhật',
            minWidth: 160,
            maxWidth: 160,
            isResizable: true,
            render: { type: 'datetime' },
        },
        {
            key: 'functionId',
            fieldName: 'userId',
            minWidth: 100,
            maxWidth: 100,
            render: { type: 'button', text: 'Chức năng', icon: '', items: actionItems },
        },
    ]

    const onStaffDelete = () => {
        if (staffModalDelete.value.trim() != actionObject.userName) {
            ShowErrorModal('UserName không chính xác')
            return
        }
        deleteAxios('users/'+actionObject.userId,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setStaffModalDelete)

                let nextPage = gridData.page
                if (gridData.items.length-1 == 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })
        
    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>

            <CustomModal title='Xóa User' isModalOpen={staffModalDelete.isToggle}
                hideModal={() => toogleModal(setStaffModalDelete)} buttonAction="Xóa" width={640}
                onClick={onStaffDelete} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setStaffModalDelete({ ...staffModalDelete, value })}
                    value={staffModalDelete.value}
                    label={`Nhập UserName: ${actionObject.userName} để xóa`} />
            </CustomModal>

            <div style={{ display: "block", width: "100%", height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: "Tất cả người dùng" }}
                            width={"100%"}
                            addButton={{ text: "Tạo mới", onClick: onAddClick }} />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={groupColumns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div>
    );
}
