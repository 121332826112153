import React from 'react';

import { ChevronDownMedIcon, ChevronRightMedIcon } from '../Icons/Icons';

import { getClasses } from './styles';

export default function DetailItem({ item, isEditable = true }) {
    const { values, title } = item;

    const {
        detailItemContainer,
        detailItemHeader,
        itemDetails,
        singleItem,
        singleItemKey,
        singleItemValue,
        detailTitle,
        editLink,
    } = getClasses();

    // toggle Item state
    const [isOpen, setIsOpen] = React.useState(true);

    // toggle item function
    const onOpenItem = () => {
        setIsOpen(!isOpen);
    };

    // styles for chevronIcons
    const chevCss = { cursor: 'pointer', width: 16, height: 16, fontSize: 16, fontWeight: '400' };
    return (
        <div className={detailItemContainer}>
            <div className={detailItemHeader}>
                <span
                    onClick={() => {
                        onOpenItem();
                    }}
                >
                    {isOpen ? <ChevronDownMedIcon css={chevCss} /> : <ChevronRightMedIcon css={chevCss} />}
                </span>
                <span className={detailTitle}>{title}</span>
                {/* {isEditable && <span className={editLink}>Chỉnh sửa</span>} */}
            </div>
            {isOpen && (
                <div className={itemDetails}>
                    {values.map((oneItem, index) => {
                        return (
                            <div key={index} className={singleItem}>
                                <span className={singleItemKey}>{oneItem.key}</span>
                                <p className={singleItemValue}>{oneItem.value}</p>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
