import React from 'react';
import { Route, Switch, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import CustomDetailView from '../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown } from '../../components/BasicInput/CustomInput';
import { getAxios } from '../../utils/axios';
import DateFilterForm from '../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from '../../components/FilterPanel/FilterPanel';
import { isPaidOptions, isAgentOptions, staffOptions, dateTypeOptions } from '../../utils/constants';
import { firstDay, lastDay } from '../../utils/date';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';
import AccountContactInvoice from './AccountContactInvoice';
import SearchInput from '../../components/SearchInput/SearchInput';

const getApi = async (url, param) => {
    return new Promise((res, rej) => {
        getAxios(url, param, (response) => {
            if (response) {
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getUserList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/users/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/user-roles/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}
const getAllChannel = async () => {
    return getApi('/channels')
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Invoices_Full') || fncCode.includes('Invoices_Read_All')){
        result.add("all")
        result.add("group")
        result.add("other")
    }
    if (fncCode.includes('Invoices_Read_Family')){
        result.add("group")
        result.add("other")
    }
    return result
}

const useIsReadyHook = ()=>{

    const [Staff,setStaff] = React.useState(false)
    const [Status,setStatus] = React.useState(false)
    const [ChannelProduct,setChannelProduct] = React.useState(false)

    return [
        {Staff,Status,ChannelProduct},
        {setStaff,setStatus,setChannelProduct}
    ]
}

let userList=[],roleList=[],channelList=[]

const Invoice = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isReady, setIsReady] = useIsReadyHook()

    const [isLoading, setIsLoading] = React.useState(true);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [channelId, setChannelId] = React.useState(locParams ? locParams.channelId : '');
    const [dateType,setDateType] =  React.useState(locParams ? locParams.dateType : 'createDate');
    const [isAgent,setIsAgent] =  React.useState(locParams ? locParams.isAgent : '');
    const [isPaid,setIsPaid] =  React.useState(locParams ? locParams.isPaid : '');
    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');

    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: firstDay, endDate: lastDay, key: "5" }
    );

    const permisson = React.useMemo(()=>parseFunctionCode(localStorage.getItem('functionCodes')),[])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [staffFilterType,setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh,setRefresh] = React.useState(0)

    React.useEffect(() => {
        getUserList(permisson,localStorage.getItem('userId'))
        .then(res=>{
            const tmp = res?.items || []
            userList = tmp.map(x=>({key:''+x.userId,text: x.userName}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoleList(permisson,localStorage.getItem('roleId')).then(res=>{
            const tmp = res || []
            roleList = tmp.map(x=>({key:x.roleNode,text: x.name}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getAllChannel().then(res=>{
            const temp = res || []
            channelList = temp.map(x=>({key:''+x.channelId,text:x.name}))
            channelList.unshift({ key: '', text: 'Tất cả' })
        }).catch(console.log)
    }, []);
    
    React.useEffect(() => {
        if (isReady.Staff){
            post(0, true);
        }
    }, [isReady.Staff]);


    //function
    const post = (page, firstLoad) => {
        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            staffFilterType,
            channelId,
            isAgent,
            isPaid,
            dateType,
            page: page + 1,
            pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            toogleFilter: filter.value
        };
        
        let url

        if (staffFilterType=="all"){
            url = '/invoices'
        } else if (staffFilterType=="group"){
            url = `/invoices/role-node/${encodeURIComponent(roleNode)}`
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            url = `/invoices/managed-by/${userId}`
            params.userId = userId
        } else {
            url = `/invoices/managed-by/me`
        }

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }
        
        getAxios(url, params, response => {
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }
            setIsLoading(false);
            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const tableAction = (action, value, item, event) => {
        const params = {
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            dateType: dateType
        }
        switch (action) {
            case 'staff':
                pushHistory(history, `/staffs/${item.assignedToId}/details`, null, event.ctrlKey)
                break;
            case 'contact':
                pushHistory(history, `/contacts/${item.contactId}/details/invoice`, params, event.ctrlKey)
                break;
            case 'invoice':
                pushHistory(history, '/invoicedetail', { code: item.code }, event.ctrlKey);
                break;
        }
    }

    const columns = [
        {
            name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true,
            render: { type: 'index' }
        },
        {
            name: 'Nhân viên', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 200, maxWidth: 200,
            isResizable: true, 
        },
        {
            name: 'Điện thoại', key: 'contactMobile', fieldName: 'contactMobile', minWidth: 150, maxWidth: 150,
            isResizable: true, render: { type: 'link', key: 'contact', onClick: tableAction }
        },
        {
            name: 'Khách hàng', key: 'contactName', fieldName: 'contactName', minWidth: 200, maxWidth: 200,
            isResizable: true,
        },
        {
            name: 'Hóa đơn', key: 'code', fieldName: 'code', minWidth: 200, maxWidth: 200,
            isResizable: true, render: { type: 'link', key: 'invoice', onClick: tableAction }
        },
        {
            name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 200, maxWidth: 200,
            isResizable: true, render: { type: 'money' }
        },
        { name: 'Thụ hưởng', key: 'assignedToId', fieldName: 'assignedToId', minWidth: 90, maxWidth: 90, isResizable: true, render: { type: 'assignedId' } },
        { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        { name: 'Ngày thanh toán', key: 'paymentDate', fieldName: 'paymentDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
    ];

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>

                {refresh == 2 &&<>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList} 
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                        onLoaded={setIsReady.setStaff}
                    />
                </>}
                
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kênh đăng"
                        width={250}
                        height={350}
                        options={channelList}
                        value={channelId}
                        onChange={setChannelId}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại tài khoản"
                        width={250}
                        height={350}
                        options={isAgentOptions}
                        value={isAgent}
                        onChange={setIsAgent}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại hóa đơn"
                        width={250}
                        height={350}
                        options={isPaidOptions}
                        value={isPaid}
                        onChange={setIsPaid}
                    />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại ngày"
                        width={250}
                        height={350}
                        options={dateTypeOptions}
                        value={dateType}
                        onChange={setDateType}
                    />
                </div>
                
                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>
            
            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: "Quản lý hóa đơn" }}
                            width={"100%"}
                        />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loggedId={localStorage.getItem('userId')}
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}

export default function () {
    let { path, url } = useRouteMatch();
    return <Switch>
        <Route exact path={`${path}/:optional/:id`}>
            <AccountContactInvoice />
        </Route>
        <Route path={path}>
            <Invoice/>
        </Route>
    </Switch>
}