import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor } from './../../styles/colors';

export const getClasses = ({ inputCss, formControlCss, labelCss }) => {
    return mergeStyleSets({
        formControlDefaultStyles: {
            width: 318,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
        },
        optionalFormControlStyles: formControlCss,
        inputDefaultStyles: {
            display: 'block',
            width: '100%',
            lineHeight: '200%',
            fontSize: 15,
        },
        optionalInputStyles: inputCss,
        defaultLabelStyles: {
            color: secondaryLightColor,
            fontWeight: '500',
            fontSize: 14,
            lineHeight: '21px',
        },
        optionalLabelStyles: labelCss,
    });
};
