import React from 'react';
import { AddIcon, PhoneCallIcon } from './../Icons/Icons';
import { borderRadius } from './../../styles/constants';
import { darkJellyBeanColor, borderGrayColor } from './../../styles/colors';

import { DefaultButton, PrimaryButton, Stack, IStackTokens } from 'office-ui-fabric-react';

import { getClasses } from './styles.js';

const btn = {
    display: 'flex',
    borderRadius: borderRadius,
    border: `1px solid ${borderGrayColor}`,
    height: 36,
    padding: '.7rem',
    alignItems: 'center',
    color: darkJellyBeanColor,
    background: 'none',
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 'bold',
    outline: 'none',
    gridGap: '1rem',
};

const btnVirtual = {
    display: 'flex',
    'align-items': 'center',
    border: 0,
    'background-color': 'transparent',
    outline: 'none',
    cursor: 'pointer'
}

const miniIcon = {
    display: 'flex',
    height: '1.25rem',
    width: '1rem',
    'margin-left': '4px'
}

const AddButton = (props) => {
    return (
        <button {...props} style={{ ...btn, ...props.style }}>
            <AddIcon />
            {props.text}
        </button>
    );
}

export function NormalButton({ text, btnCss, ...restProps }) {
    const { btnStyles, optionalBtnStyles } = getClasses({ btnCss });
    return (
        <button className={`${btnStyles} ${optionalBtnStyles}`} {...restProps}>
            {text}
        </button>
    );
}

export function FuiPrimaryButton({ btnCss, ...restProps }) {
    const { optionalBtnStyles } = getClasses({ btnCss });
    return <PrimaryButton className={`${optionalBtnStyles}`} {...restProps} />;
}

export default AddButton;

export function PhoneCallButton({ onClick, children }) {
    return (
        <button style={{ ...btnVirtual }} onClick={ onClick } title='Bấm vào để gọi'>
            { children }
            <div style={{ ...miniIcon }}>
                <PhoneCallIcon />
            </div>
        </button>
    );
}