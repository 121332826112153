import React from 'react';
import { apiUrl } from './../utils/constants';
import { token } from './../utils/token';

export function handleError(error) {
    if (Array.isArray(error)) {
        let errMsg = '';
        for (let i = 0; i < error.length; i++) {
            errMsg += error[i].message + '. ';
        }
        return { message: errMsg };
    } else {
        return error;
    }
}

export function handleInitalResponse(res) {
    if (res.ok) {
        return { status: 'successful', res: res.json() };
    } else {
        return res.json();
    }
}

export const initalState = { data: null, error: null, loading: false };

export function useFetch(path) {
    const [response, setResponse] = React.useState(initalState);

    React.useEffect(() => {
        setResponse((prevState) => {
            return {
                ...prevState,
                loading: true,
                error: null,
            };
        });
        fetch(`${apiUrl}${path}`, { headers: new Headers({ Authorization: `Bearer ${token}` }) })
            .then(handleInitalResponse)
            .then((data) => {
                if (data.status === 'successful') {
                    data.res.then((data) => {
                        setResponse({
                            data: data,
                            error: null,
                            loading: false,
                        });
                    });
                } else {
                    setResponse((prevState) => {
                        return { ...prevState, loading: false, error: handleError(data) };
                    });
                }
            })
            .catch((error) => {
                setResponse((prevState) => {
                    return { ...prevState, loading: false, error: handleError(error) };
                });
            });
    }, [path]);

    return response;
}

export function useConditionedFetch(path, method = 'GET', bodyString = '', condition = false) {
    const [response, setResponse] = React.useState(initalState);

    const configRequestOptions = React.useCallback(
        (method) => {
            switch (method) {
                case 'GET':
                    return {
                        method: method,
                        headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                    };
                case 'DELETE':
                    return {
                        method: method,
                        headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                    };
                case 'POST':
                    return {
                        method: method,
                        headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                        body: bodyString,
                    };
                case 'PUT':
                    return {
                        method: method,
                        headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                        body: bodyString,
                    };
                default:
                    console.log('error api call');
                    return null;
            }
        },
        [bodyString]
    );

    React.useEffect(() => {
        if (condition) {
            setResponse((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                    error: null,
                };
            });

            fetch(`${apiUrl}${path}`, configRequestOptions(method))
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        data.res.then((data) => {
                            setResponse({
                                data: data,
                                error: null,
                                loading: false,
                            });
                        });
                    } else {
                        setResponse((prevState) => {
                            return { ...prevState, loading: false, error: handleError(data) };
                        });
                    }
                })
                .catch((error) => {
                    setResponse((prevState) => {
                        return { ...prevState, loading: false, error: handleError(error) };
                    });
                });
        }
    }, [path, method, bodyString, condition, configRequestOptions]);

    return response;
}
