import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor, darkGrayColor } from './../../styles/colors';

export const getClasses = () => {
    return mergeStyleSets({
        headerDeco: {
            display: 'inline-block',
            width: 3,
            height: 16,
            backgroundColor: secondaryLightColor,
            marginRight: 13,
        },

        headerText: {
            textTransform: 'uppercase',
            color: darkGrayColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: '150%',
        },
    });
};
