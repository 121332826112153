import React from 'react';
import { getAxios } from '../../utils/axios';
import { useHistory, useLocation } from 'react-router-dom';
import { pushHistory, getParams } from '../../utils/helpers';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { apps, statuses} from '../../utils/constants';

const QuickSearchResult = () => {
    
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);
    //state
    const [quickSearchKey] = React.useState(locParams.quickSearchKey);
    const [panel, setPanel] = React.useState(locParams.panel ? locParams.panel : '');
    const [data, setData] = React.useState({});
    const [count, setCount] = React.useState({
        leads: 0,
        contacts: 0,
        accounts: 0,
        invoices: 0,
        cases: 0
    });
    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [column, setColumn] = React.useState([]);
    const [gridData, setGridData] = React.useState({
        error: '',
        items: [],
        page: 0,
        pageCount: 1,
    });

    const [sourceList, setSourceList] = React.useState([])

    //init

    React.useLayoutEffect(() => {
        getAxios('/sources',{pageSize: 1000000},res => {
            let sources = res.data.map(x => ({ key: x.id, text: x.name }));
            setSourceList(sources);
        }).catch(() => { })
    }, [])
        
    React.useEffect(() => {
        post();
        
    }, [isFirstLoad]);

    React.useEffect(() => {
        onChangePanel(panel);
    }, [data]);

    React.useEffect(() => {
        for (let key in count) {
            if (count.hasOwnProperty(key)) {
                if (count[key] > 0) {
                    setPanel(key);
                    setColumn(columns[key]);
                    break;
                }
            }
        }
    }, [count])
    
    //options
    const options = {
        source: sourceList,
        status: statuses   
    }

    const actionClick = (action,value,item,event) => {

        if (action == "leadDetail") {
            pushHistory(history, `/leads/${item.leadId}/details`, null, null)
        }
        if (action == "contactDetail") {
            pushHistory(history, `/contacts/${item.contactId}/details/information`, null, null) 
        }
        if (action == "accountDetail") {
            pushHistory(history, `/accounts/${item.accountId}/extra/details/call`, null, null)
        }
        if (action == "invoiceDetails") {
            pushHistory(history, '/invoicedetail',{code: value}, null)
        }
    }

    const columns = {
        leads: [
            { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
            { name: 'Họ tên', key: 'title', fieldName: 'title', minWidth: 120, maxWidth: 150, isResizable: true, render: {type: 'link', key: 'leadDetail', onClick:actionClick} },
            { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 100, maxWidth: 100, isResizable: true , render: {type: 'link', key: 'leadDetail', onClick:actionClick}},
            { name: 'Email', key: 'email', fieldName: 'email', minWidth: 140, maxWidth: 200, isResizable: true },
            { name: 'Nguồn lead', key: 'sourceId', fieldName: 'sourceId', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'dropdown', options: options.source } },
            { name: 'Trạng thái', key: 'status', fieldName: 'status', minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'dropdown', options: options.status } },
            { name: 'Số lần phân công', key: 'totalAssignments', fieldName: 'totalAssignments', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'money' } },
            { name: 'NVCS', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 150, maxWidth: 150, isResizable: true },
            { name: 'Ngày chăm sóc', key: 'assignedDate', fieldName: 'assignedDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
            { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } }],
        contacts: [
            { name: '#', key: 'title', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
            { name: 'Họ tên', key: 'name', fieldName: 'name', minWidth: 300, maxWidth: 300, isResizable: true},
            { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 120, maxWidth: 120, isResizable: true, render: {type: 'link', key: 'contactDetail', onClick:actionClick} },
            { name: 'Email', key: 'email', fieldName: 'email', minWidth: 280, maxWidth: 280, isResizable: true },
            { name: 'App', key: 'appName', fieldName: 'appName', minWidth:280, maxWidth:280, isResizable: true },
            { name: 'NVCS', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 150, maxWidth: 150, isResizable: true }],
        accounts: [
            { name: '#', key: 'title', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
            { name: 'Mã KH', key: 'customerId', fieldName: 'customerId', minWidth: 100, maxWidth: 100, isResizable: true },
            { name: 'Tên KH', key: 'name', fieldName: 'name', minWidth: 250, maxWidth: 250, isResizable: true, render: {type: 'link', key: 'accountDetail', onClick:actionClick} },
            { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'mobile' } },
            { name: 'Email', key: 'email', fieldName: 'email', minWidth: 250, maxWidth: 250, isResizable: true },
            { name: 'NVCS', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 150, maxWidth: 150, isResizable: true },
            { name: 'Ngày chăm sóc', key: 'assignedDate', fieldName: 'assignedDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
            { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
            { name: 'Ngày cập nhật', key: 'latestUpdate', fieldName: 'latestUpdate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        ],
        invoices: [
            { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
            { name: 'Nhân viên', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 200, maxWidth: 200, isResizable: true },
            { name: 'Điện thoại', key: 'contactMobile', fieldName: 'contactMobile', minWidth: 150, maxWidth: 150, isResizable: true },
            { name: 'Khách hàng', key: 'contactName', fieldName: 'contactName', minWidth: 200, maxWidth: 200, isResizable: true },
            { name: 'Hóa đơn', key: 'code', fieldName: 'code', minWidth: 200, maxWidth: 200, isResizable: true , render: {type: 'link', key: 'invoiceDetails', onClick:actionClick}},
            { name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 200, maxWidth: 200, isResizable: true, render: { type: 'money' } },
            { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
            { name: 'Ngày thanh toán', key: 'paymentDate', fieldName: 'paymentDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } }
        ],
        cases: []
    }

    //function
    const post = () => {
        setIsLoading(true);
        getAxios(`search/keyword/${quickSearchKey.trim()}`, null, response => {
            if (response && response.status == 200) {
                const objectData = response.data;
                const dataCount = {
                    leads: objectData.leads.length,
                    contacts: objectData.contacts.length,
                    accounts: objectData.accounts.length,
                    invoices: objectData.invoices.length,
                    cases: objectData.cases.length,
                };
                setCount(dataCount);
                setData(objectData);
            } else {
                setCount({
                    leads: 0,
                    contacts: 0,
                    accounts: 0,
                    invoices: 0,
                    cases: 0
                });
                setData({
                    leads: [],
                    invoices: [],
                    contacts: [],
                    accounts: [],
                    cases: []
                });
            }

            setIsLoading(false);
        });
    };


    const onChangePanel = (key) => {
        setPanel(key);
        setColumn(columns[key]);
        const item = data[key];

        if (item) {
            if (item.length == 0) {
                setGridData({
                    error: 'Không tìm thấy dữ liệu',
                    items: [],
                    page: 0,
                    pageCount: 1
                })
            } else {
                setGridData({
                    error: '',
                    items: item,
                    page: 0,
                    pageCount: 1
                })
            }
        }

        pushHistory(history, 'quicksearchresult', { quickSearchKey: quickSearchKey, panel: key });
    }


    //render
    const render = () => {
        const panelStyle = { width: '20%', textAlign: 'center', alignSelf: 'center', cursor: 'pointer' };
        return (<div style={{ display: 'block', width: '100%', height: '100%' }}>
            <div style={{ width: '100%', display: 'inline-flex', height: "40px", borderBottom: '1px solid #bbb', verticalAlign: 'middle' }}>
                <div style={{ ...panelStyle, ...{ fontWeight: panel == 'leads' ? 600 : 400 }, ...{ color: panel == 'leads' ? '#0078d4' : '#222' } }} onClick={() => onChangePanel('leads')}>{`Lead (${count.leads})`}</div>
                <div style={{ ...panelStyle, ...{ fontWeight: panel == 'contacts' ? 600 : 400 }, ...{ color: panel == 'contacts' ? '#0078d4' : '#222' } }} onClick={() => onChangePanel('contacts')}>{`Tài khoản (${count.contacts})`}</div>
                <div style={{ ...panelStyle, ...{ fontWeight: panel == 'accounts' ? 600 : 400 }, ...{ color: panel == 'accounts' ? '#0078d4' : '#222' } }} onClick={() => onChangePanel('accounts')}>{`Khách hàng (${count.accounts})`}</div>
                <div style={{ ...panelStyle, ...{ fontWeight: panel == 'invoices' ? 600 : 400 }, ...{ color: panel == 'invoices' ? '#0078d4' : '#222' } }} onClick={() => onChangePanel('invoices')}>{`Hóa đơn (${count.invoices})`}</div>
                <div style={{ ...panelStyle, ...{ fontWeight: panel == 'cases' ? 600 : 400 }, ...{ color: panel == 'cases' ? '#0078d4' : '#222' } }} onClick={() => onChangePanel('cases')}>{`Case (${count.cases})`}</div>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 40px)', paddingTop: '16px' }}>
                <CustomDetailView
                    loading={isLoading}
                    columns={column}
                    gridData={{...gridData}}
                />
            </div>
        </div>)
    };

    return render();
};

export default QuickSearchResult;
