import React from 'react';

import MoreLink from '../MoreLink/MoreLink';

import { useFetch } from '../../hooks/useFetch';

import { formatTimeDate } from '../../utils/date';

import { getClasses } from './styles';

export default function Feeds({
    noteListClassName,
    noteItemClassName,
    children,
    path,
    headingClassName,
    handleMoreLinkClick,
}) {
    const {
        container,
        heading,
        headingOptional,
        noteList,
        noteListOptional,
        noteItem,
        noteItemOptional,
        noteTitle,
        noteUpdated,
        noteContent,
        noteUpdatedAt,
        noteUpdatedBy,
    } = getClasses({ noteListClassName, noteItemClassName, headingClassName });

    const qs = '?page=1&pageSize=6';
    const { data } = useFetch(`${path}${qs}`);

    const notes = data?.items || [];
    let upto = data?.total;
    if (upto > 3)
    {
        upto = '3+';
    }

    if (notes.length > 0) {
        return (
            <div className={container}>
                <header className={`${heading} ${headingOptional}`}>
                    <h4>Lịch sử hoạt động ({upto})</h4>
                    {children}
                </header>
                <div className={`${noteList} ${noteListOptional}`}>
                    {notes.map((note) => {
                        return (
                            <div className={`${noteItem} ${noteItemOptional}`} key={note?.feedId}>
                                <p className={noteTitle}>{note.title}</p>
                                <p className={noteUpdated}>
                                    <span className={noteUpdatedAt}>{formatTimeDate(note.latestUpdate)} bởi </span>
                                    <span className={noteUpdatedBy}>{note.createdByName}</span>
                                </p>
                                <p className={noteContent}>{note.description}</p>
                            </div>
                        );
                    })}
                </div>
                <MoreLink onClick={handleMoreLinkClick} />
            </div>
        );
    } else {
        return null;
    }
}
