import React from 'react';
import { Pagination } from '@uifabric/experiments';
import './Paginator.css';

export default function Paginator(props) {
    const { page, pageCount, onPageChange } = props;

    return (
        <Pagination
            selectedPageIndex={page}
            pageCount={pageCount}
            onPageChange={onPageChange}
            format
            firstPageIconProps={{ iconName: 'DoubleChevronLeft' }}
            previousPageIconProps={{ iconName: 'ChevronLeft' }}
            nextPageIconProps={{ iconName: 'ChevronRight' }}
            lastPageIconProps={{ iconName: 'DoubleChevronRight' }}
        />
    );
}
