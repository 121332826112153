import React from 'react';

import { ActionButton } from '@fluentui/react'
import styles from './index.module.css'

function SimplePagination(props) {
    const {currentPage,isNext} = props
    const onPrev = () => {
        props.onChange(currentPage-1)
    }
    const onNext = () => {
        props.onChange(currentPage+1)
    }

    return <div className={styles.wrapper}>
        <ActionButton onClick={onPrev} iconProps={{iconName: 'ChevronLeftMed'}} disabled={currentPage < 1}/>
        
        <span className={styles.currentPage}>{currentPage+1}</span>

        <ActionButton onClick={onNext} iconProps={{iconName: 'ChevronRightMed'}} disabled={!isNext}/>

    </div>
}

export default React.memo(SimplePagination,(prev,next)=>{
    if (prev.currentPage === next.currentPage && prev.isNext === next.isNext)
        return true
    return false
})
