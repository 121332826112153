import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown, CustomTextField } from '../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../utils/axios';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import StaffFilter from './../../components/StaffFilter/StaffFilter';
import { formatDateDMY } from '../../utils/date';
import { DownloadForm } from '../../components/DownloadForm';

const PAGE_TITLE = "Báo cáo doanh thu khách hàng";

const staffList = () => {
    let tempStaffType = [{ key: '1', text: 'Tôi chăm sóc' }];
    const functionCodes = localStorage.getItem("functionCodes");

    if (functionCodes && functionCodes.length > 0) {
        const functionCodeArr = functionCodes.split(',');
        if (functionCodeArr.indexOf("Reports_Full") != -1) {
            tempStaffType.push(
                {
                    key: '',
                    text: 'Tất cả',
                },
                {
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles`,
                        key: 'roleNode',
                        text: 'name'
                    }
                },
                {
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: 'users',
                        key: 'userId',
                        text: 'userName'
                    }
                }
            );
        }

        const hasChildRole = localStorage.getItem('hasChildRole');
        if (hasChildRole != '0' && functionCodeArr.indexOf("Reports_Read_Family") != -1) {
            if (!tempStaffType.find(x => x.key == '2')) {
                tempStaffType.push({
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles/${localStorage.getItem('roleId')}/children`,
                        key: 'roleNode',
                        text: 'name',
                    }
                });
            };

            if (!tempStaffType.find(x => x.key == '3')) {
                tempStaffType.push({
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: `users/${localStorage.getItem('userId')}/children`,
                        key: 'userId',
                        text: 'userName'
                    }
                });
            };
        }
    }

    return tempStaffType.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
}


const UserRevenuesByCustomer = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [chanelList, setChanelList] = React.useState([]);

    const [staffFilter, setStaffFilter] = React.useState(
        locParams ? { key: locParams.filterKey, value: locParams.filterValue } : { key: '', value: '' });

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [channelId, setChannelId] = React.useState(locParams ? locParams.channelId : '');

    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );

    //init
    React.useEffect(() => {
        if (!locParams) {
            setIsFirstLoad(true);
        }
    }, []);

    React.useEffect(() => {
        getAxios('channels', null, response => {
            if (response.data && response.data) {
                const chanelList = response.data.map(x => ({ key: x.channelId, text: x.name }));
                chanelList.unshift({ key: '', text: 'Tất cả' });
                setChanelList(chanelList);
            }
        });

        if (locParams) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
    }, [isFirstLoad]);

    const refParam = React.useRef({})
    
    //function
    const post = (page, firstLoad,isPageChange) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        let params = {
            channelId: channelId,
            pageSize: pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            filterKey: staffFilter.key,
            filterValue: staffFilter.value
        };

        let url = "reports/user-revenues-by-customer";
        if (staffFilter.key == "1") {
            params.userId = localStorage.getItem('userId');
        }
        else if (staffFilter.key == "2") {
            params.roleNode = staffFilter.value;
        }
        else {
            params.userId = staffFilter.value;
        }

        if (!isPageChange)
            refParam.current = params
        else 
            params = refParam.current
        
        params.page= page + 1

        if (!firstLoad) {
            pushHistory(history, '/report/user-revenues-by-customer', params);
        }

        getAxios(url, params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0)
                        pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                    if (pageSize == newGridData.items.length) {
                        newGridData.isNext = true
                    }
                }
                else if (response.message) {
                    if (!isPageChange)
                        newGridData.error = response.message;
                    else 
                        return
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    };

    const actionClick = (action, value, item, event) => {
        if (action == "detail") {
            pushHistory(history, `/contacts/${item.contactId}/details`, null, event.ctrlKey);
        }
    };

    const columns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 150, maxWidth: 150, isResizable: true },
        { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick }},
        { name: 'Tên khách hàng', key: 'name', fieldName: 'name', minWidth: 300, maxWidth: 300, isResizable: true },
        { name: 'Kênh', key: 'channelName', fieldName: 'channelName', minWidth: 200, maxWidth: 200, isResizable: true },
        { name: 'Số lượng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
        { name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
    ];

    let onExportClick = null
    if (gridData.items?.length > 0)
        onExportClick = () => {

            const params = {
                channelId: channelId,
                beginDate: date.beginDate,
                endDate: date.endDate,
            };

            if (staffFilter.key == "1") {
                params.userId = localStorage.getItem('userId');
            }
            else if (staffFilter.key == "2") {
                params.roleNode = staffFilter.value;
            }
            else {
                params.userId = staffFilter.value;
            }

            getFile('/reports/user-revenues-by-customer/excel', params, response => {
                const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
                DownloadForm(response.data, filename)
            });
        }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>

                <StaffFilter data={staffFilter} typeList={staffList()} width={250} onChange={data => setStaffFilter(data)} />

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kênh đăng"
                        width={250}
                        height={350}
                        options={chanelList}
                        value={channelId}
                        onChange={setChannelId}
                    />
                </div>

                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: `${PAGE_TITLE}` }}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        dynamicPagination
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page,false,true)} />
                </div>
            </div>
        </div >
    );
}
export default UserRevenuesByCustomer;
