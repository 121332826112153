import queryString from 'query-string';
import {  secondaryLightColor } from '../styles/colors';
import { statuses } from './constants';

export function validateMobile(mobile) {
    mobile = mobile.trim();
    if (mobile !== '') {
        mobile = mobile.replace(/ /g, '');
        mobile = mobile.replace(/\./g, '');
        mobile = mobile.replace(/,/g, '');
        mobile = mobile.replace(/-/g, '');
    }

    if (mobile.length !== 10) {
        return null;
    }

    if (mobile.match(/[^0-9]/)) {
        return null;
    }

    if (!mobile.match(/^0[^02]/)) {
        return null;
    }

    return mobile;
}

export function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

//push url with search to history
export function pushHistory(history, path, params, newPage) {
    const searchString = queryString.stringify(params);
    if (newPage) {
        window.open(searchString.length > 0 ? path + '?' + searchString : path);
    } else {
        history.push({
            pathname: path,
            search: searchString,
            state: { params: params },
        });
    }
}

// get objParam from url
export function getParams(location) {
    if (!location.state && location.search.length === 0) {
        return undefined;
    } else if (location.state) {
        return location.state.params;
    } else {
        var params = queryString.parse(location.search);
        return params;
    }
}

export function detectSource(sourceData, sourceId) {
    return sourceData?.filter((src) => parseInt(src.id) === sourceId)[0]?.name;
}

export function detectStatus(status) {
    return statuses.filter((sts) => parseInt(sts.key) === status)[0]?.text;
}

// format money with comma
export function formatMoney(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
    return x;
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

// style the text blue like link color 
const styleBlueText = (text) => {
    return <span style={{color: secondaryLightColor}}>{text}</span>;
}

export {styleBlueText}

