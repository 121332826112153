import React from 'react';

export function useForm(FIELDS) {
    const [fields, setFields] = React.useState(FIELDS);

    let newFields = { ...fields };

    // validation handler: loop throup all the fields and create an errors property for each field
    const setValidationErrors = (fields) => {
        Object.keys(fields).forEach((field) => {
            if (fields[field].validations) {
                fields[field].errors = errorsForField(field);
            }
        });

        return fields;
    };

    // run all validations of a field and and return empty string ('') if no errors or the message error if an error found
    const errorsForField = (field) => {
        return fields[field].validations
            .map((validation) => {
                const { isValid, message } = validation(fields[field].value);
                return isValid ? '' : message;
            })
            .filter((value) => value.length > 0);
    };

    const handleFuiDropdownChange = (_e, item) => {
        const { name, key, value } = item;

        newFields[name].value = value;
        newFields[name].key = key;
        setFields(newFields);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        newFields[name].value = value;
        if (newFields[name].validations) {
            newFields = setValidationErrors(newFields);
        }
        setFields(newFields);
    };

    const handleBirthDateChange = (date) => {
        newFields.birthDate.value = date;
        setFields(newFields);
    };

    // create optional query string from form fields
    const optionalQueryStrings = (fieldNames) => {
        return fieldNames
            .map((fieldName) => {
                if (fields[fieldName].value) {
                    return `${fieldName}=${fields[fieldName].value}&`;
                } else {
                    return '';
                }
            })
            .join('');
    };

    return {
        fields,
        handleFuiDropdownChange,
        handleInputChange,
        handleBirthDateChange,
        optionalQueryStrings,
    };
}
