import { mergeStyleSets } from '@fluentui/react';
import { lightGrayColor, whiteColor, lowWhiteColor, primaryColor, secondaryLightColor } from './../../styles/colors';
import { borderRadius6px } from './../../styles/constants';

export const getClasses = ({ formControlCss, dropdownCss, selectedContainerCss, labelCss, dropdownSelectedCss }) => {
    return mergeStyleSets({
        formControlStyles: {
            width: 318,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 1,
        },
        optionalFormControl: formControlCss,
        dropdownStyles: {
            position: 'relative',
            alignSelf: 'flex-start',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: lightGrayColor,
            borderRadius: borderRadius6px,
            zIndex: '100',
        },
        optionalDropdownStyles: dropdownCss,
        selectedContainerStyles: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            cursor: 'pointer',
            flex: 1,
            padding: 11,
            height: '100%',
        },
        optionalSelectedContainer: selectedContainerCss,
        labelStyles: {
            color: secondaryLightColor,
            fontWeight: '500',
            fontSize: 14,
            lineHeight: '150%',
        },
        optionalLabelStyles: labelCss,
        dropdownSelectedStyles: {
            fontSize: 15,
        },
        dropdownListStyles: {
            position: 'absolute',
            backgroundColor: whiteColor,
            flex: 1,
            listStyle: 'none',
            top: '105%',
            left: 0,
            right: 0,
            borderRadius: borderRadius6px,
            overflow: 'hidden',
            boxShadow: '0 .3px 10px  rgba(0,0,0,0.5)',
            fontSize: 15,
        },
        dropdownSelected: {
            whiteSpace: 'nowrap',
        },
        optionalDropdownSelected: dropdownSelectedCss,
        dropdownItemStyles: {
            textAlign: 'start',
            padding: '0.5rem',
            cursor: 'pointer',

            '&:hover': {
                backgroundColor: primaryColor,
                color: lowWhiteColor,
            },
        },
    });
};
