import NVMBNLink from '../../components/NVMBNLink/NVMBNLink';
import { formatTimeDate } from '../../utils/date';

export const getInfoSystem = (person, createdByPerson, updatedByPerson) => {
    const { createDate, latestUpdate } = person;
    return {
        title: 'Thông tin Hệ thống',
        values: [
            {
                key: 'Ngày tạo',
                value: (
                    <>
                        <NVMBNLink person={createdByPerson} />
                        <span>{formatTimeDate(createDate)}</span>
                    </>
                ),
            },
            {
                key: 'Ngày sửa',
                value: (
                    <>
                        <NVMBNLink person={updatedByPerson} />
                        <span>{formatTimeDate(latestUpdate)}</span>
                    </>
                ),
            },
        ],
    };
};

// parse roleNode of a user to get the hierachy of rolNode in form of an array
export function getRoleNodeHierarchy(roleNode) {
    const roleNodeCharacters = roleNode.split('');

    let roleNodes = [];

    for (let i = 0; i < roleNodeCharacters.length; i = i + 2) {
        let el = roleNodeCharacters.slice(0, i + 1).join('');
        roleNodes.push(el);
    }

    return roleNodes;
}
