import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown, CustomTextField } from '../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../utils/axios';
import { formatDateDMY } from '../../utils/date';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import DateFilterFormByStatus from '../../components/DateFilter/DateFilterFormByStatus';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';
import { DownloadForm } from '../../components/DownloadForm';

const PAGE_TITLE = "Thống kê Leads theo trạng thái";

let userList=[],roleList=[], roles=[], LeadStatus=[]
const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}

const getRoles = async () => {
    return getApi('/user-roles/compact');
}

const getLeadStatus = async () => {
    return getApi('/lead-status')
}
const getUserList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/users/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/user-roles/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Reports_Full')){
        result.add("all")
        result.add("group")
        result.add("other")
    }
    if (fncCode.includes('Reports_Read_Family')){
        result.add("group")
        result.add("other")
    }
    return result
}

const useIsReadyHook = ()=>{

    const [Staff,setStaff] = React.useState(false)

    return [
        {Staff},
        {setStaff}
    ]
}

let forwardParam = {}

const TimeFilterOption = [
    
    {
        key: 'convertDate',
        text: 'Ngày chuyển đổi',
    },
    {
        key: 'createDate',
        text: 'Ngày tạo',
    },
    {
        key: 'assignDate',
        text: 'Ngày chăm sóc',
    },
    {
        key: 'updateDate',
        text: 'Ngày cập nhật',
    },
]

const LeadsByStatus = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isReady, setIsReady] = useIsReadyHook()

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [columns,setColumns] = React.useState(null);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });
    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });


    const [status,setStatus] = React.useState(locParams ? locParams.status : '153')

    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );
    const [dateType, setDateType] = React.useState(locParams ? locParams.dateType : "convertDate");

    const permisson = React.useMemo(()=>parseFunctionCode(localStorage.getItem('functionCodes')),[])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [roleId, setRoleId] = React.useState(locParams?.roleId)
    const [staffFilterType,setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh,setRefresh] = React.useState(0)

    //init
    React.useEffect(() => {
        getLeadStatus().then(res => {
            LeadStatus = res.map( x => ({key: ''+x.id, text: x.name}))
        }).then( () => {
            LeadStatus.unshift({key: '', text: 'Tất cả'})
        }).catch(console.log)
        getUserList(permisson,localStorage.getItem('userId'))
        .then(res=>{
            const tmp = res?.items || []
            userList = tmp.map(x=>({key:''+x.userId,text: x.userName}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoleList(permisson,localStorage.getItem('roleId')).then(res=>{
            const tmp = res || []
            roleList = tmp.map(x=>({key:x.roleNode,text: x.name}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoles().then( res => {
            roles = res;
        })
    }, []);

    React.useEffect(() => {
        if (isReady.Staff){
            post(0, true);
        }
    }, [isReady.Staff]);


    const actionClick = (action, value, item, event) => {
        if (action === "detail") {
            let params = {
                status : forwardParam.status,
                page : 1,
                appId : '',
                sourceId : '',
                dateType: forwardParam.dateType,
                dateKey: forwardParam.dateKey,
                beginDate: forwardParam.beginDate,
                endDate: forwardParam.endDate,
                toogleFilter: forwardParam.toogleFilter
            }

            params.filterKey = '';
            params.filterValue = '';

            let userId = localStorage.getItem('userId') || 0
            if (staffFilterType == 'me') {
                params.filterKey = '1';
                params.filterValue = userId;
            }
            if (item.userId != localStorage.getItem('userId'))
            {
                params.filterKey = '3';
                params.filterValue = ''+item.userId;
            }
            pushHistory(history, '/leads', params, event.ctrlKey);
        }
    };


    //function
    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length !== 0 && (date.beginDate.length === 0 || date.endDate.length === 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            staffFilterType,
            dateType,
            status,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,

            toogleFilter: filter.value
        };

        if (staffFilterType=="group"){
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            params.userId = userId
            params.roleNode = null;
        } else if (staffFilterType=="me"){
            params.userId = localStorage.getItem('userId')
            params.roleNode = null;
        }

        if (!firstLoad) {
            pushHistory(history, '/report/leads-by-status', params);
        }

        forwardParam = params
        
        getAxios('/reports/leads-by-status', params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }
            setColumns(customColumns(status));
            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    // const columns = [
    //     { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
    //     { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 300, maxWidth: 300, isResizable: true, render: {type : 'link', key : 'detail', onClick:actionClick}},
    //     { name: 'Tổng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
    // ];

    const customColumns = (status) => {
        if (status != '') {
            return [
                { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
                { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 300, maxWidth: 300, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
                { name: 'Tổng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } }
            ]
        }
        else
            return [
                { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
                { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 300, maxWidth: 300, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
                { name: 'Mới', key: 'statusName1', fieldName: 'statusName1', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Đang liên hệ', key: 'statusName2', fieldName: 'statusName2', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Được chuyển đổi', key: 'statusName3', fieldName: 'statusName3', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Mua ngay', key: 'statusName4', fieldName: 'statusName4', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Mua sau', key: 'statusName5', fieldName: 'statusName5', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Thất bại', key: 'statusName6', fieldName: 'statusName6', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Tổng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                { name: 'Tỷ lệ chuyển đổi', key: 'rate', fieldName: 'rate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'rate' } },
            ]
    }

    let onExportClick=null
    if (gridData.items?.length>0)
        onExportClick = ()=>{

        const params = {
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateType: dateType,
            status: status
        };

        if (staffFilterType == "me") {
            params.userId = localStorage.getItem('userId');
        }
        else if (staffFilterType == "group") {
            params.roleNode = roleNode;
            params.userId = null
        }
        else if (staffFilterType ==="other"){
            params.userId = userId;
            params.roleNode = null
        }

        getFile('/reports/leads-by-status/excel', params, response => {
            const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
            DownloadForm(response.data,filename)
        });
    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>
                
                {refresh == 2 &&<>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList} 
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                        onLoaded={setIsReady.setStaff}
                    />
                </>}
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Trạng thái"
                        width={250}
                        height={350}
                        options={LeadStatus}
                        value={status}
                        onChange={setStatus}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kiểu ngày"
                        width={250}
                        dropdownWidth={250}
                        options={TimeFilterOption}
                        value={dateType}
                        onChange={setDateType}
                    />
                </div>
                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />

            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{text: `${PAGE_TITLE}`}}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}
export default LeadsByStatus;
