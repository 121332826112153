import React from 'react'
import { CaretSolidDownIcon } from './../Icons/Icons';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';

const PropsAreEquals = (prevProps, nextProps) => {
    return prevProps.value === nextProps.value &&
        JSON.stringify(prevProps.options) === JSON.stringify(nextProps.options)
}

const DropDownPanel = (props, rootStyle) => {
    const styles = {
        title: {
            ...props.styles?.title,
            ...rootStyle.title
        },
        label: {
            ...props.styles?.label,
            ...rootStyle.label
        },
        dropdown: {
            ...props.styles?.dropdown,
            width: props.width,
            ':after': {
                border: 'none !important'
            }
        }
    }

    if (!props.styles?.title?.paddingRight)
        styles.title.paddingRight = 32

    return <Dropdown
        disabled={props.disabled}
        placeholder={props.placeholder}
        selectedKey={props.value}
        styles={styles}
        label={props.label}
        options={props.options}
        onRenderCaretDown={() =>
            <CaretSolidDownIcon css={{ fontSize: styles.title?.fontSize }} />
        }
        onChange={(e, item) => props.onChange(item.key)}
    />
}

const _DropDownPanelCategory = (props) => {
    const styles = {
        title: {
            fontSize: 18,
            fontWeight: 600,
            height: 36,
            lineHeight: 36,
            border: "none",
            paddingLeft: 0,
        },
        label: {
            fontSize: 13,
            fontWeight: 400,
            color: "#657786",
            paddingLeft: 0,
            paddingTop: 0,
            paddingBottom: 0
        }
    }
    return DropDownPanel(props, styles)
}

const _DropDownPanelQuery = (props) => {
    const styles = {
        title: {
            fontSize: 14,
            fontWeight: 500,
            border: "none",
            background: "#F5F6F7",
        }
    }
    return DropDownPanel(props, styles)
}

export default React.memo(_DropDownPanelCategory, PropsAreEquals)
export const DropDownPanelQuery = React.memo(_DropDownPanelQuery, PropsAreEquals)