import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import { CustomDropdown } from '../../components/BasicInput/CustomInput';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import CustomPeoplePicker from '../../components/CustomPeoplePicker/CustomPeoplePicker';
import SearchInput from '../../components/SearchInput/SearchInput';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import StaffFilter from './../../components/StaffFilter/StaffFilter';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';

import { handleError, handleInitalResponse } from '../../hooks/useFetch';

import { pushHistory, getParams } from '../../utils/helpers';
import { getAxios, deleteAxios, putAxios } from '../../utils/axios';
import { apiUrl, leadsApiEndpoint, usersApiEndpoint, statuses } from '../../utils/constants';
import { token } from '../../utils/token';

import { secondaryLightColor } from '../../styles/colors';

const getApi = async (url, param) => {
    return new Promise((res, rej) => {
        getAxios(url, param, (response) => {
            if (response) {
                if (response.status === 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}

const getSource = async () => {
    return getApi('/sources', { pageSize: 1000000 })
}

const getSite = async () => {
    return getApi('/apps', { pageSize: 1000000 })
}

const staffList = () => {
    let tempStaffType = [
        {
            key: '1',
            text: 'Tôi chăm sóc'
        },
        {
            key: '4',
            text: 'Chưa chăm sóc'
        }];

    const functionCodes = localStorage.getItem("functionCodes");
    if (functionCodes && functionCodes.length > 0) {
        const functionCodeArr = functionCodes.split(',');
        if (functionCodeArr.indexOf("Leads_Full") != -1 || functionCodeArr.indexOf("Leads_Read_All") != -1) {
            tempStaffType.push(
                {
                    key: '',
                    text: 'Tất cả',
                },
                {
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles/compact?isActive=true`,
                        key: 'roleId',
                        text: 'name'
                    }
                },
                {
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: 'users/compact?isActive=true',
                        key: 'userId',
                        text: 'userName'
                    }
                }
            );
        }

        const hasChildRole = localStorage.getItem('hasChildRole');
        if (hasChildRole != '0' && functionCodeArr.indexOf("Leads_Read_Family") != -1) {
            if (!tempStaffType.find(x => x.key == '2')) {
                tempStaffType.push({
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles/${localStorage.getItem('roleId')}/children`,
                        key: 'roleId',
                        text: 'name'
                    }
                });
            }

            if (!tempStaffType.find(x => x.key == '3')) {
                tempStaffType.push({
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: `users/${localStorage.getItem('userId')}/children`,
                        key: 'userId',
                        text: 'userName'
                    }
                });
            }

            if (functionCodeArr.indexOf('Users_Full') == -1 && functionCodeArr.indexOf('Users_Read_All') == -1) {
                tempStaffType.push({
                    key: '5',
                    text: 'Tất cả tôi quản lý'
                });
            }
        }
    }

    return tempStaffType.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
}

const Lead = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isFirstLoad, setIsFirstLoad] = React.useState(false);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [appId, setAppId] = React.useState(locParams ? locParams.appId : '');
    const [status, setStatus] = React.useState(locParams ? locParams.status : '151');
    const [sourceId, setSourceId] = React.useState(locParams ? locParams.sourceId : '');
    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');
    const [dateType, setDateType] = React.useState(locParams ? locParams.dateType : "assignDate");

    const [siteList, setSiteList] = React.useState([])
    const [sourceList, setSourceList] = React.useState([])
    React.useEffect(() => {
        getSource().then(res => {
            let sources = res.map(x => ({ key: x.id, text: x.name }));
            sources.unshift({ key: '', text: 'Tất cả' });
            setSourceList(sources);
        }).catch(() => { })

        getSite().then(res => {
            let sites = res.map(x => ({ key: x.appId, text: x.name }));
            sites.unshift({ key: '', text: 'Tất cả' });
            setSiteList(sites);
        }).catch(() => { })
    }, [])

    const [staffFilter, setStaffFilter] = React.useState(
        locParams ? { key: locParams.filterKey, value: locParams.filterValue } : { key: '', value: '' });

    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "4" }
    );

    const [actionObject, setActionObject] = React.useState({})

    const [leadModalDelete, setLeadModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })

    const [leadModalRemoveOwner, setLeadModalRemoveOwner] = React.useState({
        isToggle: false,
        value: '',
    })

    const [nvcs, setNVCS] = React.useState([])
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false })
    const ShowErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), [])
    const CloseErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), [])
    const [beingChangedLead, setBeingChangedLead] = React.useState(null)
    const [isUpdating, setIsUpdating] = React.useState(false)
    const [err, setErr] = React.useState(null)
    const [selectedStaffId, setSelectedStaffId] = React.useState(null);
    const [currentNVCS, setCurrentNVCS] = React.useState(null)
    const [peoplePickerVal, setPeoplePickerVal] = React.useState('')

    // state for modal (dổi chủ sở hữu)
    const [toggledModal, setToggledModal] = React.useState(false)

    // state for modal (dổi chủ sở hữu)
    const [toggledModal2, setToggledModal2] = React.useState(false)
    //init
    React.useEffect(() => {
        if (!locParams) {
            setIsFirstLoad(true);
        }
    }, []);

    // get all nvcs
    React.useEffect(() => {
        fetch(`${apiUrl}/users/compact`, {
            headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
        })
            .then(handleInitalResponse)
            .then((data) => {
                if (data.status === 'successful') {
                    data.res.then(data => {
                        const nvcs = data;
                        const nvcsOptions = nvcs.map(item => {
                            return {
                                ...item,
                                key: item.userId,
                                text: item.userName,
                                secondaryText: item.fullName,
                            };
                        })
                        setNVCS(nvcsOptions);
                    })
                } else {
                    setNVCS([])
                }
            })
            .catch((err) => {
                setNVCS([]);
                console.log(err)
            });
    }, [])

    // get nvcs of this lead
    React.useEffect(() => {
        if (beingChangedLead) {
            fetch(`${apiUrl}${usersApiEndpoint}/${beingChangedLead?.assignedToId}`, {
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            })
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        data.res.then(data => {
                            let nv = { key: data.userId, text: data.userName, secondaryText: data.fullName }
                            setCurrentNVCS(nv);
                            setSelectedStaffId(data.userId)
                        })
                    } else {
                        setCurrentNVCS(null)
                    }
                })
                .catch((err) => {
                    setCurrentNVCS(null);
                    console.log(err)
                });

        }
    }, [beingChangedLead])

    const onPeoplePickerChange = (val) => {
        setPeoplePickerVal(val)
    }


    // Perfom API call to change the owner
    React.useEffect(() => {
        if (isUpdating) {
            // PUT request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` })
            };
            if (peoplePickerVal.trim() && (!selectedStaffId)) {
                setIsUpdating(false)
                ShowErrorModal('Không tìm thấy nhân viên này')
                return;
            }
            if (!selectedStaffId) {
                setIsUpdating(false)
                ShowErrorModal('Vui lòng chọn nhân viên chăm sóc')
                return;
            }

            fetch(`${apiUrl}${leadsApiEndpoint}/${beingChangedLead?.leadId}/assigned-to/${selectedStaffId}`, requestOptions)
                .then((response) => {
                    return response.json()
                }).then((data) => {
                    if (data?.leadId) {
                        setToggledModal(false)
                        setBeingChangedLead(data)
                        setErr(null)
                        let nextPage = gridData.page
                        if (gridData.items.length - 1 == 0 && nextPage > 0) {
                            nextPage--
                        }
                        post(nextPage)
                    } else {
                        setErr(handleError(data))
                        ShowErrorModal(data?.message)
                    }
                })
                .catch((error) => {
                    setErr(handleError(error))
                    ShowErrorModal(error?.message)
                });
            setIsUpdating(false)
        }
    }, [isUpdating]);

    React.useEffect(() => {
        if (locParams) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
    }, [isFirstLoad]);

    //function
    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const onAddClick = () => {
        history.push('leads/add')
    };

    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length !== 0 && (date.beginDate.length === 0 || date.endDate.length === 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            appId: appId,
            status: status,
            sourceId: sourceId,
            dateType: dateType,
            page: page + 1,
            pageSize: pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            filterKey: staffFilter.key,
            filterValue: staffFilter.value
        };


        if (!firstLoad) {
            pushHistory(history, '/leads', params);
        }

        let url = "";
        if (staffFilter.key == "") {
            url = 'leads';
        }
        else if (staffFilter.key == "1") {
            url = 'leads/assigned-to/me';
        }
        else if (staffFilter.key == "2") {
            url = `leads/role/${staffFilter.value}`;
        }
        else if (staffFilter.key == "4") {
            url = 'leads/assigned-to/anonymous';
        }
        else if (staffFilter.key == "5") {
            url = `leads/assigned-to/${staffFilter.value}/included-children`;
        }
        else {
            url = `leads/assigned-to/${staffFilter.value}`;
        }

        getAxios(url, params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0)
                        pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const actionClick = (action, value, item, event) => {
        if (action == "detail") {
            pushHistory(history, `/leads/${item.leadId}/details`, null, event.ctrlKey);
        }
        else if (action == "update")
            pushHistory(history, `/leads/${value}/edit`, null, event.ctrlKey);
        else if (action == "delete") {
            setActionObject(item)
            setLeadModalDelete({ value: '', isToggle: true })
        } else if (action === 'change') {
            setBeingChangedLead(item)
            setToggledModal(true)
        } else if (action === 'remove-assign') {
            if (item.assignedToName == 'anonymous') {
                ShowErrorModal('Lead chưa được phân công');
            }
            else {
                setActionObject(item)
                setLeadModalRemoveOwner({ value: '', isToggle: true })
            }
        }
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    //options
    const actionItems = [
        {
            key: 'update',
            icon: 'Edit',
            text: 'Chỉnh sửa',
            onClick: actionClick
        },
        // {
        //     key: 'delete',
        //     icon: 'Cancel',
        //     text: 'Xóa',
        //     onClick: actionClick
        // },
        {
            key: 'change',
            icon: 'EditContact',
            text: 'Đổi chủ sở hữu',
            onClick: actionClick
        },
        {
            key: 'remove-assign',
            icon: 'PeopleBlock',
            text: 'Bỏ phân công',
            onClick: actionClick
        }
    ];

    const options = {
        status: statuses,
        dateTypes: [
            {
                key: 'assignDate',
                text: 'Ngày phân công chăm sóc',
            },
            {
                key: 'convertDate',
                text: 'Ngày chuyển đổi',
            },
            {
                key: 'createDate',
                text: 'Ngày tạo',
            },
            {
                key: 'updateDate',
                text: 'Ngày cập nhật',
            },
        ],
        source: sourceList,
        site: siteList
    };

    const columns = [
        { name: '#', key: '#', minWidth: 40, maxWidth: 40, isResizable: true, render: { type: 'index' } },
        { name: 'Họ tên', key: 'title', fieldName: 'title', minWidth: 140, maxWidth: 250, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
        { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
        { name: 'Email', key: 'email', fieldName: 'email', minWidth: 140, maxWidth: 200, isResizable: true },
        { name: 'Nguồn lead', key: 'sourceId', fieldName: 'sourceId', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'dropdown', options: options.source } },
        { name: 'Trạng thái', key: 'status', fieldName: 'status', minWidth: 100, maxWidth: 100, isResizable: true, render: { type: 'dropdown', options: options.status } },
        { name: 'Lần phân công', key: 'totalAssignments', fieldName: 'totalAssignments', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'money' } },
        { name: 'NVCS', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 150, maxWidth: 150, isResizable: true },
        { name: 'Ngày chăm sóc', key: 'assignedDate', fieldName: 'assignedDate', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'datetime' } },
        { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'datetime' } },
        { key: 'leadId', fieldName: 'leadId', minWidth: 40, maxWidth: 40, isResizable: true, render: { type: 'button', text: 'Chức năng', items: actionItems } },
    ];


    const onLeadDelete = () => {
        if (leadModalDelete.value.trim() != actionObject.mobile) {
            ShowErrorModal("Số điện thoại không chính xác")
            return
        }
        deleteAxios('leads/' + actionObject.leadId, null, (response) => {
            if (response) {
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setLeadModalDelete)

                let nextPage = gridData.page
                if (gridData.items.length - 1 == 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })
    }

    const onLeadRemoveOwner = () => {
        if (leadModalRemoveOwner.value.trim() != actionObject.mobile) {
            ShowErrorModal("Số điện thoại không chính xác")
            return
        }

        putAxios(`/leads/${actionObject.leadId}/assigned-to/anonymous`, null, response => {
            if (response) {
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setLeadModalRemoveOwner)

                let nextPage = gridData.page
                if (gridData.items.length - 1 == 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        });
    }

    // change owner of a lead
    const changeOwner = () => {
        setIsUpdating(true)
    }

    // select nvcs whom to change ownership to
    const selectNVCS = (id) => {
        setSelectedStaffId(id);
    };

    // get userName from id
    const getName = (id) => {
        return nvcs.filter((nv) => {
            return nv.userId === id;
        })[0]?.userName;
    };

    // config items to pass to table
    const configItems = (data) => {
        let items = [...data];

        items = items.map((item) => {
            // config one user
            const single = {
                ...item,
                name: getName(item.assignedToId),

            };
            return single;
        });

        return items;
    };

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal} />

            {/* <CustomModal title='Xóa Lead' isModalOpen={leadModalDelete.isToggle}
                hideModal={() => toogleModal(setLeadModalDelete)} buttonAction="Xóa" width={640}
                onClick={onLeadDelete} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setLeadModalDelete({ ...leadModalDelete, value })}
                    value={leadModalDelete.value}
                    label={`Nhập Số điện thoại: ${actionObject.mobile} để xóa`} />
            </CustomModal> */}


            <CustomModal title='Bỏ phân công' isModalOpen={leadModalRemoveOwner.isToggle}
                hideModal={() => toogleModal(setLeadModalRemoveOwner)}
                buttonAction="Thực thi"
                width={640}
                onClick={onLeadRemoveOwner} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setLeadModalRemoveOwner({ ...leadModalRemoveOwner, value })}
                    value={leadModalRemoveOwner.value}
                    label={`Nhập Số điện thoại: ${actionObject.mobile} để bỏ chăm sóc`} />
            </CustomModal>

            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>

                <StaffFilter data={staffFilter} typeList={staffList()} width={250} onChange={data => setStaffFilter(data)} />

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Trạng thái"
                        width={250}
                        options={options.status}
                        value={status}
                        onChange={setStatus}
                    />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Nguồn"
                        width={250}
                        options={options.source}
                        value={sourceId}
                        onChange={setSourceId}
                    />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Website"
                        width={250}
                        options={options.site}
                        value={appId}
                        onChange={setAppId}
                    />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kiểu ngày"
                        width={250}
                        dropdownWidth={250}
                        options={options.dateTypes}
                        value={dateType}
                        onChange={setDateType}
                    />
                </div>

                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: "Leads" }}
                            width={"100%"}
                            //addButton={{ text: "Tạo mới", onClick: onAddClick }}
                        />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={{ ...gridData, items: configItems(gridData.items) }}
                        onPageChange={page => post(page)} />
                </div>
            </div>
            <CustomModal
                title="Đổi chủ sở hữu"
                isModalOpen={toggledModal}
                buttonAction="Lưu"
                width={340}
                hideModal={() => {
                    setToggledModal(false);
                }}
                onClick={changeOwner}
            >
                <div style={{ display: 'flex', flexDirection: 'column', gap: 8 }}>
                    <label style={{ color: secondaryLightColor, fontWeight: 500 }}>
                        Nhân viên chăm sóc
                    </label>
                    {currentNVCS && <CustomPeoplePicker
                        nvcs={nvcs}
                        selectNVCS={selectNVCS}
                        defaultSelectedItems={[currentNVCS]}
                        onPeoplePickerChange={onPeoplePickerChange}
                        setCurrentNVCS={setCurrentNVCS}
                    />}
                    {!currentNVCS && <CustomPeoplePicker
                        nvcs={nvcs}
                        selectNVCS={selectNVCS}
                        onPeoplePickerChange={onPeoplePickerChange}
                        setCurrentNVCS={setCurrentNVCS}
                    />}
                </div>
            </CustomModal>
        </div >
    );
}

export default Lead;
