import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SectionHeader from '../../../components/SectionHeader/SectionHeader';
import CustomDetailView from '../../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../../utils/helpers';
import SearchInput from '../../../components/SearchInput/SearchInput';
import { getAxios,deleteAxios, postAxios } from '../../../utils/axios';
import CustomModal  from '../../../components/Modal/Modal';
import ErrorModal from '../../../components/Modal/ErrorModal';
import AddModal from './AddModal';

export default function ListGroup() {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);
    const {groupId} = useParams()

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');

    const [groupData,setGroupData] = React.useState({})

    const [actionObject,setActionObject] = React.useState({})
    const [modalDelete,setModalDelete] = React.useState(false)

    const [addData] = React.useState(null)
    const [modalAdd,setModalAdd] = React.useState(false)

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    //init
    React.useEffect(() => {
        getAxios(`/user-groups/${groupId}`, null, response => {
            if (response.message) {
                //todo
                ShowErrorModal(response.message)
                return
            }

            setGroupData(response.data)
        })

        const page = locParams ? locParams.page - 1 : 0;
        post(page, true);
    }, []);

    //function
    const onAddClick = () => { setModalAdd(true) };

    const post = (page, firstLoad) => {

        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            page: page + 1,
            pageSize: pageSize,
        };

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios(`user-groups/${groupId}/functions`, params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize !== 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const actionClick = (action, id, item) => {
        switch (action) {
            case "delete":
                setActionObject(item)
                setModalDelete(true)
                break
            default:
                throw new Error("invalid operation")
        }
    }

    const modalAction = {
        onDelete: ()=>{
            deleteAxios(`/user-groups/${groupId}/functions/${actionObject.functionId}`,null,(response)=>{
                if (response){
                    if (response.message)
                        return ShowErrorModal(response.message)

                    setModalDelete(false)

                    let nextPage = gridData.page
                    if (gridData.items.length-1 === 0 && nextPage > 0)
                        nextPage--
                    post(nextPage)
                }
            })
        },
        onAdd: (functionId) => {
            postAxios(`user-groups/${groupId}/functions`, {functionId}, (response) => {
                if (response) {
                    if (response.data) {
                        setModalAdd(false)
                        post(gridData.page)
                    } else if (response.message) {
                        ShowErrorModal(response.message)
                    }
                }

            })
        }
    }


    const itemActions = [{
        key: 'delete',
        text: 'Xóa',
        icon: 'Cancel',
        onClick: actionClick,
    }]
    const groupColumns = [
        {
            key: 'functionId',
            fieldName: 'functionId',
            name: 'Id',
            minWidth: 72,
            maxWidth: 72,
            isResizable: true,
        },
        {
            key: 'code',
            name: 'Code',
            fieldName: 'code',
            minWidth: 240,
            isResizable: true,
        },
        {
            key: 'name',
            name: 'Mô tả',
            fieldName: 'name',
            minWidth: 400,
            isResizable: true,
        },
        {
            key: '_functionId',
            fieldName: 'functionId',
            minWidth: 100,
            maxWidth: 100,
            render: { type: 'button', text: 'Chức năng', icon: '', items: itemActions },
        },
    ]

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>

            <CustomModal title='Xóa chức năng khỏi nhóm quyền' isModalOpen={modalDelete}
                hideModal={() => setModalDelete(false)} buttonAction="Xóa" width={640}
                onClick={modalAction.onDelete} >
                <p style={{textAlign:'center'}}>Bạn có chắc chắn muốn xóa chức năng <b>{actionObject.code}</b> không?</p>
            </CustomModal>

            <AddModal data={addData} isShow={modalAdd} hideModal={setModalAdd}
                name={groupData.name} onErrorApi={ShowErrorModal}
                onSubmit={modalAction.onAdd}
            />

            <div style={{ display: "block", width: "100%", height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: groupData.name }}
                            width={"100%"}
                            addButton={{ text: "Thêm chức năng", onClick: onAddClick , width: 170}} />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={groupColumns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div>
    );
}

