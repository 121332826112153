import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import CustomDetailView from '../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown } from '../../components/BasicInput/CustomInput';
import { getAxios } from '../../utils/axios';
import DateFilterForm from '../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from '../../components/FilterPanel/FilterPanel';
import { isPaidOptions, isAgentOptions, staffOptions, dateTypeOptions } from '../../utils/constants';
import { firstDay, lastDay } from '../../utils/date';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';
import { Link } from 'office-ui-fabric-react';

const apiHelper = {
    getApi: async (url, param) => {
        return new Promise((res, rej) => {
            getAxios(url, param, (response) => {
                if (response) {
                    if (response.status == 404)
                        return res(null)
                    if (response.message)
                        return rej(response.message)
                    return res(response.data)
                }
                rej('Error')
            })
        })
    },
    getChannel: async function(){
        return this.getApi('/channels')
    }
}

const AccountContactInvoice = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);
    const {optional,id} = useParams()

    const [passData] = React.useState(()=>{
        const obj = {...locParams,api:optional}
        switch(optional) {
            case 'leads':
                obj.title = "Leads"
                obj.api = 'contacts'
                break
            case 'accounts':
                obj.title ="Khách hàng"
                break
            case 'contacts':
                obj.title ="Tài khoản"
                break
        }
        return obj
    })

    const [isLoading, setIsLoading] = React.useState(true);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [channelList, setChannelList] = React.useState([]);
    const [channelId, setChannelId] = React.useState(locParams?.channelId || '');
    const [dateType,setDateType] =  React.useState(locParams?.dateType || 'createDate');
    const [isPaid,setIsPaid] =  React.useState(locParams?.isPaid || '');

    const [date, setDate] = React.useState(
        locParams?.dateKey ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: firstDay, endDate: lastDay, key: "5" }
    );

    React.useEffect(() => {
        apiHelper.getChannel().then(res=>{
            const temp = res || []
            const channelList = temp.map(x=>({key:''+x.channelId,text:x.name}))
            channelList.unshift({ key: '', text: 'Tất cả' })
            setChannelList(channelList)
        }).catch(console.log)

        if (locParams?.page) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }

    }, []);
    
    //function
    const post = (page, firstLoad) => {
        setIsLoading(true);
        const params ={
            ...passData,
            channelId,
            isPaid,
            dateType,
            page: page + 1,
            pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            toogleFilter: filter.value
        };

        let url = `/${passData.api}/${id}/invoices`

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }
        
        getAxios(url, params, response => {
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }
            setIsLoading(false);
            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const actionClick = (event, action) => {
        switch (action) {
            case 'linkList':
                pushHistory(history, `/${optional}`, null, event.ctrlKey)
                break;
            case 'linkDetail':
                pushHistory(history, `/${optional}/${passData.originalId}/details`, null, event.ctrlKey)
                break;
        }
    }

    const tableAction = (action, id, item, event) => {
        switch (action) {
            case 'staff':
                pushHistory(history, `/staffs/${item.assignedToId}/details`, null, event.ctrlKey)
                break;
            case 'contact':
                pushHistory(history, `/contacts/${item.contactId}/details`, null, event.ctrlKey)
                break;
            case 'invoice':
                pushHistory(history, '/invoicedetail', {code: item.code}, event.ctrlKey)
                break;
        }
    }

    const columns = [
        {
            name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true,
            render: { type: 'index' }
        },
        {
            name: 'Nhân viên', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 200, maxWidth: 200,
            isResizable: true, 
        },
        {
            name: 'Điện thoại', key: 'contactMobile', fieldName: 'contactMobile', minWidth: 150, maxWidth: 150,
            isResizable: true, render: { type: 'link', key: 'contact', onClick: tableAction }
        },
        {
            name: 'Họ tên', key: 'contactName', fieldName: 'contactName', minWidth: 200, maxWidth: 200,
            isResizable: true, render: { type: 'link', key: 'contact', onClick: tableAction }
        },
        {
            name: 'Mã hóa đơn', key: 'code', fieldName: 'code', minWidth: 200, maxWidth: 200,
            isResizable: true, render: { type: 'link', key: 'invoice', onClick: tableAction }
        },
        {
            name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 200, maxWidth: 200,
            isResizable: true, render: { type: 'money' }
        },
        { name: 'Thụ hưởng', key: 'assignedToId', fieldName: 'assignedToId', minWidth: 90, maxWidth: 90, isResizable: true, render: { type: 'assignedId' } },
        { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        { name: 'Ngày thanh toán', key: 'paymentDate', fieldName: 'paymentDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
    ];

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kênh đăng"
                        width={250}
                        height={350}
                        options={channelList}
                        value={channelId}
                        onChange={setChannelId}
                    />
                </div>
                
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại hóa đơn"
                        width={250}
                        height={350}
                        options={isPaidOptions}
                        value={isPaid}
                        onChange={setIsPaid}
                    />
                </div>

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại ngày"
                        width={250}
                        height={350}
                        options={dateTypeOptions}
                        value={dateType}
                        onChange={setDateType}
                    />
                </div>
                
                <DateFilterForm value={date} defaultKey={date.key} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <div style={{padding:16, fontSize:14}}>
                            <Link onClick={e=>actionClick(e,'linkList')}>{passData.title}</Link>
                            <label>	&gt; </label>
                            <Link onClick={e=>actionClick(e,"linkDetail")}>{passData.fullName}</Link>
                            <p style={{fontSize:18,fontWeight:'bold'}}>Danh sách hóa đơn</p>
                        </div>
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loggedId={localStorage.getItem('userId')}
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}
export default AccountContactInvoice;
