import { DefaultButton, FontIcon } from "@fluentui/react"
import React from "react"

import styles from './styles.module.css'

/**
 * title, btnName, btnAction
 */
export default React.memo(function (props) {
    const [isShow, setIsShow] = React.useState(true)
    const toggleFn = React.useCallback(() => setIsShow(v => !v), [])

    const [childHeight,setChildHeight] = React.useState('100%')
    const childDOM = React.useCallback(dom=>{
        if (dom !== null) {
            setChildHeight(dom.scrollHeight)
        }
    },[])

    return <div>
        <div className={styles.header} onClick={toggleFn}>
            <FontIcon className={styles.icon}
                iconName={isShow ? 'ChevronDownMed' : 'ChevronRightMed'}
            />
            <div className={styles.title}>
                {props.title}
            </div>
            <div className={styles.optional}>
                {props.btnName && 
                    <DefaultButton text={props.btnName} 
                        onClick={(e)=>{
                            e.stopPropagation()
                            props.btnAction(e)
                        }}
                    />
                }
            </div>
        </div>
        <div ref={childDOM} className={styles.children} style={isShow?{height:childHeight}:{height:0}}>
            {props.children}
        </div>
    </div>
})