import React from 'react';
import { useHistory, useLocation, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { getAxios,deleteAxios } from '../../utils/axios';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import AddRole from './AddRole';

import UpdateRole from './UpdateRole';

const RomanNumber=["I","II","III","IV","V","VI","VII","VIII","IX","X","XI"
,"XII","XIII","XIV","XV","XVI","XVII","XVIII","XIX","XX"]
const ReplaceName = (name,roleNode) => {
    const level = roleNode.split('/').length - 2;
    const customName = RomanNumber[level] + ') ' + name;
    const style = {textIndent: level*10};
    return [customName, style];
}

export default function Role () {
    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <ListRole />
            </Route>
            <Route path={`${path}/:roleId/edit`}>
                <UpdateRole />
            </Route>
            <Route path={`${path}/add`}>
                <AddRole />
            </Route>
        </Switch>
    </>)
}

// function UpdateRole() {
//     const { roleId } = useParams()
//     return <span>Update roleId: {roleId}</span>   
// }

function ListRole() {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');

    const [actionObject, setActionObject] = React.useState({})

    const [roleModalDelete, setRoleModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    //init
    React.useEffect(() => {
        const page = locParams ? locParams.page - 1 : 0;
        post(page, true);
    }, []);

    //function
    const onAddClick = (event) => {         
        pushHistory(history, `${location.pathname}/add`, null, event.ctrlKey);
    };

    const post = (page, firstLoad) => {

        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            page: page + 1,
            pageSize: pageSize,
        };

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios('user-roles', params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    newGridData.items.forEach(x=>{
                        const customObj =  ReplaceName(x.name, x.roleNode);
                        x.name = customObj[0];
                        x.customStyle = customObj[1];
                    });

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize !== 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const actionClick = (action, id, item, event) => {
        switch (action) {
            case "update":
                pushHistory(history, `${location.pathname}/${item.roleId}/edit`, null, event.ctrlKey);
                break
            case "delete":
                setActionObject(item)
                setRoleModalDelete({value:'',isToggle:true})
                break
            case "detail":
                pushHistory(history, `/roled-users/${item.roleId}`, null, event.ctrlKey);
                break
            default:
                throw new Error("invalid operation");
        }
    }

    //options
    const actionItems = [
        {
            key: 'update',
            text: 'Chỉnh sửa',
            icon: 'Edit',
            onClick: actionClick,
        },
        {
            key: 'delete',
            text: 'Xóa',
            icon: 'Cancel',
            onClick: actionClick,
        }
    ];


    const roleColumns = [
        {
            key: 'ordinal',
            name: 'STT',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            render: { type: 'index' }
        },
        {
            key: 'name',
            name: 'Tên Role',
            fieldName: 'name',
            minWidth: 280,
            isResizable: true,
            render: { type: 'link', onClick: actionClick, key: 'detail', styleField: 'customStyle' }
        },
        {
            key: 'code',
            name: 'Code',
            fieldName: 'code',
            minWidth: 120,
            isResizable: true,
        },
        {
            key: 'isActive',
            name: 'isActive',
            fieldName: 'isActive',
            minWidth: 90,
            isResizable: true,
            render: { type: 'checkbox' }
        },
        {
            key: 'createDate',
            name: 'Ngày tạo',
            fieldName: 'createDate',
            minWidth: 160,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'latestUpdate',
            name: 'Ngày cập nhật',
            fieldName: 'latestUpdate',
            minWidth: 160,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'functionId',
            fieldName: 'roleId',
            minWidth: 100,
            render: { type: 'button', text: 'Chức năng', icon: '', items: actionItems },
        },
    ]

    const onRoleDelete = () => {
        if (roleModalDelete.value.trim() !== actionObject.code) {
            ShowErrorModal("Mã Code không chính xác")
            return
        }
        deleteAxios('user-roles/'+actionObject.roleId,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setRoleModalDelete)

                let nextPage = gridData.page
                if (gridData.items.length-1 === 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })

    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>

            <CustomModal title='Xóa Nhóm' isModalOpen={roleModalDelete.isToggle}
                hideModal={() => toogleModal(setRoleModalDelete)} buttonAction="Xóa" width={640}
                onClick={onRoleDelete} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setRoleModalDelete({ ...roleModalDelete, value })}
                    value={roleModalDelete.value}
                    label={`Nhập Code: ${actionObject.code} để xóa`} />
            </CustomModal>

            <div style={{ display: "block", width: "100%", height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: "Nhóm" }}
                            width={"100%"}
                            addButton={{ text: "Tạo mới", onClick: onAddClick }} />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={roleColumns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div>
    );
}
