import {
    useParams,
    useHistory
} from "react-router-dom";
import React, { useEffect } from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { FontIcon } from "@fluentui/react";
import { DefaultButton,Link } from 'office-ui-fabric-react';

import { ComboBox } from 'office-ui-fabric-react/lib/index';

import { CustomButton,CustomTextField } from "../../components/BasicInput/CustomInput";
import { getAxios, putAxios } from "../../utils/axios";

import { formatDateTime } from "../../utils/date";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateRole.module.css'
import { pushHistory } from "../../utils/helpers";

const options= [
    { key: true, text: 'Hoạt động' },
    { key: false, text: 'Không hoạt động' },
];

const choiceStyle = {
    flexContainer: {
        display: 'flex',
        selectors: {
            ".ms-ChoiceField": {
                margin:'3px 24px 0 0',
            }
        }
    }
}

const initalData = {isActive:true}

const onUpdateRole = async(data,roleId) => {
    return new Promise((res,rej) => {
        putAxios('user-roles/'+roleId,data,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(code,name,parentRoleNode)=>{
    const errorValidate = {}

    if (!name){
        errorValidate.name = 'Tên nhóm không được bỏ trống'
    }
    if (!parentRoleNode){
        errorValidate.parentRoleNode = 'Cấp cha không được bỏ trống'
    }

    return errorValidate
}

const getUserRole= ()=>{
    return new Promise((res,rej) => {
        getAxios('user-roles',{pageSize:1000000},response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const ToogleBox = React.memo((props) => {
    return (
        <div className={styles.toggle}>
            <div className={styles.toggle__header} onClick={()=>props.onChange(!props.value)}>
                <FontIcon className={styles.toggle__icon}
                    iconName={props.value?'ChevronDownMed':'ChevronRightMed'}/>
                <span className={styles.toggle__text}>Thông tin Hệ thống</span>
            </div>
            {props.value && <div className={styles.toggle__children}>
                {props.children}
            </div>}
        </div>
    )
})

const getUserInfo = (id) => {
    return new Promise((res,rej)=>{
        getAxios('/users/'+id,null,(response)=>{
            if (response){
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}

export default function UpdateRole() {
    const { roleId } = useParams()
    const history = useHistory()

    const [data,setData] = React.useState(initalData)
    const [isToggle, setIsToggle] = React.useState(1)

    const [selectedKey, setSelectedKey] = React.useState('');
    const [userRoleList,setUserRoleList] = React.useState([])

    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const onClick = (id, event) => {
        pushHistory(history, `/staffs/${id}/edit`, null, event.ctrlKey);
    }

    const OnBeforeUpdate = async () => {
        const errorValidate = validate(data.code,data.name,data.parentRoleNode)
        if (Object.keys(errorValidate).length === 0){
            setErrorInput({})
            return new Promise((res,rej)=>onUpdateRole(data,roleId).then(res).catch(err=>{
                ShowErrorModal(err)
                rej(err)
            }))
        }
        setErrorInput(errorValidate)
        return Promise.reject(errorValidate)
    }

    const onUpdate = () => {
        OnBeforeUpdate().then(()=>history.goBack()).catch(()=>{})
    }

    const onChange = React.useCallback(
        (ev, option) => {
            setSelectedKey(option?.key);
            if (option?.roleNode !== data.parentRoleNode)
                setData(x=>({...x,parentRoleNode:option?.roleNode}))
        },
        [data],
    );

    useEffect(()=>{
        getUserRole().then((data)=>{
            data.items.forEach(x=>{
                x.key=x.roleId
                x.text=x.name
            })
            setUserRoleList(data.items)

            getAxios(`user-roles/${roleId}`,null, async response=>{
                if (response.message) {
                    //todo
                    ShowErrorModal(response.message)
                    return
                }
                const roleData = response.data

                const fnList = [], attr = []
                if (roleData.createById > 0){
                    fnList.push(getUserInfo(roleData.createById))
                    attr.push('createBy')
                }
                if (roleData.updateById > 0){
                    fnList.push(getUserInfo(roleData.updateById))
                    attr.push('updatedBy')
                }

                const dataUser = await Promise.all(fnList)
                for (let i=0;i<attr.length;i++){
                    roleData[attr[i]] = dataUser[i]
                }

                let parentRoleNode = roleData.roleNode
                parentRoleNode = parentRoleNode.substr(0,parentRoleNode.lastIndexOf('/',parentRoleNode.length-2)+1)
                const parentRole = data.items.find(x=>x.roleNode===parentRoleNode)

                response.data.parentRoleNode = parentRole?.roleNode
                setData(response.data)
                setSelectedKey(parentRole?.roleId);
            })

        }).catch(err=>ShowErrorModal(err))
    },[])

    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>

            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Chỉnh sửa/Chi tiết Nhóm</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin</span>
                <div className={styles.box__component}>
                <div className={styles.item}>
                        <label className={styles.item__label}>Code</label>
                        <CustomTextField width={318} errorMessage={errorInput.code}
                            value={data.code||''} onChange={v=>setData(prev=>({...prev,code:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Tên Nhóm</label>
                        <CustomTextField width={318} errorMessage={errorInput.name}
                            value={data.name||''} onChange={v=>setData(prev=>({...prev,name:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Cấp cha</label>
                        <ComboBox
                            errorMessage={errorInput.parentRoleNode}
                            useComboBoxAsMenuWidth
                            styles={{errorMessage:{fontFamily:'inherit'},
                            container :{width:318},optionsContainer :{maxHeight:200}}}
                            selectedKey={selectedKey}
                            allowFreeform
                            autoComplete='on'
                            options={userRoleList}
                            onChange={onChange}
                            onKeyDown={e=>{
                                if (selectedKey && (e.key === "Backspace" || e.key === "Delete")) {
                                    onChange(null,null)
                                }
                            }}
                        />
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Active</label>
                        <ChoiceGroup styles={choiceStyle} options={options}
                            selectedKey={data.isActive}
                            onChange={(e,it)=>setData(prev=>({...prev,isActive:it.key}))} />
                    </div>
                </div>
                {data?.roleId&&
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    <CustomButton label="Lưu" onClick={onUpdate} />
                </div>}
            </div>

            <div className={styles.box}>
                <ToogleBox value={isToggle} onChange={setIsToggle}>
                    <div className={styles.statusbox}>
                        <div className={styles.statusbox__label}>Ngày tạo</div>
                        {data?.roleId&&<>
                        {data?.createBy&&<>
                        <Link className={styles.statusbox__link} onClick={(event)=>onClick(data.createdBy.userId, event)}>{data.createBy.userName}</Link></>}
                        <span>{formatDateTime(data.createDate)}</span></>}
                    </div>
                    <div className={styles.statusbox}>
                        <div className={styles.statusbox__label}>Ngày cập nhật mới nhất</div>
                        {data?.roleId&&<>
                        {data?.updatedBy&&<>
                        <Link className={styles.statusbox__link} onClick={(event)=>onClick(data.updatedBy.userId, event)}>{data.updatedBy.userName}</Link></>}
                        <span>{formatDateTime(data.latestUpdate)}</span></>}
                    </div>
                </ToogleBox>
            </div>
        </div>
    );
}