import React from 'react';
import { Breadcrumb } from 'office-ui-fabric-react/lib/Breadcrumb';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { getAxios } from '../../utils/axios';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import MultiChannelProductFilter from '../../components/ChannelProductFilter/MultiChannelProductFilter';

const UserRevenuesByProductsDetail = () => {
    // router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    // state
    const [isFirstLoad, setIsFirstLoad] = React.useState(false);

    const [userId] = React.useState(locParams.userId);
    const [userName] = React.useState(locParams.userName);
    const [filter, setFilter] = React.useState(locParams && locParams.toggleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    //parent state
    const [staffFilter] = React.useState(
        locParams ? { key: locParams.filterKey, value: locParams.filterValue } : { key: '', value: '' });

    const [productFilter, SetProductFilter] = React.useState(
        locParams ? { channelId: locParams.channelId, productId: locParams.productId } : { channelId: '', productId: '' });

    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );

    //init
    React.useEffect(() => {
        if (!locParams) {
            setIsFirstLoad(true);
        }
    }, []);

    React.useEffect(() => {
        if (locParams && locParams.page) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
    }, [isFirstLoad]);


    // function
    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            userId: userId,
            userName: userName,
            filterKey: staffFilter.key,
            filterValue: staffFilter.value,
            channelId: productFilter.channelId,
            products: productFilter.productId,
            isPaid:true,
            dateType:'createDate',
            page: page + 1,
            pageSize: pageSize,
            dateKey: date.key,
            beginDate: date.beginDate,
            endDate: date.endDate
        };

        if (!firstLoad) {
            pushHistory(history, '/report/user-revenues-by-products-detail', params);
        }

        var url = userId == localStorage.getItem('userId') ? `invoices/managed-by/me` : `invoices/managed-by/${userId}`;

        getAxios(url, params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toggleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const actionClick = (action, value, item, event) => {
        if (action == "detail") {
            pushHistory(history, `/contacts/${item.contactId}/details/relative`, null, event.ctrlKey);
        } else if (action == 'invoice') {
            pushHistory(history, '/invoicedetail', { code: value }, event.ctrlKey);
        }
    };

    //option
    const columns = [
        { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Số điện thoại', key: 'contactMobile', fieldName: 'contactMobile', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'link', key: 'detail', onClick: actionClick } },
        { name: 'Tên khách', key: 'contactName', fieldName: 'contactName', minWidth: 250, maxWidth: 250, isResizable: true },
        { name: 'Hóa đơn', key: 'code', fieldName: 'code', minWidth: 250, maxWidth: 100, isResizable: true, render: { type: 'link', key: 'invoice', onClick: actionClick } },
        { name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 250, maxWidth: 250, isResizable: true, render: { type: 'money' } },
        { name: 'Ngày thanh toán', key: 'createDate', fieldName: 'createDate', minWidth: 250, maxWidth: 250, isResizable: true, render: { type: 'datetime' } },
    ];

    const breadCumItems = [
        {
            text: 'Tổng hợp', key: 'group', onClick: (event) => {
                const params = {
                    dateKey: date.key,
                    beginDate: date.beginDate,
                    endDate: date.endDate,
                    filterKey: staffFilter.key,
                    filterValue: staffFilter.value,
                    channelId: productFilter.channelId,
                    products: productFilter.productId,
                };

                pushHistory(history, '/report/user-revenues-by-products', params, event.ctrlKey);
            }
        },
        { text: `${userName}`, key: 'detail', isCurrentItem: true, as: 'h4' },
    ];

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toggleFilter(false)}
                onClick={() => post(0, false)}>

                <MultiChannelProductFilter
                    value={productFilter}
                    onChange={(value) => SetProductFilter(value)} />

                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "120px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <Breadcrumb items={breadCumItems} />
                        <SectionHeader
                            title={{ text: `Báo cáo doanh thu dịch vụ theo nhân viên - Chi tiết của ${userName}` }}
                            width={"100%"}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toggleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 120px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}
export default UserRevenuesByProductsDetail;
