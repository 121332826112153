import React from 'react';
import { IconButton } from 'office-ui-fabric-react';
import { CustomButton } from '../../components/BasicInput/CustomInput';

const FilterPanel = (props) => {
    const render = () => {
        return (
            <div style={{ width: props.width, maxHeight: '100%', overflow: "hidden", display: "flex", flexDirection: "column", border: '1px solid #eee', backgroundColor: "white", borderRadius: "10px", float: "right" }}>
                <div style={{ height: '70px', paddingLeft: '25px', textAlign: "left", lineHeight: '70px' }}>
                    <span style={{ fontSize: 28, fontWeight: 500 }}>Lọc</span>
                    <IconButton iconProps={{ iconName: 'CalculatorMultiply' }} style={{ float: "right", margin: '15px', height: '40px', width: '40px', border: '1px solid #ccc', borderRadius: '50%' }} onClick={() => props.onClose()} />
                </div>
                <div style={{ padding: "8px 25px", borderTop: '1px solid #28a8ea', borderBottom: '1px solid #28a8ea', maxHeight: 'calc(100% - 140px)', overflow: "auto" }}>
                    {props.children}
                </div>
                <div style={{ height: '70px', paddingTop: '20px', textAlign: "center" }}>
                    <CustomButton label="Tìm" onClick={() => props.onClick()} />
                </div>
            </div>
        )
    }

    return render();
}

export default FilterPanel;
