import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor, middleGrayColor, textGrayColor } from './../../styles/colors';

import { borderRadius15px } from './../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        briefInfo: {
            backgroundColor: middleGrayColor,
            display: 'flex',
            gridColumnStart: '1',
            gridColumnEnd: '3',
            borderBottomLeftRadius: borderRadius15px,
            borderBottomRightRadius: borderRadius15px,
            padding: 16,
            gap: 32,
        },
        content: {
            display: 'flex',
            gap: 16,
        },

        briefInfoTitle: {
            color: textGrayColor,
            fontSize: 12,
        },
        briefInfoContent: {
            color: secondaryLightColor,
            fontSize: 14,
        },
    });
};
