import React from 'react';
import { Link } from 'react-router-dom';

// 3rd party packaged
import { useParams } from 'react-router-dom';

// ui components
import SectionHeaderFlex from './../../components/SectionHeaderFlex/SectionHeaderFlex';
import CustomDetailView from './../../components/Table/CustomDetailView';
import SearchField from '../../components/SearchInput/SearchField';
import AddButton from '../../components/Buttons/Buttons';

import { getAxios, putAxios } from "../../utils/axios";
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import BasicInput from '../../components/BasicInput/BasicInput';

// hooks
import { useForm } from '../../hooks/useForm';
import { useFetch, handleError } from '../../hooks/useFetch';

// utils
import { usersApiEndpoint, userRolesApiEndpoint, apiUrl } from './../../utils/constants';
import { token } from './../../utils/token';
import {secondaryLightColor} from '../../styles/colors';

// private hooks
import { useFetchWithQS } from './hooks';

const FIELDS = { keyword: { value: '' } };
const USER_FIELDS = {userName: { value: ''} }

export default function RoledUsers() {
    const id = useParams().id;
    // local states
    const [page, setPage] = React.useState(1);

    // useForm hook is call to get form state
    const { fields, handleInputChange, optionalQueryStrings } = useForm(FIELDS);
    // get form state for a user
    const { fields: userFields, handleInputChange: handleUserInputChange } = useForm(USER_FIELDS);

    const { data: roleData } = useFetch(`${userRolesApiEndpoint}/${id}`);

    // // get roleNode
    // const roleNode = roleData?.roleNode;

    // config optionalQueryString and dedaultQS to pass to useFetch
    const exception = [];
    const optionalQS = optionalQueryStrings(Object.keys(fields).filter((field) => exception.indexOf(field) === -1));

    // const defaultQS = `roleNode=${roleNode}&page=${page}&pageSize=20`;
    const defaultQS = `page=${page}&pageSize=20`;
    const [passedQS, setPassedQS] = React.useState(optionalQS);
    const [isReloading,setIsReloading] = React.useState(false)
    const [apiTimesCall, setApiTimesCall] = React.useState(1)

    // call api to get data
    const { data, error: err, loading } = useFetchWithQS(`${userRolesApiEndpoint}/${id}/users`, passedQS, defaultQS, isReloading, apiTimesCall);

    const [roleUserModalAdd, setRoleUserModalAdd] = React.useState({
        isToggle: false,
        value: '',
    })
    const [userList,setUserList] = React.useState([])
    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const [isUpdating, setIsUpdating] = React.useState(false)
    const [beingChangedUser, setBeingChangedUser] = React.useState(null)
    const [errors, setErrors] = React.useState({});
    const [toggledModal, setToggledModal] = React.useState(false);

    const [modalState, setModalState] = React.useState({
        title: '',
        handleClick: () => {},
        type: 'PUT',
        buttonText: 'Xóa',
    });

    // reset form in modal
    const resetForm = React.useCallback(() => {
        userFields.userName.value = '';
    }, [userFields.userName]);

    React.useEffect(()=>{
        getAxios('users',{pageSize:1000000},response=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)
                setUserList(response.data?.items)
            }
        })
    },[])

      // calling api to PUT user 
      React.useEffect(() => {
        if (isUpdating) {
            const configRequestOptions = (method) => {
                switch (method) {
                  
                    case 'PUT': {
                        return {
                            method: modalState.type,
                            headers: new Headers({
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            }),
                        };
                    }
                    default:
                        return;
                }
            };

            fetch(
                `${apiUrl}${usersApiEndpoint}/${beingChangedUser.userId}/role/empty`,
                configRequestOptions(modalState.type)
            )
                .then(async (res) => {
                    const data = await res.json();

                    if (res.ok) {
                        return data;
                    } else {
                        Promise.reject(data);
                    }
                })
                .then(
                    (data) => {
                        setToggledModal(false);
                        setIsUpdating(false);
                        setErrors({});
                        resetForm();
                        setApiTimesCall(prev => prev + 1)
                    },
                    (error) => {
                        ShowErrorModal(handleError(error).message);
                    }
                );

            setIsUpdating(false);
        }
    }, [ShowErrorModal, beingChangedUser?.userId, isUpdating, modalState.type, resetForm]);


    //  calculate pageCount
    let pageCount;
    if (data) {
        pageCount = data.total > 20 ? Math.ceil(data.total / 20) : 1;
    }

    // handle dropdown actions in data table
    const actionClick = (action, value, item) => {
        setBeingChangedUser(item);
        setModalState({
            title: 'Bỏ user ra khỏi nhóm',
            type: 'PUT',
            handleClick: () => {
                if (!userFields.userName.value.trim()) {
                    setErrors({ errUserName: 'Vui lòng nhập Username' });
                    return;
                } else if (userFields.userName.value.trim() === item.userName) {
                    setIsUpdating(true);
                } else {
                    setErrors({ errUserName: 'Username không đúng' });
                    return;
                }
            },
            buttonText: 'Xóa',
        });
        setToggledModal(true);
        return;
    };

    // action items for rows in table
    const actionItems = [
        {
            key: 'delete',
            icon: 'Cancel',
            text: 'Xóa',
            onClick: actionClick
        },
    ];

    const columns = [
        {
            key: '0',
            name: 'UserID',
            fieldName: 'userId',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
        },
        {
            name: 'Tên tài khoản',
            key: '1',
            // fieldName: 'userName',
            minWidth: 360,
            maxWidth: 360,
            isResizable: true,
            onRender: (item) => {
                return (
                    <Link style={{ color: secondaryLightColor, textDecoration: 'none' }} to={`/staffs/${item.userId}/details`}>
                        {item.userName}
                    </Link>
                );
            },
        },

        {
            name: 'Họ và tên',
            key: '2',
            fieldName: 'fullName',
            minWidth: 360,
            maxWidth: 360,
            isResizable: true,
        },

        {
            name: 'Điện thoại',
            key: '3',
            fieldName: 'mobile',
            minWidth: 250,
            maxWidth: 250,
            isResizable: true,
        },
        { name: 'Email', key: '4', fieldName: 'email', minWidth: 200, maxWidth: 200, isResizable: true },
        {
            key: 'userId',
            fieldName: 'userId',
            minWidtth: 50,
            maxWidth: 50,
            render: { type: 'button', text: 'Chức năng', items: actionItems },
        },
    ];

    const handleSearch = (e) => {
        e.preventDefault();
        setPage(1);
        setPassedQS(optionalQS);
    };

    const onAddClick = () => {
        setRoleUserModalAdd({value:'',isToggle:true})
    };

    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const onRoleUserAdd = () => {
        const userName = roleUserModalAdd.value.trim()
        if (userName === '') {
            setErrorInput({userName:'Tên tài khoản không được bỏ trống'})
            return
        }
        const userId=userList.find(x=>x.userName===userName)?.userId
        if (!userId){
            setErrorInput({userName:'Tên tài khoản không tồn tại'})
            return
        }

        putAxios(`users/${userId}/role/${id}`,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)
                toogleModal(setRoleUserModalAdd)
                setIsReloading(x=>!x)
            }
        })
    }

    // render modal body
    const renderModalContent = (type) => {
        if (type === 'PUT') {
            return (
                <div>
                    <BasicInput
                        label={`Nhập username: ${beingChangedUser?.userName} để xóa`}
                        name="userName"
                        onChange={handleUserInputChange}
                        formControlCss={{ width: '100%' }}
                        value={userFields.userName.value}
                        errorMessage={errors.errUserName}
                    />
                </div>
            );
        } 
        return null;
    }

    return (
        <div
            style={{
                display: 'block',
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
                borderRadius: '10px',
                float: 'left',
                overflow: 'auto',
            }}
        >
            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={CloseErrorModal}
            />

            <CustomModal
                title="Thêm Người dùng vào Nhóm"
                isModalOpen={roleUserModalAdd.isToggle}
                hideModal={() => {
                    setErrorInput({});
                    toogleModal(setRoleUserModalAdd);
                }}
                buttonAction="Lưu"
                width={640}
                onClick={onRoleUserAdd}
            >
                <p style={{ fontSize: 18, fontWeight: 700, paddingBottom: 16 }}>{roleData?.name}</p>
                <TextField
                    styles={{
                        subComponentStyles: { label: { root: { color: '#006FB6' } } },
                        fieldGroup: {
                            border: '1px solid #D0D6E0',
                            borderRadius: 6,
                        },
                    }}
                    onChange={(e, value) => setRoleUserModalAdd({ ...roleUserModalAdd, value })}
                    value={roleUserModalAdd.value}
                    errorMessage={errorInput.userName}
                    label="Nhập tên tài khoản của người dùng"
                />
            </CustomModal>

            <div style={{ width: '100%', display: 'inline-flex', height: '70px' }}>
                <SectionHeaderFlex text={roleData?.name} />
                <AddButton
                    text={'Thêm người dùng'}
                    style={{ alignSelf: 'center', marginRight: 'auto' }}
                    onClick={onAddClick}
                />
                <div
                    style={{
                        height: '100%',
                        width: '500px',
                        padding: '17px',
                        display: 'flex',
                        flexDirection: 'row-reverse',
                    }}
                >
                    <form onSubmit={handleSearch}>
                        <SearchField
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={fields.keyword.value}
                            onChange={handleInputChange}
                            name="keyword"
                            onSearchClick={handleSearch}
                        />
                    </form>
                </div>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                <CustomDetailView
                    loading={loading}
                    columns={columns}
                    gridData={{
                        error: err ? err?.message : '',
                        items: data ? data?.items : [],
                        page: page - 1,
                        pageCount: pageCount,
                    }}
                    onPageChange={(page) => setPage(page + 1)}
                />
            </div>

            {/* modal for deleting a user */}
            <CustomModal
                title={modalState.title}
                isModalOpen={toggledModal}
                buttonAction={modalState.buttonText}
                width={640}
                height={modalState.height}
                hideModal={() => {
                    setToggledModal(false);
                    setErrors({});
                    resetForm()
                }}
                onClick={ modalState.handleClick}
            >
                {renderModalContent(modalState.type)}
            </CustomModal>
            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={CloseErrorModal}
            />
        </div>
    );
}