import React from 'react';
import NVMBNLink from '../NVMBNLink/NVMBNLink';

import { getClasses } from './styles';

const { briefInfo, briefInfoTitle, briefInfoContent } = getClasses();

export default function BriefInfo(props) {
    const { data, nvcs } = props;
    const name = data?.name ? data.name : data?.title;

    return (
        <div className={briefInfo}>
            <div>
                <span className={briefInfoTitle}>Họ và tên</span>
                <p style={{ fontSize: 14 }}>{name}</p>
            </div>
            <div>
                <span className={briefInfoTitle}>Điện Thoại</span>
                <p className={briefInfoContent}>{data?.mobile}</p>
            </div>
            <div>
                <span className={briefInfoTitle}>Email</span>
                <p className={briefInfoContent}>{data?.email}</p>
            </div>
            <div>
                <span className={briefInfoTitle}>NVCS</span>
                <p className={briefInfoContent}>
                    {/* <NVMBNLink person={{ fullName: nvcs?.userName, userId: nvcs?.userId }} /> */}
                    {nvcs?.userName}
                </p>
            </div>
        </div>
    );
}
