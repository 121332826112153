import { mergeStyleSets } from '@fluentui/react';
import { darkJellyBeanColor, borderGrayColor } from './../../styles/colors';

export const getClasses = ({ btnCss }) => {
    return mergeStyleSets({
        btnStyles: {
            display: 'flex',
            borderRadius: 6,
            border: `1px solid ${borderGrayColor}`,
            height: 36,
            padding: '10.5px',
            alignItems: 'center',
            justifyContent: 'center',
            color: darkJellyBeanColor,
            background: 'none',
            cursor: 'pointer',
            fontSize: 15,
            fontWeight: '500',
            outline: 'none',
            gridGap: '10.5px',
            lineHeight: '20px',
        },
        optionalBtnStyles: btnCss,
    });
};
