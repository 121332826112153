import React from 'react';
import { ActionButton } from 'office-ui-fabric-react';
import { ReactComponent as Logo } from './../../logo.svg';
import SearchInput from '../../components/SearchInput/SearchInput';
import styles from './Header.module.css';
import { pushHistory, getParams } from '../../utils/helpers';
import { useHistory, useLocation } from 'react-router-dom';

export default function MainHeader() {
    const history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [quickSearchKey, setQuickSearchKey] = React.useState(locParams && locParams.quickSearchKey ? locParams.quickSearchKey : '');
    const userName = localStorage.getItem('userName');

    const logout = () => {
        localStorage.removeItem('AuthToken');
        localStorage.removeItem('userName');
        window.location.href = process.env.REACT_APP_LOGOUT_TARGET;
    }

    const onSearch = () => {
        const pathname = history.location.pathname;
        const needReload = pathname == '/quicksearchresult';
        let panel = 'leads';

        switch (pathname)
        {
            case 'leads':
                panel = 'leads';
                break;
            case '/contacts':
                panel = 'contacts';
                break;
            case '/accounts':
                panel = 'accounts';
                break;
            case '/invoices':
                panel = 'invoices';
                break;
        }

        pushHistory(history, '/quicksearchresult', { quickSearchKey: quickSearchKey, panel: panel });
        if (needReload) {
            history.go(0);
        }
    }

    return (
        <header id='app_header' className={styles.header}>
            <span className={styles.logo}>
                <Logo />
            </span>
            <div>
                <SearchInput
                    style={{ alignSelf: 'center' }}
                    width={400}
                    value={quickSearchKey}
                    onChange={setQuickSearchKey}
                    onEnter={onSearch}
                    onIconClick={onSearch}
                    placeholder="Tìm kiếm leads và xem thêm..."
                />
            </div>
            <div style={{ position: "absolute", right: 0, marginRight: '36px' }}>
                <span style={{ marginRight: '16px' }}><b>{userName}</b></span>
                <ActionButton iconProps={{ IconNames: 'logout' }} onClick={logout}>
                    Đăng xuất
                </ActionButton>
            </div>
        </header>
    );
}
