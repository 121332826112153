import {
    useHistory
} from "react-router-dom";
import React, { useEffect } from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { DefaultButton } from 'office-ui-fabric-react';

import { ComboBox } from 'office-ui-fabric-react/lib/index';

import { CustomButton,CustomTextField } from "../../components/BasicInput/CustomInput";
import { getAxios, postAxios } from "../../utils/axios";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateRole.module.css'

const options= [
    { key: true, text: 'Hoạt động' },
    { key: false, text: 'Không hoạt động' },
];

const choiceStyle = {
    flexContainer: {
        display: 'flex',
        selectors: {
            ".ms-ChoiceField": {
                margin:'3px 24px 0 0',
            }
        }
    }
}

const initalData = {isActive:true}

const onCreateFunction = async(data) => {
    return new Promise((res,rej) => {
        postAxios('user-roles',data,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(code,name,parentRoleNode)=>{
    const errorValidate = {}

    if (!name){
        errorValidate.name = 'Tên nhóm không được bỏ trống'
    }
    if (!parentRoleNode){
        errorValidate.parentRoleNode = 'Cấp cha không được bỏ trống'
    }
    
    return errorValidate
}

const getUserRole= ()=>{
    return new Promise((res,rej) => {
        getAxios('user-roles',{pageSize:1000000},response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

export default function AddRole() {
    const history = useHistory()
    
    const [data,setData] = React.useState(initalData)

    const [selectedKey, setSelectedKey] = React.useState('');
    const [userRoleList,setUserRoleList] = React.useState([])

    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const OnBeforeCreate = async () => {
        const errorValidate = validate(data.code,data.name,data.parentRoleNode)
        if (Object.keys(errorValidate).length == 0){
            setErrorInput({})
            return new Promise((res,rej)=>onCreateFunction(data).then(res).catch(err=>{
                ShowErrorModal(err)
                rej(err)
            }))
        }
        setErrorInput(errorValidate)
        return Promise.reject(errorValidate)
    }

    const onAdd = () => {
        OnBeforeCreate().then(()=>history.goBack()).catch(()=>{})
    }
    
    const onAddMore = (callback) => {
        OnBeforeCreate().then(()=>{
            setData(initalData)
            setSelectedKey('')
        }).catch(()=>{})
    }

    const onChange = React.useCallback(
        (ev, option) => {
            setSelectedKey(option?.key);
            if (option)
                setData(x=>({...x,parentRoleNode:option.roleNode}))
        },
        [],
    );

    useEffect(()=>{
        getUserRole().then((data)=>{
            data.items.forEach(x=>{
                x.key=x.roleId
                x.text=x.name
            })
            setUserRoleList(data.items)
        }).catch(err=>ShowErrorModal(err))
    },[])

    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Thêm mới Nhóm</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin</span>
                <div className={styles.box__component}>
                <div className={styles.item}>
                        <label className={styles.item__label}>Code</label>
                        <CustomTextField width={318} errorMessage={errorInput.code}
                            value={data.code||''} onChange={v=>setData(prev=>({...prev,code:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Tên Nhóm</label>
                        <CustomTextField width={318} errorMessage={errorInput.name}
                            value={data.name||''} onChange={v=>setData(prev=>({...prev,name:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Cấp cha</label>
                        <ComboBox 
                            errorMessage={errorInput.parentRoleNode}
                            useComboBoxAsMenuWidth
                            styles={{errorMessage:{fontFamily:'inherit'},
                            container :{width:318},optionsContainer :{maxHeight:200}}}
                            selectedKey={selectedKey}
                            allowFreeform
                            autoComplete='on'
                            options={userRoleList}
                            onChange={onChange}
                            onKeyDown={e=>{
                                if (selectedKey && (e.key == "Backspace" || e.key == "Delete")) {
                                    onChange(null,null)
                                }
                            }}
                        />
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Active</label>
                        <ChoiceGroup styles={choiceStyle} options={options} 
                            selectedKey={data.isActive} 
                            onChange={(e,it)=>setData(prev=>({...prev,isActive:it.key}))} />
                    </div>
                </div>
                
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    <DefaultButton style={{marginRight: 16}} text="Lưu & Thêm mới" onClick={onAddMore} />
                    <CustomButton label="Lưu" onClick={onAdd} />
                </div>
            </div>
        </div>
    );
}   