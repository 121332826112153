import {
    useParams,
    useHistory
} from "react-router-dom";
import React, { useEffect } from 'react';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { FontIcon } from "@fluentui/react";
import { DefaultButton,Link } from 'office-ui-fabric-react';

import { CustomButton,CustomTextField } from "../../components/BasicInput/CustomInput";
import { getAxios,putAxios } from "../../utils/axios";
import { formatDateTime } from "../../utils/date";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateFunction.module.css'
import { pushHistory } from "../../utils/helpers";

const options= [
    { key: true, text: 'Hoạt động' },
    { key: false, text: 'Không hoạt động' },
];

const choiceStyle = {
    flexContainer: {
        display: 'flex',
        selectors: {
            ".ms-ChoiceField": {
                margin:'3px 24px 0 0',
            }
        }
    }
}

const ToogleBox = React.memo((props) => {
    return (
        <div className={styles.toggle}>
            <div className={styles.toggle__header} onClick={()=>props.onChange(!props.value)}>
                <FontIcon className={styles.toggle__icon} 
                    iconName={props.value?'ChevronDownMed':'ChevronRightMed'}/>
                <span className={styles.toggle__text}>Thông tin Hệ thống</span>
            </div>
            {props.value && <div className={styles.toggle__children}>
                {props.children}
            </div>}
        </div>
    )
})

const getUserInfo = (id) => {
    return new Promise((res,rej)=>{
        getAxios('/users/'+id,null,(response)=>{
            if (response){
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}

const validate=(code,name,errorProvider)=>{
    if (!code){
        errorProvider("Mã Code không được bỏ trống")
        return false
    }
    if (!name){
        errorProvider("Thông tin mô tả không được bỏ trống")
        return false
    }
    return true
}

export default function FeatureEdit() {
    const { functionId } = useParams()
    const history = useHistory()
    
    const [data,setData] = React.useState({})
    const [isToggle, setIsToggle] = React.useState(1)
    const [refresh,setRefresh] = React.useState(true)

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])


    const onClick = (id, event) => {
        pushHistory(history, `/staffs/${id}/edit`, null, event.ctrlKey);
    }

    const onUpdate = () => {
        if (!validate(data.code,data.name,ShowErrorModal)){
            return
        }

        const param = {
            code:data.code,
            name:data.name,
            isActive:data.isActive
        }
        putAxios('user-group-functions/'+functionId,param,(response)=>{
            if (response.message){
                ShowErrorModal(response.message)
                return
            }
            history.goBack()
            // refreshData()
        })
    }
   
    useEffect(()=>{
        getAxios(`user-group-functions/${functionId}`,null, async response=>{
            if (response.message) {
                //todo 
                ShowErrorModal(response.message)
                return
            }
            const functionData = response.data

            const fnList = [], attr = []
            if (functionData.createdById > 0){
                fnList.push(getUserInfo(functionData.createdById))
                attr.push('createBy')
            }
            if (functionData.updatedById > 0){
                fnList.push(getUserInfo(functionData.updatedById))
                attr.push('updatedBy')
            }

            Promise.all(fnList).then(dataUser=>{
                for (let i=0;i<attr.length;i++){
                    functionData[attr[i]] = dataUser[i]
                }
            }).catch(()=>{})
            .finally(()=>
                setData(functionData)
            )
        })
        return ()=>setData({})
    },[refresh])

    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Chỉnh sửa/Chi tiết Chức năng</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Code</label>
                        <CustomTextField width={318}
                            value={data.code||''} onChange={v=>setData(prev=>({...prev,code:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Active</label>
                        <ChoiceGroup styles={choiceStyle} options={options}
                            selectedKey={data.isActive === undefined?true:data.isActive}
                            onChange={(e,it)=>setData(prev=>({...prev,isActive:it.key}))} />
                    </div>
                </div>

                <span className={styles.box__label}>Thông tin mô tả</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Mô tả</label>
                        <CustomTextField resizable={true} width={652} rows={7}
                            value={data.name||''}
                            onChange={v=>setData(prev=>({...prev,name:v}))} />
                    </div>
                </div>
                {data?.functionId&&
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    <CustomButton label="Lưu" onClick={onUpdate} />
                </div>}
            </div>
        
            <div className={styles.box}>
                <ToogleBox value={isToggle} onChange={setIsToggle}>
                    <div className={styles.statusbox}>
                        <div className={styles.statusbox__label}>Ngày tạo</div>
                        {data?.functionId&&<>
                        {data?.createBy&&<>
                        <Link className={styles.statusbox__link} onClick={(event)=>onClick(data.createdBy.userId, event)}>{data.createBy.userName}</Link></>}
                        <span>{formatDateTime(data.createDate)}</span></>}
                    </div>
                    <div className={styles.statusbox}>
                        <div className={styles.statusbox__label}>Ngày cập nhật mới nhất</div>
                        {data?.functionId&&<>
                        {data?.updatedBy&&<>
                        <Link className={styles.statusbox__link} onClick={(event)=>onClick(data.updatedBy.userId, event)}>{data.updatedBy.userName}</Link></>}
                        <span>{formatDateTime(data.latestUpdate)}</span></>}
                    </div>
                </ToogleBox> 
            </div>
        </div>
    );
}   