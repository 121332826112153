import React from 'react';
import { Link, useHistory } from 'react-router-dom';

// components
import CustomDetailView from '../../../components/Table/CustomDetailView';
import MoreLink from '../../../components/MoreLink/MoreLink';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// utils
import { contactsApiEndpoint, channelsApiEndpoint, productsApiEndpoint, leadsApiEndpoint, postsApiEndpoint } from '../../../utils/constants';
import { formatTimeDate } from '../../../utils/date';

// local style
import {getClasses} from './styles'
import { secondaryLightColor } from '../../../styles/colors';
import { pushHistory } from '../../../utils/helpers';

const { container, heading } = getClasses();

export default function ServicedClassifieds({ id, contactId }) {
    const history = useHistory();
    const path = `page=1&pageSize=6`;

    const { error, data, loading } = useFetch(
        `${contactsApiEndpoint}/${contactId}${postsApiEndpoint}/has-services?${path}`
    );
    const { data: channelsData} = useFetch(channelsApiEndpoint)
    const { data: productsData} = useFetch(productsApiEndpoint)

    const getChannelName  = (id) => {
        const channelName = channelsData?.filter(channel => {
            return channel.channelId === id
        })[0].name
        return channelName;
    }

    const getProductName  = (id) => {
        const productName = productsData?.filter(product => {
            return product.productId === id
        })[0].name
        return productName;
    }

    const configData = (data) => {
        return data.map(item => {
            return {
                ...item, channelName: getChannelName(item.channelId), productName: getProductName(item.productId)
            };
        });
    }


    const columns = [
        {
            key: '#',
            name: '#',
            minWidth: 80,
            maxWidth: 80,
            isResizable: true,
            onRender: (a,index) => index+1
        },
        {
            key: '1',
            name: 'Tiêu đề',
            // fieldName: 'title',
            minWidth: 260,
            isResizable: true,
            isMultiline: true,
            onRender: (item) => {
                return (<Link style ={{ color: secondaryLightColor, fontSize: 14, textDecoration: 'none'  }} to={`/serviced-classifieds/${item.postId}`}>{item.title}</Link>)
            }
        },
        {
            key: 'channelId',
            fieldName: 'channelName',
            name: 'Kênh',
            // minWidth: 100,
            // maxWidth: 150,
            isMultiline: true,
            isResizable: true,
        },
        {
            key: 'productId',
            fieldName: 'productName',
            name: 'Dịch vụ',
            // minWidth: 100,
            // maxWidth: 150,
            isMultiline: true,
            isResizable: true,
        },
        {
            key: '2',
            name: 'Bắt đầu',
            // minWidth: 100,
            // maxWidth: 150,
            isResizable: true,
            isMultiline: true,
            onRender: (item) => {
                return formatTimeDate(item.startDate);
            },
        },
        {
            key: '3',
            name: 'Kết thúc',
            fieldName: 'endDate',
            // minWidth: 100,
            // maxWidth: 150,
            isResizable: true,
            isMultiline: true,
            onRender: (item) => {
                return formatTimeDate(item.endDate);
            },
        },
        {
            key: 'invoiceCode',
            name: 'Mã hóa đơn',
            fieldName: 'invoiceCode',
            // minWidth: 120,
            // maxWidth: 120,
            isResizable: true,
            isMultiline: true,
        },
        {
            key: 'paymentDate',
            name: 'Ngày thanh toán',
            fieldName: 'paymentDate',
            minWidth: 110,
            // maxWidth: 170,
            isResizable: true,
            isMultiline: true,
            onRender: (item) => {
                return formatTimeDate(item.paymentDate);
            },
        },
    ];

    const renderHeading = (data) => {
        if (data) {
            return `Tin đang có dịch vụ  (${data?.total} +)`;
        } else {
            return 'Tin đang có dịch vụ ';
        }
    };
    return (
        <div
            style={{
                flex: 1,
                flexDirection: 'column',
                gap: 16,
                display: 'flex',
                marginTop: 16,
            }}
        >
            <div className={container}>
                <h4 className={heading}>{renderHeading()}</h4>

                {data && (
                    <CustomDetailView
                        loading={loading}
                        columns={columns}
                        gridData={{
                            error: error ? error?.message : '',
                            items: data ? configData(data.items) : [],
                        }}
                    />
                )}

                {error && <p style={{ textAlign: 'center', margin: '16px 0', fontSize: 14 }}>{error.message}</p>}

                {data && (
                    <MoreLink
                        moreClassName={{ marginTop: 16 }}
                        onClick={(event) => {
                            pushHistory(history, `${leadsApiEndpoint}/${id}/serviced-classifieds`, null, event.ctrlKey);
                        }}
                    />
                )}
            </div>
        </div>
    );
}
