import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor, borderGrayColor, lightGrayColor, textGrayColor } from '../../../styles/colors';

export const getClasses = () => {
    return mergeStyleSets({
        detailItemContainer: {},
        detailItemHeader: {
            padding: 16,
            backgroundColor: lightGrayColor,
            fontSize: 13,
            fontWeight: 500,
            display: 'flex',
            gap: 16,
        },
        detailTitle: { marginRight: 'auto' },
        editLink: {
            color: secondaryLightColor,
            cursor: 'pointer',
        },

        itemDetails: {
            display: 'grid',
            gridTemplateColumns: 'auto auto',
            gridGap: 16,
            padding: '16px 0',
        },
        singleItem: {
            borderBottom: `1px solid ${borderGrayColor}`,
            padding: 8,
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
        },
        singleItemKey: {
            color: textGrayColor,
            fontSize: 12,
        },
        singleItemValue: {
            fontSize: 14,
        },
    });
};

export const getDetailItemClasses = () => {
    return mergeStyleSets({
        blueText: {
            color: secondaryLightColor,
        },
    });
};
