import React from 'react';
import { Icon, Dropdown, DropdownMenuItemType } from '@fluentui/react';
import { CustomTextField } from '../../components/BasicInput/CustomInput';

export const CustomMultiDropdown = (props) => {
    const widthValue = props.width ? props.width : 300;
    const height = props.height ? props.height : 450;

    let styles = {};
    if (props.options.length > 10) {
        styles.dropdownItemHeader = {
            position: 'fixed',
            backgroundColor: 'white',
            'zIndex': 2,
            borderBottom: '1px solid #ddd',
            width: 'calc(100% - 8px)',
            top: 0
        };
        styles.dropdownItemsWrapper = { paddingTop: 36 };
    }

    let tfFilter = {};
    const [filter, SetFilter] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [value, setValue] = React.useState([]);


    React.useEffect(() => {
        if (props.value) {
            const valueArr = props.value.split(',');
            setValue(valueArr);
        }
        else {
            setValue([]);
        }
    }, [props.value]);


    React.useEffect(() => {
        if (props.options.length > 10) {
            setOptions([{
                key: 'filter',
                text: '',
                itemType: DropdownMenuItemType.Header
            }, ...props.options])
        }
        else {
            setOptions(props.options);
        }
    }, [props.options]);

    const onFilterChange = (text) => {
        if (props.options.length > 10) {
            SetFilter(text);
            if (text.length == 0) {
                setOptions([{
                    key: 'filter',
                    text: '',
                    itemType: DropdownMenuItemType.Header
                }, ...props.options]);
            }
            else {
                let tempOptions = props.options.filter(x => (x.key != 'filter' && x.text.toLowerCase().indexOf(text.toLowerCase()) != -1) && value.indexOf(x.key) == -1);

                const selectedItems = props.options.filter(x => value.indexOf(x.key) != -1);
                tempOptions = [...selectedItems, ...tempOptions];

                tempOptions.unshift({
                    key: 'filter',
                    text: '',
                    itemType: DropdownMenuItemType.Header
                });

                if (tempOptions.length == 1) {
                    tempOptions.push({
                        key: null,
                        text: 'Không tìm thấy kết quả',
                        itemType: DropdownMenuItemType.Normal,
                        disabled: true
                    });
                }

                setOptions(tempOptions);
            }
        }
    };


    const onRenderOption = (opt) => {
        if (opt.key == 'filter') {
            return <div style={{ display: 'inline-flex', lineHeight: '36px', width: `${widthValue - 8}px` }}>
                <Icon iconName="Search" />
                <CustomTextField
                    styles={{ fieldGroup: { marginBottom: 10 } }}
                    placeholder='...'
                    value={filter}
                    height={36}
                    width='calc(100% - 16px)'
                    borderless
                    componentRef={tfFilter}
                    onChange={(value) => onFilterChange(value)} />
            </div>
        } else {
            return <div>{opt.text}</div>
        }
    };

    const onRestoreFocus = () => {
        onFilterChange('');
    }

    const onMouse = () => {
        if (tfFilter && tfFilter.current) {
            tfFilter.current.focus();
        }
    }

    let calloutProps = { calloutMaxHeight: height };
    if (props.options.length > 10) {
        calloutProps.onRestoreFocus = onRestoreFocus;
        calloutProps.onMouseMove = onMouse;
        calloutProps.onMouseEnter = onMouse;
        calloutProps.onMouseOver = onMouse;
    }

    const multiSelectOnChange = (e, opt, index) => {
        let valueArr = [...value];

        if (opt.selected) {
            if (props.limit) {
                if (valueArr.length == props.limit) {
                    return false;
                }
            }

            valueArr.push(opt.key.toString());
        }
        else {
            if (props.required && valueArr.length == 1){
                return false
            }
            const index = valueArr.indexOf(opt.key.toString());
            if (index != -1) {
                valueArr.splice(index, 1);
            }
        }

        setValue(valueArr);
        if (props.onChange) {
            props.onChange(valueArr.join(','));
        }
    }

    const render = () => {
        return (
            <div style={{ width: widthValue }}>
                <Dropdown
                    styles={styles}
                    label={props.label}
                    calloutProps={calloutProps}
                    options={options}
                    disabled={props.disabled ? props.disabled : false}
                    selectedKeys={value}
                    onRenderOption={onRenderOption}
                    onChange={multiSelectOnChange}
                    multiSelect />
            </div >
        )
    }

    return render();
}