import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// ui components
import BasicInput from '../../components/BasicInput/BasicInput';
import DropDown from '../../components/DropDown/DropDown';
import SectionHeaderFlex from './../../components/SectionHeaderFlex/SectionHeaderFlex';
import { FuiPrimaryButton } from '../../components/Buttons/Buttons';

// utils
import { apiUrl, leadsApiEndpoint } from '../../utils/constants';
import { token } from './../../utils/token';

// hooks
import { useForm } from './../../hooks/useForm';
import { handleError } from './../../hooks/useFetch';

// styles
import {
    whiteColor,
    lightPrimaryColor,
    secondaryLightColor,
    darkGrayColor,
    buttonGrayColor,
} from './../../styles/colors';

import { getFormHeaderClasses, getFormClasses, getClasses } from './styles';
import { getAxios } from '../../utils/axios';

function FormHeader({ text }) {
    const { headerDeco, headerText } = getFormHeaderClasses();
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={headerDeco}></span>
            <h3 className={headerText}>{text}</h3>
        </div>
    );
}

// define initial form values
const FIELDS = {
    leadId: { value: '' },
    mobile: { value: '' },
    email: { value: '' },
    title: { value: '' },
    description: { value: '' },
    status: { key: '0', value: '' },
    sourceId: { key: '1', value: '' },
};

const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getSource = async () => {
    return getApi('/sources',{pageSize:1000000})
}


// define LeadEdit component
export default function LeadEdit() {
    const history = useHistory();
    const id = useParams()?.id;

    // styles
    const { wrapper, formGroup, form, formContainer, btnGroup } = getFormClasses();
    const { sectionContainer } = getClasses();

    //  states
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [info, setInfo] = React.useState('');
    const [error, setError] = React.useState(null);
    const [lead, setLead] = React.useState(useLocation().state || null);
    const [leadFormState, setLeadFormState] = React.useState(FIELDS);

    const [sources,setSources] = React.useState([])
    React.useEffect(()=>{
        getSource().then(res => {
            setSources(res.map(x => ({
                key: x.id.toString(),
                value: x.id,
                text: x.name,
                name: 'sourceId'
            })))
        }).catch(()=>{})
    },[])
    // useForm is called here
    const { fields, handleInputChange, handleFuiDropdownChange } = useForm(leadFormState);

    // get lead
    React.useEffect(() => {
        if (id) {
            fetch(`${apiUrl}${leadsApiEndpoint}/${id}`, {
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            })
                .then((res) => res.json())
                .then((data) => {
                    setLead(data);
                    setLeadFormState({
                        leadId: { value: id },
                        mobile: { value: data.mobile },
                        email: { value: data.email },
                        title: { value: data.title },
                        description: { value: data.description },
                        status: { key: data.status.toString(), value: data.status.toString() },
                        sourceId: { key: data.sourceId.toString(), value: data.sourceId.toString() },
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [id]);

    // bind data to edit
    React.useEffect(() => {
        if (lead) {
            fields.title.value = lead.title;
            fields.mobile.value = lead.mobile;
            fields.email.value = lead.email;
            fields.status.value = lead.status?.toString();
            fields.status.key = lead.status?.toString();
            fields.sourceId.value = lead.sourceId?.toString();
            fields.sourceId.key = lead.sourceId?.toString();
            fields.description.value = lead.description;
        }
    }, [lead]);

    // Perfom API call
    React.useEffect(() => {
        if (isUpdating) {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'PUT',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                body: JSON.stringify({
                    leadId: id,
                    mobile: fields.mobile.value || '',
                    email: fields.email.value || '',
                    title: fields.title.value || '',
                    description: fields.description.value || '',
                    // status: fields.status.value,
                    sourceId: fields.sourceId.value,
                }),
            };

            fetch(`${apiUrl}${leadsApiEndpoint}/${lead?.leadId}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data?.leadId) {
                        setInfo('Cập nhật thành công');
                        setLead(data);
                        setError(null);
                        history.push('/leads');
                    } else {
                        setError(handleError(data));
                        setInfo('');
                    }
                    setIsUpdating(false);
                })
                .catch((error) => {
                    setError(handleError(error));
                    setIsUpdating(false);
                    setInfo('');
                });
        }
    }, [isUpdating]);

    // Save
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsUpdating(true);
    };

    // go back
    const goBack = () => {
        history.goBack();
    };

    return (
        <div className={sectionContainer}>
            <SectionHeaderFlex
                boxCss={{ backgroundColor: lightPrimaryColor }}
                subText="Leads"
                mainText={lead?.title}
                sectionHeaderCss={{ backgroundColor: whiteColor }}
            />
            <div className={formContainer}>
                <form className={form}>
                    <div>
                        <FormHeader text="Thông tin Lead" />
                        <div className={`${wrapper} ${formGroup}`}>
                            <BasicInput
                                label="Họ & Tên"
                                labelCss={{ color: secondaryLightColor }}
                                onChange={handleInputChange}
                                value={fields.title.value}
                                name="title"
                            />
                            <BasicInput
                                label="Số điện thoại"
                                labelCss={{ color: secondaryLightColor }}
                                onChange={handleInputChange}
                                name="mobile"
                                value={fields.mobile.value}
                            />
                            <BasicInput
                                label="Email"
                                labelCss={{ color: secondaryLightColor }}
                                onChange={handleInputChange}
                                value={fields.email.value}
                                name="email"
                            />
                            {/* <DropDown
                                label="Trạng thái"
                                required={true}
                                onChange={handleFuiDropdownChange}
                                options={statuses}
                                selectedKey={fields.status.key}
                            /> */}
                        </div>
                    </div>
                    <div>
                        <FormHeader text="Thông tin bổ sung" />
                        <div className={`${wrapper} ${formGroup}`}>
                            <DropDown
                                label="Nguồn Lead"
                                onChange={handleFuiDropdownChange}
                                options={sources}
                                selectedKey={fields.sourceId.key}
                            />
                        </div>
                    </div>
                    <div>
                        <FormHeader text="Thông tin mô tả" />
                        <div className={`${wrapper} ${formGroup}`}>
                            <BasicInput
                                label="Mô tả"
                                labelCss={{ color: secondaryLightColor }}
                                rows="6"
                                name="description"
                                multiline
                                inputCss={{
                                    backgroundColor: whiteColor,
                                    minWidth: 652,
                                    padding: 4,
                                    height: 120,
                                }}
                                onChange={handleInputChange}
                                value={fields.description.value}
                            />
                        </div>
                    </div>

                    <div style={{ textAlign: 'center', fontSize: 14 }}>
                        {error && <p style={{ color: 'red' }}>{error?.message}</p>}
                        {info && <p>{info}</p>}
                    </div>

                    <div className={btnGroup}>
                        <FuiPrimaryButton
                            text="Hủy"
                            btnCss={{
                                backgroundColor: buttonGrayColor,
                                color: darkGrayColor,
                                marginRight: 16,
                                padding: '8px 12px',
                                border: 'none',
                            }}
                            onClick={goBack}
                        />
                        {/* <FuiPrimaryButton */}
                        {/*     text="Lưu & Tạo mới" */}
                        {/*     btnCss={{ */}
                        {/*         backgroundColor: buttonGrayColor, */}
                        {/*         color: darkGrayColor, */}
                        {/*         border: 'none', */}
                        {/*         marginRight: 8, */}
                        {/*         padding: '8px 12px', */}
                        {/*     }} */}
                        {/* /> */}
                        <FuiPrimaryButton text="Lưu" onClick={handleSubmit} disabled={isUpdating} />
                    </div>
                </form>
            </div>
        </div>
    );
}
