import React from 'react';
import { useHistory, useLocation, Switch, Route, useRouteMatch } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { getAxios,deleteAxios } from '../../utils/axios';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import FilterPanel from '../../components/FilterPanel/FilterPanel';
import FilterButton from '../../components/Buttons/FilterButon';
import { CustomDropdown } from '../../components/BasicInput/CustomInput';
import { TextField } from 'office-ui-fabric-react/lib/TextField';

import UpdateFunction from './UpdateFunction'
import AddFunction from './AddFunction'

export default function Function() {
    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <ListFunction />
            </Route>
            <Route path={`${path}/:functionId/edit`}>
                <UpdateFunction />
            </Route>
            <Route path={`${path}/add`}>
                <AddFunction />
            </Route>
        </Switch>
    </>)
}

// function UpdateFunction() {
//     const { functionId } = useParams()
//     return <span>Update functionId: {functionId}</span>
// }

function ListFunction() {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter
        ? { value: true, panelWidth: '300px', contentWidth: '320px' }
        : { value: false, panelWidth: '0px', contentWidth: '4px' });
    const [isActive, setIsActive] = React.useState(locParams ? locParams.isActive : '')

    const [actionObject, setActionObject] = React.useState({})

    const [functionModalDelete, setFunctionModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    //init
    React.useEffect(() => {
        const page = locParams ? locParams.page - 1 : 0;
        post(page, true);
    }, []);

    //function
    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    };

    const onAddClick = (event) => { 
        pushHistory(history, `${location.pathname}/add`, null, event);
    };

    const post = (page, firstLoad) => {

        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            isActive: isActive,
            toogleFilter: filter.value,
            page: page + 1,
            pageSize: pageSize,
        };

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios('user-group-functions', params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize !== 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const actionClick = (action, id, item, event) => {
        switch (action) {
            case "update":
                pushHistory(history, `${location.pathname}/${item.functionId}/edit`, null, event.ctrlKey);
                break
            case "delete":
                setActionObject(item)
                setFunctionModalDelete({value:'',isToggle:true})
                break
            default:
                throw "invalid operation";
        }
    }

    //options
    const actionItems = [
        {
            key: 'update',
            text: 'Chỉnh sửa',
            icon: 'Edit',
            onClick: actionClick,
        },
        {
            key: 'delete',
            text: 'Xóa',
            icon: 'Cancel',
            onClick: actionClick,
        }
    ];

    const featureOptions = [
        { key: '', text: 'Tất cả' },
        { key: 'true', text: 'Hoạt động' },
        { key: 'false', text: 'Không hoạt động' },
    ]
    const featureColumns = [
        {
            key: 'STT',
            name: 'STT',
            minWidth: 72,
            maxWidth: 72,
            isResizable: false,
            render: { type: 'index' }
        },
        {
            key: 'Code',
            name: 'Code',
            fieldName: 'code',
            minWidth: 240,
            isResizable: true,
        },
        {
            key: 'name',
            name: 'Mô tả',
            fieldName: 'name',
            minWidth: 400,
            isResizable: true,
        },
        {
            key: 'isActive',
            name: 'Active',
            fieldName: 'isActive',
            minWidth: 140,
            isResizable: true,
            render: { type: 'checkbox' }
        },
        {
            key: 'functionId',
            fieldName: 'functionId',
            minWidth: 100,
            render: { type: 'button', text: 'Chức năng', icon: '', items: actionItems },
        },
    ]

    const onFunctionDelete = () => {
        if (functionModalDelete.value.trim() !== actionObject.code) {
            ShowErrorModal('Mã Code không chính xác')
            return
        }
        deleteAxios('user-group-functions/'+actionObject.functionId,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setFunctionModalDelete)

                let nextPage = gridData.page
                if (gridData.items.length-1 === 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })
        
    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
            
            <CustomModal title='Xóa Role' isModalOpen={functionModalDelete.isToggle}
                hideModal={() => toogleModal(setFunctionModalDelete)} buttonAction="Xóa" width={640}
                onClick={onFunctionDelete} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setFunctionModalDelete({ ...functionModalDelete, value })}
                    value={functionModalDelete.value}
                    label={`Nhập Code: ${actionObject.code} để xóa`} />
            </CustomModal>

            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0)} >
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Active"
                        width={250}
                        dropdownWidth={250}
                        options={featureOptions}
                        value={isActive}
                        onChange={setIsActive}
                    />
                </div>
            </FilterPanel>
            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: "Chức năng" }}
                            width={"100%"}
                            addButton={{ text: "Tạo mới", onClick: onAddClick }} />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={featureColumns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div>
    );
}
