import { Button } from '@uifabric/experiments';
import { ChoiceGroup, FontIcon } from 'office-ui-fabric-react';
import React from 'react';
import ErrorModal from '../../../components/Modal/ErrorModal';
import CustomModal from '../../../components/Modal/Modal';
import { LeadContext } from '../../../context/lead';
import { buttonGrayColor, darkGrayColor, milkGreenColor, secondaryLightColor, tintColor, whiteColor } from '../../../styles/colors';
import { getAxios, putAxios } from '../../../utils/axios';
import { capitalizeFirstLetter } from '../../../utils/helpers';
import styles from './ProgressStatuses.module.css';

const ProgressStatuses = (props) => {
    //state
    const { lead, getLead } = React.useContext(LeadContext);

    const [progressingStatus, setProgressingStatus] = React.useState([]);
    const [reasonOption, setReasonOption] = React.useState([]);

    const [selectedStatus, setSelectedStatus] = React.useState('');
    const [reason, setReason] = React.useState('');
    const [currentStatus, setCurrentStatus] = React.useState(props.currentStatus)

    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const [toggledModal, setToggledModal] = React.useState(false);

    const [error, setError] = React.useState(null);
    const showErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);
    const closeErrorModal = React.useCallback(() => {
        setErrorModal({ value: '', isDisplay: false });
    }, []);


    //function
    const actionClick = (id) => {
        setSelectedStatus(id);
    }

    const failedStatusAction = () => {
        setToggledModal(false);
        putAxios(`/leads/${lead.leadId}/status/${selectedStatus}/reason/${reason}`, null, res => {
            if (res.status != 200) {
                setError(res.message);
                debugger
                showErrorModal(capitalizeFirstLetter(res.message?.toString()))
            }
            else {
                getLead(lead);
                setError(null);
                setCurrentStatus(selectedStatus)
                setSelectedStatus('');
                showErrorModal('Chuyển trạng thái lead thành công');
            }
        })
    }

    const confirmClick = () => {
        if (selectedStatus == currentStatus) {
            setReason('')
            showErrorModal('Cập nhật cùng một trạng thái! Vui lòng chọn lại trạng thái khác.')
        }
        else if (selectedStatus == '159') {
            setToggledModal(true)
        }
        else {
            putAxios(`/leads/${lead.leadId}/status/${selectedStatus}/reason/`, null, res => {
                if (res.status != 200) {
                    setError(res.message);
                    showErrorModal(capitalizeFirstLetter(res.message?.toString()))
                }
                else {
                    getLead(lead);
                    setError(null);
                    setCurrentStatus(selectedStatus)
                    setSelectedStatus('');
                    setReason('');
                    showErrorModal('Chuyển trạng thái lead thành công');
                }
            })
        }
    }
        //render thanh trạng thái
    const getProgress = (item) => {
        const itemIndex = progressingStatus.findIndex(x => x.key == item.key);
        const currentStatusIndex = currentStatus && progressingStatus.findIndex(x => x.key == currentStatus);
        const selectedStatusIndex = selectedStatus && progressingStatus.findIndex(x => x.key == selectedStatus);

        if (selectedStatus && selectedStatusIndex <= currentStatusIndex) {
            if (item.key == selectedStatus)
                return { style: { color: whiteColor, backgroundColor: secondaryLightColor }, icon: '', isRenderedName: true }
            else if (itemIndex == currentStatusIndex)
                return { style: { color: whiteColor, backgroundColor: tintColor }, icon: '', isRenderedName: true }
            if (itemIndex < selectedStatusIndex)
                return { style: { color: '', backgroundColor: milkGreenColor }, icon: <FontIcon iconName='SkypeCircleCheck' className={styles.progressIcon}></FontIcon>, isRenderedName: false }
            return { style: { color: darkGrayColor, backgroundColor: buttonGrayColor }, icon: '', isRenderedName: true }
        }

        else {
            if (currentStatus && itemIndex <= currentStatusIndex) {
                if (itemIndex == currentStatusIndex)
                    return { style: { color: whiteColor, backgroundColor: tintColor }, icon: '', isRenderedName: true }
                else return { style: { color: '', backgroundColor: milkGreenColor }, icon: <FontIcon iconName='SkypeCircleCheck' className={styles.progressIcon}></FontIcon>, isRenderedName: false }
            }
            else if (selectedStatus && itemIndex == selectedStatusIndex)
                return { style: { color: whiteColor, backgroundColor: secondaryLightColor }, icon: '', isRenderedName: true }
            else if (selectedStatus && currentStatus && itemIndex > currentStatusIndex && itemIndex <= selectedStatusIndex) {
                return { style: { color: secondaryLightColor, backgroundColor: whiteColor, border: `1px ${secondaryLightColor} solid` }, icon: '', isRenderedName: true }
            }
            else return { style: { color: darkGrayColor, backgroundColor: buttonGrayColor }, icon: '', isRenderedName: true }
        }
    }

    //effect
    React.useEffect(() => {
        getAxios('/lead-status', null, res => {
            if (res && res.data) {
                const _temp = res.data.map(item => ({ key: '' + item.id, id: item.id, name: item.name, code: item.code }))
                const convertedStatus = _temp.find(item => item.id == 153 || item.code == 'lead_qualified');
                _temp.splice(_temp.findIndex(item => item.id == convertedStatus.id), 1);
                _temp.push(convertedStatus);
                setProgressingStatus(_temp)
            }
        })

        getAxios('/lead-reasons', null, res => {
            if (res && res.data) {
                const _temp = res.data.map(item => ({ key: '' + item.id, value: item.id, text: item.name, name: 'reasonId' }))
                setReasonOption(_temp)
            }
        })
    }, [])


    //render
    const render = () => {
        return <>
            <div className={styles.progressBar}>
                {progressingStatus && progressingStatus.map(item => <Button
                    icon={getProgress(item).icon}
                    key={item.key}
                    style={{ ...getProgress(item).style }}
                    className={styles.statusButton}
                    onClick={() => actionClick(item.key)}
                    disabled={(currentStatus == '153')}
                >
                    {getProgress(item).isRenderedName && item.name}

                </Button>)}
                <Button
                    style={{ marginLeft: 50, width: 350, color: whiteColor, borderRadius: 8, backgroundColor: secondaryLightColor }}
                    onClick={(e) => confirmClick(e)}
                    disabled={(currentStatus == '153' || selectedStatus == '')}
                >
                    Xác nhận
                </Button>
            </div>
            <CustomModal
                title="Lý do thất bại"
                isModalOpen={toggledModal}
                buttonAction="Lưu"
                width={640}
                onClick={failedStatusAction}
                hideModal={() => {
                    setToggledModal(false);
                }}
            >
                <ChoiceGroup
                    options={reasonOption}
                    onChange={(_e, item) => setReason(item.value)}
                >

                </ChoiceGroup>
            </CustomModal>
            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={() => {
                    closeErrorModal();
                }}
            />
        </>
    }

    return render();
}

export default ProgressStatuses;