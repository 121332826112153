import React from 'react';
import CustomModal from '../../../components/Modal/Modal';
import { CustomTextField } from '../../../components/BasicInput/CustomInput';

export default function AddModal(props){
    const [data,setData] = React.useState(props.data)
    const [error,setError] = React.useState({})
    
    const hideModal = () => {
        props.hideModal(false)  
    }

    const onClick = ()=>{
        const errorTmp = {}
        if (!data.title){
            errorTmp.title = "Chủ đề không được bỏ trống"
        }
        if (!data.description){
            errorTmp.description = "Nội dung không được bỏ trống"
        }
        if (Object.keys(errorTmp).length > 0){
            return setError(errorTmp)
        }
        return props.onSubmit(data)
    }
    const onChange = (field) => {
        return value=>setData({...data,[field]:value})
    }

    React.useEffect(()=>{
        if (props.isShow){
            setData({})
            setError({})
        }
    },[props.isShow])

    return <>
        <CustomModal title='Thêm mới ghi chú' isModalOpen={props.isShow}
            hideModal={hideModal} buttonAction="Lưu" width={640}
            onClick={onClick} >
            <CustomTextField label="Chủ đề" width="100%"
                value={data.title||''} onChange={onChange('title')}
                errorMessage={error.title}
            />
            <CustomTextField label="Nội dung" width="100%" rows={5}
                value={data.description||''} onChange={onChange('description')}
                errorMessage={error.description}
            />
        </CustomModal>
    </>
}