import { mergeStyleSets } from '@fluentui/react';

import {
    whiteColor,
    secondaryLightColor,
    borderLightGrayColor,
    textGrayColor,
    lightGrayColor,
} from '../../styles/colors';

export const getClasses = ({ fileListClassName, fileItemClassName, headingClassName }) => {
    return mergeStyleSets({
        container: {
            borderRadius: 15,
            backgroundColor: whiteColor,
            boxShadow: '0px 1px 2px rgba(28, 30, 33, 0.2)',
            border: `1px solid ${borderLightGrayColor}`,
            overflow: 'hidden',
        },
        heading: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: 16,
            alignItems: 'center',
            backgroundColor: lightGrayColor,
            borderBottom: `1px solid ${borderLightGrayColor}`,
        },
        headingOptional: headingClassName,
        fileList: {
            display: 'flex',
            flexDirection: 'column',
            padding: 16,
            flexWrap: 'wrap',
        },
        fileListOptional: fileListClassName,
        fileItem: {
            display: 'flex',
            gap: 8,
            padding: '8px 0',
            minWidth: 130,
            borderBottom: `1px solid ${borderLightGrayColor}`,
            flex: 1
        },
        fileItemOptional: fileItemClassName,
        fileInfo: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        fileName: {
            color: secondaryLightColor,
            fontSize: 13,
        },
        fileValues: {
            fontSize: 12,
            color: textGrayColor,
        },
        imgContainer: {
            width: 32,
            height: 32,
        },
        img: {
            width: '100%',
        },
    });
};
