import React from 'react';
import { whiteColor } from './../../styles/colors';
import styles from './SectionHeader.module.css';
import AddButton from '../Buttons/Buttons';
import SearchInput from '../SearchInput/SearchInput';
import DropDownPanelCategory from '../DropDown/CustomDropdown';
import { PrimaryButton } from 'office-ui-fabric-react';

const sectionHeader = {
    padding: 17,
    backgroundColor: whiteColor,
    display: 'flex',
    position: 'relative',
    borderRadius: 15,
};

const SectionHeader = (props) => {
    const renderTitle = (props) => {
        return (<h2 className={styles.text}>{props.text}</h2>)
    }

    const renderDropdown = (props) => {
        return (
            <DropDownPanelCategory
                disabled={props.disabled}
                options={props.options}
                value={props.value}
                width={props.width ? props.width : 140}
                onChange={(key) => props.onChange(key)}
            />
        )
    };

    const renderAddButton = (props) => {
        return (
            <div style={{ marginRight: 'auto', marginLeft: '8px' }}>
                <AddButton
                    style={{ width: props?.width||125 }}
                    text={props.text}
                    onClick={(event) => props.onClick(event)} />
            </div>);
    }

    const renderExcelButton = () => {
        return (
            <div style={{ marginRight: 'auto', marginLeft: '8px' }}>
                <PrimaryButton
                    text="Excel"
                    onClick={props.onExportClick} />
            </div>)
    }

    const renderSearch = (props) => {
        const style = { alignSelf: 'center', width: props.width ? props.width : 400, color: props.color ? props.color : "unset" };
        return (

            <SearchInput
                style={style}
                value={props.value}
                onChange={props.onChange ? (e) => props.onChange(e.target.value) : null}
                placeholder={props.placeholder ? props.placeholder : "Tìm kiếm..."}
            />);
    }

    const render = () => {
        return (
            <div style={{ width: props.width ? props.width : "490px", height: props.height ? props.height : "auto" }}>
                <header style={{ ...sectionHeader, ...props.sectionHeaderCss }}>
                    <div className={styles.textContainer}>
                        {props.title && renderTitle(props.title)}
                    </div>
                    {props.dropdown && renderDropdown(props.dropdown)}
                    {props.addButton && renderAddButton(props.addButton)}
                    {props.onExportClick && renderExcelButton()}
                    {props.searchInput && renderSearch(props.searchInput)}
                </header >
            </div>)
    }

    return render();
}

export default SectionHeader;
