import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { getAxios,deleteAxios, putAxios } from '../../utils/axios';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { CustomDropdown } from '../../components/BasicInput/CustomInput';
import { isAgentOptions } from '../../utils/constants';
import StaffFilter2 from '../../components/StaffFilter/StaffFilter2';
import CustomPeoplePicker from '../../components/CustomPeoplePicker/CustomPeoplePicker';

let userList=[],roleList=[],nvcsList=[]
const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getNvcsList = async (permisson,id) => {
    return getApi('/users/compact',{pageSize:10000000}).then((res)=>{
        if (res)
            return res
        throw false
    })
}
const getUserList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/users',{pageSize:10000000}).then((res)=>{
            if (res)
                return res
            throw false
        })
    }
    return getApi(`/users/${id}/children`)
}
const getRoleList = async (permisson,id) => {
    if (permisson.has('all')){
        return getApi('/user-roles/',{pageSize:10000000}).then((res)=>{
            if (res)
                return res.items
            throw false
        })
    }
    return getApi(`/user-roles/${id}/children`)
}

const parseFunctionCode = (data) => {
    const fncCode = data.split(',')
    const result = new Set(["me"])
    if (fncCode.includes('Accounts_Full') || fncCode.includes('Accounts_Read_All')){
        result.add("all")
        result.add("group")
        result.add("other")
        result.add("orphan")
    }
    if (fncCode.includes('Accounts_Read_Family')){
        result.add("group")
        result.add("other")
        result.add("mychild")
    }
    return result
}

const TimeFilterOption = [
    {
        key: 'assignDate',
        text: 'Ngày chăm sóc',
    },
    {
        key: 'createDate',
        text: 'Ngày tạo',
    },
    {
        key: 'updateDate',
        text: 'Ngày cập nhật',
    },
]


const Account = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');
    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });
    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );

    const [dateType, setDateType] = React.useState(locParams ? locParams.dateType : "assignDate");
    
    const [isAgent,setIsAgent] =  React.useState(locParams ? locParams.isAgent : '');

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0px', contentWidth: '4px' });

    const permisson = React.useMemo(()=>parseFunctionCode(localStorage.getItem('functionCodes')),[])
    const [userId, setUserId] = React.useState(locParams?.userId);
    const [roleNode, setRoleNode] = React.useState(locParams?.roleNode);
    const [staffFilterType,setStaffFilterType] = React.useState(locParams?.staffFilterType);
    const [refresh,setRefresh] = React.useState(0)

    const [actionObject, setActionObject] = React.useState({})

    const [accountModalDelete, setAccountModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })

    const [accountModalChangeOwn, setAccountModalChangeOwn] = React.useState({
        isToggle: false,
        value: null,
    })

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    //init
    React.useEffect(() => {
        getNvcsList()
        .then(res=>{
            const tmp = res || []
            nvcsList = tmp.map(x=>({...x,key:''+x.userId,text: x.userName}))
        }).catch(console.log)

        getUserList(permisson,localStorage.getItem('userId'))
        .then(res=>{
            const tmp = res.items || []
            userList = tmp.map(x=>({key:''+x.userId,text: x.userName}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
        getRoleList(permisson,localStorage.getItem('roleId')).then(res=>{
            const tmp = res || []
            roleList = tmp.map(x=>({key:x.roleNode,text: x.name}))
        }).catch(console.log).finally(()=>setRefresh(a=>a+1))
    }, []);

    React.useEffect(() => {
        if (isFirstLoad){
            if (locParams) {
                const page = locParams.page - 1;
                post(page, true);
            } else {
                post(0, true);
            }
        }
    }, [isFirstLoad]);

    //function

    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            staffFilterType,
            isAgent,
            dateType,
            keyword: keyword?.trim(),
            page: page + 1,
            pageSize: pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            toogleFilter: filter.value
        };

        let url

        if (staffFilterType=="all"){
            url = '/accounts'
        } else if (staffFilterType=="mychild"){
            url = `/accounts/managed-by/${localStorage.getItem('userId')}/included-children`
        } else if (staffFilterType=="group"){
            url = `/accounts/role-node/${encodeURIComponent(roleNode)}`
            params.roleNode = roleNode
        } else if (staffFilterType=="other"){
            url = `/accounts/managed-by/${userId}`
            params.userId = userId
        } else if (staffFilterType=="orphan"){
            url = '/accounts/managed-by/0'
        } else {
            url = `/accounts/managed-by/${localStorage.getItem('userId')}`
        }

        if (!firstLoad) {
            pushHistory(history, '/accounts', params);
        }

        getAxios(url, params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize != 0)
                        pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const actionClick = (action, value, item, event) => {
        if (action == "update") {
            pushHistory(history, `/accounts/${value}/edit`, null, event.ctrlKey);
        }
        else if (action == "link") {
            pushHistory(history, `/accounts/${item.accountId}/extra/relating-to`, null, event.ctrlKey);
        }
        else if (action === 'change') {
            setActionObject(item)
            let value = nvcsList.find(x=>x.key==item.assignedToId)
            if (value)
                value=[value]

            setAccountModalChangeOwn({value,isToggle:true})
        }
        else if (action == "delete") {
            setActionObject(item)
            setAccountModalDelete({value:'',isToggle:true})
        }
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    //options
    const actionItems = [
        {
            key: 'update',
            icon: 'Edit',
            text: 'Chỉnh sửa',
            onClick: actionClick
        },
        {
            key: 'delete',
            icon: 'Cancel',
            text: 'Xóa',
            onClick: actionClick
        },
        {
            key: 'change',
            icon: 'EditContact',
            text: 'Đổi chủ sở hữu',
            onClick: actionClick
        }
    ];

    const columns = [
        { name: '#', key: 'title', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
        { name: 'Mã KH', key: 'customerId', fieldName: 'customerId', minWidth: 100, maxWidth: 100, isResizable: true },
        { name: 'Tên KH', key: 'name', fieldName: 'name', minWidth: 250, maxWidth: 250, isResizable: true, render: { type: 'link', key: 'link', onClick: actionClick } },
        { name: 'Điện thoại', key: 'mobile', fieldName: 'mobile', minWidth: 120, maxWidth: 120, isResizable: true, render: { type: 'mobile' } },
        { name: 'Email', key: 'email', fieldName: 'email', minWidth: 250, maxWidth: 250, isResizable: true },
        { name: 'NVCS', key: 'assignedToName', fieldName: 'assignedToName', minWidth: 150, maxWidth: 150, isResizable: true},
        { name: 'Ngày chăm sóc', key: 'assignedDate', fieldName: 'assignedDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        { name: 'Ngày tạo', key: 'createDate', fieldName: 'createDate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        { name: 'Ngày cập nhật', key: 'latestUpdate', fieldName: 'latestUpdate', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'datetime' } },
        { key: 'accountId', fieldName: 'accountId', minWidth: 50, maxWidth: 50, render: { type: 'button', text: 'Chức năng', items: actionItems } },
    ];

    const onAccountDelete = () => {
        if (accountModalDelete.value?.trim() != actionObject.mobile) {
            ShowErrorModal('Số điện thoại không chính xác')
            return
        }
        deleteAxios('accounts/'+actionObject.accountId,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setAccountModalDelete)

                let nextPage = gridData.page
                if (gridData.items.length-1 == 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })
        
    }

    const onAccountChangeOwn = () => {
        if (!accountModalChangeOwn.value?.length) {
            ShowErrorModal('Không tìm thấy nhân viên chăm sóc')
            return
        }
        const newId = accountModalChangeOwn.value[0].userId
        putAxios(`/accounts/${actionObject.accountId}/assigned-to/${newId}`,null,(response)=>{
            if (response){
                if (response.message)
                    return ShowErrorModal(response.message)

                toogleModal(setAccountModalChangeOwn)

                post(gridData.page)
            }
        })
        
    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>

            <CustomModal title='Xóa Khách hàng' isModalOpen={accountModalDelete.isToggle}
                hideModal={() => toogleModal(setAccountModalDelete)} buttonAction="Xóa" width={640}
                onClick={onAccountDelete} >
                <TextField styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }} onChange={(e, value) => setAccountModalDelete({ ...accountModalDelete, value })}
                    value={accountModalDelete.value}
                    label={`Nhập SDT của Khách hàng để xóa: ${actionObject.mobile}`} />
            </CustomModal>

            <CustomModal title='Đổi chủ sở hữu' isModalOpen={accountModalChangeOwn.isToggle}
                hideModal={() => toogleModal(setAccountModalChangeOwn)} buttonAction="Lưu" width={640}
                onClick={onAccountChangeOwn} >
                
                <CustomPeoplePicker
                    nvcs={nvcsList}
                    selectNVCS={()=>{}}
                    onPeoplePickerChange ={()=>{}}
                    defaultSelectedItems={accountModalChangeOwn.value}
                    onChange={value=>setAccountModalChangeOwn({...accountModalChangeOwn,value})}
                />

            </CustomModal>

            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>
                {refresh == 2 &&<>
                    <StaffFilter2 permisson={permisson} userList={userList} roleList={roleList} 
                        staffFilterType={staffFilterType} userId={userId} roleNode={roleNode} onLoaded={setIsFirstLoad}
                        setStaffFilterType={setStaffFilterType} setUserId={setUserId} setRoleNode={setRoleNode}
                    />
                </>}
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Loại tài khoản"
                        width={250}
                        height={350}
                        options={isAgentOptions}
                        value={isAgent}
                        onChange={setIsAgent}
                    />
                </div>
                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kiểu ngày"
                        width={250}
                        dropdownWidth={250}
                        options={TimeFilterOption}
                        value={dateType}
                        onChange={setDateType}
                    />
                </div>
                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            width={"100%"}
                            title={{ text: "Khách hàng" }}
                            // addButton={{ text: "Tạo mới", onClick: onAddClick }} 
                        />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>
        </div >
    );
}
export default Account;
