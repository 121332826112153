import { mergeStyleSets } from '@fluentui/react';

import { whiteColor, secondaryLightColor, darkGrayColor } from './../../styles/colors';
import { borderRadius15px } from './../../styles/constants';

export const getClasses = () => {
    return mergeStyleSets({
        sectionContainer: {
            display: 'flex',
            flexDirection: 'column',
            borderRadius: borderRadius15px,
            paddingBottomd: '1rem',
            height: '100%',
            gap: 16,
        },
    });
};

export const getFormClasses = () =>
    mergeStyleSets({
        wrapper: {
            padding: '0 1rem',
        },
        formGroup: {
            display: 'flex',
            gap: 16,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            gap: 32,
        },
        formContainer: {
            backgroundColor: whiteColor,
            padding: '16px 0 16px 0',
            borderRadius: borderRadius15px,
            marginBottom: 16,
        },
        btnGroup: {
            alignSelf: 'flex-end',
            display: 'flex',
        },
    });

export const getFormHeaderClasses = () => {
    return mergeStyleSets({
        headerDeco: {
            display: 'inline-block',
            width: 3,
            height: 16,
            backgroundColor: secondaryLightColor,
            marginRight: 13,
        },

        headerText: {
            textTransform: 'uppercase',
            color: darkGrayColor,
            fontWeight: '500',
            fontSize: 15,
            lineHeight: '150%',
        },
    });
};
