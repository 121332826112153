import {
    useHistory
} from "react-router-dom";
import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';

import { Checkbox } from 'office-ui-fabric-react/lib/Checkbox';

import { CustomButton,CustomTextField } from "../../components/BasicInput/CustomInput";
import {  postAxios } from "../../utils/axios";
import { validateEmail, validateMobile } from "../../utils/helpers";

import ErrorModal from '../../components/Modal/ErrorModal';

import styles from './UpdateAccount.module.css'

const initalData = {isCompany:false,isAgent:false}

const onCreateAccount = async(data) => {
    return new Promise((res,rej) => {
        const param = {
            name: data.name.trim(),
            mobile: validateMobile(data.mobile),
            email: data.email?.trim(),
            accountTypeId: 0,
            isCompany: data.isCompany,
            isAgent: data.isAgent,
            description: data.description
        }
        postAxios('accounts',param,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(name,mobile,email)=>{
    const errorValidate = {}

    if (!name){
        errorValidate.name = 'Họ và tên không được bỏ trống'
    }
    if (!mobile){
        errorValidate.mobile = "Số điện thoại không được bỏ trống"
    }
    else if (!validateMobile(mobile)){
        errorValidate.mobile = "Số điện thoại không hợp lệ"
    }
    if (email && email.trim().length > 0  && validateEmail(email.trim()) === false){
        errorValidate.email = "Email không hợp lệ"
    }
    
    return errorValidate
}

export default function AddRole() {
    const history = useHistory()
    
    const [data,setData] = React.useState(initalData)

    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const OnBeforeCreate = async () => {
        const errorValidate = validate(data.name,data.mobile,data.email)
        if (Object.keys(errorValidate).length == 0){
            setErrorInput({})
            return new Promise((res,rej)=>onCreateAccount(data).then(res).catch(err=>{
                ShowErrorModal(err)
                rej(err)
            }))
        }
        setErrorInput(errorValidate)
        return Promise.reject(errorValidate)
    }

    const onAdd = () => {
        OnBeforeCreate().then(()=>history.goBack()).catch(()=>{})
    }
    
    const onAddMore = (callback) => {
        OnBeforeCreate().then(()=>{
            setData(initalData)
        }).catch(()=>{})
    }

    return (
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Thêm mới Khách hàng</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin khách hàng</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Họ & Tên</label>
                        <CustomTextField width={318} errorMessage={errorInput.name}
                            value={data.name||''} onChange={v=>setData(prev=>({...prev,name:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Số điện thoại</label>
                        <CustomTextField width={318} errorMessage={errorInput.mobile}
                            value={data.mobile||''} onChange={v=>setData(prev=>({...prev,mobile:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Email</label>
                        <CustomTextField width={318} errorMessage={errorInput.email}
                            value={data.email||''} onChange={v=>setData(prev=>({...prev,email:v}))}/>
                    </div>
                </div>

                <span className={styles.box__label}>Thông tin bổ sung</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <Checkbox styles={{root:{marginRight:36}}} label="Đại lý/CTV" 
                            checked={data.isAgent} onChange={(e,v)=>setData(prev=>({...prev,isAgent:v}))}
                        />
                    </div>

                    <div className={styles.item}>
                        <Checkbox label="Công ty" 
                            checked={data.isCompany} onChange={(e,v)=>setData(prev=>({...prev,isCompany:v}))}
                        />
                    </div>

                </div>

                <span className={styles.box__label}>Thông tin mô tả</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Mô tả</label>
                        <CustomTextField resizable={true} width={652} rows={7} 
                            value={data.description||''}
                            onChange={v=>setData(prev=>({...prev,description:v}))} />
                    </div>
                </div>
                
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    <DefaultButton style={{marginRight: 16}} text="Lưu & Thêm mới" onClick={onAddMore} />
                    <CustomButton label="Lưu" onClick={onAdd} />
                </div>
            </div>
        </div>
    );
}   