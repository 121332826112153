import {
    useHistory
} from "react-router-dom";
import React from 'react';
import { DefaultButton,Spinner,SpinnerSize } from 'office-ui-fabric-react';
import { CustomButton,CustomDropdown,CustomTextField } from "../../components/BasicInput/CustomInput";
import {  getAxios, postAxios } from "../../utils/axios";
import { validateEmail, validateMobile } from "../../utils/helpers";
import ErrorModal from '../../components/Modal/ErrorModal';
import styles from './styles.module.css'

const getApi = async (url,param) => {
    return new Promise((res,rej)=>{
        getAxios(url,param,(response)=>{
            if (response){
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getSource = async () => {
    return getApi('/sources',{pageSize:1000000})
}
const getStatus = async () => {
    return getApi('/lead-status',{pageSize:1000000}).then(res=>{
        activityTypeId = res.find(x=>x.code=='lead_new').id
        return true
    }).catch(()=>{activityTypeId=151})
}
let activityTypeId
let sourceList

const onCreateLead = async(data) => {
    return new Promise((res,rej) => {
        const param = {
            title: data.title.trim(),
            mobile: validateMobile(data.mobile),
            email: data.email?.trim(),
            description: data.description,
            sourceId: data.sourceId,
            status: activityTypeId
        }
        postAxios('leads/me',param,response=>{
            if (response.message) {
                rej(response.message)
                return
            }
            res(response.data)
        })
    })
}

const validate=(title,mobile,email,sourceId,status)=>{
    const errorValidate = {}

    if (!title){
        errorValidate.title = 'Họ và tên không được bỏ trống'
    }
    if (!mobile){
        errorValidate.mobile = "Số điện thoại không được bỏ trống"
    }
    else if (!validateMobile(mobile)){
        errorValidate.mobile = "Số điện thoại không hợp lệ"
    }
    if (email && email.trim().length > 0  && validateEmail(email.trim()) === false){
        errorValidate.email = "Email không hợp lệ"
    }
    if (!sourceId?.toString()) {
        errorValidate.sourceId = "Nguồn lead không được bỏ trống"
    }

    return errorValidate
}

const AddLead = React.memo(() => {
    const history = useHistory()
    
    const [data,setData] = React.useState({})

    const [loading,setLoading] = React.useState(true)
    const [errorInput,setErrorInput] = React.useState({})
    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    const OnBeforeCreate = async () => {
        const errorValidate = validate(data.title,data.mobile,data.email,data.sourceId)
        if (Object.keys(errorValidate).length == 0){
            setErrorInput({})
            return new Promise((res,rej)=>onCreateLead(data).then(res).catch(err=>{
                ShowErrorModal(err)
                rej(err)
            }))
        }
        setErrorInput(errorValidate)
        return Promise.reject(errorValidate)
    }

    const onAdd = () => {
        OnBeforeCreate().then(()=>history.goBack()).catch(()=>{})
    }
    
    const onAddMore = () => {
        OnBeforeCreate().then(()=>{
            setData({})
        }).catch(()=>{})
    }

    React.useEffect(()=>{
        // Promise.all([getSource(),getStatus()]).then(([res1,res2])=>{
        Promise.all([getSource()]).then(([res1])=>{
            sourceList= res1.map(x=>({key:x.id,text:x.name}))
            // res2.forEach(x=>x.key=x.id)
            // statusList= res2.map(x=>({key:x.id,text:x.name}))
            getStatus()
            setLoading(false)  
        }).catch(ShowErrorModal)
    },[])

    return  loading ? <Spinner size={SpinnerSize.large} />
        :
        <div style={{width: '100%', height: '100%', overflow:'auto' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <div className={styles.box}>
                <div className={styles.box__icon}></div>
                <span className={styles.box__text}>Thêm mới Lead</span>
            </div>

            <div className={styles.box}>
                <span className={styles.box__label}>Thông tin Lead</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Họ & Tên</label>
                        <CustomTextField width={318} errorMessage={errorInput.title}
                            value={data.title||''} onChange={v=>setData(prev=>({...prev,title:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Số điện thoại</label>
                        <CustomTextField width={318} errorMessage={errorInput.mobile}
                            value={data.mobile||''} onChange={v=>setData(prev=>({...prev,mobile:v}))}/>
                    </div>

                    <div className={styles.item}>
                        <label className={styles.item__label}>Email</label>
                        <CustomTextField width={318} errorMessage={errorInput.email}
                            value={data.email||''} onChange={v=>setData(prev=>({...prev,email:v}))}/>
                    </div>

                    
                </div>

                <span className={styles.box__label}>Thông tin bổ sung</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Nguồn lead</label>
                        <CustomDropdown width={318} errorMessage={errorInput.sourceId}
                        value={data.sourceId||''} onChange={v=>setData(prev=>({...prev,sourceId:v}))}
                            options={sourceList} />
                    </div>
                </div>

                <span className={styles.box__label}>Thông tin mô tả</span>
                <div className={styles.box__component}>
                    <div className={styles.item}>
                        <label className={styles.item__label}>Mô tả</label>
                        <CustomTextField resizable={true} width={652} rows={7} 
                            value={data.description||''}
                            onChange={v=>setData(prev=>({...prev,description:v}))} />
                    </div>
                </div>
                
                <div className={styles.box__component +' '+ styles['box__component--right']}>
                    <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={()=>history.goBack()} />
                    <DefaultButton style={{marginRight: 16}} text="Lưu & Thêm mới" onClick={onAddMore} />
                    <CustomButton label="Lưu" onClick={onAdd} />
                </div>
            </div>
        </div>  
})

export default AddLead