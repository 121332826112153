import React from 'react';
import { Link } from 'react-router-dom';
import { secondaryLightColor } from '../../styles/colors';

import { ChevronRightMedIcon } from '../Icons/Icons';

import { getClasses } from './styles';

// SectionHeaderBox Component for decoration
const SectionHeaderBox = ({ boxStyles, optionalBox }) => {
    return <div className={`${boxStyles} ${optionalBox}`}></div>;
};

// includes subHeader (subText) and mainHeader (mainText)
const SectionHeaderCombo = ({ subText, mainText, subtextSectionHeader, mainTextStyles, subTextLinkHeader }) => {
    const renderSubTextLink = (subText) => {
        const links = subText.map((subTextLink, index) => {
            if (index !== subText.length - 1) {
                return (
                    <div key={subTextLink.key} style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <Link className={`${subTextLinkHeader}`} to={`${subTextLink.link}`}>
                            {subTextLink.text}
                        </Link>
                        <ChevronRightMedIcon
                            css={{
                                fontSize: 10,
                                width: 10,
                                height: 10,
                                color: `${secondaryLightColor}`,
                                marginRight: 8,
                            }}
                        />
                    </div>
                );
            } else {
                return (
                    <Link key={subTextLink.key} className={`${subTextLinkHeader}`} to={`${subTextLink.link}`}>
                        {subTextLink.text}
                    </Link>
                );
            }
        });
        return links;
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'start',
                justifyContent: 'space-between',
                height: 36,
            }}
        >
            {' '}
            {Array.isArray(subText) ? (
                <>
                    <div style={{ display: 'flex', margin: 0 }}>{renderSubTextLink(subText)}</div>
                    <span className={mainTextStyles}>{mainText}</span>
                </>
            ) : (
                <>
                    <span className={`${subtextSectionHeader}`}>{subText}</span>
                    <span className={mainTextStyles}>{mainText}</span>
                </>
            )}
        </div>
    );
};

export default function SectionHeaderFlex({ text, children, sectionHeaderCss, boxCss, subText, mainText }) {
    const {
        textContainer,
        sectionHeader,
        optionalSectionHeader,
        boxStyles,
        optionalBox,
        subtextSectionHeader,
        subTextLinkHeader,
        mainTextStyles,
    } = getClasses({ sectionHeaderCss, boxCss });

    return (
        <header className={`${sectionHeader} ${optionalSectionHeader}`}>
            <div className={textContainer}>
                <SectionHeaderBox boxStyles={boxStyles} optionalBox={optionalBox} />
                {text ? (
                    <h2>{text}</h2>
                ) : (
                    <SectionHeaderCombo
                        subtextSectionHeader={subtextSectionHeader}
                        subTextLinkHeader={subTextLinkHeader}
                        mainTextStyles={mainTextStyles}
                        subText={subText}
                        mainText={mainText}
                    />
                )}
            </div>
            {children}
        </header>
    );
}
