import { getDetailItemClasses } from './styles';

import { CheckboxIcon, CheckboxCompositeIcon } from '../../../components/Icons/Icons';

// utils
import { formatTimeDate } from '../../../utils/date';
import NVMBNLink from '../../../components/NVMBNLink/NVMBNLink';
import { ItemScheduledSignal } from '@uifabric/experiments';
const { blueText } = getDetailItemClasses();

const styleBlueText = (text) => {
    return <span className={blueText}>{text}</span>;
};

export const getAccountInfo = (account) => {
    const { accountId, mobile, email, name, customerId, isCompany } = account;

    let info = {
        key: accountId,
        title: 'Thông tin khách hàng',
        values: [
            {
                key: 'Tên khách hàng',
                value: name,
            },
            {
                key: 'Điện thoại',
                value: styleBlueText(mobile),
            },
            {
                key: 'Email',
                value: styleBlueText(email),
            },
            {
                key: 'Mã Khách hàng',
                value: customerId,
            },
            {
                key: 'Loại Khách hàng',
                value: isCompany ? 'Công ty' : 'Cá nhân',
            },
        ],
    };

    return info;
};

export const getAccountInfoDescription = (account) => {
    const { description } = account;
    return {
        title: 'Thông tin Mô tả',
        values: [
            {
                key: 'Mô tả',
                value: description,
            },
        ],
    };
};

export const getAccountPlusInfo = (account) => {
    const { accountTypeId, isAgent, accountId, parentId } = account;
    return {
        title: 'Thông tin thêm',
        values: [
            {
                key: 'Loại Account',
                value: accountTypeId,
            },
            {
                key: 'Đại lý/CTV',
                value: isAgent ? <CheckboxCompositeIcon /> : <CheckboxIcon />,
            },
            {
                key: 'AccountId',
                value: accountId,
            },
            {
                key: 'Cấp cha',
                value: parentId > 0 ? <CheckboxCompositeIcon /> : <CheckboxIcon />,
            },
        ],
    };
};

export const getAccountInfoSystem = (account  ) => {
    const { createDate, latestUpdate, updatedById, updatedByName, createdById, createdByName, assignedDate, assignedToId } = account;

    const createdByPerson = {
        fullName: createdByName,
        userId: createdById
    }

    const updatedByPerson = {
        fullName: updatedByName,
        userId: updatedById
    }

    const infomation_label = {
        color: '#657786',
        fontSize: '12px',
        fontWeight: '400'
    }

    const information_item = {
        color: '#006FB6',
        fontSize: '12px',
        fontWeight: '400'
    }

    return {
        title: 'Thông tin Hệ thống',
        values: [
            {
                key: 'Người tạo',
                value: (
                    <>
                        <span style={infomation_label}>{formatTimeDate(createDate)} bởi </span> 
                        <span style={information_item}>{createdByName}</span>
                    </>
                ),
            },
            {
                key: 'Người cập nhật',
                value: (
                    <>
                        <span style={infomation_label}>{formatTimeDate(latestUpdate)} bởi </span> &nbsp;
                        <span style={information_item}>{updatedByName}</span>
                    </>
                ),
            },
            {
                key: 'Ngày chăm sóc',
                value: (
                    <>
                        <span style={infomation_label}>{formatTimeDate(assignedDate)}</span> &nbsp;
                        {/* <span style={information_item}>{updatedByName}</span> */}
                    </>
                ),
            },
        ],
    };
};
