import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

// ui components
import { NormalButton } from '../../components/Buttons/Buttons';
import BriefInfo from '../../components/BriefInfo/BriefInfo';
import SectionHeaderFlex from './../../components/SectionHeaderFlex/SectionHeaderFlex';
import SideInfo from './SideInfo/SideInfo';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import BasicInput from '../../components/BasicInput/BasicInput';

// context
import { AccountContext } from '../../context/account';

// customer hooks
import { useFetch } from '../../hooks/useFetch';
import { useForm } from '../../hooks/useForm';

// utils
import { accountsApiEndpoint, apiUrl } from './../../utils/constants';
import { handleError } from '../../hooks/useFetch';
import { token } from './../../utils/token';

//axios
import { postAxios } from '../../utils/axios';

import MainContent from './MainContent/MainContent';

// reusable styles
import {
    whiteColor,
    lightPrimaryColor,
    darkGrayColor,
    buttonGrayColor,
    secondaryLightColor,
} from './../../styles/colors';

// local styles
import { getClasses } from './styles';
import { pushHistory } from '../../utils/helpers';

const FIELDS = {
    mobile: { value: '' },
};
// LeadDetails component (main)
export default function AccountDetails() {
    const { id } = useParams();
    const history = useHistory();
    const { error, data, loading } = useFetch(`${accountsApiEndpoint}/${id}`);
    const { getAccount } = React.useContext(AccountContext);

    const [toggledModal, setToggledModal] = React.useState(false);
    const [errorModal, setErrorModal] = React.useState({ value: '', isDisplay: false });
    const showErrorModal = React.useCallback((err) => setErrorModal({ value: err, isDisplay: true }), []);

    const [isDeleting, setIsDeleting] = React.useState(false);

    const { fields, handleInputChange } = useForm(FIELDS);

    // getAccount
    React.useEffect(() => {
        if (data) {
            getAccount(data);
        } else {
            getAccount(null);
        }
    }, [data, getAccount]);

    // delete account
    React.useEffect(() => {
        if (isDeleting) {
            // POST request using fetch inside useEffect React hook
            const requestOptions = {
                method: 'DELETE',
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            };
            fetch(`${apiUrl}${accountsApiEndpoint}/${data?.accountId}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data.accountId) {
                        history.push('/accounts');
                    } else {
                        handleError(data.message);
                    }
                    setIsDeleting(false);
                })
                .catch((error) => {
                    handleError(error);
                });
        }
    }, [data?.accountId, history, isDeleting]);


    const { sectionContainer, headerContainer, btnGroup, content } = getClasses();

    const deleteAccount = () => {
        if (fields.mobile.value === data.mobile) {
            fields.mobile.value = '';
            setIsDeleting(true);
        } else {
            showErrorModal('SĐT không đúng');
            return;
        }
    };

    const closeErrorModal = React.useCallback(() => setErrorModal({ value: '', isDisplay: false }), []);

    return (
        <div className={sectionContainer}>
            <div className={headerContainer}>
                <SectionHeaderFlex
                    boxCss={{ backgroundColor: lightPrimaryColor }}
                    subText={data?.isCompany ? 'Công ty' : 'Cá nhân'}
                    mainText={data?.customerId}
                    sectionHeaderCss={{
                        backgroundColor: whiteColor,
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                >
                    <div className={btnGroup}>
                        <NormalButton
                            text="Gọi điện thoại"
                            btnCss={{
                                color: darkGrayColor,
                                backgroundColor: buttonGrayColor,
                                marginRight: 8,
                                border: 'none',
                            }}
                            onClick={() => {
                                if (data) {
                                    const url = `/accounts/${data.accountId}/call`;
                                    postAxios(url, null, response => {
                                        if (response && response.message) {
                                            setErrorModal({ value: response.message, isDisplay: true });
                                        }
                                    });
                                }
                            }}
                        />
                        <NormalButton
                            text="Chỉnh sửa"
                            btnCss={{
                                color: darkGrayColor,
                                backgroundColor: buttonGrayColor,
                                marginRight: 8,
                                border: 'none',
                            }}
                            onClick={event => pushHistory(history, `${accountsApiEndpoint}/${data?.accountId}/edit`, null, event.ctrlKey)}
                        />
                        <NormalButton
                            text="Xóa"
                            btnCss={{
                                backgroundColor: buttonGrayColor,
                                color: darkGrayColor,
                                marginRight: 8,
                                border: 'none',
                            }}
                            onClick={() => setToggledModal(true)}
                        />
                    </div>
                </SectionHeaderFlex>
                <BriefInfo data={data} nvcs={{ userName: data?.assignedToName, userId: data?.assignedToId }} />
            </div>

            {loading && <Spinner size={SpinnerSize.large} />}

            {data && !loading ? (
                <>
                    <div className={content}>
                        <MainContent />
                        <SideInfo path={accountsApiEndpoint} />
                    </div>
                </>
            ) : (
                    <p style={{ textAlign: 'center', color: 'red' }}>{error?.message}</p>
                )}

            <ErrorModal
                message={errorModal.value}
                isModalOpen={errorModal.isDisplay}
                width={640}
                hideModal={() => {
                    closeErrorModal();
                }}
            />

            <CustomModal
                title="Xóa Account"
                isModalOpen={toggledModal}
                buttonAction="Xóa"
                width={640}
                hideModal={() => {
                    setToggledModal(false);
                }}
                onClick={deleteAccount}
            >
                <BasicInput
                    label={`Nhập SĐT của Account để xóa: ${data?.mobile}`}
                    labelCss={{ color: secondaryLightColor }}
                    formControlCss={{ width: '100%' }}
                    name="mobile"
                    onChange={handleInputChange}
                    value={fields.mobile.value}
                />
            </CustomModal>
        </div>
    );
}
