import * as React from 'react';
import { Nav } from 'office-ui-fabric-react/lib/Nav';
import { useHistory } from 'react-router-dom';

const MenuBar = (props) => {
    let history = useHistory();

    const iconStyle = {
        root: {
            fontSize: '22px',
            width: 40,
            color: '#e4e8ed'
        }
    };

    const navLinkGroups = [
        {
            links: [
                {
                    key: '1',
                    name: 'Thu gọn',
                    iconProps: {
                        iconName: 'ChevronLeftSmall', styles: iconStyle
                    },
                    onClick: props.toogleMenu
                },
                {
                    key: '2',
                    name: 'Leads',
                    //url: '/leads',
                    onClick: () => { history.replace('/leads') },
                    iconProps: {
                        iconName: 'AccountManagement', styles: iconStyle
                    },
                },
                {
                    key: '3',
                    name: 'Tài khoản',
                    onClick: () => { history.push('/contacts') },
                    iconProps: {
                        iconName: 'ContactLink', styles: iconStyle
                    },
                },
                {
                    key: '4',
                    name: 'Khách hàng',
                    onClick: () => { history.push('/accounts') },
                    iconProps: {
                        iconName: 'AccountBrowser', styles: iconStyle
                    },
                },
                {
                    key: '4.1',
                    name: 'Hóa đơn',
                    onClick: () => { history.push('/invoices') },
                    iconProps: {
                        iconName: 'M365InvoicingLogo', styles: iconStyle
                    },
                },
                {
                    key: '5',
                    name: 'Báo cáo',
                    onClick: () => { history.push('/reports') },
                    iconProps: {
                        iconName: 'ReportLibraryMirrored', styles: iconStyle
                    },
                    //links: [
                    //    {
                    //        key: '5.1',
                    //        name: 'Doanh thu khách hàng so sánh tháng',
                    //        onClick: () => { history.push('/report/customer-revenue') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.2',
                    //        name: 'Doanh thu nhân viên tổng hợp',
                    //        onClick: () => { history.push('/report/user-revenue-briefs') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.3',
                    //        name: 'Dịch vụ sắp hết hạn',
                    //        onClick: () => { history.push('/report/expiration-services') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.4',
                    //        name: 'Hóa đơn chưa thanh toán tổng hợp',
                    //        onClick: () => { history.push('/report/user-invoice-unpaid-briefs') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.5',
                    //        name: 'Doanh thu nhân viên theo ngày',
                    //        onClick: () => { history.push('/report/user-revenue-briefs-by-date') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.6',
                    //        name: 'Doanh thu nhân viên so sáng tháng',
                    //        onClick: () => { history.push('/report/user-revenue') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.7',
                    //        name: 'Doanh thu khách hàng',
                    //        onClick: () => { history.push('/report/user-revenues-by-customer') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.8',
                    //        name: 'Khách hàng theo nhân viên',
                    //        onClick: () => { history.push('/report/user-contact-briefs') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //    {
                    //        key: '5.9',
                    //        name: 'Doanh thu dịch vụ theo nhân viên tổng hợp',
                    //        onClick: () => { history.push('/report/user-revenues-by-products') },
                    //        iconProps: {
                    //            iconName: 'ReportDocument', styles: iconStyle
                    //        },
                    //    },
                    //],
                },
                {
                    key: '6',
                    name: 'Phân quyền',
                    iconProps: {
                        iconName: 'Permissions', styles: iconStyle
                    },
                    links: [
                        {
                            key: '6.1',
                            name: 'Nhân viên',
                            onClick: () => { history.push('/staffs') },
                            iconProps: {
                                iconName: 'People', styles: iconStyle
                            },
                        },
                        {
                            key: '6.2',
                            name: 'Nhóm',
                            onClick: () => { history.push('/roles') },
                            iconProps: {
                                iconName: 'Group', styles: iconStyle
                            },
                        },
                        {
                            key: '6.3',
                            name: 'Chức năng',
                            onClick: () => { history.push('/functions') },
                            iconProps: {
                                iconName: 'CheckList', styles: iconStyle
                            },
                        },
                        {
                            key: '6.4',
                            name: 'Nhóm chức năng',
                            onClick: () => { history.push('/groups') },
                            iconProps: {
                                iconName: 'AccountBrowser', styles: iconStyle
                            },
                        }
                    ],
                },
            ],
        },
    ];

    const render = () => {
        const navStyles = {
            root: {
                width: props.width,
                height: props.height,
                boxSizing: 'border-box',
                border: '1px solid #eee',
                overflowY: 'auto',
                marginTop: '64px',
                backgroundColor: '#27517b',
                transition: 'width .5s ease'
            },
            linkText: {
                height: '60px',
                lineHeight: '60px',
                color: '#e4e8ed',
                fontSize: '16px'
            },
            link: {
                backgroundColor: '#27517b',
                color: '#e4e8ed',
                height: '60px',
                selectors: {
                    '.ms-Nav-compositeLink:hover &': {
                        color: '#e4e8ed',
                        backgroundColor: '#3b4144',
                    },
                },
            },
            chevronButton: {
                display: 'none'
            }
        };

        return (
            <Nav styles={navStyles} groups={navLinkGroups} />
        );
    }

    return render();
};

export default MenuBar;
