import React from 'react';
import { useHistory } from 'react-router-dom';
import { LeadContext } from '../../../context/lead';
import CustomDetailView from '../../../components/Table/CustomDetailView'
import { getAxios } from '../../../utils/axios';

import styles from './styles.module.css'
import { pushHistory } from '../../../utils/helpers';
import { formatDate, thirtyDaysAgo, aYearAgo } from '../../../utils/date';

const getApi = async (url, param) => {
    return new Promise((res, rej) => {
        getAxios(url, param, (response) => {
            if (response) {
                if (response.status == 404)
                    return res(null)
                if (response.message)
                    return rej(response.message)
                return res(response.data)
            }
            rej('Error')
        })
    })
}
const getInvoices = async (id) => {
    return getApi(`contacts/${id}/invoices`, { pageSize: 6, beginDate: formatDate(aYearAgo), endDate: formatDate(new Date()), dateKey: '1', dateType: 'createDate' })
}

const Invoice = React.memo(() => {
    let history = useHistory();
    const { lead: leadData } = React.useContext(LeadContext);

    const onClick = (action, value, item, event) => {
        switch (action) {
            case 'invoice':
                pushHistory(history, '/invoicedetail', { code: value }, event.ctrlKey);
                break;
            default:
                break;
        }
    }

    const onAllClick = (e) => {
        pushHistory(history,`/invoices/leads/${leadData.contactId}`,{ 
            beginDate: formatDate(aYearAgo),
            endDate: formatDate(new Date()),
            dateKey: '1',
            dateType: 'createDate',
            fullName:leadData.title,
            originalId:leadData.leadId
        },e.ctrlKey)
    }

    const column = [
        {
            key: 'code',
            fieldName: 'code',
            name: 'Mã hóa đơn',
            minWidth: 200,
            maxWidth: 200,
            isResizable: true,
            render: { type: 'link', key: 'invoice', onClick: onClick }
        },
        {
            key: 'createDate',
            fieldName: 'createDate',
            name: 'Ngày tạo',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'paymentDate',
            fieldName: 'paymentDate',
            name: 'Ngày thanh toán',
            minWidth: 140,
            maxWidth: 140,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'amount',
            fieldName: 'amount',
            name: 'Số tiền',
            minWidth: 120,
            maxWidth: 120,
            isResizable: true,
            render: { type: 'money' }
        },
        {
            key: 'assignedToId',
            fieldName: 'assignedToId',
            name: 'Thụ hưởng',
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            render: { type: 'assignedId' }
        }
    ];

    const [loading, setLoading] = React.useState(true)
    const [gridData, setGridData] = React.useState({
        error: '', items: [], page: 0, pageCount: 1, total: 0
    })

    React.useEffect(() => {
        if (leadData) {
            setLoading(false)
            getInvoices(leadData.contactId).then(data => {
                const items = data?.items
                if (+items?.length) {
                    setGridData(x => ({ ...x, items, total: data.total }))
                }
                else {
                    setLoading(false)
                    setGridData({ error: 'Không có dữ liệu', total: 0 })
                }
                    
            }).catch(error => {
                setLoading(false)
                setGridData({ error, total: 0 })
            })
        }
    }, [leadData?.contactId])

    let upto = gridData.total
    if (upto > 6) {
        upto = '6+'
    }

    return <div className={styles.subbox}>
        <div className={styles.subbox__header}>
            <p>Hóa đơn ({upto})</p>
        </div>
        <div style={{ margin: '0 -16px 0 -16px' }}>
            <CustomDetailView
                loggedId={localStorage.getItem('userId')}
                loading={loading}
                columns={column}
                gridData={gridData}
            />
        </div>
        {gridData.total>0 && <>
        <div className={styles.subbox__showmore}>
            <p onClick={onAllClick} className={styles.link + ' ' + styles['link--showmore']}>Xem tất cả</p>
        </div>
        </>}
    </div>
})

export default Invoice