/**
 * api
 */
export const apiUrl = process.env.REACT_APP_API_ENDPOINT;
export const leadsApiEndpoint = '/leads';
export const leadReasonsApiEndpoint = '/lead-reasons';
export const accountsApiEndpoint = '/accounts';
export const contactsApiEndpoint = '/contacts';
export const usersApiEndpoint = '/users';
export const userGroupsApiEndpoint = '/user-groups';
export const userRolesApiEndpoint = '/user-roles';
export const postsApiEndpoint = '/posts';
export const invoicesApiEndpoint = '/invoices';
export const attchmentsApiEndpoint = '/attachments';
export const feedsApiEndpoint = '/feeds';
export const activityTypesApiEndpoint = '/activity-types';
export const mediaTypeApiEndpoint = '/media-types'
export const sourcesApiEndpoint = '/sources';
export const contactsApiEndpoind = '/contacts'
export const channelsApiEndpoint = '/channels';
export const productsApiEndpoint = '/products';

/**
 * status
 */
export const statuses = [
    {
        key: '',
        text: 'Tất cả',
        value: '',
        name: 'status',
    },
    {
        key: '151',
        text: 'Mới',
        value: '151',
        name: 'status',
    },
    {
        key: '152',
        text: 'Đang liên hệ',
        value: '152',
        name: 'status',
    },
    {
        key: '154',
        text: 'Mua ngay',
        value: '154',
        name: 'status',
    },
    {
        key: '155',
        text: 'Mua sau',
        value: '155',
        name: 'status',
    },
    {
        key: '159',
        text: 'Thất bại',
        value: '159',
        name: 'status',
    },
    {
        key: '153',
        text: 'Được chuyển đổi',
        value: '153',
        name: 'status',
    },
];

export const sources = [
    {
        key: '0',
        text: 'Tất cả',
        value: '0',
        name: 'sourceId',
    },
    {
        key: '1',
        text: 'Muaban.net',
        value: '1',
        name: 'sourceId',
    },
    {
        key: '2',
        text: 'Mogi.vn',
        value: '2',
        name: 'sourceId',
    },
    {
        key: '3',
        text: 'Vieclam.net',
        value: '3',
        name: 'sourceId',
    },
    {
        key: '4',
        text: 'MuaOto.net',
        value: '4',
        name: 'sourceId',
    },
    {
        key: '5',
        text: 'MBPay.vn',
        value: '5',
        name: 'sourceId',
    },
    {
        key: '6',
        text: 'AdBooking',
        value: '6',
        name: 'sourceId',
    },
    {
        key: '8',
        text: 'BNet',
        value: '8',
        name: 'sourceId',
    },
];

export const isAgentOptions = [
    { key: '', text: 'Tất cả' },
    { key: 'true', text: 'Đại lý' },
    { key: 'false', text: 'Thường' },
]

export const staffOptions = [
    { key: '', text: 'Tất cả' },
    { key: 'me', text: 'Của tôi' },
    { key: 'group', text: 'Của nhóm' },
    { key: 'other', text: 'Của nhân viên chăm sóc khác' },
]

export const isPaidOptions = [
    { key: '', text: 'Tất cả' },
    { key: 'true', text: 'Đã thanh toán' },
    { key: 'false', text: 'Chưa thanh toán' },
]

export const dateTypeOptions = [
    { key: 'createDate', text: 'Ngày tạo' },
    { key: 'paymentDate', text: 'Ngày thanh toán' },
]

export const apps = [
    {
        key: '0',
        text: 'Không có',
        value: '0',
        name: 'appId'
    },
    { 
        key: '1',
        text: 'Muaban.net',
        value: '1',
        name: 'appId',
    },
    {
        key: '2',
        text: 'Mogi.vn',
        value: '2',
        name: 'appId'
    },
    {
        key: '5',
        text: 'MBPay.vn',
        value: '5',
        name: 'appId'
    },
    {
        key: '6',
        text: 'AdBooking',
        value: '6',
        name: 'appId'
    },
    {
        key: '8',
        text: 'BNet',
        value: '8',
        name: 'appId'
    }
]