import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// ui components
import BasicInput from '../../components/BasicInput/BasicInput';
import SectionHeaderFlex from './../../components/SectionHeaderFlex/SectionHeaderFlex';
import { FuiPrimaryButton } from '../../components/Buttons/Buttons';
import DateSelect from '../../components/DateSelect/DateSelect';

// utils
import { apiUrl, contactsApiEndpoint } from '../../utils/constants';
import { token } from '../../utils/token';

// hooks
import { useForm } from './../../hooks/useForm';
import { handleError } from './../../hooks/useFetch';

// styles
import {
    whiteColor,
    contactDecoColor,
    secondaryLightColor,
    darkGrayColor,
    buttonGrayColor,
} from './../../styles/colors';

import { getFormHeaderClasses, getFormClasses, getClasses, errField, normalField, datePickerErrStyles } from './styles';

// define initial form values
const FIELDS = {
    contactId: { value: '' },
    name: { value: '' },
    mobile: { value: '' },
    email: { value: '' },
    description: { value: '' },
    accountId: { value: '' },
    isAgent: { value: false },
    appId: { value: 1 },
    appUserId: { value: '' },
    birthDate: { value: '' },
};

function FormHeader({ text }) {
    const { headerDeco, headerText } = getFormHeaderClasses();
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <span className={headerDeco}></span>
            <h3 className={headerText}>{text}</h3>
        </div>
    );
}

export default function ContactEditAdd() {
    const history = useHistory();
    const id = useParams()?.id;

    const { wrapper, formGroup, formGroupItem, form, formContainer, btnGroup } = getFormClasses();
    const { sectionContainer } = getClasses();

    //  states
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [contact, setContact] = React.useState(null);
    const [contactFormState, setContactFormState] = React.useState(FIELDS);
    const [errors, setErrors] = React.useState({});

    // useForm is called here
    const { fields, handleInputChange, handleBirthDateChange } = useForm(contactFormState);

    // initialize api var based on editing or creating
    let heading = '';
    let path = '';
    let method = '';

    if (id) {
        heading = `Chỉnh sửa ${contact?.name}`;
        path = `${contactsApiEndpoint}/${contact?.contactId}`;
        method = 'PUT';
    } else {
        heading = 'Thêm mới contact';
        path = `${contactsApiEndpoint}`;
        method = 'POST';
    }

    // get contact
    React.useEffect(() => {
        if (id) {
            fetch(`${apiUrl}${contactsApiEndpoint}/${id}`, {
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
            })
                .then((res) => res.json())
                .then((data) => {
                    setContact(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [id]);

    // bind data to edit
    React.useEffect(() => {
        if (contact) {
            setContactFormState({
                ...contactFormState,
                contacId: { value: id },
                mobile: { value: contact.mobile },
                name: { value: contact.name },
                email: { value: contact.email },
                birthDate: { value: new Date(contact.birthDate) },
                description: { value: contact.description },
                accountId: { value: contact.accountId.value },
                isAgent: { value: contact.isAgent.value },
                appId: { value: contact.appId.value },
                appUserId: { value: contact.appUserId?.value },
            });

            fields.mobile.value = contact.mobile;
            fields.email.value = contact.email;
            fields.name.value = contact.name;
            fields.birthDate.value = new Date(contact.birthDate);
            fields.description.value = contact.description;
            fields.accountId.value = contact.accountId;
            fields.isAgent.value = contact.isAgent;
            fields.appId.value = contact.appId;
            fields.appUserId.value = contact.appUserId;
        }
    }, [contact]);

    // Perfom API call
    React.useEffect(() => {
        if (isUpdating) {
            let bodyOptions = {};
            if (id) {
                bodyOptions = {
                    contactId: id,
                    mobile: fields.mobile.value || '',
                    email: fields.email.value || '',
                    name: fields.name.value || '',
                    birthDate: new Date(fields.birthDate.value),
                    description: fields.description.value || '',
                    accountId: fields.accountId.value,
                    isAgent: fields.isAgent.value,
                    appId: fields.appId.value,
                    appUserId: fields.appUserId.value,
                };
            } else {
                bodyOptions = {
                    mobile: fields.mobile.value || '',
                    email: fields.email.value || '',
                    name: fields.name.value || '',
                    birthDate: new Date(fields.birthDate.value),
                    description: fields.description.value || '',
                };
            }
            // POST/PUT request using fetch inside useEffect React hook
            const requestOptions = {
                method: method,
                headers: new Headers({ 'Content-Type': 'application/json', Authorization: `Bearer ${token}` }),
                body: JSON.stringify(bodyOptions),
            };

            fetch(`${apiUrl}${path}`, requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    if (data?.contactId) {
                        resetForm();
                        setErrors({});
                        history.push('/contacts');
                    } else {
                        if (data?.code.includes('mobile')) {
                            setErrors({ errMobile: data?.message });
                        } else if (data?.code.includes('email')) {
                            setErrors({ errEmail: data?.message });
                        } else if (data?.code.includes('birthDate')) {
                            setErrors({ errBirth: data?.message });
                        } else if (data?.code.includes('name')) {
                            setErrors({ errName: data?.message });
                        } else {
                            setErrors(handleError(data));
                        }
                    }
                    setIsUpdating(false);
                })
                .catch((error) => {
                    setError(handleError(error));
                    setIsUpdating(false);
                });
        }
    }, [isUpdating]);

    // Save
    const handleSubmit = (e) => {
        e.preventDefault();
        setIsUpdating(true);
    };

    // go back
    const goBack = () => {
        history.goBack();
    };

    const resetForm = () => {
        fields.name.value = '';
        fields.email.value = '';
        fields.mobile.value = '';
        fields.birthDate.value = '';
        fields.description.value = '';
    };

    return (
        <div className={sectionContainer}>
            <SectionHeaderFlex
                boxCss={{ backgroundColor: contactDecoColor }}
                subText="Contact"
                mainText={heading}
                sectionHeaderCss={{ backgroundColor: whiteColor }}
            />
            <div className={formContainer}>
                <form className={form}>
                    <div style={{ display: 'flex', gap: 8, flexDirection: 'column' }}>
                        <FormHeader text="Thông tin Contact" />
                        <div className={`${wrapper} ${formGroup}`}>
                            <div className={formGroupItem}>
                                <BasicInput
                                    label="Họ & Tên"
                                    labelCss={{ color: secondaryLightColor }}
                                    onChange={handleInputChange}
                                    value={fields.name.value}
                                    name="name"
                                    borderless
                                    style={{
                                        border: errors?.errName ? errField : normalField,
                                    }}
                                />
                                {errors.errName && <p style={{ color: 'red', fontSize: 14 }}>{errors.errName}</p>}
                            </div>
                            <div className={formGroupItem}>
                                <BasicInput
                                    label="Số điện thoại"
                                    labelCss={{ color: secondaryLightColor }}
                                    onChange={handleInputChange}
                                    value={fields.mobile.value}
                                    name="mobile"
                                    borderless
                                    style={{ border: errors?.errMobile ? errField : normalField }}
                                />
                                {errors.errMobile && <p style={{ color: 'red' }}>{errors.errMobile}</p>}
                            </div>
                            <div className={formGroupItem}>
                                <BasicInput
                                    label="Email"
                                    labelCss={{ color: secondaryLightColor }}
                                    onChange={handleInputChange}
                                    value={fields.email.value}
                                    name="email"
                                    borderless
                                    style={{
                                        border: errors?.errEmail ? errField : normalField,
                                    }}
                                />
                                {errors.errEmail && <p style={{ color: 'red' }}>{errors.errEmail}</p>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <FormHeader text="Thông tin bổ sung" />
                        <div className={`${wrapper} ${formGroup}`}>
                            <div className={formGroupItem}>
                                <DateSelect
                                    label={<span style={{ color: secondaryLightColor }}>Ngày sinh</span>}
                                    value={fields.birthDate.value}
                                    onSelectDate={handleBirthDateChange}
                                    width={316}
                                    borderless={errors?.errBirth}
                                    styles={errors?.errBirth ? datePickerErrStyles : ''}
                                />
                                {errors.errBirth && <p style={{ color: 'red' }}>{errors.errBirth}</p>}
                            </div>
                        </div>
                    </div>
                    <div>
                        <FormHeader text="Thông tin mô tả" />
                        <div className={`${wrapper} ${formGroup}`}>
                            <BasicInput
                                label="Mô tả"
                                labelCss={{ color: secondaryLightColor }}
                                inputCss={{
                                    backgroundColor: whiteColor,
                                    width: 652,
                                    height: 120,
                                }}
                                multiline
                                rows={6}
                                onChange={handleInputChange}
                                value={fields.description.value}
                                name="description"
                            />
                        </div>
                    </div>

                    <div style={{ textAlign: 'center', fontSize: 14 }}>
                        {error && <p style={{ color: 'red' }}>{error?.message}</p>}
                    </div>

                    <div className={btnGroup}>
                        <FuiPrimaryButton
                            text="Hủy"
                            btnCss={{
                                backgroundColor: buttonGrayColor,
                                color: darkGrayColor,
                                border: 'none',
                                marginRight: 16,
                                padding: '8px 12px',
                            }}
                            onClick={goBack}
                        />
                        <FuiPrimaryButton
                            text="Lưu"
                            btnCss={{
                                backgroundColor: secondaryLightColor,
                                color: whiteColor,
                                border: 'none',
                                padding: '8px 24px',
                            }}
                            onClick={handleSubmit}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
}
