import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Env from './domain/Setting/Env';
import AuthenticateError from './domain/Setting/AuthenticateError';
import { getAwaitAxios } from './utils/axios';

const renderApp = () => {
    const path = window.location.pathname;

    const getToken = async () => {
        await getAwaitAxios('users/token', null, (response) => {
            if (response.data) {
                localStorage.setItem('AuthToken', response.data.token);
                localStorage.setItem('roleId', response.data.roleId);
                localStorage.setItem('functionCodes', response.data.functionCodes);
                ReactDOM.render(<App />, document.getElementById('root'));
            }
            else {
                ReactDOM.render(<AuthenticateError mes={response.message} />, document.getElementById('root'));
            }
        });
    }

    if (path.startsWith('/env')) {
        ReactDOM.render(<Env />, document.getElementById('root'));
        return;
    }

    if (path.startsWith('/authenticateError')) {
        ReactDOM.render(<AuthenticateError />, document.getElementById('root'));
        return;
    }

    if (!path.startsWith('/auth/')) {
        const token = localStorage.getItem('AuthToken');
        if (token === null || token.length === 0) {
            const returnURL = `${window.location.origin}/auth`;
            window.location.href = `${process.env.REACT_APP_OAUTH_ENDPOINT}?returnURL=${returnURL}`;
        }
        else {
            (async () => { await getToken() })();
            return;
        };
    }

    ReactDOM.render(<App />, document.getElementById('root'));
}

renderApp();
reportWebVitals();
