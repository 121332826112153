import React from 'react';
import { useId } from '@uifabric/react-hooks';
import {
  Modal,
  IconButton,
} from 'office-ui-fabric-react';
import { CustomButton } from '../../components/BasicInput/CustomInput';

/*
maxWidth
maxHeight
maxWidth
maxHeight

message
isModalOpen
hideModal
*/

export default function ErrorModal (props) {
  const titleId = useId('title');

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isModalOpen}
        onDismiss={props.hideModal}
        isBlocking={false}
        styles={{
            main:{
                borderRadius:12
            },
        }}
      >
        <div style={{ height: '70px', textAlign: "center", lineHeight: '70px' }}>
            <span style={{ fontSize: 20, fontWeight: 500 }}>Thông báo</span>
            <IconButton iconProps={{ iconName: 'CalculatorMultiply' }} style={{ position: "absolute", right:0, margin: '15px', height: '40px', width: '40px', border: '1px solid #ccc', borderRadius: '50%' }} 
                onClick={props.hideModal} />
        </div>
        
        <div style={{borderTop: '1px solid #D0D6E0', height:1}} ></div>
        
        <div style={{padding:16, maxWidth: props.maxWidth, maxHeight: props.maxHeight,
          width: props.width, height: props.height,
          overflow: 'auto', textAlign:'center'}}>
          {props.message}
        </div>

        <div style={{textAlign:'right', padding:16}}>
            <CustomButton label="Đóng" onClick={props.hideModal} />
        </div>

      </Modal>
    </div>
  );
};