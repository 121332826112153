import React from 'react';
import { DefaultButton } from 'office-ui-fabric-react';


const volume0Icon = { iconName: 'FilterSolid' };
const volume3Icon = { iconName: 'Filter' };

const FilterButton = props => {
    const styles = {
        root: { height: 36, width: 36, minWidth: 36, marginLeft: 12 }
    };

    return (
        <DefaultButton
            styles={styles}
            iconProps={props.value ? volume0Icon : volume3Icon}
            onClick={() => props.onClick(!props.value)}
        />
    );
};

export default FilterButton;
