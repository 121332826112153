import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import { CustomDropdown, CustomTextField } from '../../components/BasicInput/CustomInput';
import { getAxios, getFile } from '../../utils/axios';
import { formatDateDMY } from '../../utils/date';
import DateFilterForm from './../../components/DateFilter/DateFilterForm';
import FilterButton from '../../components/Buttons/FilterButon';
import FilterPanel from './../../components/FilterPanel/FilterPanel';
import StaffFilter from './../../components/StaffFilter/StaffFilter';
import { PrimaryButton } from 'office-ui-fabric-react';
import { DownloadForm } from '../../components/DownloadForm';

const PAGE_TITLE = 'Báo cáo doanh thu nhân viên tổng hợp'

const staffList = () => {
    let tempStaffType = [{ key: '1', text: 'Tôi chăm sóc' }];
    const functionCodes = localStorage.getItem("functionCodes");

    if (functionCodes && functionCodes.length > 0) {
        const functionCodeArr = functionCodes.split(',');
        if (functionCodeArr.indexOf("Reports_Full") != -1) {
            tempStaffType.push(
                {
                    key: '',
                    text: 'Tất cả',
                },
                {
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles`,
                        key: 'roleNode',
                        text: 'name'
                    }
                },
                {
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: 'users',
                        key: 'userId',
                        text: 'userName'
                    }
                }
            );
        }

        const hasChildRole = localStorage.getItem('hasChildRole');
        if (hasChildRole != '0' && functionCodeArr.indexOf("Reports_Read_Family") != -1) {
            if (!tempStaffType.find(x => x.key == '2')) {
                tempStaffType.push({
                    key: '2',
                    text: 'Nhóm',
                    data: {
                        url: `user-roles/${localStorage.getItem('roleId')}/children`,
                        key: 'roleNode',
                        text: 'name',
                    }
                });
            };

            if (!tempStaffType.find(x => x.key == '3')) {
                tempStaffType.push({
                    key: '3',
                    text: 'Nhân viên khác',
                    data: {
                        url: `users/${localStorage.getItem('userId')}/children`,
                        key: 'userId',
                        text: 'userName'
                    }
                });
            };
        }
    }

    return tempStaffType.sort((a, b) => (a.key > b.key) ? 1 : ((b.key > a.key) ? -1 : 0));
}

const UserRevenueBriefs = () => {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isFirstLoad, setIsFirstLoad] = React.useState(false);
    const [roleList, setRoleList] = React.useState([]);
    const [chanelList, setChanelList] = React.useState([]);

    const [staffFilter, setStaffFilter] = React.useState(
        locParams ? { key: locParams.filterKey, value: locParams.filterValue } : { key: '', value: '' });

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [filter, setFilter] = React.useState(locParams && locParams.toogleFilter ? { value: true, panelWidth: '300px', contentWidth: '320px' } : { value: false, panelWidth: '0', contentWidth: '4px' });
    const [channelId, setChannelId] = React.useState(locParams ? locParams.channelId : '');
    const [date, setDate] = React.useState(
        locParams ? { beginDate: locParams.beginDate, endDate: locParams.endDate, key: locParams.dateKey } : { beginDate: "", endDate: "", key: "5" }
    );

    const [columns, setColumns] = React.useState([]);

    //init
    React.useEffect(() => {
        if (!locParams) {
            setIsFirstLoad(true);
        }
    }, []);

    React.useEffect(() => {
        getAxios('channels', null, response => {
            if (response.data && response.data) {
                const chanelList = response.data.map(x => ({ key: x.channelId, text: x.name }));
                chanelList.unshift({ key: '', text: 'Tất cả' });
                setChanelList(chanelList);
            }
        });

        if (locParams) {
            const page = locParams.page - 1;
            post(page, true);
        } else {
            post(0, true);
        }
    }, [isFirstLoad]);


    //function
    const post = (page, firstLoad) => {
        if (firstLoad) {
            if (date.key.length != 0 && (date.beginDate.length == 0 || date.endDate.length == 0)) {
                return false;
            }
        }

        setIsLoading(true);

        const params = {
            channelId: channelId,
            page: page + 1,
            pageSize: pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            filterKey: staffFilter.key,
            filterValue: staffFilter.value
        };

        let url = "reports/user-revenue-briefs";
        if (staffFilter.key == "1") {
            params.userId = localStorage.getItem('userId');
        }
        else if (staffFilter.key == "2") {
            params.roleNode = staffFilter.value;
        }
        else {
            params.userId = staffFilter.value;
        }

        if (!firstLoad) {
            pushHistory(history, '/report/user-revenue-briefs', params);
        }

        getAxios(url, params, response => {
            setIsLoading(false);
            var newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data;
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setColumns(customClolumn(params.channelId));
            setGridData(newGridData);
        });
    };

    const toogleFilter = (value) => {
        if (value) {
            setFilter({ value: true, panelWidth: '300px', contentWidth: '320px' })
        } else {
            setFilter({ value: false, panelWidth: '0px', contentWidth: '4px' })
        }
    }

    const customClolumn = (channelId) => {
        if (channelId != '') {
            return [
                { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
                { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 400, maxWidth: 400, isResizable: true },
                {
                    name: 'Tổng', key: 'Group', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                        { name: 'Số lượng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                        { name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                    ]
                }
            ]
        }
        else {
            return [
                { name: '#', key: '#', minWidth: 50, maxWidth: 50, isResizable: true, render: { type: 'index' } },
                { name: 'Nhân viên', key: 'userName', fieldName: 'userName', minWidth: 250, maxWidth: 250, isResizable: true },
                // {
                //     name: 'Báo giấy HCM', key: 'Group1', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                //         { name: 'Số lượng', key: 'total1', fieldName: 'total1', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                //         { name: 'Số tiền', key: 'amount1', fieldName: 'amount1', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                //     ]
                // },
                // {
                //     name: 'Báo giấy HN', key: 'Group2', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                //         { name: 'Số lượng', key: 'total2', fieldName: 'total2', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                //         { name: 'Số tiền', key: 'amount2', fieldName: 'amount2', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                //     ]
                // },
                {
                    name: 'Muaban.net', key: 'Group6', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                        { name: 'Số lượng', key: 'total6', fieldName: 'total6', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                        { name: 'Số tiền', key: 'amount6', fieldName: 'amount6', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                    ]
                },
                {
                    name: 'Mogi.vn', key: 'Group10', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                        { name: 'Số lượng', key: 'total10', fieldName: 'total10', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                        { name: 'Số tiền', key: 'amount10', fieldName: 'amount10', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                    ]
                },
                {
                    name: 'Tổng', key: 'Group', fieldName: '', minWidth: 300, maxWidth: 300, render: { type: 'group' }, subColumns: [
                        { name: 'Số lượng', key: 'total', fieldName: 'total', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                        { name: 'Số tiền', key: 'amount', fieldName: 'amount', minWidth: 150, maxWidth: 150, isResizable: true, render: { type: 'money' } },
                    ]
                }
            ];
        }
    }

    let onExportClick=null
    if (gridData.items?.length>0)
        onExportClick = ()=>{

        const params = {
            channelId: channelId,
            page: gridData.page + 1,
            pageSize: pageSize,
            beginDate: date.beginDate,
            endDate: date.endDate,
            dateKey: date.key,
            filterKey: staffFilter.key,
            filterValue: staffFilter.value
        };

        if (staffFilter.key == "1") {
            params.userId = localStorage.getItem('userId');
        }
        else if (staffFilter.key == "2") {
            params.roleNode = staffFilter.value;
        }
        else {
            params.userId = staffFilter.value;
        }

        getFile('/reports/user-revenue-briefs/excel', params, response => {
            const filename = `${PAGE_TITLE} (${formatDateDMY(new Date())}).xlsx`
            DownloadForm(response.data,filename)
        });
    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <FilterPanel
                width={filter.panelWidth}
                onClose={() => toogleFilter(false)}
                onClick={() => post(0, false)}>
                <StaffFilter data={staffFilter} typeList={staffList()} width={250} onChange={data => setStaffFilter(data)} />

                <div style={{ paddingBottom: '10px' }}>
                    <CustomDropdown
                        label="Kênh đăng"
                        width={250}
                        height={350}
                        options={chanelList}
                        value={channelId}
                        onChange={setChannelId}
                    />
                </div>

                <DateFilterForm value={date} width={250} onChange={(date) => setDate(date)} />
            </FilterPanel>

            <div style={{ display: "block", width: `calc(100% - ${filter.contentWidth})`, height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 100px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: PAGE_TITLE }}
                            width={"100%"}
                            onExportClick={onExportClick}
                        />
                    </div>
                    <div style={{ height: "100%", width: '100px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <FilterButton value={filter.value} onClick={toogleFilter} />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        isReport={true}
                        loading={isLoading}
                        columns={columns}
                        gridData={gridData}
                        onPageChange={page => post(page)}
                        fixedMode />
                </div>
            </div>
        </div >
    );
}
export default UserRevenueBriefs;
