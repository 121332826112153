import React from 'react';
import { useHistory } from 'react-router-dom';

// components
import CustomDetailView from '../../../components/Table/CustomDetailView';
import MoreLink from '../../../components/MoreLink/MoreLink';

// hooks
import { useFetch } from '../../../hooks/useFetch';

// utils
import { accountsApiEndpoint, invoicesApiEndpoint } from '../../../utils/constants';
import { formatMoney, pushHistory } from '../../../utils/helpers';
import { formatTimeDate, formatDate , thirtyDaysAgo, aYearAgo } from '../../../utils/date';

// local styles
import { getClasses } from './styles';
import { AccountContext } from '../../../context/account';
const { container, heading, } = getClasses();

export default function Invoices({ accountId }) {

    const history = useHistory();
    const path = `page=1&pageSize=6&beginDate=${formatDate(aYearAgo)}&endDate=${formatDate(new Date())}&dateType=createDate`;

    const { error, data, loading } = useFetch(`${accountsApiEndpoint}/${accountId}${invoicesApiEndpoint}?${path}`);

    const onClick = (action, value, item, event) => {
        switch (action) {
            case 'invoice':
                pushHistory(history, '/invoicedetail', { code: value }, event.ctrlKey);
                break;
            default:
                alert('cliked');
        }
    }

    const columns = [
        {
            key: 'code',
            fieldName: 'code',
            name: 'Mã Hóa đơn',
            fieldName: 'code',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            render: { type: 'link', key: 'invoice', onClick: onClick }
        },
        {
            key: 'createDate',
            fieldName: 'createDate',
            name: 'Ngày tạo',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.createDate);
            },
        },
        {
            key: 'paymentDate',
            fieldName: 'paymentDate',
            name: 'Ngày thanh toán',
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return formatTimeDate(item.paymentDate);
            },
        },
        {
            key: 'amount',
            fieldName: 'amount',
            name: 'Số tiền',
            minWidth: 80,
            isResizable: true,
            onRender: (item) => {
                return formatMoney(item.amount);
            },
        },
        {
            key: 'assignedToId',
            fieldName: 'assignedToId',
            name: 'Thụ hưởng',
            minWidth: 90,
            maxWidth: 90,
            isResizable: true,
            render: { type: 'assignedId' }
        }
    ];

    let upto = data?.total;
    if (upto){
        if (upto > 6){
            upto = '6+'
        }
    }
    else upto ='0'

    const { account } = React.useContext(AccountContext);

    const onMoreClick = (e)=>{
        pushHistory(history, `/invoices/accounts/${account.accountId}`, {
            beginDate: formatDate(aYearAgo),
            endDate: formatDate(new Date()),
            dateKey: '1',
            fullName: account.name,
            originalId: account.accountId
        }, e.ctrlKey)
    }

    return (
        <div
            style={{
                flex: 1,
                flexDirection: 'column',
                gap: 16,
                display: 'flex',
                marginTop: 16,
            }}
        >
            <div className={container}>
                <h4 className={heading} > {`Hóa đơn (${upto})`}</h4>
                {/* <h4 className={heading}>{`Hóa đơn (${data?.total} +)`}</h4> */}
                {data && (
                    <CustomDetailView
                        loggedId={localStorage.getItem('userId')}
                        loading={loading}
                        columns={columns}
                        gridData={{
                            error: error ? error?.message : '',
                            items: data ? data.items : [],
                        }}
                    />
                )}

                {error && <p style={{ textAlign: 'center', margin: '16px 0', fontSize: 14 }}>{error.message}</p>}

                {data && <MoreLink
                    moreClassName={{ marginTop: 16 }}
                    onClick={onMoreClick}
                />}
            </div>
        </div>
    );
}
