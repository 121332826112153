import { mergeStyleSets } from '@fluentui/react';

import { secondaryLightColor } from '../../../styles/colors';

export const getDetailItemClasses = () => {
    return mergeStyleSets({
        blueText: {
            color: secondaryLightColor,
        },
    });
};
