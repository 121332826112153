import React from 'react';
import { useHistory, useLocation, useParams, Switch, Route, useRouteMatch } from 'react-router-dom';
import SectionHeader from './../../components/SectionHeader/SectionHeader';
import CustomDetailView from './../../components/Table/CustomDetailView';
import { pushHistory, getParams } from '../../utils/helpers';
import SearchInput from '../../components/SearchInput/SearchInput';
import { getAxios,deleteAxios } from '../../utils/axios';
import CustomModal from '../../components/Modal/Modal';
import ErrorModal from '../../components/Modal/ErrorModal';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import BasicInput from '../../components/BasicInput/BasicInput';
import GroupFunction from './GroupFunction';

// hooks
import { useForm } from '../../hooks/useForm';
import { handleInitalResponse, handleError } from '../../hooks/useFetch';

// utils
import { token } from '../../utils/token';
import { apiUrl, userGroupsApiEndpoint } from '../../utils/constants';
import {secondaryLightColor} from '../../styles/colors';
import { requiredValidation } from '../../utils/validation'

export default function Group() {
    let { path } = useRouteMatch();

    return (<>
        <Switch>
            <Route exact path={path}>
                <ListGroup />
            </Route>
            <Route path={`${path}/:groupId/edit`}>
                <UpdateGroup />
            </Route>
            <Route path={`${path}/:groupId/functions`}>
                <GroupFunction />
            </Route>
        </Switch>
    </>)
}

function UpdateGroup() {
    const { groupId } = useParams()
    return <span>Update groupId: {groupId}</span>
}

// initial form field to update or add new group
const FIELDS = {
    name: { 
        value: '',
        validations: [requiredValidation('Vui lòng nhập tên nhóm chức năng')],

    },
};

function ListGroup() {
    //router
    let history = useHistory();
    const location = useLocation();
    const locParams = getParams(location);

    const [isLoading, setIsLoading] = React.useState(false);
    const [pageSize] = React.useState(20);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    const [keyword, setKeyword] = React.useState(locParams ? locParams.keyword : '');

    const [actionObject, setActionObject] = React.useState({})

    const [groupModalDelete, setGroupModalDelete] = React.useState({
        isToggle: false,
        value: '',
    })

    const [errorModal,setErrorModal] = React.useState({value:'',isDisplay:false})
    const ShowErrorModal = React.useCallback((err)=>setErrorModal({value:err,isDisplay:true}),[])
    const CloseErrorModal = React.useCallback(()=>setErrorModal({value:'',isDisplay:false}),[])

    // state to manage edit and add group within a modal
    const [toggledModal, setToggledModal] = React.useState(false);
    const [isUpdating, setIsUpdating] = React.useState(false);
    const [modalState, setModalState] = React.useState({
        title: '',
        handleClick: () => {},
        type: 'PUT',
        buttonText: 'Lưu',
    });

    
    // errors to handle form
    const [errors, setErrors] = React.useState({});

    // useForm is called here to update the form fields every time a form field is changed
    const { fields, handleInputChange } = useForm(FIELDS);

    //init
    React.useEffect(() => {
        const page = locParams ? locParams.page - 1 : 0;
        post(page, true);
    }, []);

    //function
    const onAddClick = () => {
    resetForm();
     setModalState({
            title: 'Thêm mới nhóm chức năng',
            type: 'POST',
            handleClick: () => {
            if (fields.name.value.trim() === '') {
                setErrors({ errName: 'Vui lòng nhập tên nhóm chức năng' })
                return;
            }
                setIsUpdating(true);
            },
            buttonText: 'Lưu',
        });
        setToggledModal(true);
    };

    const post = (page, firstLoad) => {

        setIsLoading(true);

        const params = {
            keyword: keyword?.trim(),
            page: page + 1,
            pageSize: pageSize,
        };

        if (!firstLoad) {
            pushHistory(history, location.pathname, params);
        }

        getAxios('user-groups', params, (response) => {
            setIsLoading(false);
            var newGridData = { error: '', items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.items;

                    var pageCount = response.data.total / pageSize;
                    if (response.data.total % pageSize !== 0) pageCount = Math.floor(pageCount) + 1;

                    newGridData.page = page;
                    newGridData.pageCount = pageCount;
                } else if (response.message) {
                    newGridData.error = response.message;
                }
            }

            setGridData(newGridData);
        });
    };

  // calling api to PUT , POST a group
    React.useEffect(() => {
        if (isUpdating) {
            if (fields.name.errors?.length > 0 ) {
                setErrors({ errName: fields.name.errors.map((err) => err) });
                setIsUpdating(false);
                return;
            }

            // confif extra path for url to cal api
            let extraPath = '';
            if (modalState.type === 'PUT') {
                extraPath = `${actionObject?.groupId}`;
            } else if (modalState.type === 'POST') {
                extraPath = ``;
            }

            const configRequestOptions = () => {
                return {
                    method: modalState.type,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    }),
                    body: JSON.stringify({
                        name: fields.name.value,
                    }),
                };
            };

            fetch(`${apiUrl}${userGroupsApiEndpoint}/${extraPath}`, configRequestOptions())
                .then(handleInitalResponse)
                .then((data) => {
                    if (data.status === 'successful') {
                        setToggledModal(false);
                        setErrors({});
                        post(0);
                        resetForm();
                    } else if (data.code.includes('name')) {
                          ShowErrorModal(handleError(data).message);
                    } else {
                        ShowErrorModal(handleError(data).message);
                    }
                })
                // catch unexpected errors
                .catch((error) => {
                    ShowErrorModal(handleError(error).message);
                });

            setIsUpdating(false);
        }
    }, [isUpdating]);


    const toogleModal = (setFn) =>
        setFn(prev => ({ ...prev, isToggle: !prev.isToggle }))

    const actionClick = (action, id, item, event) => {
        switch (action) {
             case 'update':
                setActionObject(item);
                setModalState({
                    title: 'Chỉnh sửa nhóm chức năng',
                    type: 'PUT',
                    handleClick: () => setIsUpdating(true),
                    buttonText: 'Lưu',
                });
                setToggledModal(true);
                fields.name.value = item.name;
                break;
            case "update":
                pushHistory(history, `${location.pathname}/${item.groupId}/edit`, null, event.ctrlKey);
                break
            case "delete":
                setActionObject(item)
                setGroupModalDelete({value:'',isToggle:true})
                break
            case 'list':
                pushHistory(history, `groups/${item.groupId}/functions`, null, event.ctrlKey);
                break;

        }
    }

    
    // reset form after editng or adding
    const resetForm = () => {
        fields.name.value = '';
    };

    //options
    const actionItems = [
        {
            key: 'update',
            text: 'Chỉnh sửa',
            icon: 'Edit',
            onClick: actionClick,
        },
        {
            key: 'delete',
            text: 'Xóa',
            icon: 'Cancel',
            onClick: actionClick,
        },
         {
            key: 'list',
            text: 'Danh sách chức năng',
            icon: 'link',
            onClick: actionClick,
        },
    ];


    const groupColumns = [
        {
            key: 'groupId',
            fieldName: 'groupId',
            name: 'Mã nhóm',
            minWidth: 72,
            maxWidth: 72,
            isResizable: false,
        },
        {
            key: 'name',
            name: 'Tên nhóm chức năng',
            fieldName: 'name',
            minWidth: 240,
            isResizable: true,
        },
        {
            key: 'createDate',
            name: 'Ngày tạo',
            fieldName: 'createDate',
            minWidth: 400,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'latestUpdate',
            name: 'Ngày cập nhật',
            fieldName: 'latestUpdate',
            minWidth: 140,
            isResizable: true,
            render: { type: 'datetime' }
        },
        {
            key: 'functionId',
            fieldName: 'groupId',
            minWidth: 100,
            render: { type: 'button', text: 'Chức năng', icon: '', items: actionItems },
        },
    ]

    const onGroupDelete = () => {
        if (parseInt(groupModalDelete.value.trim()) !== actionObject.groupId) {
             // ShowErrorModal('Mã nhóm quyền không chính xác');
            setErrors({ errDelete: 'Mã nhóm chức năng không chính xác' });
            return;
        }
        deleteAxios('user-groups/'+actionObject.groupId,null,(response)=>{
            if (response){
                if (response.message){
                    setErrors({ errDelete: response.message });
                    return;
                }
                // return ShowErrorModal(response.message)

                toogleModal(setGroupModalDelete)
                setErrors({});

                let nextPage = gridData.page
                if (gridData.items.length-1 === 0 && nextPage > 0)
                    nextPage--
                post(nextPage)
            }
        })

    }

    //render
    return (
        <div style={{ display: 'block', width: '100%', height: '100%' }}>
            <ErrorModal message={errorModal.value} isModalOpen={errorModal.isDisplay} width={640}
                hideModal={CloseErrorModal}/>
                
            <CustomModal title='Xóa Nhóm chức năng' isModalOpen={groupModalDelete.isToggle}
                hideModal={() => {
                    toogleModal(setGroupModalDelete)
                    setErrors({})
                }}
                buttonAction="Xóa" width={640}
                onClick={onGroupDelete} >
            <TextField 
                styles={{
                    subComponentStyles: { label: { root: { color: '#006FB6' } } },
                    fieldGroup: {
                        border: '1px solid #D0D6E0',
                        borderRadius: 6
                    }
                }}  
                onChange={(e, value) => setGroupModalDelete({ ...groupModalDelete, value })}
                errorMessage={errors.errDelete}
                value={groupModalDelete.value}
                label={`Nhập Mã nhóm chức năng: ${actionObject.groupId} để xóa`} />
            </CustomModal>

            <div style={{ display: "block", width: "100%", height: "100%", backgroundColor: "white", borderRadius: "10px", float: "left" }}>
                <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                    <div style={{ width: "calc(100% - 500px)", height: "100%" }}>
                        <SectionHeader
                            title={{ text: "Nhóm chức năng" }}
                            width={"100%"}
                            addButton={{ text: "Tạo mới", onClick: onAddClick }} />
                    </div>
                    <div style={{ height: "100%", width: '500px', padding: "17px", display: 'flex', flexDirection: "row-reverse" }}>
                        <SearchInput
                            width={250}
                            height={36}
                            placeholder="Tìm kiếm trong nhóm này"
                            value={keyword}
                            onChange={setKeyword}
                            onEnter={() => post(0)}
                        />
                    </div>
                </div>
                <div style={{ width: '100%', height: 'calc(100% - 70px)' }}>
                    <CustomDetailView
                        loading={isLoading}
                        columns={groupColumns}
                        gridData={gridData}
                        onPageChange={page => post(page)} />
                </div>
            </div>

             <CustomModal
                title={modalState.title}
                isModalOpen={toggledModal}
                buttonAction={modalState.buttonText}
                width={640}
                hideModal={() => {
                    setToggledModal(false);
                    setErrors({})
                }}
                onClick={modalState.handleClick}
            >
                <BasicInput
                    onChange={handleInputChange}
                    value={fields.name.value}
                    label={`Tên nhóm chức năng`}
                    name="name"
                    labelCss={{ color: secondaryLightColor }}
                    formControlCss={{width: '100%'}}
                    errorMessage={errors.errName}
                />
            </CustomModal>
        </div>
    );
}
