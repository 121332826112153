import React from 'react';

const AccountContext = React.createContext();

function AccountProvider({ children }) {
    const [account, setAccount] = React.useState(null);
    const [createdByPerson, setCreatedByPerson] = React.useState(null);
    const [updatedByPerson, setUpdatedByPerson] = React.useState(null);
    const [nvcs, setNVCS] = React.useState(null);

    // get lead
    const getAccount = (account) => {
        setAccount(account);
    };

    // get NVC
    const getNVCS = (nvcs) => {
        setNVCS(nvcs);
    };

    // getCreatedByPerson
    const getCreatedByPerson = (createdBy) => {
        setCreatedByPerson(createdBy);
    };

    // const getUpdatedByPerson
    const getUpdatedByPerson = (updatedBy) => {
        setUpdatedByPerson(updatedBy);
    };

    return (
        <AccountContext.Provider
            value={{
                account,
                getAccount,
                createdByPerson,
                getUpdatedByPerson,
                updatedByPerson,
                getCreatedByPerson,
                nvcs,
                getNVCS,
            }}
        >
            {children}
        </AccountContext.Provider>
    );
}

export { AccountProvider, AccountContext };
