import React from 'react';
import { Spinner } from '@fluentui/react';

import MoreLink from '../MoreLink/MoreLink';

import { useFetch } from '../../hooks/useFetch';

import { getClasses } from './styles';

import { formatTimeDate } from '../../utils/date';

export default function Attachments({
    fileListClassName,
    fileItemClassName,
    headingClassName,
    path = '',
    children,
    handleMoreLinkClick,
}) {
    const {
        container,
        heading,
        headingOptional,
        imgContainer,
        img,
        fileValues,
        fileItem,
        fileItemOptional,
        fileName,
        fileInfo,
        fileList,
        fileListOptional,
    } = getClasses({ fileListClassName, fileItemClassName, headingClassName });

    const qs = '?page=1&pageSize=6';

    const { data, error, loading } = useFetch(`${path}${qs}`);

    const files = data?.items || [];

    let upto = data?.total || '0'
    if (upto > 6)
    {upto = '6+'}

    return (
        <div className={container}>
            <header className={`${heading} ${headingOptional}`}>
                <h4>Tập tin ({upto})</h4>
                {children}
            </header>
            {files.length > 0 && (
                <div className={`${fileList} ${fileListOptional}`}>
                    {files.map((file) => {
                        return (
                            <div className={`${fileItem} ${fileItemOptional}`} key={file.attachmentId}>
                                <div className={imgContainer}>
                                    <img className={img} src={file.mediaUrl} alt="" />
                                </div>
                                <div className={fileInfo}>
                                    <p className={fileName}>{file.name}</p>
                                    <p> <span className={fileValues}> {formatTimeDate(file.latestUpdate)} bởi </span> <span className={fileName}>{file.createdByName}</span></p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            {loading && <Spinner />}
            {error && <p style={{ textAlign: 'center', margin: '16px 0', fontSize: 14 }}>Hiện tại chưa có tập tin nào</p>}

            {files.length > 0 && <MoreLink onClick={handleMoreLinkClick} />}
        </div>
    );
}
