import React from 'react';
import { getClasses } from './styles';

const MoreLink = ({ text = 'Xem tất cả', moreClassName, ...restProps }) => {
    const { more, moreOptional } = getClasses({ moreClassName });
    return (
        <div {...restProps} className={`${more} ${moreOptional}`}>
            {text}
        </div>
    );
};

export default MoreLink;
