import React from 'react';
import { NavLink, Route, useParams, Redirect } from 'react-router-dom';

import Details from '../Details/Details';
import Activities from '../Activities/Activities';
import ServicedClassifieds from '../ServicedClassifieds/ServicedClassifieds';

import { getClasses } from './styles';
import Invoice from '../Invoices';
/**
 * Details Component
 */
export default function MainContent({contactId}) {
    const { id } = useParams();
    const { mainContent, contentNav, navLink, navLinkActive } = getClasses();
    return (
        <div className={mainContent}>
            <nav className={contentNav}>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/leads/${id}/invoices`}>
                    Hóa đơn
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/leads/${id}/serviced-classifieds`}>
                    Dịch vụ
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/leads/${id}/details`}>
                    Chi tiết
                </NavLink>
                <NavLink className={navLink} activeClassName={navLinkActive} to={`/leads/${id}/activities`}>
                    Hoạt động
                </NavLink>
            </nav>
            <div>
                <Route path={`/leads/${id}/invoices`}>
                    <Invoice/>
                </Route>
                <Route path={`/leads/${id}/details`}>
                    <Details />
                </Route>
                <Route path={`/leads/${id}/activities`}>
                    <Activities id={id} />
                </Route>
                <Route path={`/leads/${id}/serviced-classifieds`}>
                    <ServicedClassifieds id={id} contactId ={contactId}/>
                </Route>
                <Route exact path={`/leads/${id}`}>
                    <Redirect to={{ pathname: `/leads/${id}/details` }} />
                </Route>
            </div>
        </div>
    );
}
