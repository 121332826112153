import React from 'react';
import { useLocation } from 'react-router-dom';
import SectionHeader from '../../components/SectionHeader/SectionHeader';
import CustomDetailView from '../../components/Table/CustomDetailView';
import { getParams } from '../../utils/helpers';
import { getAxios } from '../../utils/axios';

const InvoiceDetail = () => {
    // router
    const location = useLocation();
    const locParams = getParams(location);
    const [invoiceId] = React.useState(locParams.invoiceId);
    const [code] = React.useState(locParams.code);
    const [data, setData] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(true);
    const [gridData, setGridData] = React.useState({
        error: "",
        items: [],
        page: 0,
        pageCount: 1
    });

    // init
    React.useEffect(() => { post() }, []);

    // function
    const formatDatetime = (sDate) => {
        if (sDate == undefined) {
            return undefined;
        }
        else {
            const date = new Date(sDate);
            const year = date.getFullYear();
            if (year < 1900) {
                return (<span> - </span>)
            }

            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hour = date.getHours().toString().padStart(2, '0');
            const minute = date.getMinutes().toString().padStart(2, '0');
            //const second = date.getSeconds().toString().padStart(2, '0');

            return (<span style={{ width: "100%", textAlign: "center" }}>{day}/{month}/{year} {hour}:{minute}</span>)
        }
    }

    const post = () => {
        setIsLoading(true);
        const url = invoiceId ? `invoices/${invoiceId}` : `invoices/code/${code}`;
        getAxios(url, null, response => {
            let newGridData = { error: "", items: [], page: 0, pageCount: 1 };

            if (response) {
                if (response.data) {
                    newGridData.items = response.data.products;
                    setData(response.data);
                }
                else if (response.message) {
                    newGridData.error = response.message;
                }
            }
            setIsLoading(false);

            setGridData(newGridData);
        });
    };

    const formatMoney = (number) => {
        let x = Math.round(number).toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
        return (<span style={{ width: "100%", textAlign: "right", paddingLeft: '5px' }}>{x}</span>)
    }

    const columns = [
        { name: 'Dịch vụ', key: 'name', fieldName: 'name', minWidth: 250, maxWidth: 250, isResizable: true },
        {
            name: 'Số lượng', key: 'quantity', fieldName: 'quantity', minWidth: 200, maxWidth: 200, isResizable: true, render: { type: 'money' }
            , footer: { type: 'input', value: 'Giảm giá', style: { float: 'right' } }
        },
        {
            name: 'Thành tiền', key: 'amount', fieldName: 'amount', minWidth: 350, maxWidth:350, isResizable: true,
            render: { type: 'money' },
            footer: { type: 'input', value: formatMoney(-data.couponAmount), style: { float: 'right' } }
        }
    ];


    // render
    const render = () => {

        const leftPanelStyle = {
            width: '420px',
            float: 'left',
            borderRadius: '10px',
            backgroundColor: '#f7f8f9',
            paddingLeft: '20px',
            marginLeft: '16px'
        };

        const footerStyle = {
            width: '100%',
            height: '60px',
            display: 'inline-flex',
            lineHeight: '60px',
            padding: '0 16px',
            fontSize: '16px',
            fontWeight: 600,
            textAlign: 'right'
        }

        const rightPanelStyle = {
            width: '420px',
            float: 'right',
            borderRadius: '10px',
            paddingLeft: '20px',
            marginRight: '16px'
        };

        return (
            <div style={{ display: 'block', width: '100%', height: '100%' }}>
                <div style={{ display: "block", width: "900px", height: "100%", backgroundColor: "white", borderRadius: "10px" }}>
                    <div style={{ width: '100%', display: 'inline-flex', height: "70px" }}>
                        <SectionHeader
                            title={{ text: "Thông tin hóa đơn" }}
                            width={"100%"} />
                    </div>
                    <div style={{ width: '100%', height: "240px" }}>
                        <table style={leftPanelStyle}>
                            <tbody>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Mã hóa đơn</td>
                                    <td><b>{data.code}</b></td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Trạng thái</td>
                                    <td style={{ color: 'red' }}>{data.isPaid ? 'Đã thanh toán' : 'Chưa thanh toán'}</td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Người thụ hưởng</td>
                                    <td>{data.assignedToName}</td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Ngày tạo</td>
                                    <td>{formatDatetime(data.createDate)}</td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Ngày thanh toán</td>
                                    <td>{formatDatetime(data.paymentDate)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table style={rightPanelStyle}>
                            <tbody>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Tài khoản</td>
                                    <td>{data.contactName}</td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Mã khách hàng</td>
                                    <td>{data.customerId}</td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Ứng dụng</td>
                                    <td>{data.appName}</td>
                                </tr>
                                <tr style={{ height: '40px' }}>
                                    <td style={{ width: '150px' }}>Kênh</td>
                                    <td>{data.channelName}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style={{ width: '100%', maxHeight: 'calc(100% - 480px)' }}>
                        <CustomDetailView
                            loading={isLoading}
                            columns={columns}
                            gridData={gridData}
                            fixedMode
                        />
                    </div>
                    <div style={footerStyle}>
                        <div style={{ width: '450px' }}>TỔNG TIỀN</div>
                        <div style={{ width: 'calc(100% - 450px)', color: '#f57301', paddingRight: '16px' }}>{formatMoney(data.amount)} đ</div>
                    </div>
                </div>
            </div >
        );
    }

    return render();
}

export default InvoiceDetail;
