import React from 'react';
import { useId, useBoolean } from '@uifabric/react-hooks';
import {
  DefaultButton,
  Modal,
  IconButton,
} from 'office-ui-fabric-react';
import { CustomButton } from '../../components/BasicInput/CustomInput';

/*
title
maxWidth
maxHeight
maxWidth
maxHeight
isModalOpen
hideModal
buttonAction
onClick
*/

export default function (props) {
  const titleId = useId('title');

  return (
    <div>
      <Modal
        titleAriaId={titleId}
        isOpen={props.isModalOpen}
        onDismiss={props.hideModal}
        isBlocking={false}
        styles={{
            main:{
                borderRadius:12
            },
        }}
      >
        <div style={{ height: '70px', textAlign: "center", lineHeight: '70px' }}>
            <span style={{ fontSize: 20, fontWeight: 500 }}>{props.title}</span>
            <IconButton iconProps={{ iconName: 'CalculatorMultiply' }} style={{ position: "absolute", right:0, margin: '15px', height: '40px', width: '40px', border: '1px solid #ccc', borderRadius: '50%' }} 
                onClick={props.hideModal} />
        </div>
        
        <div style={{borderTop: '1px solid #D0D6E0', height:1}} ></div>
        
        <div style={{padding:16, maxWidth: props.maxWidth, maxHeight: props.maxHeight,
          width: props.width, height: props.height,
          overflow: 'auto'}}>
          {props.children}
        </div>

        <div style={{textAlign:'right', padding:16}}>
            <DefaultButton style={{marginRight: 16}} text="Hủy" onClick={props.hideModal} />
            <CustomButton label={props.buttonAction} onClick={props.onClick} />
        </div>

      </Modal>
    </div>
  );
};