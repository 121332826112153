import React from 'react';
import { SearchIcon } from './../Icons/Icons';
import { CustomTextField } from '../BasicInput/CustomInput';

const SearchInput = (props) => {
    const searchInput = {
        height: props.height ? props.height : 36,
        width: props.width ? props.width : 300,
        display: 'flex',
        alignItems: 'center',
        paddingRight: 5,
        border: props.borderless ? 'none' : '1px solid',
        paddingLeft: '12px'
    };

    const inputStyles = {
        wrapper: {
            borderLeft: '1px solid #bbb',
            marginLeft: '10px',
            width: 'calc(100 % - 50px)'
        }
    };

    const onKeyPress = (e) => {
        if (props.onEnter && e.charCode == 13) {
            props.onEnter();
        }
    }

    const onIconClick = (e) => {
        if (props.onIconClick) {
            props.onIconClick(e);
        }
    }

    return (
        <div style={{ ...searchInput }}>
            <SearchIcon css={{ cursor: 'pointer', marginRight: '0 5px' }} onClick={onIconClick} />
            <CustomTextField
                borderless={true}
                styles={inputStyles}
                placeholder={props.placeholder}
                value={props.value}
                onKeyPress={onKeyPress}
                onChange={props.onChange}
                width={props.width - 40}
            />
        </div >
    );
}

export default SearchInput;
