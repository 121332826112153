import React from 'react';
import { CustomDropdown } from '../BasicInput/CustomInput';
import DateSelect from '../DateSelect/DateSelect';

// date utils
import {
    formatDate,
    monthNames,
    yesterday,
    tenDaysAgo,
    firstDay,
    lastDay,
    firstDayLastMonth,
    lastDayLastMonth,
    firstDay2MonthsAgo,
    lastDay2MonthsAgo,
    thirtyDaysAgo,
} from '../../utils/date';

const dateOptions = [
    {
        key: '1',
        text: 'Từ... đến...',
        range: {
            beginDate: new Date(),
            endDate: new Date(),
        },
    },
    {
        key: '2',
        text: 'Hôm nay',
        range: {
            beginDate: new Date(),
            endDate: new Date(),
        },
    },
    {
        key: '3',
        text: 'Hôm qua',
        range: {
            beginDate: new Date(yesterday),
            endDate: new Date(yesterday),
        },
    },
    {
        key: '4',
        text: 'Trong vòng 10 ngày',
        range: {
            beginDate: new Date(tenDaysAgo),
            endDate: new Date(),
        },
    },
    {
        key: '5',
        text: 'Trong vòng 30 ngày',
        range: {
            beginDate: new Date(thirtyDaysAgo),
            endDate: new Date(),
        },
    },
    {
        key: '6',
        text: 'Tháng ' + (firstDay.getMonth() + 1) + '/' + firstDay.getFullYear(),
        range: {
            beginDate: firstDay,
            endDate: lastDay,
        },
    },
    {
        key: '7',
        text: 'Tháng ' + (firstDayLastMonth.getMonth() + 1) + '/' + firstDayLastMonth.getFullYear(),
        range: {
            beginDate: firstDayLastMonth,
            endDate: lastDayLastMonth,
        },
    },
    {
        key: '8',
        text: 'Tháng ' + (firstDay2MonthsAgo.getMonth() + 1) + '/' + firstDay2MonthsAgo.getFullYear(),
        range: {
            beginDate: firstDay2MonthsAgo,
            endDate: lastDay2MonthsAgo,
        },
    },
    //{
    //    key: '8',
    //    text: '3 tháng gần nhất',
    //    range: {
    //        beginDate: firstDay2MonthsAgo,
    //        endDate: new Date(),
    //    },
    //}
];

const DateFilterForm = (props) => {
    const width = props.width ? props.width : 300;
    const height = props.height ? props.height : 30;

    const [disabledDate, setDisabledDate] = React.useState(true);
    const [key, setKey] = React.useState(props.value.key);
    const [from, setFrom] = React.useState(props.value.beginDate != "" ? new Date(props.value.beginDate) : new Date());
    const [to, setTo] = React.useState(props.value.endDate != "" ? new Date(props.value.endDate) : new Date());

    React.useEffect(() => {
        onDateChange(props.value.key);
    }, []);

    // update new stats
    const onDateChange = (key) => {
        var item = dateOptions.find(x => x.key == key);
        if (item) {
            setKey(item.key);
            if (item.key === '1') {
                setFrom(props.value.beginDate != "" ? new Date(props.value.beginDate) : new Date());
                setTo(props.value.endDate != "" ? new Date(props.value.endDate) : new Date());
                setDisabledDate(false);
                dateChange(from, to, '1');
            } else {
                setDisabledDate(true);
                setFrom(item.range.beginDate);
                setTo(item.range.endDate);
                dateChange(item.range.beginDate, item.range.endDate, item.key);
            }
        }
    };

    const onSelectDateFrom = (date) => {
        setKey("1");
        setFrom(date);
        dateChange(date, to, "1");
    };

    const onSelectDateTo = (date) => {
        setKey("1");
        setTo(date);
        dateChange(from, date, "1");
    };

    const dateChange = (from, to, dateKey) => {
        if (props.onChange) {
            const fromDate = formatDate(from);
            const toDate = formatDate(to);
            props.onChange({ beginDate: fromDate, endDate: toDate, key: dateKey });
        }
    }

    const render = () => {
        return (
            <div style={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
                <div style={{ width: width, paddingBottom: '10px' }}>
                    <CustomDropdown
                        key="dateSelect"
                        label="Chọn ngày"
                        width={width}
                        value={key}
                        onChange={onDateChange}
                        options={dateOptions}
                        width={width} />
                </div>

                <div style={{ paddingBottom: '10px', width: width }}>
                    <DateSelect
                        key="dateFrom"
                        label="Từ"
                        onSelectDate={onSelectDateFrom}
                        value={from}
                        disabled={disabledDate}
                        width={width}
                        height={height} />
                </div>
                <div style={{ width: width }}>
                    <DateSelect
                        key="dateTo"
                        label="Đến"
                        onSelectDate={onSelectDateTo}
                        value={to}
                        disabled={disabledDate}
                        width={width}
                        height={height} />
                </div>
            </div>
        )
    }

    return render();
}

const areEqual = (prevProps, nextProps) => {
    var result = prevProps.value === nextProps.value;
    return result;
}

export default React.memo(DateFilterForm, areEqual);
