import React from 'react';
import { NavLink, Route, Redirect, useHistory } from 'react-router-dom';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react';

import BasicInput from '../../../components/BasicInput/BasicInput';
import Feeds from '../../../components/Feeds/Feeds';

import { FeedContext } from '../../LeadDetails/LeadDetails';

import { leadsApiEndpoint, feedsApiEndpoint } from '../../../utils/constants';

import { getClasses } from './styles';
import { pushHistory } from '../../../utils/helpers';

const { nav, navLink, navLinkActive, navLinkFitContent, container, formContainer, navFormContainer } = getClasses();

function FeedForm() {
    const { handleFeedUpdate, feedFields, errors, handleFeedInputChange } = React.useContext(FeedContext);

    return (
        <form style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
            <BasicInput
                label="Tiêu đề"
                formControlCss={{ width: '100%' }}
                name="title"
                errorMessage={errors.errTitle}
                onChange={handleFeedInputChange}
                value={feedFields.title.value}
            />
            <BasicInput
                label="Mô tả"
                formControlCss={{ width: '100%' }}
                name="description"
                rows={6}
                multiline
                errorMessage={errors.errDescription}
                onChange={handleFeedInputChange}
                value={feedFields.description.value}
            />
            <div style={{ alignSelf: 'flex-end' }}>
                <PrimaryButton text="Lưu" onClick={handleFeedUpdate} />
            </div>
        </form>
    );
}

//CRM-672: ẩn tab Email
// function EmailForm() {
//     const [toValue, setToValue] = React.useState('');

//     return (
//         <form style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
//             <BasicInput label="Từ" type="email" formControlCss={{ width: '100%' }} />
//             <BasicInput label="Đến" type="email" formControlCss={{ width: '100%' }} />
//             <BasicInput label="Bcc" value={toValue} onChange={(e) => setToValue(e.value)} formControlCss={{ width: '100%' }} />
//             <BasicInput label="Chủ đề" formControlCss={{ width: '100%' }} />
//             <div style={{ marginTop: '1rem' }}>
//                 <TextField multiline rows={6} />
//             </div>
//             <div style={{ alignSelf: 'flex-end' }}>
//                 <PrimaryButton text="Gửi" />
//             </div>
//         </form>
//     );
// }

export default function Activities({ id }) {
    const history = useHistory();
    const [clicked, setClicked] = React.useState(false);

    const lastLinkClicked = () => {
        if (clicked) {
            return;
        }

        setClicked(true);
    };
    const firstLinkClicked = () => {
        setClicked(false);
    };

    return (
        <>
            <div className={container}>
                <div className={navFormContainer}>
                    <nav className={nav}>
                        <NavLink
                            className={navLink}
                            onClick={firstLinkClicked}
                            activeClassName={navLinkActive}
                            to={`/leads/${id}/activities/call`}
                        >
                            Ghi chú
                        </NavLink>
                        {/* <NavLink
                            className={navLink}
                            activeClassName={navLinkActive}
                            onClick={lastLinkClicked}
                            to={`/leads/${id}/activities/email`}
                        >
                        </NavLink> */}
                        {clicked && <NavLink className={navLink} onClick={lastLinkClicked} to="#"></NavLink>}
                    </nav>

                    <div className={formContainer}>
                        <Route path={`/leads/${id}/activities/call`}>
                            <FeedForm />
                        </Route>
                        {/* <Route path={`/leads/${id}/activities/email`}>
                            <EmailForm />
                        </Route> */}
                        <Route exact path={`/leads/${id}/activities`}>
                            <Redirect to={{ pathname: `/leads/${id}/activities/call` }} />
                        </Route>
                    </div>
                </div>
                <Feeds
                    noteItemClassname={{ border: 'none' }}
                    path={`${leadsApiEndpoint}/${id}${feedsApiEndpoint}`}
                    handleMoreLinkClick={(event) => pushHistory(history, `/leads/${id}/feeds`, null, event.ctrlKey)}
                />
            </div>
        </>
    );
}
