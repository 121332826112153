import React from 'react';
import { mergeStyles } from 'office-ui-fabric-react/lib/Styling';
import { DetailsHeader, DetailsRow, CheckboxVisibility, DetailsList, SelectionMode, Selection } from 'office-ui-fabric-react/lib/DetailsList';
import { DetailsListLayoutMode, ProgressIndicator, Icon, DefaultButton, CommandButton } from '@fluentui/react';
import Paginator from '../../components/Paginator/Paginator';
import { whiteColor, middleGrayColor, borderGrayColor } from './../../styles/colors';
import SimplePagination from '../SimplePagination';
import { CheckIcon } from '../Icons/Icons';

const CustomDetailView = (props) => {
    // scroll to top when changing page
    const tableRef = React.useRef()
    React.useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollTop = 0;
            // tableRef.current.scrollTop = 1; using when active scroll up to top to return previous page
        }


    }, [props.gridData.page])

    const formatMobile = (value) => {
        if (value == undefined) {
            return value;
        } else {
            return (<span style={{ width: "100%", textAlign: "center" }}>{value}</span>)
        }
    }

    const formatBoolen = (value) => {
        if (value == undefined) {
            return value;
        }
        else if (value) {
            return <Icon iconName="CheckMark" style={{ marginLeft: "calc(50% - 12px)", fontSize: "16px", border: "1px solid", borderRadius: "50%" }} />
        }
        else {
            return undefined;
        }
    }

    const formatDate = (sDate) => {
        if (sDate == undefined) {
            return undefined;
        }
        else {
            var date = new Date(sDate);
            var year = date.getFullYear();
            if (year < 1900) {
                return (<span></span>)
            }

            var month = (date.getMonth() + 1).toString().padStart(2, '0');
            var day = date.getDate().toString().padStart(2, '0');

            return (<span style={{ width: "100%", textAlign: "center" }}>{day}/{month}/{year}</span>)
        }
    }

    const formatDatetime = (sDate) => {
        if (sDate == undefined) {
            return undefined;
        }
        else {
            var date = new Date(sDate);
            var year = date.getFullYear();
            if (year < 1900) {
                return (<span></span>)
            }

            var month = (date.getMonth() + 1).toString().padStart(2, '0');
            var day = date.getDate().toString().padStart(2, '0');
            var hour = date.getHours().toString().padStart(2, '0');
            var minute = date.getMinutes().toString().padStart(2, '0');
            var second = date.getSeconds().toString().padStart(2, '0');

            return (<span style={{ width: "100%", textAlign: "center" }}>{day}/{month}/{year} {hour}:{minute}</span>)
        }
    }

    const formatTimedate = (sDate) => {
        if (sDate == undefined) {
            return undefined;
        }
        else {
            var date = new Date(sDate);
            var year = date.getFullYear();
            if (year < 1900) {
                return (<span></span>)
            }

            var month = (date.getMonth() + 1).toString().padStart(2, '0');
            var day = date.getDate().toString().padStart(2, '0');
            var hour = date.getHours().toString().padStart(2, '0');
            var minute = date.getMinutes().toString().padStart(2, '0');
            var second = date.getSeconds().toString().padStart(2, '0');

            return (<span style={{ width: "100%", textAlign: "center" }}>{hour}:{minute} {day}/{month}/{year}</span>)
        }
    }

    const formatMoney = (number) => {
        let x = Math.round(number).toString();
        var pattern = /(-?\d+)(\d{3})/;
        while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
        return (<span style={{ width: "100%", textAlign: "right", paddingLeft: '5px' }}>{x}</span>)
    }

    const formatRate = (rate) => {
        let x = rate + '%';
        return (<span style={{ width: "100%", textAlign: "center", paddingLeft: '5px' }}>{x}</span>)
    }

    const formatButton = (value, rowItem, obj) => {
        if (obj.items) {

            let newItems = [];
            obj.items.forEach(item => {
                newItems.push(
                    {
                        key: item.key,
                        iconProps: { iconName: item.icon },
                        text: item.text,
                        onClick: (event) => item.onClick(item.key, value, rowItem, event)
                    });
            });

            const menuProps = {
                onDismiss: ev => {
                    if (ev && ev.shiftKey) {
                        ev.preventDefault();
                    }
                },
                items: newItems,
                directionalHintFixed: false,
                directionalHint: 'Left top edge',
                directionalHintForRTL: 'Left top edge',
            };

            return (
                <div style={{ width: '100%' }}>
                    <CommandButton
                        key={"button_" + value}
                        style={{ float: "right" }}
                        iconProps={{ iconName: obj.icon }}
                        menuProps={menuProps} />
                </div>
            );
        }
        else {
            return <DefaultButton
                text={obj.text}
                icon={obj.icon}
                onClick={(event) => obj.onClick(value, rowItem, event)} />
        }
    }

    const formatDropdown = (value, options) => {
        if (options) {
            const item = options.find(x => x.key == value);
            if (item) {
                return <span>{item.text}</span>;
            }
            else {
                return <span></span>;
            }
        } else {
            return <span></span>;
        }
    }

    const formatLink = (value, item, obj) => {
        if (value?.toString()) {
            return (
                <span
                    style={{ ...{ color: '#0078d4', cursor: "pointer" }, ...item[obj.styleField] }}
                    onClick={(e) => obj.onClick(obj.key, value, item, e)}>{value}
                </span>)
        } else {
            return <span></span>
        }
    }

    
    const formatIndex = (value) => {
        const page = props.gridData.page;
        return <span style={{ flexGrow: 1, textAlign: 'center' }}>{page * 20 + value + 1}</span>;
    }

    const formatAssignedToId = (value) => {
        if (value == props.loggedId) {
            return (<span style={{display: 'flex',width: '100%', justifyContent: 'center'}}><CheckIcon/></span>);
        }
        else {
            return null;
        }
    }
    const formatStyle = (value, item, obj) => {
        const style = item[obj.styleField];
        return <span style={style}>{value}</span>
    }

    const renderDetailsHeader = (detailsHeaderProps) => {
        const height = props.isReport ? '84px' : '42px';
        return (
            <DetailsHeader
                {...detailsHeaderProps}
                styles={{
                    root: {
                        height: height,
                        backgroundColor: `${middleGrayColor}`,
                        padding: 0,
                        '.ms_FocusZone': { padding: 0 },
                        '.ms-DetailsHeader-cell': {
                            display: 'inline-flex',
                            justifyContent: 'space-around',
                            background: middleGrayColor,
                            padding: 0,
                            border: `.3px solid ${borderGrayColor}`,
                            borderBottom: 'none',
                            height: height,
                            lineHeight: height
                        },
                        '.ms-DetailsHeader-cellTitle': {
                            alignItems: 'center',
                            height: height,
                            lineHeight: height,
                        }
                    }
                }}
                onRenderColumnHeaderTooltip={(columnProps) => renderDetailsHeaderItemColumn(columnProps)}
            />
        );
    };

    const renderDetailsFooter = (detailsFooterProps) => {
        return (<DetailsRow
            {...detailsFooterProps}
            columns={detailsFooterProps.columns}
            item={{}}
            itemIndex={-1}
            groupNestingDepth={detailsFooterProps.groupNestingDepth}
            selectionMode={SelectionMode.single}
            selection={detailsFooterProps.selection}
            onRenderItemColumn={(item, index, column) => renderDetailsFooterItemColumn(item, index, column)} />)
    }

    const renderDetailsFooterItemColumn = (item, index, column) => {
        var footer = props.columns.find(x => x.key == column.key).footer;
        var render = props.columns.find(x => x.key == column.key).render;

        if (!footer) return (<span></span>);
        else if (footer.type == "input") {
            return (<div style={{ fontSize: 14, fontWeight: 600, ...footer.style }}>{footer.value}</div>)
        }
        else if (footer == "sum") {
            var items = props.gridData.items;
            if (items.length == 0) {
                return (<div><b>0</b></div>)
            }
            else {
                var total = 0
                for (var i = 0, _len = items.length; i < _len; i++) {
                    total += items[i][column.fieldName];
                }

                if (render && render.type == "money")
                    return (<div style={{ float: 'right' }}><b>{formatMoney(total)}</b></div>)
                else
                    return (<div><b>{total}</b></div>)
            }
        }
        else {
            return undefined;
        }
    }

    const renderSubColumn = (props) => {
        const styles = {
            outline: 'transparent',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            boxSizing: 'border-box',
            overflow: 'hidden',
            padding: '0px 0px 0px 0px',
            textAlign: "center",
            fontWeight: 600,
            fontSize: '14px',
            height: props.subColumns ? '42px' : '82px',
            lineHeight: props.subColumns ? '42px' : '82px',
            width: props.maxWidth
        };

        if (props.subColumns) {
            return (
                <div style={{ width: props.maxWidth, textAlign: "center", height: '82px' }}>
                    <div style={{ ...styles, borderBottom: '1px solid #ddd' }}> {props.name} </div >
                    <div style={styles}>
                        {
                            props.subColumns.map(subColumn =>
                                <div key={subColumn.key} style={styles}>
                                    {subColumn.name}
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }
        else {
            return <div style={styles}>{props.name}</div>
        }
    }

    const renderSubData = (props, item) => {
        return (
            <div style={{ width: props.maxWidth, textAlign: "center", height: '42px', lineHeight: '42px', float: "left", display: "inline-flex" }}>
                {
                    props.subColumns.map(subColumn =>
                        <div key={subColumn.key} style={{ width: subColumn.maxWidth, textAlign: "center", height: '100%' }}>
                            {renderItemColumn(item, 0, subColumn)}
                        </div>
                    )
                }
            </div>
        )
    }

    const renderDetailsHeaderItemColumn = (detailsHeaderProps) => {
        const column = detailsHeaderProps.column;
        const styles = {
            outline: 'transparent',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            boxSizing: 'border-box',
            overflow: 'hidden',
            padding: '0px 0px 0px 0px',
            textAlign: "center",
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '42px',
        };
        if (props.isReport) {
            { return renderSubColumn(column) }
        }
        else {
            return <div style={styles}>{column.name}</div>
        }
    }

    const renderItemColumn = (item, index, column) => {
        var value = item[column.fieldName];
        var render = column.render;

        if (!render) {
            return <div>{value}</div>;
        }
        else {
            switch (render.type) {
                case 'group':
                    return renderSubData(column, item);
                case 'mobile':
                    return formatMobile(value);
                case 'date':
                    return formatDate(value);
                case 'datetime':
                    return formatDatetime(value);
                case 'timedate':
                    return formatTimedate(value);
                case 'checkbox':
                    return formatBoolen(value);
                case 'money':
                    return formatMoney(value);
                case 'button':
                    return formatButton(value, item, render);
                case 'dropdown':
                    return formatDropdown(value, render.options);
                case 'link':
                    return formatLink(value, item, render);
                case 'index':
                    return formatIndex(index)
                case 'custom':
                    return formatStyle(value, item, render);
                case 'rate':
                    return formatRate(value);
                case 'assignedId':
                    return formatAssignedToId(value);
                default:
                    return <div>{value}</div>;
            }
        }
    }

    const renderRow = (props) => {
        const customStyles = {};

        if (props) {
            if (props.itemIndex === 0) {
                customStyles.root = {
                    fontSize: 14,
                    background: whiteColor,
                    '.ms-DetailsRow-cell': {
                        borderTop: `.5px solid ${borderGrayColor}`,
                        borderBottom: `.5px solid ${borderGrayColor}`,
                        display: 'flex',
                        alignItems: 'center',
                    },
                };
            }

            if (props.itemIndex) {
                customStyles.root = {
                    fontSize: 14,
                    background: whiteColor,
                    '.ms-DetailsRow-cell': {
                        borderBottom: `.5px solid ${borderGrayColor}`,
                        display: 'flex',
                        alignItems: 'center',
                    },
                };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    const renderProgressIndicator = (loading) => {
        return (loading &&
            <div style={{ height: '30px' }}>
                <ProgressIndicator />
            </div>
        )
    }

    const renderError = (error) => {
        if (error && error.length > 0) {
            return <div style={{ whiteSpace: "pre-line", padding: '24px', color: 'red', fontSize: '18px' }}>{error}</div>
        }
    }
    
    const renderDetailView = (columns, items) => {

        if (items && items.length > 0) {
            const footerColumn = columns.find(x => x.footer);

            const ccl = mergeStyles({
                borderLeft: `1px solid ${borderGrayColor}`,
            });
            const ccr = mergeStyles({
                borderRight: `1px solid ${borderGrayColor}`,
            });

            const newColumns = props.columns.map((column, index) => {
                if (index === 0) {
                    return { ...column, className: ccl };
                } else if (index === columns.length - 1) {
                    return { ...column, className: ccr };
                } else {
                    return { ...column };
                }
            });

            let height = '100%';
            if (props.gridData.pageCount > 1 || props.dynamicPagination) {
                height = "calc(100% - 40px)";
            }

            const styles = {
                root: {
                    overflow: 'hidden',
                    width: 'max-content'
                }
            };

            

            return (
                <div style={{ width: "100%", maxHeight: '100%', overflow: 'auto'}}  ref={tableRef}>
                    <DetailsList
                        styles={styles}
                        columns={newColumns}
                        items={items}
                        selectionMode={SelectionMode.single}
                        layoutMode={props.fixedMode && props.fixedMode ? DetailsListLayoutMode.fixedColumns : DetailsListLayoutMode.justify}
                        checkboxVisibility={CheckboxVisibility.hidden}
                        onRenderItemColumn={renderItemColumn}
                        onRenderDetailsHeader={renderDetailsHeader}
                        onRenderDetailsFooter={footerColumn ? renderDetailsFooter : null}
                        onRenderRow={renderRow}
                    />
                    {renderPaginator(props.gridData.page, props.gridData.pageCount,props.onPageChange)}
                </div>)
        }   
    }


    // const [hitBotCount,setHitBotCount] = React.useState(-1);
    // const [hitTopCount,setHitTopCount] = React.useState(0);

    // const onScroll = (e) => {
    //     const {scrollTop, scrollHeight, clientHeight} = tableRef.current;
    //     console.log(scrollTop,hitTopCount)
    //     if (tableRef.current) {             
    //         if (scrollTop + clientHeight === scrollHeight)
    //         {
    //             if (hitBotCount <= 1)
    //             {
    //                 setHitBotCount(hitBotCount + 1);
    //                 tableRef.current.scrollTop = scrollTop - 1;
    //             }
    //             else if (props.gridData.items.length > 10)
    //             {
    //                 props.onPageChange(props.gridData.page + 1);
    //                 setHitBotCount(0);

    //             }
    //         }
    //         if (scrollTop === 0)
    //         {
    //             if (hitTopCount <= 1)
    //             {
    //                 setHitTopCount(hitTopCount +1);
                    
    //                 tableRef.current.scrollTop = 1;
    //             }
    //             else if (props.gridData.page > 1)
    //             {
    //                 props.onPageChange(props.gridData.page - 1);
    //                 setHitTopCount(0);
    //             }
    //         }
    //     } 
    // } 

    const renderPaginator = (page, pageCount, onPageChange) => {
        if (props.dynamicPagination) {
            return <div style={{width: '100%', textAlign: "center", lineHeight: '40px', overflow:'hidden'}}>
                <SimplePagination
                    currentPage={page}
                    isNext={props.gridData.isNext}
                    onChange={(page) => onPageChange(page)} />
            </div>
        }
        if (pageCount > 1) {
            return <div style={{width: '100%', textAlign: "center", lineHeight: '40px', overflow:'hidden'}}>
                <Paginator
                    page={page}
                    pageCount={pageCount}
                    onPageChange={(page) => onPageChange(page)} />
            </div>
        }
    }

    const render = () => {
        if (props && props.gridData && (props.gridData.error.length > 0 || props.gridData.items.length > 0)) {
            const isError = props.gridData.error && props.gridData.error.length > 0;
            return (
                <div style={{ width: '100%', height: '100%', padding: "0px 16px"}}>
                    {!isError && renderProgressIndicator(props.loading)}

                    {isError && renderError(props.gridData.error)}

                    {!isError && renderDetailView(props.columns, props.gridData.items)}
                </div>)
        }
        else {
            return (<div></div>);
        }
    }

    return render();
}

const areEqual = (prevProps, nextProps) => {
    var result = true;

    if (prevProps.gridData.error === nextProps.gridData.error && nextProps.gridData.error == '') {
        if (prevProps.gridData.items.length + nextProps.gridData.items.length != 0) {
            var prevItems = JSON.stringify(prevProps.gridData.items);
            var nextItems = JSON.stringify(nextProps.gridData.items);

            if (prevItems !== nextItems) {
                result = false;
            }
        }
        if (prevProps.columns !== nextProps.columns)
            result = false;
    }
    else {
        result = false;
    }

    if (result) {
        if ((prevProps.gridData.page !== nextProps.gridData.page) || (prevProps.gridData.pageCount !== nextProps.gridData.pageCount)) {
            result = false;
        }
    }

    return result;
}

export default React.memo(CustomDetailView, areEqual);
