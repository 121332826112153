import React from 'react';
import { handleError, initalState } from '../../hooks/useFetch';
import { apiUrl } from './../../utils/constants';
import { token } from './../../utils/token';

export function useFetchWithQS(path, passedQS, defaultQS, isReloading, con) {
    const [response, setResponse] = React.useState(initalState);

    React.useEffect(() => {
        if (con) {
            setResponse((prevState) => {
                return {
                    ...prevState,
                    loading: true,
                    error: null,
                };
            });

            fetch(`${apiUrl}${path}?${passedQS}${defaultQS}`, {
                headers: new Headers({ Authorization: `Bearer ${token}` }),
            })
                .then(async (res) => {
                    const data = await res.json()
                    if (res.ok) {
                        return data;                    
                    } else {
                       return Promise.reject(data);
                    }
                })
                .then(
                    data => {
                         setResponse({
                            data: data,
                            error: null,
                            loading: false,
                        });
                    }, 
                    error => {
                         setResponse((prevState) => {
                                return { ...prevState, loading: false, error: handleError(error) };
                        });                
                    }
                )
                .catch((error) => {
                        setResponse((prevState) => {
                            return { ...prevState, loading: false, error: handleError(error) };
                        });
                });
        }
    }, [path, passedQS, defaultQS, isReloading, con]);

    return response;
}
